import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { uuidv4 } from '@firebase/util';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { NotepadLine, NotepadLineSource } from 'src/app/models/notepad-line';
import { notepadActions } from 'src/app/store/actions/notepad.actions';
import { selectNotepadLines } from 'src/app/store/reducers/notepad.reducer';
import { AppState } from '../../store/reducers/index';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotepadComponent
  implements OnDestroy, AfterViewInit, AfterViewChecked
{
  public readonly lines$ = this.store.select(selectNotepadLines);
  private readonly destroyed$ = new Subject<void>();
  private readonly setLineContentActions: {
    elementId: string;
    content: string;
  }[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<AppState>
  ) {}

  public ngAfterViewInit(): void {
    this.setupSettingLineContent();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public ngAfterViewChecked(): void {
    if (this.setLineContentActions.length > 0) {
      for (const setLineContentAction of this.setLineContentActions) {
        this.setElementContent(
          setLineContentAction.elementId,
          setLineContentAction.content
        );
      }
      this.setLineContentActions.length = 0;
    }
  }

  public elementId(id: string): string {
    return `notepad-line-id-${id}`;
  }

  public deleteLine(id: NotepadLine['id']): void {
    const action = notepadActions.remove({ id });
    this.store.dispatch(action);
  }

  public addLine(): void {
    const id = uuidv4();
    const action = notepadActions.add({
      id,
      content: '',
      source: NotepadLineSource.manual,
    });
    this.store.dispatch(action);
  }

  public onContentChange(event: Event, id: NotepadLine['id']): void {
    const htmlElement = event.target as HTMLElement;

    const content = htmlElement.innerText;
    const normalizedContent = content.replace(/\s+/g, ' ');

    this.lines$
      .pipe(
        take(1),
        map((lines) => lines.find((l) => l.id === id))
      )
      .subscribe((line) => {
        if (normalizedContent !== line.content) {
          const action = notepadActions.update({
            id,
            content: normalizedContent,
          });
          this.store.dispatch(action);
        }
      });
  }

  public trackLine(_: number, line: NotepadLine): string {
    return line.id;
  }

  public iconToShow(line: { source: NotepadLineSource }): string {
    return line.source === NotepadLineSource.calculator ? 'calculate' : 'edit';
  }

  public isEditable(line: { source: NotepadLineSource }): boolean {
    return line.source === NotepadLineSource.manual;
  }

  private setupSettingLineContent(): void {
    this.lines$
      .pipe(
        takeUntil(this.destroyed$),
        startWith([] as NotepadLine[]),
        pairwise()
      )
      .subscribe(([previousLines, currentLines]) => {
        for (const currentLine of currentLines) {
          const previousLine = previousLines.find(
            (prevLine) => prevLine.id === currentLine.id
          );

          const isNew = !previousLine;
          const isUpdated =
            !isNew && currentLine.content !== previousLine.content;

          if (isNew || isUpdated) {
            const elementId = this.elementId(currentLine.id);
            // The DOM element that will receive the content of the line does not exist here yet.
            // Therefore it is needed to wait for the ChangeDetector to have done it's work so the DOM element does exist.
            // This can be done by listening to the AfterViewChecked event.
            this.setLineContentActions.push({
              elementId,
              content: currentLine.content,
            });
          }
        }
      });
  }

  private setElementContent(elementId: string, content: string): void {
    const htmlElement = document.getElementById(elementId);
    htmlElement.innerText = content;
    this.changeDetectorRef.markForCheck();
  }
}
