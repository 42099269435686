import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  UnitScoreClassName,
  UserUnitScoreMapped,
} from 'src/app/helpers/score-helper';
import { AppState } from 'src/app/store/reducers';
import {
  selectInteractionIndex,
  selectInteractionUnit,
  selectUnitContext,
} from 'src/app/store/reducers/interaction.reducer';
import {
  selectUnitTotalScoreToClass,
  selectUserUnitScoreToClassMap,
} from 'src/app/store/reducers/score.reducer';
import { UnitContext } from '../../../../shared/enums/unit-context';
import { Unit } from '../../../../shared/models/unit';

type UnitData = {
  unit: Unit;
  userUnitScoreMapped: UserUnitScoreMapped;
  activeInteractionIndices: [UnitContext, number];
};

@Component({
  selector: 'app-unit-location-tracker',
  templateUrl: './unit-location-tracker.component.html',
  styleUrls: ['./unit-location-tracker.component.scss'],
})
export class UnitLocationTrackerComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  unitData$: Observable<UnitData>;
  unitData: UnitData;

  unitContexts = UnitContext;

  renderUnitContexts = [
    UnitContext.introduction,
    UnitContext.extra,
    UnitContext.deepening,
    UnitContext.test,
  ];

  activeUnitContext$: Observable<UnitContext>;
  activeInteractionIndex$: Observable<number>;
  unitScoreClass$: Observable<UnitScoreClassName>;

  distanceBetweenCircles = 50;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.activeUnitContext$ = this.store.select(selectUnitContext);
    this.activeInteractionIndex$ = this.store.select(selectInteractionIndex);

    const unit$ = this.store.select(selectInteractionUnit);
    const userUnitScoreMapped$ = this.store.select(
      selectUserUnitScoreToClassMap
    );
    this.unitData$ = combineLatest([
      unit$,
      userUnitScoreMapped$,
      this.activeUnitContext$,
      this.activeInteractionIndex$,
    ]).pipe(
      filter(
        ([
          unit,
          userUnitScoreMapped,
          activeUnitContext,
          activeInteractionIndex,
        ]) => !!unit && !!userUnitScoreMapped
      ),
      map(
        ([
          unit,
          userUnitScoreMapped,
          activeUnitContext,
          activeInteractionIndex,
        ]) => ({
          unit,
          userUnitScoreMapped,
          activeInteractionIndices: [
            activeUnitContext,
            activeInteractionIndex,
          ] as [UnitContext, number],
        })
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.unitData$.pipe(takeUntil(this.ngDestroyed$)).subscribe((unitData) => {
      this.unitData = unitData;
    });

    this.unitScoreClass$ = this.store.select(selectUnitTotalScoreToClass);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  isActiveInteraction(
    unitContext: UnitContext,
    interactionIndex: number
  ): boolean {
    return (
      this.unitData?.activeInteractionIndices[0] === unitContext &&
      this.unitData?.activeInteractionIndices[1] === interactionIndex
    );
  }

  getUnitContextTransform(context: UnitContext) {
    let offset = 0;
    const introductionOffset = 210;
    const contextPadding = 10;
    for (const unitContext of this.renderUnitContexts) {
      if (unitContext === context) {
        break;
      }
      const length =
        this.unitData?.unit[this.unitContexts[unitContext]]?.length;
      offset += !!length ? length * this.distanceBetweenCircles : 0;
    }
    return (
      'translate(' +
      (offset +
        introductionOffset +
        this.renderUnitContexts.indexOf(context) * contextPadding) +
      ')'
    );
  }

  getScoreCircleTransform() {
    const { introduction, extra, deepening, test, subtype } =
      this.unitData.unit;
    const contextPadding = 10;
    const lastContextOffset =
      subtype === 'thematic' ? contextPadding * 2 : contextPadding * 5;
    const introductionOffset = 210;
    const totalLength =
      introduction.length + extra.length + deepening.length + test.length;
    const translateValue =
      totalLength * this.distanceBetweenCircles +
      introductionOffset +
      lastContextOffset;

    return 'translate(' + translateValue + ')';
  }

  calculateLineLength() {
    const { introduction, extra, deepening, test } = this.unitData.unit;
    const introductionOffset = 210;
    const extraOffset = 40;
    const totalLength =
      introduction.length + extra.length + deepening.length + test.length;
    return (
      totalLength * this.distanceBetweenCircles +
      introductionOffset +
      extraOffset
    );
  }
}
