<h1 mat-dialog-title>
    Kies rollen voor: {{ targetUser?.name }}
</h1>
<div mat-dialog-content>
    <mat-table [dataSource]="roles">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ displayCell(element) | titlecase }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has-role">
            <mat-header-cell *matHeaderCellDef>Actief</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ userHasRole(element) ? '✔' : '' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actie</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button *ngIf="!isProtected(element) && !userHasRole(element) && !roleIsBeingHandled(element)"
                    (click)="addRole(element)" mat-stroked-button color="primary">Kies</button>
                <button *ngIf="!isProtected(element) && userHasRole(element) && !roleIsBeingHandled(element)"
                    (click)="removeRole(element)" mat-stroked-button color="warn">Verwijder</button>
                <mat-spinner *ngIf="roleIsBeingHandled(element)" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </mat-cell>
        </ng-container>
        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</div>