import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { EntityType } from 'shared/enums/entity-type';
import { ArchiveScoresRequest } from 'shared/models/archive-scores-request';
import { Chapter } from 'shared/models/chapter';
import { Domain } from 'shared/models/domain';
import { Project } from 'shared/models/project';
import { DatabaseService } from 'src/app/services/database.service';
import { StructureService } from 'src/app/services/structure.service';
import { FormGroupModel } from 'src/app/types/form-group-model';
import { DialogComponent, DialogPreset } from '../dialog/dialog.component';

@Component({
  selector: 'app-delete-user-scores',
  templateUrl: './delete-user-scores.component.html',
  styleUrls: ['./delete-user-scores.component.scss'],
})
export class DeleteUserScoresComponent implements OnInit, OnDestroy {
  targetUid: string;
  title = 'Gebruiker scores wissen';

  confirmationText =
    'Weet je zeker dat je alle scores van deze gebruiker wilt wissen?';

  deleteScoresRequest: FormGroupModel<ArchiveScoresRequest>;
  projects$: Observable<Project[]>;
  domains$: Observable<Domain[]>;

  chapters$: Observable<Chapter[]>;

  projectId = new FormControl<Project['id']>(null);
  domainId = new FormControl<Domain['id']>(null);

  chapterId = new FormControl<Chapter['id']>(null);

  ngDestroyed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  triggerDelete$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private db: DatabaseService,
    private structureService: StructureService,
    public dialogRef: MatDialogRef<DeleteUserScoresComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { uid: string; title: string; text: string },
    private dialog: MatDialog
  ) {
    this.targetUid = data.uid;
    this.title = data.title;
  }

  ngOnInit(): void {
    this.projects$ = this.db
      .getProjectsWithScoresForUser(this.targetUid)
      .pipe(shareReplay(1));

    const cachedDomains$ = this.projectId.valueChanges.pipe(
      switchMap((projectId) => {
        if (!projectId) {
          return of(null);
        }
        return this.structureService
          .getChildrenForEntity(projectId, EntityType.project)
          .pipe(filter((domains) => !!domains.length));
      })
    );

    const domainsWithScores$ = this.projectId.valueChanges.pipe(
      switchMap((projectId) => {
        if (!projectId) {
          return of(null);
        }
        return this.db.getDomainsWithScoresForUser(this.targetUid, projectId);
      })
    );

    this.domains$ = zip(cachedDomains$, domainsWithScores$).pipe(
      map(([domains, domainsWithScores]) => {
        if (domains && domainsWithScores) {
          return domains.filter((domain) =>
            domainsWithScores.includes(domain.id)
          );
        } else {
          return [];
        }
      }),
      shareReplay(1)
    );

    const cachedChapters$ = this.domainId.valueChanges.pipe(
      switchMap((domainId) => {
        if (!domainId) {
          return of(null);
        }
        return this.structureService
          .getChildrenForEntity(domainId, EntityType.domain)
          .pipe(filter((chapters) => !!chapters.length));
      })
    );

    const chaptersWithScores$ = this.domainId.valueChanges.pipe(
      switchMap((domainId) => {
        if (!domainId) {
          return of(null);
        }
        return this.db.getChaptersWithScoresForUser(
          this.targetUid,
          this.projectId.value,
          domainId
        );
      })
    );

    this.projectId.valueChanges.subscribe(() => {
      this.domainId.setValue(null);
      this.chapterId.setValue(null);
    });

    this.chapters$ = zip(cachedChapters$, chaptersWithScores$).pipe(
      map(([chapters, chaptersWithScores]) => {
        if (chapters && chaptersWithScores) {
          return chapters.filter((chapter) =>
            chaptersWithScores.includes(chapter.id)
          );
        } else {
          return [];
        }
      }),
      shareReplay(1)
    );

    this.chapters$.subscribe();

    combineLatest([
      this.projects$,
      this.domains$,
      this.chapters$,
      this.projectId.valueChanges,
      this.domainId.valueChanges,
      this.chapterId.valueChanges,
    ])
      .pipe(
        map(([projects, domains, chapters, projectId, domainId, chapterId]) => {
          let text = `Weet je zeker dat je alle scores voor:\n\n`;
          if (projectId) {
            text += `Project: ${
              projects.find((p) => p.id === projectId)?.title
            }\n\n`;
          }
          if (domainId) {
            text += `Domein: ${
              domains.find((d) => d.id === domainId)?.title
            }\n\n`;
          }
          if (chapterId) {
            text += `Hoofdstuk: ${
              chapters.find((c) => c.id === chapterId)?.title
            }\n\n`;
          }
          text += ` wilt wissen?\n\nDeze actie kan niet ongedaan worden gemaakt.`;
          return text;
        })
      )
      .subscribe((text) => {
        this.confirmationText = text;
      });
  }

  deleteUserScores() {
    const deleteScoresRequest = {
      uid: this.targetUid,
      path: this.projectId.value
        ? {
            projectId: this.projectId.value,
            domainId: this.domainId.value,
            chapterId: this.chapterId.value,
          }
        : null,
    };

    return this.db.deleteSpecificUserScores(deleteScoresRequest);
  }

  triggerDelete() {
    const confirmationDialogRef = this.dialog.open<DialogComponent>(
      DialogComponent,
      {
        data: {
          title: 'Scores permanent wissen',
          text: this.confirmationText,
          preset: DialogPreset.cancelOk,
        },
      }
    );
    confirmationDialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((confirmed) => {
        if (confirmed) {
          this.deleteUserScores()
            .then(() => {
              this.dialog
                .open<DialogComponent>(DialogComponent, {
                  data: {
                    title: 'Scores gewist',
                    text: `Alle scores van deze gebruiker zijn gewist.`,
                    preset: DialogPreset.ok,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.dialogRef.close();
                });
            })
            .catch((error) => {
              this.dialog.open<DialogComponent>(DialogComponent, {
                data: {
                  title: 'Scores niet gewist',
                  text: `Er is iets misgegaan bij het wissen van de scores van deze gebruiker.`,
                  preset: DialogPreset.ok,
                },
              });

              console.error(error);
            });
        }
      });
  }
  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
