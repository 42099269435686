<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav bg-dark" fixedInViewport position="end">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list (click)="drawer.toggle()">

      <a mat-list-item routerLink="/" *ngIf="isLoggedIn$ | async">
        Overzicht inhoud
      </a>

      <a mat-list-item routerLink="/dashboard" *ngIf="(userIsTeacher$ | async)">
        Volgsysteem
      </a>

      <a mat-list-item (click)="toggleFullscreen()">
        Toggle volledig scherm
      </a>

      <a mat-list-item *ngIf="(userIsAdmin$ | async) || (userIsDev$ | async)" (click)="deleteUserScores()">
        Wis scores
      </a>

      <a mat-list-item routerLink="/import" *ngIf="(userIsAdmin$ | async) ||
      (userIsDev$ | async)">
        JSON import
      </a>
      <a mat-list-item routerLink="/admin/users" *ngIf="(userIsAdmin$ | async)">
        Admin panel
      </a>

      <a mat-list-item *ngIf="(showChooseOrgButton$ | async)" (click)="chooseOrganization()">
        Kies school
      </a>

      <a mat-list-item *ngIf="(userIsTeacher$ | async) === false && (user$ | async)?.organizationId | truthy"
        (click)="chooseTeacher()">
        Kies docent
      </a>

      <a mat-list-item *ngIf="(user$ | async)?.organizationId | truthy" (click)="chooseGroups()">
        Kies groep
      </a>

      <a mat-list-item *ngIf="(userIsTeacher$ | async) && ((user$ | async)?.organizationId | truthy)"
        routerLink="/admin/groups">
        Beheer groepen
      </a>

      <a mat-list-item *ngIf="(userIsTeacher$ | async) && ((user$ | async)?.organizationId | truthy)"
        routerLink="/exams-teacher">
        Toetsen beheren
      </a>

      <a mat-list-item *ngIf="((user$ | async)?.organizationId | truthy)" routerLink="/exams-student">
        Toetsen
      </a>

      <a mat-list-item *ngIf="(isLoggedIn$ | async)" (click)="changeName()">
        Account settings
      </a>

      <a mat-list-item *ngIf="(isLoggedIn$ | async)" (click)="showCodeDialog()">
        Licentiecode invoeren
      </a>

      <a mat-list-item *ngIf="(user$ | async)" (click)="signOut()">
        Uitloggen
      </a>

      <mat-list-item *ngIf="isDevModeAllowed$ | async">
        <mat-slide-toggle color="primary" [checked]="isDevMode$ | async" (change)="setDevMode($event)">
          Developer mode
        </mat-slide-toggle>
      </mat-list-item>

      <mat-list-item *ngIf="isContentAdminModeAllowed$ | async">
        <mat-slide-toggle color="primary" [checked]="isContentAdminMode$ | async"
          (change)="setContentAdminMode($event)">
          Content admin mode
        </mat-slide-toggle>
      </mat-list-item>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="bg-base">
    <mat-toolbar color="primary" class="bg-light mat-elevation-z1">
      <img class="logo" src="/assets/logo.svg" routerLink="/" alt="Logo" aria-label="Logo" />

      <h1 *ngIf="!!activeProject" [class]="'text-' + activeProject.projectColor">
        {{ activeProject.subtitle }}
      </h1>

      <h1 *ngIf="!isProduction" class="dev-label">
        DEV
      </h1>

      <div class="btn-wrapper">
        <span (click)="drawer.toggle()" class="name">{{(user$ | async)?.name}}</span>

        <button *ngIf="(showChooseOrgButtonInHeader$ | async)" mat-stroked-button color="warn" type="button"
          aria-label="Kies school" (click)="chooseOrganization()">
          Kies je school
        </button>

        <button *ngIf="(userIsAdmin$ | async)" mat-icon-button color="accent" type="button" aria-label="Admin section"
          routerLink="/admin/users" title="Admin panel">
          <mat-icon>admin_panel_settings</mat-icon>
        </button>

        <button *ngIf="((user$ | async)?.organizationId | truthy) &&
         ((userIsTeacher$ | async) || (userIsAdmin$ | async))" mat-icon-button color="accent" type="button"
          aria-label="Volgsysteem" routerLink="/dashboard" title="Volgsysteem">
          <mat-icon>fact_check</mat-icon>
        </button>

        <button *ngIf="(user$ | async)" mat-icon-button color="primary" type="button" aria-label="Home" routerLink="/"
          title="Home">
          <mat-icon>home</mat-icon>
        </button>

        <button *ngIf="(user$ | async)" mat-icon-button color="warn" type="button" aria-label="Uitloggen"
          (click)="signOut()" title="Uitloggen">
          <mat-icon>logout</mat-icon>
        </button>

        <button mat-icon-button color="primary" type="button" aria-label="Menu" (click)="drawer.toggle()" title="Menu">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <mat-progress-bar *ngIf="(isLoading$ | async)" mode="indeterminate" color="accent"></mat-progress-bar>
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>