import { matchInteractionOption } from 'src/app/components/interaction/match-interaction-option';
import { InteractionEvent } from 'src/app/enums/interaction-event';
import { InteractionModel } from 'src/app/enums/interaction-model';
import { getFeedback } from 'src/app/helpers/feedback-helper';
import {
  InteractionOptionHotspot,
  InteractionOptionImage,
  InteractionOptionText,
  Order,
} from 'src/app/models/interaction-option';
import { Feedback } from 'src/app/types/feedback';
import { Interaction } from '../../models/interaction';

const findOptionIndex = (
  option:
    | InteractionOptionText
    | InteractionOptionImage
    | InteractionOptionHotspot
    | Order,
  parsedInteraction: Interaction
) => {
  if (parsedInteraction.model === InteractionModel.sortToHotspots) {
    return (
      parsedInteraction.optionsHotspot as InteractionOptionHotspot[]
    ).findIndex((o) => o.index === (option as InteractionOptionHotspot).index);
  }

  const options =
    parsedInteraction.optionsText ||
    parsedInteraction.optionsImage ||
    parsedInteraction.order;

  const optionIndex = options.findIndex((o) => o === option);

  if (optionIndex === -1) {
    throw new Error('Could not find option');
  }

  return optionIndex;
};

export type ValidateAnswersOutput = {
  events: Set<InteractionEvent>;
  feedback: string;
  feedbackSound: string;
  correct: boolean;
  answersCorrectness: boolean[];
  modifiedInteractionOptionHotspots: InteractionOptionHotspot[];
  // if(!isTest) {
  //    markRadio
  //    markInput
  //    markPointSpot
  //    markOptionHotspot(answer as InteractionOptionHotspot);
  //    resetDragOption(answer as InteractionOptionHotspot);
  // }
};

export type Answers = string[] | InteractionOptionHotspot[];
export type Answer = string | InteractionOptionHotspot;

export const validateAnswers = ({
  tries,
  isTestOrExam,
  timerExpired,
  parsedInteraction,
  answers,
}: {
  tries: number;
  isTestOrExam: boolean;
  timerExpired: boolean;
  parsedInteraction: Interaction;
  answers: Answers;
}): ValidateAnswersOutput => {
  const answerCorrectness: boolean[] = [];
  let outputEvents: Set<InteractionEvent> = new Set<InteractionEvent>();
  const feedbackArray: Feedback[] = [];
  let outputFeedback = '';
  let outputFeedbackSound = null;
  let outputCorrect = false;
  const modifiedInteractionOptionHotspots: InteractionOptionHotspot[] = [];

  const isLastTry =
    isTestOrExam ||
    (parsedInteraction.maxTimesAnswered &&
      tries === parsedInteraction.maxTimesAnswered);

  // Point and click needs all correct options to have been clicked.
  const clickedOptions: Set<InteractionOptionImage> = new Set();

  answers.forEach((answer: Answer, index: number) => {
    const option = matchInteractionOption(answer, index, parsedInteraction);

    let correct: boolean;
    if (option) {
      correct = option.correct;
      const optionIndex = findOptionIndex(option, parsedInteraction);

      if (optionIndex !== -1) {
        outputEvents.add(`option${optionIndex}Selected` as InteractionEvent);
      }

      if (parsedInteraction.model === InteractionModel.pointAndClick) {
        if (clickedOptions.has(option as InteractionOptionImage)) {
          correct = false;
        } else {
          clickedOptions.add(option as InteractionOptionImage);
        }
      } else if (parsedInteraction.model === InteractionModel.sortToHotspots) {
        modifiedInteractionOptionHotspots.push(
          option as InteractionOptionHotspot
        );
      }
    } else {
      correct = false;
    }

    answerCorrectness.push(correct);

    const { feedback, events, feedbackSound } = getFeedback(
      parsedInteraction,
      option,
      correct,
      timerExpired,
      isLastTry,
      tries > 1
    );

    outputFeedbackSound = feedbackSound;

    let feedbackLabel = answer as string;
    if ([InteractionModel.sortToHotspots].includes(parsedInteraction.model)) {
      feedbackLabel = (option as InteractionOptionHotspot).index + 1 + '';
    } else if (
      [
        InteractionModel.pointAndClick,
        InteractionModel.sortToHotspots,
      ].includes(parsedInteraction.model)
    ) {
      feedbackLabel = index + 1 + '';
    } else if (
      [
        InteractionModel.sortObjectsOrder,
        InteractionModel.multipleChoice,
      ].includes(parsedInteraction.model)
    ) {
      feedbackLabel = '';
    }

    feedbackArray.push({
      label: feedbackLabel,
      text: feedback,
      correct,
    });

    outputFeedback =
      (outputFeedback ? outputFeedback + '<br/><br/>' : '') +
      '<strong>' +
      feedbackLabel +
      `</strong> <span class="${correct ? 'correct' : 'incorrect'}">${
        correct ? '✓' : '✗'
      }</span><br/>` +
      feedback;

    outputEvents = isTestOrExam
      ? new Set()
      : new Set([...outputEvents, ...events]);
  });

  // // FIXME writes to the options!!!
  // // TODO do this based on answer correctness
  // if (!isTest) {
  //   // Reset incorrect draggables
  //   parsedInteraction.optionsHotspot
  //     ?.filter((option) => !option.correct)
  //     .forEach((option) => option.draggable?.reset());

  //   // Lock correct draggables
  //   parsedInteraction.optionsHotspot
  //     ?.filter((option) => option.correct)
  //     .forEach((option) => (option.draggable.disabled = true));
  // }

  //   this.hasDragged = false;

  if (parsedInteraction.model === InteractionModel.sortToHotspots) {
    outputCorrect =
      answerCorrectness.filter((x) => x).length ===
      Math.min(
        parsedInteraction.hotspots.length,
        parsedInteraction.optionsHotspot.length
      );
  } else if (parsedInteraction.model === InteractionModel.pointAndClick) {
    const clickedAllOptions = parsedInteraction.optionsImage
      .filter((o) => o.correct)
      .every((o) => clickedOptions.has(o));
    outputCorrect = clickedAllOptions;
  } else {
    outputCorrect = answerCorrectness.every((x) => x);
  }

  if (!outputCorrect) {
    outputEvents.delete(InteractionEvent.feedbackCorrectOpen);
    outputEvents.add(`feedbackFalse${tries}NoOption` as InteractionEvent);
  }

  if (timerExpired) {
    outputCorrect = false;
  }

  if (isTestOrExam) {
    outputFeedback = timerExpired
      ? `<span class="incorrect">✗</span> Dat was buiten de tijd.`
      : '';
  } else {
    // Construct feedback
    const mergedFeedback: { [key: string]: Feedback } = {};
    feedbackArray.forEach((feedbackObject) => {
      if (!mergedFeedback[feedbackObject.text]) {
        mergedFeedback[feedbackObject.text] = {
          label: feedbackObject.label,
          text: feedbackObject.text,
          correct: feedbackObject.correct,
        };
      } else {
        mergedFeedback[feedbackObject.text].label =
          mergedFeedback[feedbackObject.text].label +
          ' | ' +
          feedbackObject.label;
      }

      outputFeedback = Object.values(mergedFeedback)
        .map(
          (feedbackOb) =>
            '<strong>' +
            feedbackOb.label +
            `</strong> <span class="${
              feedbackOb.correct ? 'correct' : 'incorrect'
            }">${feedbackOb.correct ? '✓' : '✗'}</span><br/>` +
            feedbackOb.text
        )
        .join('<br/><br/>');
    });
  }

  // Events and actions
  //   this.handleActions();

  // Auto-focus or focus and select incorrect input.
  //   this.shouldFindFocus = true;
  //   if (!outputCorrect) {
  //     this.selectFirstIncorrectInput();
  //   }

  // Flatten var obj
  // TODO a bit hacky, maybe store JSON string instead?
  //   const vars =
  //     this.parsedInteraction.varsShuffled &&
  //     Object.values(this.parsedInteraction.varsShuffled).map((x) => '' + x);

  //   const varsShuffledIndexes = this.parsedInteraction.varsShuffledIndexes;

  // TODO move outside of this function
  // if (typeof answers[0] !== 'string') {
  //   answers = answerCorrectness.map((correct) => correct.toString());
  // }

  return {
    correct: outputCorrect,
    feedback: outputFeedback,
    events: outputEvents,
    answersCorrectness: answerCorrectness,
    modifiedInteractionOptionHotspots,
    feedbackSound: outputFeedbackSound, // TODO add unit test
  };
};
