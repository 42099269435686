import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { UserRole } from 'shared/enums/user-role.enum';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { AppState } from 'src/app/store/reducers';
import { LoginProviderName } from '../../enums/login-provider.enum';
import { AuthService } from '../../services/auth.service';
import {
  selectIsDbUserLoaded,
  selectUserRoles,
} from '../../store/reducers/user.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  preferredLoginProviderName: LoginProviderName;
  loginProviderNames = LoginProviderName;
  showNonPreferredProviders = false;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  loginWithGoogle() {
    this.authService.googleSignIn();
  }

  loginWithEntree() {
    this.authService.entreeSignIn();
  }

  loginWithSurf() {
    this.authService.surfSignIn();
  }

  ngOnInit() {
    const returnUrl$ = this.activatedRoute.queryParams.pipe(
      map((queryParams) => queryParams?.returnUrl),
      takeUntil(this.ngDestroyed$)
    );

    this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => paramMap.get(AppRouteParams.loginProvider)),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((providerName: LoginProviderName) => {
        if (providerName) {
          this.preferredLoginProviderName = providerName;
          this.showNonPreferredProviders = false;
        } else {
          this.showNonPreferredProviders = true;
        }
      });

    combineLatest([
      this.store.select(selectIsDbUserLoaded),
      this.store.select(selectUserRoles),
      returnUrl$.pipe(startWith('')),
    ])
      .pipe(
        filter(([isLoggedIn]) => isLoggedIn),
        debounceTime(1),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(([isLoggedIn, userRoles, returnUrl]) => {
        if (returnUrl) {
          this.router.navigate([returnUrl]);
          return;
        }

        // Route teachers to the teacher dashboard
        if (userRoles?.has(UserRole.teacher)) {
          this.router.navigate(['dashboard']);
          return;
        }

        this.router.navigate(['']);
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
