import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotepadLineSource } from 'src/app/models/notepad-line';

export const notepadActions = createActionGroup({
  source: 'Notepad',
  events: {
    add: props<{ id: string; content: string; source: NotepadLineSource }>(),
    update: props<{ id: string; content: string }>(),
    remove: props<{ id: string }>(),
    ['toggle dialog']: emptyProps(),
    ['open dialog']: emptyProps(),
    ['close dialog']: props<{ clear: boolean }>(),
    reset: emptyProps(),
    ['set dialog state']: props<{ isOpen: boolean }>(),
    ['is readonly mode']: props<{ isReadonlyMode: boolean }>(),
  },
});
