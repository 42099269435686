import { regularExpressions } from '../../../../shared/helpers/regex';
import { Interaction } from '../../../../shared/models/interaction';
import {
  InteractionOptionHotspot,
  InteractionOptionImage,
  InteractionOptionText,
  Order,
  correctnessSorter,
} from '../../../../shared/models/interaction-option';
import { Point } from '../../../../shared/types/shapes';

export const matchInteractionOption = (
  answer: string | InteractionOptionHotspot,
  answerIndex: number,
  parsedInteraction: Interaction
):
  | InteractionOptionText
  | InteractionOptionImage
  | InteractionOptionHotspot
  | Order
  | undefined => {
  if (parsedInteraction.optionsText) {
    return matchInteractionTextOption(
      answer as string,
      answerIndex,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsImage) {
    return matchInteractionImageOption(
      answer as string,
      answerIndex,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsHotspot) {
    return matchInteractionHotspotOption(
      answer as InteractionOptionHotspot,
      parsedInteraction
    );
  }

  if (parsedInteraction.optionsSortOrder) {
    return matchInteractionSortOrderOption(answer as string, parsedInteraction);
  }
};

export const matchInteractionTextOption = (
  answer: string,
  answerIndex: number,
  parsedInteraction: Interaction
): InteractionOptionText | undefined => {
  const isNumeric = parsedInteraction.numeric;
  const ignoreSpaces = parsedInteraction.ignoreSpaces;

  if (isNumeric) {
    if (ignoreSpaces) {
      // Remove thousand separators
      answer = answer.replace(regularExpressions.possibleThousandSeparator, '');
    }

    // Replace comma with a dot as decimal separator.
    answer = answer.replace(/,/g, '.');
  }

  const caseInsensitive = !isNumeric && !parsedInteraction.caseSensitive;

  //make a copy of the optionsText array and sort it
  const optionsText = parsedInteraction.optionsText
    .slice()
    .sort(correctnessSorter);

  const option = optionsText.find((o) => {
    const value = o.option;
    const field = o.field;

    if (!field || (field && answerIndex === +field)) {
      if (value.startsWith('>')) {
        return +answer > +value.substring(1);
      } else if (value.startsWith('<')) {
        return +answer < +value.substring(1);
      } else if (value.includes('<<')) {
        const [lower, upper] = value.split('<<');
        return +answer >= +lower && +answer <= +upper;
      } else if (isNumeric) {
        let valueParsed = value;
        if (typeof value === 'string') {
          valueParsed = value.replace(/,/g, '.');
        }

        return +answer === +valueParsed;
      } else if (caseInsensitive) {
        return answer.toLowerCase() === value.toLowerCase();
      } else {
        return answer === value;
      }
    }
  });

  return option;
};

export const matchInteractionImageOption = (
  answer: string,
  answerIndex: number,
  parsedInteraction: Interaction
): InteractionOptionImage | undefined => {
  const point = new Point(+answer.split(',')[0], +answer.split(',')[1]);

  //make a copy of the optionsImage array and sort it by correct
  const optionsImage = parsedInteraction.optionsImage
    .slice()
    .sort(correctnessSorter);

  return optionsImage.find((option) => option.shape?.isPointInside(point));
};

export const matchInteractionHotspotOption = (
  option: InteractionOptionHotspot,
  parsedInteraction: Interaction
): InteractionOptionHotspot | undefined => {
  // We are returning a mutated copy
  const matchedOption = {
    ...option,
  };

  const hotspots = parsedInteraction.hotspots;

  // Find hotspots where the option collides.
  const matchingHotspots = hotspots.filter((hotspot) =>
    hotspot.shape.isPointInside(matchedOption.draggedTo)
  );

  if (matchingHotspots) {
    matchedOption.correct = !!matchingHotspots.find((hotspot) => {
      if (hotspot.id) {
        return hotspot.id === matchedOption.target;
      } else if (matchedOption.target) {
        return hotspots[+matchedOption.target - 1] === hotspot;
      } else {
        return hotspots[matchedOption.index] === hotspot;
      }
    });

    matchedOption.feedback =
      (matchedOption.correct && matchedOption.feedbackCorrect) ||
      matchedOption.feedbackFalse;
  }

  return matchingHotspots && matchedOption;
};

export const matchInteractionSortOrderOption = (
  order: string,
  parsedInteraction: Interaction
): Order | undefined => parsedInteraction.order.find((o) => o.option === order);
