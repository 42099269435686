import { UnitContext } from '../../../shared/enums/unit-context';
import { calcMinIntroScore } from '../../../shared/helpers/scores/calc-unit-score';
import { InteractionScore } from '../../../shared/models/score.enum';
import { UserUnitScore } from '../../../shared/models/user-unit-score';

type CurrentLocationInUnit = {
  unitContext: UnitContext;
  interactionIndex: number;
};

const contextOrderAndLength = [
  [UnitContext.introduction, 4],
  [UnitContext.extra, 4],
  [UnitContext.deepening, 3],
  // [UnitContext.test, 4], // We don't count the test context
];

export const calcUnitSkips = (
  userUnitScore: UserUnitScore,
  currentUserLocation?: CurrentLocationInUnit
): number => {
  const contexts = userUnitScore.contexts;
  const list: Array<InteractionScore[]> = [];
  // Ordered map object
  const map = new Map<UnitContext, InteractionScore[]>();

  const passedMinIntroScore = calcMinIntroScore(userUnitScore);

  // Add all scores to a list
  for (const [context, length] of contextOrderAndLength) {
    const scores = contexts[context];
    let scoreArr: InteractionScore[];
    if (scores) {
      scoreArr = Array(length).fill(InteractionScore.null);
      for (const index in scores) {
        if (Object.prototype.hasOwnProperty.call(scores, index)) {
          scoreArr[index] = scores[index];
        }
      }
    } else {
      scoreArr = Array(length).fill(InteractionScore.null);
    }

    list.push(scoreArr);
    map.set(context, scoreArr);
  }

  // Loop through the list and count all InteractionScore.null
  // either since the last score or since the current location
  // whichever is greater
  let skipsBetweenLastScore = 0;
  const lastScoreLocation = findLastScoreLocation(map);
  if (lastScoreLocation) {
    skipsBetweenLastScore = countSkipsSinceLocation(
      map,
      lastScoreLocation,
      passedMinIntroScore
    );
  }

  let skipsSinceCurrentLocation = 0;
  if (currentUserLocation) {
    skipsSinceCurrentLocation = countSkipsSinceLocation(
      map,
      currentUserLocation,
      passedMinIntroScore
    );
  }

  return Math.max(skipsBetweenLastScore, skipsSinceCurrentLocation);
};

const findLastScoreLocation = (
  map: Map<UnitContext, InteractionScore[]>
): CurrentLocationInUnit | undefined => {
  // Loop backwards through the map and find the last score
  for (const [context, scores] of [...map].reverse()) {
    for (let i = scores.length - 1; i >= 0; i--) {
      if (scores[i] !== InteractionScore.null) {
        return {
          unitContext: context,
          interactionIndex: i,
        };
      }
    }
  }

  return undefined;
};

const countSkipsSinceLocation = (
  scoreMap: Map<UnitContext, InteractionScore[]>,
  location: CurrentLocationInUnit,
  passedMinIntroScore: boolean
): number => {
  // When intro score is good enough, we don't count the extra context
  // Loop through the map in order and count all InteractionScore.null
  // until the current location is reached
  let count = 0;
  for (const [context, scores] of scoreMap) {
    // Loop through the scores
    for (let i = 0; i < scores.length; i++) {
      // Check if we found the location
      if (context === location.unitContext && i === location.interactionIndex) {
        return count;
      }

      // Count the skips
      if (scores[i] === InteractionScore.null) {
        if (context === UnitContext.extra && passedMinIntroScore) {
          // Skip the extra context
          continue;
        }

        count++;
      }
    }
  }

  return count;
};
