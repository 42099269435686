<form class="org-form" *ngIf="userIsAdmin$ | async">
    <mat-form-field appearance="outline">
        <mat-label>School</mat-label>
        <input type="text" matInput [formControl]="orgControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOrg">
            <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                {{ displayOrg(option) }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>

<h3 *ngIf="userIsAdmin$ | async">{{ (organization$ | async)?.name }}</h3>

<mat-table [dataSource]="groups$ | async">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
        <mat-cell *matCellDef="let group"> {{ displayCell(group['name']) }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="owners">
        <mat-header-cell *matHeaderCellDef>Beheerders</mat-header-cell>
        <mat-cell *matCellDef="let group"> {{ displayCell(group['owners']) }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="memberCount">
        <mat-header-cell *matHeaderCellDef>Leden</mat-header-cell>
        <mat-cell *matCellDef="let group"> {{ displayCell(group['memberCount']) }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Acties</mat-header-cell>
        <mat-cell *matCellDef="let group">
            <ng-container *ngIf="!groupsBeingHandled.has(group.id)">
                <button (click)="renameGroupDialog(group)" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="deleteGroup(group)" mat-icon-button color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-container>
            <mat-spinner *ngIf="groupsBeingHandled.has(group.id)" diameter="18"></mat-spinner>
        </mat-cell>
    </ng-container>
    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<button class="add-btn" [disabled]="!selectedOrganizationId" (click)="addGroupDialog()" mat-fab color="primary"
    aria-label="Add">
    <mat-icon>add</mat-icon>
</button>