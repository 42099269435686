import { Interaction } from 'shared/models/interaction';
import { UnitContext } from 'shared/enums/unit-context';
import { InteractionIndex, Unit } from 'shared/models/unit';

export const getVariantCountAtUnitPos = ({
  unit,
  unitContext,
  index,
}: {
  unit: Unit;
  unitContext: UnitContext;
  index: InteractionIndex;
}): number => {
  switch (unitContext) {
    case UnitContext.presentation:
    case UnitContext.infoPopup:
      return null;
    case UnitContext.introduction:
      if (Array.isArray(unit.introduction[index])) {
        return unit.introduction[index].length;
      }
      return null;
    case UnitContext.extra:
      if (Array.isArray(unit.extra[index])) {
        return unit.extra[index].length;
      }
      return null;
    case UnitContext.deepening:
      if (Array.isArray(unit.deepening[index])) {
        return unit.deepening[index].length;
      }
      return null;
    case UnitContext.test:
      if (Array.isArray(unit.test[index])) {
        return unit.test[index].length;
      }
      return null;
    default:
      console.error('Unknown unit context');
      break;
  }
};

export const getInteractionIdFromContextAndIndex = ({
  unit,
  unitContext,
  interactionIndex,
  variantIndex,
}: {
  unit: Unit;
  unitContext: UnitContext;
  interactionIndex: number;
  variantIndex?: number;
}): Interaction['id'] => {
  switch (unitContext) {
    case UnitContext.presentation:
      return unit.presentation;
    case UnitContext.infoPopup:
      return unit.infoPopup;
    case UnitContext.introduction:
      if (Array.isArray(unit.introduction[interactionIndex])) {
        if (typeof variantIndex !== 'number') {
          throw new Error('variantIndex is undefined');
        }
        return unit.introduction[interactionIndex][variantIndex];
      }
      return unit.introduction[interactionIndex] as Interaction['id'];
    case UnitContext.extra:
      if (Array.isArray(unit.extra[interactionIndex])) {
        if (typeof variantIndex !== 'number') {
          throw new Error('variantIndex is undefined');
        }
        return unit.extra[interactionIndex][variantIndex];
      }
      return unit.extra[interactionIndex] as Interaction['id'];
    case UnitContext.deepening:
      if (Array.isArray(unit.deepening[interactionIndex])) {
        if (typeof variantIndex !== 'number') {
          throw new Error('variantIndex is undefined');
        }
        return unit.deepening[interactionIndex][variantIndex];
      }
      return unit.deepening[interactionIndex] as Interaction['id'];
    case UnitContext.test:
      if (Array.isArray(unit.test[interactionIndex])) {
        if (typeof variantIndex !== 'number') {
          throw new Error('variantIndex is undefined');
        }
        return unit.test[interactionIndex][variantIndex];
      }
      return unit.test[interactionIndex] as Interaction['id'];
    default:
      console.error('Interaction not found in unit');
      break;
  }
};
