import { EntityType } from '../enums/entity-type';
import { ProjectColor } from '../enums/project-color';
import { SharedBasics } from './shared-basics';

export enum EducationTrack {
  mbo = 'mbo',
  vmbo = 'vmbo',
}

export type Project = SharedBasics & {
  public: boolean;
  projectColor: ProjectColor;
  subtitle?: string; // Duplicated part of title designated as subtitle
  type: EntityType.project;
  educationTrack?: EducationTrack;
};
