<h1 mat-dialog-title>Naam</h1>
<form #userSettingsForm="ngForm" [formGroup]="userSettingsFormGroup" novalidate (ngSubmit)="saveUserSettings()">
    <div mat-dialog-content>

        <p *ngIf="userNameIncomplete$ | async" class="text-warn">
            Vul hier je naam in
        </p>

        <mat-form-field appearance="fill">
            <mat-label>Voornaam</mat-label>
            <input autocomplete="given-name" type="text" matInput formControlName="firstName">
            <mat-error *ngIf="userSettingsFormGroup.controls['firstName'].hasError('required')">
                Voornaam is <strong>verplicht</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Tussenvoegsel</mat-label>
            <input autocomplete="additional-name" type="text" matInput formControlName="middleName">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Achternaam</mat-label>
            <input autocomplete="family-name" type="text" matInput formControlName="lastName">
            <mat-error *ngIf="userSettingsFormGroup.controls['lastName'].hasError('required')">
                Achternaam is <strong>verplicht</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button [disabled]="userNameIncomplete$ | async" mat-dialog-close>Sluiten</button>
        <button [class.spinner]="saveSettingsInProgress"
            [disabled]="!userSettingsForm.valid || !userSettingsForm.dirty || saveSettingsInProgress" mat-raised-button
            type="submit">Opslaan</button>
    </div>
</form>