import { isNullOrUndefined, isTypeOf, typeOfString } from './type.extensions';

export const GUARD_ARGUMENT_NULL_UNDEFINED_OR_EMPTY_ERROR_MESSAGE =
  'Value cannot be null, undefined or empty';
export const GUARD_ARGUMENT_NULL_OR_UNDEFINED_ERROR_MESSAGE =
  'Value cannot be null or undefined';
export const GUARD_ARGUMENT_NOT_STRING_ERROR_MESSAGE = 'Value must be a string';

export const isNotNullOrUndefined = <T>(
  value: T | null | undefined
): boolean => {
  if (isNullOrUndefined(value)) {
    throw new Error(GUARD_ARGUMENT_NULL_OR_UNDEFINED_ERROR_MESSAGE);
  }
  return true;
};

export const isString = (value: any): boolean => {
  if (!isTypeOf(value, typeOfString)) {
    throw new Error(GUARD_ARGUMENT_NOT_STRING_ERROR_MESSAGE);
  }
  return true;
};
