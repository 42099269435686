<mat-card>
    <mat-card-header>
        <mat-card-title>
            Toets klaarzetten
        </mat-card-title>
        <mat-card-subtitle>
            {{ exam?.name }}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p class="exam-description">
            {{ exam?.description }}
        </p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="fill">
                <mat-label>Extra titel</mat-label>
                <input autocomplete="off" matInput formControlName="customTitle">
                <mat-hint>Bijv.: Toets week 1 groep A</mat-hint>
            </mat-form-field>

            <div class="date-time-wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Opengesteld van</mat-label>
                    <input autocomplete="off" matInput formControlName="openFrom" [matDatepicker]="pickerStart">
                    <mat-datepicker-toggle *ngIf="form.controls.openFrom.value" matSuffix (click)="clearFromDate()">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Tijd</mat-label>
                    <input matInput [format]="24" formControlName="openFromTime" [ngxTimepicker]="timeFrom"
                        autocomplete="off" readonly>
                    <ngx-material-timepicker-toggle [for]="timeFrom"></ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #timeFrom></ngx-material-timepicker>
                </mat-form-field>
            </div>

            <div class="date-time-wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Opengesteld t/m</mat-label>
                    <input autocomplete="off" matInput formControlName="openTo" [matDatepicker]="pickerEnd">
                    <mat-datepicker-toggle *ngIf="form.controls.openTo.value" matSuffix (click)="clearToDate()">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Tijd</mat-label>
                    <input matInput [format]="24" formControlName="openToTime" [ngxTimepicker]="timeTo"
                        autocomplete="off" readonly>
                    <ngx-material-timepicker-toggle [for]="timeTo"></ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #timeTo></ngx-material-timepicker>
                </mat-form-field>
            </div>

            <div class="date-time-wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Tijdslimiet in minuten</mat-label>
                    <input matInput type="number" formControlName="duration">
                </mat-form-field>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Wachtwoordzin</mat-label>
                <input autocomplete="off" matInput placeholder="mooi paard" formControlName="passphrase">
                <mat-hint>
                    Toetswachtwoord voor studenten. Kies iets eenvoudigs, bijv. "mooi paard".
                </mat-hint>
            </mat-form-field>

            <fieldset>
                <a mat-stroked-button [routerLink]="['/exams-teacher']">Annuleren</a>

                <app-button-with-spinner (click)="onSubmit()" [isLoading]="isLoading$">Toets
                    klaarzetten</app-button-with-spinner>
            </fieldset>

        </form>
    </mat-card-content>
</mat-card>