import { UserRole } from '../enums/user-role.enum';

export const getRoleName = (role: UserRole) => {
  switch (role) {
    case UserRole.admin:
      return 'admin';
    case UserRole.developer:
      return 'developer';
    case UserRole.teacher:
      return 'teacher';
    case UserRole.dummy:
      return 'dummy';
    default:
      throw new Error('Unknown role');
  }
};
