import { UserRole } from '../enums/user-role.enum';
import { ISODateTimeStringWithOffset } from '../helpers/time-helper';
import { Modify } from '../types/utility-types';
import { Group, GroupReference } from './group';
import { License } from './license';
import { Project } from './project';

// App user comes partly from auth and partly from DB
export type AppUser = Modify<
  DbUser,
  {
    name?: string;
    roles?: Set<UserRole>;
    licenses?: Set<License['code']>;
    createdDate?: ISODateTimeStringWithOffset;
  }
>;

export type AuthUser = Readonly<{
  uid: string;
  email?: string;
  emailVerified?: boolean;
  photoURL?: string;
  providerData?: Array<Record<string, string>>;
}>;

export type DbUser = AuthUser &
  Readonly<{
    name: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    roles?: UserRole[];
    licenses?: License['code'][];
    licensedAccessUntil?: ISODateTimeStringWithOffset;
    vmboAccessUntil?: ISODateTimeStringWithOffset;
    projectAccess?: Project['id'][];
    organizationId?: string;
    organizationHardLink?: boolean;
    groups?: GroupReference[];
    favGroups?: Group['id'][];
    teachers?: UserReference[];
    createdDate: string;
    lastSeen?: ISODateTimeStringWithOffset;
  }>;

export type UserReference = {
  name?: string;
  uid: AppUser['uid'];
};

export const getUserRef = (user: AppUser | DbUser): UserReference => ({
  name: user.name,
  uid: user.uid,
});

export const buildUserRef = (uid: string, name: string): UserReference => ({
  name,
  uid,
});

export const dbToAppUser = (dbUser: DbUser): AppUser => ({
  ...dbUser,
  roles: new Set(dbUser.roles),
  licenses: new Set(dbUser.licenses),
});
