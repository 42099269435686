import { createAction, props } from '@ngrx/store';
import { ExamSession } from 'shared/models/exam-session';

export const studentExamsActions = {
  loadExamSessionsForUser: createAction(
    '[Student Exams] Load exam sessions for user',
    props<{ uid: string }>()
  ),

  examSessionsForUserLoaded: createAction(
    '[Student Exams] Exam sessions for user loaded',
    props<{ examSessions: ExamSession[] }>()
  ),
};
