<div mat-dialog-title>Toetsresultaat {{ uid | username | async }}</div>
<div mat-dialog-content>
    <div class="dialog-container">
        <h3>Score per onderdeel</h3>
        <div class="score-list">
            <div *ngFor="let score of scoresPerLabel | keyvalue" class="score-item">
                <div class="score-label">
                    <b>{{ score.key }}</b>
                    <div class="score-details">
                        {{ score.value }} van {{ maxScorePerLabel[score.key] }} punten
                    </div>
                </div>
                <div class="score-bar">
                    <mat-progress-bar color="accent" [value]="(score.value/maxScorePerLabel[score.key])*100">
                    </mat-progress-bar>
                </div>
                <div class="score-percentage">
                    {{ (score.value/maxScorePerLabel[score.key])*100 | number:'1.0-0' }}%
                </div>
            </div>

            <div class="score-item total-score">
                <div class="score-label">
                    <b>Totaalscore</b>
                    <div class="score-details">
                        {{ totalScore }} van {{ maxTotalScore }} punten
                    </div>
                </div>
                <div class="score-bar">
                    <mat-progress-bar [value]="percentageScore"></mat-progress-bar>
                </div>
                <div class="score-percentage">
                    {{ percentageScore }}%
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</div>