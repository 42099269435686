import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { flatten, pick, uniqBy } from 'lodash';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';
import {
  InteractionIssue,
  findCommonInteractionIssues,
  searchIssuesRecursive,
} from 'src/app/components/import/find-issues';
import { downloadCSV } from 'src/app/helpers/download-helper';
import { StructuralEntity } from 'src/app/models/structural-entity';
import { Unit } from 'src/app/models/unit';
import { DatabaseService } from 'src/app/services/database.service';
import { AppState } from 'src/app/store/reducers';
import { environment } from 'src/environments/environment';
import { EntityType } from '../../enums/entity-type';
import { getDateAndTimeUpToSecondsFromISOString } from '../../helpers/time-helper';
import { utility } from '../../helpers/utility';
import { constants } from '../../misc/constants';
import { Interaction } from '../../models/interaction';
import { ImportOrganizationRecord } from '../../models/organization';
import { castToStructuralEntity } from '../../models/structural-entity';
import { InteractionAttempt } from '../../models/user-interaction-attempt';
import { LocalStorageService } from '../../services/local-storage.service';
import { StructureService } from '../../services/structure.service';
import { selectUserIsDev } from '../../store/reducers/user.reducer';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit, OnDestroy {
  @ViewChild('importEntityForm') importEntityForm: NgForm;
  @ViewChild('importSchoolForm') importSchoolForm: NgForm;
  @ViewChild('listInteractionsForm') listInteractionsForm: NgForm;
  @ViewChild('importDummyDataForm') importDummyDataForm: NgForm;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  displayedColumns: string[] = ['id', 'issueType', 'path'];
  interactionIssues: InteractionIssue[];
  entityPanelOpenState = true;
  issuePanelOpenState = true;
  isProduction: boolean;
  $userIsDev: Observable<boolean>;
  interactions$: Observable<Interaction[]>;
  displayedInteractionColumns: string[] = [
    'id',
    'name',
    'version',
    'versionDate',
    'disabled',
  ];

  // Server calls
  validateDbInProgress = false;
  validateChildrenInProgress = false;
  listInteractionsInProgress = false;
  deleteInteractionsInProgress = false;
  setDisableInteractionsInProgress = false;
  listUnusedInteractionsInProgress = false;

  importEntityFormGroup = this.fb.group({
    jsonImport: [
      null,
      Validators.compose([Validators.required, Validators.minLength(5)]),
    ],
  });

  importSchoolFormGroup = this.fb.group({
    jsonImport: [
      null,
      Validators.compose([Validators.required, Validators.minLength(5)]),
    ],
  });

  listInteractionsFormGroup = this.fb.group({
    interactionIds: [null],
  });

  importDummyDataFormGroup = this.fb.group({
    jsonImport: [
      null,
      Validators.compose([Validators.required, Validators.minLength(5)]),
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private databaseService: DatabaseService,
    private structureService: StructureService,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {
    this.isProduction = environment.production;
  }

  ngOnInit() {
    this.$userIsDev = this.store.select(selectUserIsDev);
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  async importJsonEntities() {
    try {
      const fieldValue = this.importEntityFormGroup.controls.jsonImport.value;
      let entities = JSON.parse(fieldValue);

      if (!Array.isArray(entities)) {
        entities = [entities];
      }

      const issues = findCommonInteractionIssues(
        entities.filter((x) => x.type === EntityType.interaction)
      );

      this.interactionIssues = issues;

      if (issues) {
        const dialogRef = await this.overrideDialog();
        const forceUpload = await dialogRef.afterClosed().toPromise();
        if (!forceUpload) {
          return;
        }
      }

      await this.databaseService.importEntity(entities).then(() => {
        LocalStorageService.clearCache();
      });

      this.showSuccessDialog();

      this.importEntityFormGroup.reset();
    } catch (error) {
      console.error(error);
      this.showImportErrorDialog();
    }
  }

  async importSchoolJson() {
    try {
      const fieldValue = this.importSchoolFormGroup.controls.jsonImport.value;
      let schools: ImportOrganizationRecord[] = JSON.parse(fieldValue);

      if (!Array.isArray(schools)) {
        schools = [schools];
      }

      await this.databaseService.importSchools(schools);

      this.showSuccessDialog();

      this.importSchoolFormGroup.reset();
    } catch (error) {
      console.error(error);
      this.showImportErrorDialog();
    }
  }

  listInteractions() {
    this.listInteractionsInProgress = true;

    try {
      const fieldValue =
        this.listInteractionsFormGroup.controls.interactionIds.value;
      const ids: Interaction['id'][] = fieldValue
        ?.split(',')
        .map((id: string) => id.replace(/\W/g, ''));

      this.interactions$ = ids
        ? this.databaseService.getInteractions(ids)
        : from(this.databaseService.getAllInteractions());

      this.interactions$
        .pipe(first(), takeUntil(this.ngDestroyed$))
        .subscribe((interactions) => {
          const data = interactions.map((interaction) =>
            pick(interaction, [
              'id',
              'name',
              'version',
              'versionDate',
              'disabled',
            ])
          );

          console.table(data);

          this.listInteractionsInProgress = false;
        });
    } catch (error) {
      this.listInteractionsInProgress = false;
      console.error(error);
    }
  }

  async importDummyDataJson() {
    try {
      const fieldValue =
        this.importDummyDataFormGroup.controls.jsonImport.value;
      const dummyData: InteractionAttempt[] = JSON.parse(fieldValue);

      await this.databaseService.importDummyData(dummyData);

      this.showSuccessDialog();

      this.importDummyDataFormGroup.reset();
    } catch (error) {
      console.error(error);
      this.showImportErrorDialog();
    }
  }

  async deleteDummyScores() {
    const dialogRefConfirm = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        data: {
          title: 'Delete dummy scores',
          text: 'Are you sure you want to delete all dummy scores?',
          preset: DialogPreset.okCancel,
        },
      }
    );

    dialogRefConfirm
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.databaseService
            .deleteDummyScores()
            .then(() => {
              this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
                data: {
                  title: 'Dummy scores deleted',
                  text: 'Data deleted successfully',
                  preset: DialogPreset.ok,
                },
              });
            })
            .catch((e) => {
              console.error(e);
              this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
                data: {
                  title: 'Oops',
                  text: `Error: ${e.message}`,
                  preset: DialogPreset.ok,
                },
              });
            });
        }
      });
  }

  async deleteScoresForUsers() {
    const uids = window
      .prompt('Enter UIDs to delete scores for')
      ?.split(',')
      .map((x) => x.trim())
      .filter((uid) => !!uid);

    if (!uids?.length) {
      return;
    }

    const dialogRefConfirm = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        data: {
          title: 'Delete user scores',
          text: `Are you sure you want to delete the scores for these users? UIDs: ${uids.join(
            ', '
          )}`,
          preset: DialogPreset.okCancel,
        },
      }
    );

    dialogRefConfirm
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.databaseService
            .deleteUserScores(uids)
            .then(() => {
              this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
                data: {
                  title: 'User scores deleted',
                  text: 'Data deleted successfully',
                  preset: DialogPreset.ok,
                },
              });
            })
            .catch((e) => {
              console.error(e);
              this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
                data: {
                  title: 'Oops',
                  text: `Error: ${e.message}`,
                  preset: DialogPreset.ok,
                },
              });
            });
        }
      });
  }

  async validateDb() {
    this.validateDbInProgress = true;
    const interactions = await this.databaseService.getAllInteractions();
    const issues = findCommonInteractionIssues(interactions);

    this.interactionIssues = issues;
    console.table(issues);

    if (!issues) {
      this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'All good',
          text: 'No issues found',
          preset: DialogPreset.ok,
        },
      });
    }

    this.validateDbInProgress = false;
  }

  async downloadStructure() {
    const findInteractionIdUnitContextLocations = (
      interactionId: string,
      unit: Unit
    ) => ({
      presentation: unit.presentation === interactionId,
      infoPopup: unit.infoPopup === interactionId,
      introduction: !!unit.introduction?.flat(Infinity).includes(interactionId),
      extra: !!unit.extra?.flat(Infinity).includes(interactionId),
      deepening: !!unit.deepening?.flat(Infinity).includes(interactionId),
      test: !!unit.test?.flat(Infinity).includes(interactionId),
    });

    const projects = await this.databaseService
      .getEntitiesOfType(EntityType.project)
      .toPromise();

    for (const project of projects) {
      const domains = (
        await this.structureService
          .getChildrenForEntity(project.id, EntityType.project)
          .toPromise()
      ).map((domain) => ({ ...domain, projectId: project.id }));

      const chapters = (
        await Promise.all(
          domains.map(async (domain) =>
            (
              await this.structureService
                .getChildrenForEntity(domain.id, EntityType.domain)
                .toPromise()
            ).map((chapter) => ({
              ...chapter,
              chapterId: chapter.id,
              domainId: domain.id,
              projectId: project.id,
            }))
          )
        )
      ).flat();

      const units = (
        await Promise.all(
          chapters.map(async (chapter) =>
            (
              await this.structureService
                .getChildrenForEntity(chapter.id, EntityType.chapter)
                .toPromise()
            ).map((unit) => ({
              ...unit,
              projectId: chapter.projectId,
              domainId: chapter.domainId,
              chapterId: chapter.chapterId,
              unitId: unit.id,
            }))
          )
        )
      ).flat();

      const interactions = (
        await Promise.all(
          units.map(async (unit) =>
            (
              await this.structureService
                .getChildrenForEntity(unit.id, EntityType.unit)
                .toPromise()
            ).map((interaction: Interaction) => ({
              ...interaction,
              projectId: unit.projectId,
              domainId: unit.domainId,
              chapterId: unit.chapterId,
              unitId: unit.unitId,
              unitVersion: unit.version,
              unitVersionDate: unit.versionDate,
              interactionId: interaction.id,
              interactionName: interaction.name,
              interactionModel: interaction.model,
              interactionVersion: interaction.version,
              interactionVersionDate: interaction.versionDate,
              foundIn: findInteractionIdUnitContextLocations(
                interaction.id,
                unit as Unit
              ),
            }))
          )
        )
      ).flat();

      const headers = [
        'Project ID',
        'Domain ID',
        'Chapter ID',
        'Unit ID',
        'Unit Version',
        'Unit Version Date',
        'Interaction ID',
        'Interaction Name',
        'Interaction Model',
        'Interaction Version',
        'Interaction Version Date',
        'Found In',
      ];

      const interactionsMapped = interactions.map((interaction) => [
        interaction.projectId,
        interaction.domainId,
        interaction.chapterId,
        interaction.unitId,
        interaction.unitVersion,
        interaction.unitVersionDate,
        interaction.interactionId,
        interaction.interactionName,
        interaction.interactionModel,
        interaction.interactionVersion,
        interaction.interactionVersionDate,
        Object.entries(interaction.foundIn)
          .filter(([, value]) => value)
          .map(([key]) => key)
          .join(', '),
      ]);

      const csvData = [headers, ...interactionsMapped];
      const dateTime = getDateAndTimeUpToSecondsFromISOString(
        new Date().toISOString()
      );

      downloadCSV(csvData, `${dateTime}-${project.id}-structure`);
    }
  }

  async listUnusedInteractions() {
    this.listUnusedInteractionsInProgress = true;

    const allInteractions = await this.databaseService.getAllInteractions();

    const projects = await this.structureService
      .getEntitiesOfType(EntityType.project)
      .toPromise();
    const usedInteractionIds = (await this.getAllDescendants(projects))
      .filter((d) => d.type === EntityType.interaction)
      .map((i) => i.id);

    usedInteractionIds.push(constants.placeholderInteractionId);

    const unusedInteraction = allInteractions.filter(
      (i) => !usedInteractionIds.includes(i.id)
    );
    const usedInteractions = allInteractions.filter((i) =>
      usedInteractionIds.includes(i.id)
    );

    console.log(
      'Unused',
      unusedInteraction.map((i) => i.id)
    );
    console.log(
      'Used',
      usedInteractions.map((i) => i.id)
    );

    this.interactions$ = of(unusedInteraction);

    this.listUnusedInteractionsInProgress = false;
  }

  async findMissingChildren() {
    this.validateChildrenInProgress = true;
    const entitiesWithMissingChildren: {
      parentId: string;
      parentType: EntityType;
      missingChildren: string[];
    }[] = [];

    const projects = await this.structureService
      .getEntitiesOfType(EntityType.project)
      .toPromise();
    const allDescendants = uniqBy(
      await this.getAllDescendants(projects),
      (entity) => entity.id + ',' + entity.type
    );

    for (const entity of projects.concat(allDescendants)) {
      const missing = await this.listMissingChildrenForEntity(entity);

      if (missing.length) {
        entitiesWithMissingChildren.push({
          parentId: entity.id,
          parentType: entity.type,
          missingChildren: missing,
        });
      }
    }

    const missingChildrenUnique = entitiesWithMissingChildren
      .map((item) => item.missingChildren)
      .flat()
      .filter((x, i, a) => a.indexOf(x) === i);

    if (missingChildrenUnique.length) {
      this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'Missing children',
          code: `Missing children (${
            missingChildrenUnique.length
          }):\n${missingChildrenUnique.join(
            ', '
          )}\n\nFound in: ${JSON.stringify(
            entitiesWithMissingChildren,
            null,
            2
          )}`,
          preset: DialogPreset.ok,
        },
      });
    } else {
      this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'Missing children',
          text: `All good. No missing children found`,
          preset: DialogPreset.ok,
        },
      });
    }

    this.validateChildrenInProgress = false;
  }

  async listMissingChildrenForEntity(
    entity: StructuralEntity
  ): Promise<StructuralEntity['id'][]> {
    if (entity.type === EntityType.interaction) {
      return [];
    }

    return await this.structureService
      .getChildrenForEntity(entity.id, entity.type)
      .toPromise()
      .then(async (children) => {
        const childList = await this.structureService
          .listChildrenForEntity(entity.id, entity.type)
          .toPromise();

        return (
          childList?.filter(
            (childId) => !children.find((child) => child.id === childId)
          ) || []
        );
      });
  }

  async getAllDescendants(
    entities: StructuralEntity[]
  ): Promise<StructuralEntity[]> {
    const filtered = entities.filter(
      (entity) => entity.type !== EntityType.interaction
    );

    const children = filtered.length
      ? (
          await this.structureService
            .getChildrenForEntities(filtered)
            .toPromise()
        )?.flat() || []
      : [];

    const childrenMapped = children.map((child) => {
      if (
        child.type === EntityType.interaction ||
        child.type === EntityType.unit
      ) {
        return castToStructuralEntity(child as Interaction | Unit);
      }

      return child;
    });

    return childrenMapped.length
      ? childrenMapped.concat(await this.getAllDescendants(childrenMapped))
      : [];
  }

  autoFixEntityJson() {
    const fieldValue = this.importEntityFormGroup.controls.jsonImport.value;
    let entities = JSON.parse(fieldValue);

    if (!Array.isArray(entities)) {
      entities = [entities];
    }

    const interactions = entities.filter(
      (x: any) => x.type === EntityType.interaction
    );
    const other = entities.filter(
      (x: any) => x.type !== EntityType.interaction
    );

    const fixed = flatten(
      interactions
        .map((interaction) => searchIssuesRecursive(interaction, true))
        .filter(utility.isTruthy)
    ) as Interaction[];

    this.importEntityFormGroup.controls.jsonImport.setValue(
      JSON.stringify(other.concat(fixed))
    );

    const issues = findCommonInteractionIssues(fixed);
    this.interactionIssues = issues;
  }

  toggleDisableInteraction(interaction: Interaction) {
    this.databaseService
      .entitySetDisabled(
        interaction.id,
        EntityType.interaction,
        !interaction.disabled
      )
      .then(() => {
        interaction.disabled = !interaction.disabled;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  setDisableInteractions(
    interactions$: Observable<Interaction[]>,
    disabled: boolean
  ) {
    this.setDisableInteractionsInProgress = true;

    interactions$
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((interactions) => {
        const promises: Promise<any>[] = [];

        interactions
          .filter((interaction) => interaction.disabled !== disabled)
          .forEach((interaction) => {
            promises.push(
              this.databaseService
                .entitySetDisabled(
                  interaction.id,
                  EntityType.interaction,
                  !interaction.disabled
                )
                .then(() => {
                  interaction.disabled = !interaction.disabled;
                })
            );
          });

        Promise.all(promises)
          .then(() => {
            this.listInteractions();
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.setDisableInteractionsInProgress = false;
          });
      });
  }

  async deleteInteractions(interactions$: Observable<Interaction[]>) {
    const confirmed = await this.dialog
      .open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'Are you sure?',
          text: 'This will delete all listed interactions and cannot be undone',
          preset: DialogPreset.cancelOk,
        },
      })
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    this.deleteInteractionsInProgress = true;

    interactions$
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((interactions) => {
        const promises: Promise<any>[] = [];

        interactions.forEach((interaction) => {
          promises.push(
            this.databaseService.deleteEntity(
              interaction.id,
              EntityType.interaction
            )
          );
        });

        return Promise.all(promises)
          .then(() => {
            this.listInteractions();

            this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
              data: {
                title: 'Success',
                text: 'Interactions deleted',
                preset: DialogPreset.ok,
              },
            });
          })
          .catch(() => {
            this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
              data: {
                title: 'Error',
                text: 'Error deleting (some) interactions',
                preset: DialogPreset.ok,
              },
            });
          })
          .finally(() => {
            this.deleteInteractionsInProgress = false;
          });
      });
  }

  private showSuccessDialog() {
    return this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Success',
        text: 'Data import successful',
        preset: DialogPreset.ok,
      },
    });
  }

  private showImportErrorDialog() {
    return this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Oops',
        text: 'Failed to import data, see console for details',
        preset: DialogPreset.ok,
      },
    });
  }

  private async overrideDialog() {
    return this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Issues detected',
        text: 'Possible issues are detected, please check and resolve them.',
        options: [
          { text: 'Force upload', value: true },
          { text: 'Check', value: false, focus: true },
        ],
      },
    });
  }
}
