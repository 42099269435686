export class CountdownTimer {
  hours: number;
  minutes: number;
  seconds: number;

  constructor(hours: number = 0, minutes: number = 0, seconds: number = 0) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  public get totalSeconds() {
    return this.hours * 3600 + this.minutes * 60 + this.seconds;
  }

  public get expired() {
    return this.totalSeconds <= 0;
  }

  public static fromMilliseconds(milliseconds: number): CountdownTimer {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return new CountdownTimer(hours, minutes, seconds);
  }

  public static fromSeconds(seconds: number): CountdownTimer {
    return CountdownTimer.fromMilliseconds(seconds * 1000);
  }
}
