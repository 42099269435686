import { GenericDataSource } from '../../services/generic-datasource';
import { UserFilter, UserService } from 'src/app/services/user.service';
import { DbUser } from 'src/app/models/user';

export class UsersDataSource extends GenericDataSource<
  DbUser,
  UserFilter
> {
  constructor(licensesService: UserService) {
    super(licensesService);
  }
}
