import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { all, create } from 'mathjs';
import { first, map, tap, withLatestFrom } from 'rxjs/operators';
import { CalculatorDialogComponent } from 'src/app/components/calculator';
import { MathFormulaParser } from 'src/app/helpers/math-formula.parser';
import { constants } from 'src/app/misc/constants';
import { calculatorActions } from '../actions/calculator.actions';
import { AppState } from '../reducers';
import {
  selectBlockedOperators,
  selectInput,
} from '../reducers/calculator.reducer';

@Injectable()
export class CalculatorEffects {
  public readonly toggleCalculatorDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calculatorActions.toggleDialog),
        tap(() => {
          this.toggleDialog();
        })
      ),
    {
      dispatch: false,
    }
  );

  public readonly closeCalculatorDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calculatorActions.closeDialog),
        tap(() => {
          this.closeDialog();
        })
      ),
    {
      dispatch: false,
    }
  );

  public readonly openCalculatorDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calculatorActions.openDialog),
        tap(() => {
          this.openDialog();
        })
      ),
    {
      dispatch: false,
    }
  );

  public readonly calculateResult = createEffect(() =>
    this.actions$.pipe(
      ofType(calculatorActions.calculate),
      withLatestFrom(
        this.store.select(selectInput),
        this.store.select(selectBlockedOperators)
      ),
      map(([, input, blockedOperators]) => {
        const result = this.calculate(input, blockedOperators);
        return calculatorActions.calculationResult({
          input,
          result,
        });
      })
    )
  );

  private calculatorDialogRef: MatDialogRef<CalculatorDialogComponent>;

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>
  ) {}

  private calculate(value: string, blockedOperators: string[]): string {
    const mathjs = create(all, {
      // eslint-disable-next-line id-blacklist
      number: 'BigNumber',
    });

    // Evaluate the expression
    const result = mathjs.evaluate(
      MathFormulaParser.parseToMachineFormat(value, blockedOperators)
    );

    // Convert the result to a number and apply custom formatting
    const formattedResult = this.formatNumber(result.toNumber());

    // Parse the formatted result back to European format
    return MathFormulaParser.parseToEuropeanFormat(
      formattedResult,
      blockedOperators
    );
  }

  private formatNumber(num: number): string {
    // Convert to fixed notation with up to 7 decimal places
    const fixed = num.toFixed(constants.roundingPrecision);

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = fixed.split('.');

    if (!decimalPart) {
      return integerPart; // Return just the integer part if there's no decimal
    }

    // Trim trailing zeros from the decimal part
    const trimmedDecimal = decimalPart.replace(/0+$/, '');

    if (trimmedDecimal.length === 0) {
      return integerPart; // Return just the integer part if all decimals were zeros
    }

    // Combine the integer part with the trimmed decimal part
    return `${integerPart}.${trimmedDecimal}`;
  }

  private toggleDialog(): void {
    if (this.calculatorDialogRef) {
      this.closeDialog();
      return;
    }

    this.openDialog();
  }

  private openDialog(): void {
    this.calculatorDialogRef = this.dialog.open<CalculatorDialogComponent>(
      CalculatorDialogComponent,
      {
        hasBackdrop: false,
        panelClass: CalculatorDialogComponent.panelClass,
        position: {
          top: '350px',
          left: '950px',
        },
        autoFocus: false,
        id: CalculatorDialogComponent.panelClass,
      }
    );

    this.store.dispatch(calculatorActions.setDialogState({ isOpen: true }));

    this.calculatorDialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.calculatorDialogRef = null;
        this.store.dispatch(
          calculatorActions.setDialogState({ isOpen: false })
        );
      });
  }

  private closeDialog(): void {
    if (this.calculatorDialogRef) {
      this.calculatorDialogRef.close();
    }
  }
}
