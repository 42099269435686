import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  CollectionReference,
  DocumentData,
  Query,
  collection,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {
  EnterLicenseResult,
  GenerateLicensePayload,
  MarkLicensesAsDistributedPayload,
} from 'functions/src/models';
import { Observable } from 'rxjs';
import { constants } from '../misc/constants';
import { License, LicenseDurationType } from '../models/license';
import { SearchService } from '../types/search-service';
import {
  PaginatedResponse,
  Pagination,
  PaginationService,
} from './pagination.service';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

export type LicenseFilter = {
  code?: License['code'];
  type?: License['type'];
  durationType?: LicenseDurationType;
  organizationId?: License['organizationId'];
  distributionChannel?: License['distributionChannel'];
  assignedTo?: License['assignedTo'];
  generatedAtStart?: License['generatedAt'];
  generatedAtEnd?: License['generatedAt'];
  distributedAtStart?: License['distributedAt'];
  distributedAtEnd?: License['distributedAt'];
};

@Injectable({
  providedIn: 'root',
})
export class LicenseService implements SearchService<LicenseFilter, License> {
  searchItems: (
    filter: LicenseFilter,
    pagination: Pagination
  ) => Observable<PaginatedResponse<License>> = this.searchLicenses.bind(this);

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private paginationService: PaginationService,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  generateLicenses(config: GenerateLicensePayload): Promise<License[]> {
    return this.aff.httpsCallable('license-generate')(config).toPromise();
  }

  enterLicenseCode(code: string): Promise<EnterLicenseResult> {
    return this.aff.httpsCallable('license-enter')(code).toPromise();
  }

  markLicensesAsDistributed(
    payload: MarkLicensesAsDistributedPayload
  ): Promise<License['distributedAt']> {
    return this.aff
      .httpsCallable('license-markLicensesAsDistributed')(payload)
      .toPromise();
  }

  searchLicenses(
    licenseFilter?: LicenseFilter,
    pagination?: Pagination
  ): Observable<PaginatedResponse<License>> {
    const buildQuery = (ref: CollectionReference) => {
      const orderByField = 'generatedAt';
      const orderByDirection = 'desc';
      let licenseQuery: Query<DocumentData> = query(
        ref,
        orderBy(orderByField, orderByDirection)
      );

      // Code takes first precedence
      if (licenseFilter.code) {
        return query(ref, where('code', '==', licenseFilter.code));
      }

      // Generated at takes second precedence
      if (licenseFilter.generatedAtStart || licenseFilter.generatedAtEnd) {
        licenseQuery = query(
          licenseQuery,
          where('generatedAt', '>=', licenseFilter.generatedAtStart || 0)
        );
        licenseQuery = query(
          licenseQuery,
          where(
            'generatedAt',
            '<=',
            licenseFilter.generatedAtEnd || this.timeService.now()
          )
        );
        return licenseQuery;
      }

      // User takes third precedence
      if (licenseFilter.assignedTo) {
        licenseQuery = query(
          licenseQuery,
          where('assignedTo', '==', licenseFilter.assignedTo)
        );
        return licenseQuery;
      }

      if (licenseFilter.distributedAtStart || licenseFilter.distributedAtEnd) {
        licenseQuery = query(
          ref,
          orderBy('distributedAt', 'desc'),
          where('distributedAt', '>=', licenseFilter.distributedAtStart || 0),
          where(
            'distributedAt',
            '<=',
            licenseFilter.distributedAtEnd || this.timeService.now()
          )
        );
      }

      if (licenseFilter.organizationId) {
        licenseQuery = query(
          licenseQuery,
          where('organizationId', '==', licenseFilter.organizationId)
        );
      }

      if (typeof licenseFilter.type === 'number') {
        licenseQuery = query(
          licenseQuery,
          where('type', '==', licenseFilter.type)
        );
      }

      if (licenseFilter.durationType) {
        licenseQuery = query(
          licenseQuery,
          where('durationType', '==', licenseFilter.durationType)
        );
      }

      if (licenseFilter.distributionChannel) {
        licenseQuery = query(
          licenseQuery,
          where('distributionChannel', '==', licenseFilter.distributionChannel)
        );
      }

      return licenseQuery;
    };

    const q = buildQuery(
      collection(this.afs.firestore, constants.dbCollections.licenses)
    );

    return this.paginationService.getPage<License>({
      queryName: 'licenses-search',
      queryFn: q,
      pagination,
    });
  }
}
