import { UnitContext } from '../enums/unit-context';
export const findUnitContext = (contextName: string) => {
  switch (contextName?.toLowerCase()) {
    case 'presentation':
      return UnitContext.presentation;
    case 'infopopup':
      return UnitContext.infoPopup;
    case 'introduction':
      return UnitContext.introduction;
    case 'extra':
      return UnitContext.extra;
    case 'deepening':
      return UnitContext.deepening;
    case 'test':
      return UnitContext.test;
    default:
      return null;
  }
};
