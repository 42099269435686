import { InteractionEvent } from '../../../shared/enums/interaction-event';
import { Interaction } from '../../../shared/models/interaction';
import {
  InteractionOptionHotspot,
  InteractionOptionImage,
  InteractionOptionText,
} from '../../../shared/models/interaction-option';

export const getFeedback = (
  parsedInteraction: Interaction,
  option:
    | InteractionOptionText
    | InteractionOptionImage
    | InteractionOptionHotspot,
  correct: boolean,
  timerExpired: boolean,
  isLastTry: boolean,
  isAfterFirstTry: boolean
) => {
  const events: Set<InteractionEvent> = new Set([
    InteractionEvent.feedbackOpen,
  ]);

  // Each step overwrites the previous.
  let feedback = '';

  // Basic and default feedback.
  if (correct) {
    feedback = parsedInteraction.feedbackCorrect
      ? parsedInteraction.feedbackCorrect
      : 'Goed.';
    events.add(InteractionEvent.feedbackCorrectOpen);
  } else {
    feedback = parsedInteraction.feedbackFalse
      ? parsedInteraction.feedbackFalse
      : 'Nee.';
    events.add(InteractionEvent.feedbackFalseOpen);
  }

  if (
    parsedInteraction.feedbackFalseAfterFirstTry &&
    !correct &&
    isAfterFirstTry
  ) {
    feedback = parsedInteraction.feedbackFalseAfterFirstTry;
  }

  // Option has feedback.
  if (option?.feedback) {
    feedback = option.feedback;
    if (correct) {
      events.add(InteractionEvent.feedbackCorrectOpen);
    } else {
      events.add(InteractionEvent.feedbackFalseOpen);
    }
  }

  // Extract the feedbackSound from the option
  const feedbackSound = option?.feedbackSound;

  // Late but correct.
  if (timerExpired && correct && parsedInteraction.feedbackCorrectLate) {
    feedback = parsedInteraction.feedbackCorrectLate;
    events.add(InteractionEvent.feedbackCorrectLateOpen);
  }

  // Option has specific late feedback.
  if (timerExpired && option?.feedbackLate) {
    feedback = option.feedbackLate;
  }

  // Last try and incorrect.
  if (isLastTry && !correct) {
    if (timerExpired && parsedInteraction.feedbackFalseLastTryLate) {
      feedback = parsedInteraction.feedbackFalseLastTryLate;
    } else if (parsedInteraction.feedbackFalseLastTry) {
      feedback = parsedInteraction.feedbackFalseLastTry;
      events.add(InteractionEvent.feedbackFalseLastTryOpen);
    }
  }

  return { feedback, events, feedbackSound };
};
