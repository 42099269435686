import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { ExamSession } from '../../../shared/models/exam-session';
import { AppRouteParams } from '../enums/route-params.enum';
import { ExamService } from '../services/exam.service';
import { TimeService } from '../services/time-service.interface';
import { examActions } from '../store/actions/exam.actions';
import { setIsLoading } from '../store/actions/shared.actions';
import { AppState } from '../store/reducers';
import {
  selectIsDbUserLoaded,
  selectUserIsTeacher,
} from '../store/reducers/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class ExamSessionDataResolver implements Resolve<ExamSession | null> {
  private userLoaded$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private examService: ExamService,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {
    this.userLoaded$ = this.store
      .select(selectIsDbUserLoaded)
      .pipe(filter((isLoaded) => isLoaded));
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ExamSession | null> {
    const examSessionId = route.paramMap.get(AppRouteParams.examSessionId);
    if (!examSessionId) {
      this.navigateToError();
      return of(null);
    }

    this.store.dispatch(setIsLoading(true));

    return this.userLoaded$.pipe(
      withLatestFrom(this.store.select(selectUserIsTeacher)),
      switchMap(([_, isTeacher]) =>
        this.handleExamSessionResolution(examSessionId, isTeacher)
      ),
      catchError((error) => {
        console.error('Error resolving exam session:', error);
        this.navigateToHome();
        return of(null);
      }),
      finalize(() => this.store.dispatch(setIsLoading(false)))
    );
  }

  private handleExamSessionResolution(
    examSessionId: string,
    isTeacher: boolean
  ): Observable<ExamSession | null> {
    return this.examService.getExamSession(examSessionId).pipe(
      switchMap((examSession) => {
        if (!examSession) {
          this.navigateToHome();
          return of(null);
        }

        if (!this.canAccessExamSession(isTeacher, examSession)) {
          this.navigateToStudentExams();
          return of(null);
        }

        return this.examService.getExam(examSession.examId).pipe(
          map((exam) => {
            this.store.dispatch(examActions.examSessionLoaded({ examSession }));
            this.store.dispatch(examActions.examLoaded({ exam }));
            return examSession;
          })
        );
      }),
      catchError((error) => {
        console.error('Error fetching exam session:', error);
        this.navigateToHome();
        return of(null);
      })
    );
  }

  private canAccessExamSession(
    isTeacher: boolean,
    examSession: ExamSession
  ): boolean {
    const isOpen = examSession.openUntil - this.timeService.now() > 0;
    return isTeacher || isOpen;
  }

  private navigateToError(): void {
    this.router.navigate(['/error']);
  }

  private navigateToHome(): void {
    this.router.navigate(['/']);
  }

  private navigateToStudentExams(): void {
    this.router.navigate(['/exams-student']);
  }
}
