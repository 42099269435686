import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ExamSession } from 'shared/models/exam-session';
import { Interaction } from 'shared/models/interaction';
import { InteractionModel } from '../../../shared/enums/interaction-model';
import { InteractionVars } from '../../../shared/models/interaction-vars';
import { Unit } from '../../../shared/models/unit';
import {
  pickRandomVarIndexes,
  pickShuffledVars,
} from '../helpers/shuffle-vars';
import {
  LocalStorageService,
  SessionInteractionUsedVarIndexes,
} from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VariationService {
  constructor() {}

  addShuffledVars(
    interaction: Interaction,
    unit?: Unit,
    examSessionId?: ExamSession['id'],
    attemptVarsShuffledIndexes?: Interaction['varsShuffledIndexes']
  ): Interaction {
    if (!interaction.vars) {
      return interaction;
    }

    let varsShuffledIndexes: Interaction['varsShuffledIndexes'];
    let sessionStorageVarsShuffledIndexes: SessionInteractionUsedVarIndexes;

    if (attemptVarsShuffledIndexes) {
      varsShuffledIndexes = attemptVarsShuffledIndexes;
    }
    // Check if random indexes are already assigned for thematic unit, otherwise assign them.
    // Ignore the presentation.
    else if (
      unit?.subtype === 'thematic' &&
      interaction.model !== InteractionModel.presentation
    ) {
      varsShuffledIndexes = LocalStorageService.getThematicUnitVars(unit.id);

      // Check the health, content might be changed or have errors
      const existAndValid = this.checkVarsShuffledIndexesHealth(
        interaction.vars,
        varsShuffledIndexes
      );

      // Create and store picks for future use when not found.
      if (!existAndValid) {
        varsShuffledIndexes = pickRandomVarIndexes(interaction.vars);
        LocalStorageService.setThematicUnitVars(unit.id, varsShuffledIndexes);
      }
    } else {
      sessionStorageVarsShuffledIndexes =
        LocalStorageService.getUsedInteractionVarIndexes(interaction.id);
    }

    // Default shuffle.
    varsShuffledIndexes ??= pickRandomVarIndexes(
      interaction.vars,
      sessionStorageVarsShuffledIndexes
    );

    // If pickRandomVarIndexes returned null, it means that all indexes are used.
    if (!varsShuffledIndexes) {
      LocalStorageService.removeUsedInteractionVarIndexes(interaction.id);
      varsShuffledIndexes = pickRandomVarIndexes(interaction.vars);
    }

    // Store varsShuffledIndexes
    if (unit?.subtype !== 'thematic' && !examSessionId) {
      LocalStorageService.addInteractionVar(
        interaction.id,
        varsShuffledIndexes
      );
    }

    // Pick the shuffled vars.
    const varsShuffled = pickShuffledVars(
      interaction.vars,
      varsShuffledIndexes
    );

    return {
      ...cloneDeep(interaction),
      varsShuffledIndexes,
      varsShuffled,
    };
  }

  removeThematicUnitVars(unitId: Unit['id']) {
    LocalStorageService.removeThematicUnitVars(unitId);
  }

  private checkVarsShuffledIndexesHealth(
    interactionVars: InteractionVars,
    varsShuffledIndexes: Interaction['varsShuffledIndexes']
  ): boolean {
    if (!varsShuffledIndexes) {
      return false;
    }

    for (const [key, value] of Object.entries(interactionVars)) {
      // Check if we have all the keys
      if (!(key in varsShuffledIndexes)) {
        return false;
      }

      // Check if the index is valid
      if (varsShuffledIndexes[key] >= value.length) {
        return false;
      }
    }

    return true;
  }
}
