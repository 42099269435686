import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidHoursMinuteString } from '../helpers/time-helper';

export const validTimeString = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (isValidHoursMinuteString(control.value)) {
    return null;
  }

  return { invalidTimeString: true };
};
