import { cloneDeep } from 'lodash';
import { UnitScore } from '../../models/score.enum';
import { Unit } from '../../models/unit';
import { UserChapterScore } from '../../models/user-chapter-score';
import { UserUnitScore } from '../../models/user-unit-score';
import { mathHelper } from '../math-helper';

export const mergeUserUnitScoreToUserChapterScore = (
  userUnitScore: UserUnitScore,
  userChapterScore: UserChapterScore
): UserChapterScore => {
  const resultObj = cloneDeep(userChapterScore);

  const alreadyDone = userChapterScore.unitsComplete.some(
    (unitId: Unit['id']) => unitId === userUnitScore.unitId
  );
  const unitComplete =
    userUnitScore.score === UnitScore.correct ||
    userUnitScore.score === UnitScore.correctWithHelp;
  const unitIncorrect = userUnitScore.score === UnitScore.incorrect;

  if (unitComplete) {
    resultObj.unitsInProgress = userChapterScore.unitsInProgress.filter(
      (unitId: Unit['id']) => unitId !== userUnitScore.unitId
    );
    resultObj.unitsIncorrect = userChapterScore.unitsIncorrect.filter(
      (unitId: Unit['id']) => unitId !== userUnitScore.unitId
    );
    if (!alreadyDone) {
      resultObj.unitsComplete.push(userUnitScore.unitId);
    }
  } else {
    resultObj.unitsInProgress = Array.from(
      new Set([...resultObj.unitsInProgress, userUnitScore.unitId])
    );
  }

  if (unitIncorrect && !alreadyDone) {
    resultObj.unitsIncorrect = Array.from(
      new Set([...resultObj.unitsIncorrect, userUnitScore.unitId])
    );
  }

  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsComplete.length)
  );
  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsInProgress.length)
  );
  resultObj.percentageIncorrect = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsIncorrect.length)
  );

  resultObj.timeTaken = userUnitScore.timeTaken;
  resultObj.totalTime = userChapterScore.totalTime + userUnitScore.timeTaken;
  resultObj.timestamp = userUnitScore.timestamp;

  return resultObj;
};
