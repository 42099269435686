import { Interaction } from 'src/app/models/interaction';
import { Rectangle, Shape } from 'src/app/types/shapes';

export const fieldInputParser = (
  interaction: Interaction,
  prefilledAnswers: string[] = []
): string => {
  let html = '';

  // Compensate for padding and such.
  const inputPadding = 4;
  const inputBorder = 2;

  interaction?.fields.forEach((field, i) => {
    const rectangle = Shape.create(field.area);
    if (!(rectangle instanceof Rectangle)) {
      throw new Error('Unsupported shape provided to create input.');
    }

    const style = [
      `top:${rectangle.topLeft.y}px;`,
      `left:${rectangle.topLeft.x}px;`,
      `width:${rectangle.width - inputPadding}px;`,
      `height:${rectangle.height - inputPadding}px;`,
      `font-size:${rectangle.height - inputPadding - inputBorder}px;`,
    ].join('');

    const value = prefilledAnswers[i] ?? '';

    html += `<input class="field-input" style="${style}" value="${value}" />`;
  });

  return html;
};
