import { UnitContext } from '../../../shared/enums/unit-context';
import { InteractionScore, UnitScore } from '../../../shared/models/score.enum';
import { UserUnitScore } from '../../../shared/models/user-unit-score';

export type UnitScoreClassName =
  | 'fill-green'
  | 'fill-yellow'
  | 'fill-red'
  | 'fill-steel-grey'
  | null;

export type UserUnitScoreMapped = Readonly<{
  // UnitContext
  [context: number]: {
    // Interaction index, score class name
    [index: number]: UnitScoreClassName;
  };
}>;

export const mapInteractionScoreToClassName = (
  score: InteractionScore
): UnitScoreClassName => {
  switch (score) {
    case InteractionScore.correct:
      return 'fill-green';
    case InteractionScore.correctWithHelp:
      return 'fill-yellow';
    case InteractionScore.incorrect:
      return 'fill-red';
    default:
      return null;
  }
};

export const mapUserUnitScoreToClassMap = (userUnitScore: UserUnitScore) => {
  if (!userUnitScore) {
    return null;
  }

  const contexts = userUnitScore.contexts;
  const classMapped: { [key: string]: { [key: string]: any } } = {
    [UnitContext.introduction]: {},
    [UnitContext.extra]: {},
    [UnitContext.deepening]: {},
    [UnitContext.test]: {},
  };

  for (const contextKey in contexts) {
    if (Object.prototype.hasOwnProperty.call(contexts, contextKey)) {
      const scoreObj = contexts[contextKey];

      for (const indexKey in scoreObj) {
        if (Object.prototype.hasOwnProperty.call(scoreObj, indexKey)) {
          const score = scoreObj[indexKey];
          classMapped[contextKey][indexKey] =
            mapInteractionScoreToClassName(score);
        }
      }
    }
  }

  return classMapped;
};

export const mapUnitScoreToClassName = (
  score: UnitScore
): UnitScoreClassName => {
  switch (score) {
    case UnitScore.correct:
      return 'fill-green';
    case UnitScore.correctWithHelp:
      return 'fill-yellow';
    case UnitScore.incorrect:
      return 'fill-red';
    case UnitScore.inconclusive:
      return 'fill-steel-grey';
    default:
      return null;
  }
};
