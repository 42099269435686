import { regularExpressions } from 'src/app/helpers/regex';
import { Interaction } from '../models/interaction';

export const selectParser = (
  inputString: string,
  interaction: Interaction,
  pos = 0
): string => {
  const dynamicSelectMatch = inputString.match(
    regularExpressions.dynamicSelect
  );

  if (dynamicSelectMatch) {
    const options = interaction.optionsText
      .filter(
        (option) => (pos === 0 && !option.field) || option.field === '' + pos
      )
      .map(
        (option) => `<option value="${option.option}">${option.option}</option>`
      );

    const selectHtml = [
      `<select>`,
      `<option></option>`,
      ...options,
      `</select>`,
    ].join('');

    inputString = inputString.replace(
      regularExpressions.dynamicSelect,
      selectHtml
    );

    return selectParser(inputString, interaction, ++pos);
  }

  return inputString;
};
