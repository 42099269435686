import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  CreateExamInstancePayload,
  SaveExamAnswerPayload,
} from 'functions/src/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Exam } from 'shared/models/exam';

import {
  DbExamSession,
  ExamSession,
  ExamSessionFinalized,
  ExamSessionNotSubmitted,
  isFinalizedExamSession,
} from 'shared/models/exam-session';
import {
  ExamInstance,
  ExamInstanceResults,
} from '../../../shared/models/exam-instance';
import {
  ExamResultsOverview,
  ExamSessionResult,
} from '../../../shared/models/exam-result';
import { ExamResultFilter } from '../../../shared/models/exam-result-filter';
import { Interaction } from '../../../shared/models/interaction';
import { EducationTrack } from '../../../shared/models/project';
import { AppUser } from '../../../shared/models/user';
import { StandaloneInteractionAttempt } from '../../../shared/models/user-interaction-attempt';
import { constants } from 'shared/constants';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

export interface ExamAdjacentIndexes {
  previous: ExamSessionIndex;
  next: ExamSessionIndex;
}

export type ExamSessionIndex = {
  sectionIndex: number;
  interactionIndex: number;
} | null;

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  constructor(
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  saveExamAnswer(saveExamAnswerPayload: SaveExamAnswerPayload): Promise<void> {
    return this.aff
      .httpsCallable('exams-saveExamAnswer')(saveExamAnswerPayload)
      .toPromise();
  }

  // TODO not used?
  getExamSessionAnswerByIndex({
    examSessionId,
    examSectionIndex,
    examInteractionIndex,
  }: {
    examSessionId: ExamSession['id'];
    examSectionIndex: number;
    examInteractionIndex: number;
  }): Promise<StandaloneInteractionAttempt['answers'] | undefined> {
    return this.getExamSession(examSessionId)
      .pipe(
        map((examSession) => {
          const examSection = examSession?.sections[examSectionIndex];
          const examInteraction =
            examSection?.interactions[examInteractionIndex];

          return examInteraction?.answers;
        })
      )
      .toPromise();
  }

  startExamSession(
    examSessionId: ExamSessionNotSubmitted['id']
  ): Promise<ExamSessionNotSubmitted> {
    return this.aff
      .httpsCallable('exams-startExamSession')(examSessionId)
      .toPromise();
  }

  submitExamSession(
    examSessionId: ExamSessionNotSubmitted['id']
  ): Promise<ExamSessionResult> {
    return this.aff
      .httpsCallable<string, ExamSessionResult>('exams-submitExamSession')(
        examSessionId
      )
      .toPromise();
  }

  createExamSession(
    examInstanceId: ExamInstance['id']
  ): Promise<ExamSessionNotSubmitted['id']> {
    return this.aff
      .httpsCallable('exams-createExamSession')(examInstanceId)
      .toPromise();
  }

  getExamInstanceById(id: ExamInstance['id']): Observable<ExamInstance> {
    return this.aff.httpsCallable('exams-getExamInstanceById')(id);
  }

  getExamInstanceResultsById(
    id: ExamInstance['id']
  ): Promise<ExamResultsOverview> {
    return this.aff
      .httpsCallable('exams-getExamInstanceResultsById')(id)
      .toPromise();
  }

  getExamInstanceByPassphrase(passphrase: string): Promise<ExamInstance> {
    return this.aff
      .httpsCallable('exams-getExamInstanceByPassphrase')(passphrase)
      .toPromise();
  }

  getExamSession(sessionId: ExamSession['id']): Observable<ExamSession> {
    if (!sessionId) {
      throw new Error('sessionId is required');
    }
    return this.aff.httpsCallable('exams-getExamSession')(sessionId);
  }

  createExamInstance(payload: CreateExamInstancePayload) {
    return this.aff
      .httpsCallable('exams-createExamInstance')(payload)
      .toPromise();
  }

  getExamSessionsForUser(uid: AppUser['uid']): Observable<ExamSession[]> {
    if (!uid) {
      throw new Error('UID is required');
    }

    return this.afs
      .collection<DbExamSession>(constants.dbCollections.examSessions, (ref) =>
        ref.where('uid', '==', uid)
      )
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        ),
        map((examSessions) =>
          examSessions.sort((a, b) => {
            const aIsFinalized = this.isFinalizedExamSessionWrapped(a);
            const bIsFinalized = this.isFinalizedExamSessionWrapped(b);

            if (!a.startedAt && !b.startedAt) {
              return 0;
            }

            if (!a.startedAt) {
              return 1;
            }

            if (!b.startedAt) {
              return -1;
            }

            if (!aIsFinalized && !bIsFinalized) {
              return b.startedAt - a.startedAt;
            }

            if (!aIsFinalized) {
              return -1;
            }

            if (!bIsFinalized) {
              return 1;
            }

            return b.finishedAt - a.finishedAt;
          })
        )
      );
  }

  getExam(examId: Exam['id']): Observable<Exam> {
    return this.afs
      .collection<Exam>(constants.dbCollections.exams)
      .doc(examId)
      .get()
      .pipe(map((docSnapshot) => docSnapshot.data()));
  }

  getExams(educationTrack?: EducationTrack): Observable<Exam[]> {
    return this.afs
      .collection<Exam>(constants.dbCollections.exams, (ref) => {
        const query = ref.orderBy('name');
        // TODO: Not needed?
        // if (educationTrack) {
        //   query = query.where('educationTrack', '==', educationTrack);
        // }
        return query;
      })
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
        )
      );
  }

  getExamInstances(filter: ExamResultFilter): Promise<ExamInstanceResults[]> {
    return this.aff.httpsCallable('exams-getExamInstances')(filter).toPromise();
  }

  storeExamInteractionShuffledVars(
    examSessionId: ExamSession['id'],
    examSectionIndex: number,
    examInteractionIndex: number,
    interactionId: Interaction['id'],
    varsShuffledIndexes: Interaction['varsShuffledIndexes']
  ) {
    return this.aff.httpsCallable('exams-storeExamInteractionShuffledVars')({
      address: {
        examSessionId,
        examSectionIndex,
        examInteractionIndex,
        interactionId,
      },
      vars: varsShuffledIndexes,
    });
  }

  isFinalizedExamSessionWrapped(
    examSession: ExamSession
  ): examSession is ExamSessionFinalized {
    return isFinalizedExamSession(
      examSession as ExamSessionFinalized,
      this.timeService.now()
    );
  }

  getExamSessionResult(
    examSessionId: ExamSession['id']
  ): Observable<ExamSessionResult> {
    return this.aff.httpsCallable<string, ExamSessionResult>(
      'exams-getExamSessionResult'
    )(examSessionId);
  }
}
