import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  FormGroupState,
  SetValueAction,
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  setErrors,
  setValue,
  updateGroup,
} from 'ngrx-forms';
import * as ArrayExtensions from '../../extensions/array.extensions';
import { MathFormulaParser } from '../../helpers/math-formula.parser';
import { calculatorActions } from '../actions/calculator.actions';

export interface CalculatorFormState {
  input: string;
}

export class BoxedString {
  constructor(public value: string) {}
}

export interface CalculatorFeatureState {
  dialogIsOpen?: boolean;
  error: boolean;
  form: FormGroupState<CalculatorFormState> | null;
  calculations: [string, string][]; // [ Input, Result ][]
  lastCopiedValue: BoxedString;
  blockedOperators: string[];
}

const initialCalculatorFormState: CalculatorFormState = {
  input: '',
};

const initialState: CalculatorFeatureState = {
  dialogIsOpen: false,
  error: false,
  form: createFormGroupState('CalculatorForm', initialCalculatorFormState),
  calculations: [],
  lastCopiedValue: new BoxedString(''),
  blockedOperators: [],
};

const reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => ({
    ...state,
    error: false,
  })),
  on(
    calculatorActions.destroyed,
    (state): CalculatorFeatureState => ({
      ...initialState,
    })
  ),
  on(
    calculatorActions.blockOperators,
    (state, { operators }): CalculatorFeatureState => ({
      ...state,
      blockedOperators: operators,
    })
  ),
  on(
    calculatorActions.input,
    (state, { value }): CalculatorFeatureState => ({
      ...state,
      error: false,
      form: setValue(state.form, {
        input: MathFormulaParser.parseToEuropeanFormat(
          state.form.controls.input.value + value,
          state.blockedOperators
        ),
      }),
    })
  ),
  on(
    calculatorActions.backspace,
    (state): CalculatorFeatureState => ({
      ...state,
      error: false,
      form: setValue(state.form, {
        input: Array.from(state.form.controls.input.value)
          .slice(0, -1)
          .join(''),
      }),
    })
  ),
  on(
    calculatorActions.revalidate,
    (state): CalculatorFeatureState => ({
      ...state,
      form: setValue(state.form, {
        input: MathFormulaParser.parseToEuropeanFormat(
          state.form.controls.input.value,
          state.blockedOperators
        ),
      }),
    })
  ),
  on(
    calculatorActions.clear,
    (state): CalculatorFeatureState => ({
      ...state,
      error: false,
      form: setValue(state.form, initialCalculatorFormState),
      calculations: [],
    })
  ),
  on(
    calculatorActions.copy,
    (state): CalculatorFeatureState => ({
      ...state,
      error: false,
      lastCopiedValue: new BoxedString(state.calculations.at(-1)?.at(1) || ''),
    })
  ),
  on(
    calculatorActions.calculationResult,
    (state, action): CalculatorFeatureState => {
      try {
        const calculations: [string, string][] = [
          ...state.calculations,
          [action.input, action.result],
        ];

        return {
          ...state,
          error: false,
          form: setValue(state.form, { input: action.result }),
          calculations,
        };
      } catch {
        return {
          ...state,
          error: true,
        };
      }
    }
  ),
  on(
    calculatorActions.setDialogState,
    (state, action): CalculatorFeatureState => ({
      ...state,
      dialogIsOpen: action.isOpen,
    })
  )
);

const validateAndUpdateForm = (featureState: CalculatorFeatureState) =>
  updateGroup<CalculatorFormState>({
    input: (state) => {
      if (featureState.error) {
        return setErrors(state, { inputError: true });
      }
      return setValue(setErrors(state, {}), state.value);
    },
  });

export const calculatorReducer = (
  state: CalculatorFeatureState | undefined,
  action: Action
): CalculatorFeatureState => {
  const reducedState = reducer(state, action);

  if (reducedState === null) {
    return reducedState;
  }

  if (reducedState.form === null) {
    return reducedState;
  }

  return {
    ...reducedState,
    form: validateAndUpdateForm(reducedState)(reducedState.form),
  };
};

export const selectCalculatorState =
  createFeatureSelector<CalculatorFeatureState>('calculator');

export const selectForm = createSelector(
  selectCalculatorState,
  (state) => state.form
);

export const selectInput = createSelector(
  selectForm,
  (form) => form?.controls.input.value
);

export const selectCalculations = createSelector(
  selectCalculatorState,
  (state) => state.calculations
);

export const selectLastCopiedValue = createSelector(
  selectCalculatorState,
  (state) => state.lastCopiedValue
);

export const selectBlockedOperators = createSelector(
  selectCalculatorState,
  (state) => state.blockedOperators
);

export const selectIsAllowedToCalculatePercentages = createSelector(
  selectBlockedOperators,
  (blockedOperators: string[]) =>
    ArrayExtensions.isNullOrUndefinedOrEmpty(blockedOperators) ||
    !blockedOperators.includes('%')
);

export const selectCalculatorDialogIsOpen = createSelector(
  selectCalculatorState,
  (state) => state.dialogIsOpen
);
