<form *ngIf="form$ | async as form" [ngrxFormState]="form" (submit)="onSubmit()" class="calculator">

  <fieldset class="input">
    <mat-form-field>
      <input matInput autocomplete="off" [ngrxFormControlState]="form.controls.input">
    </mat-form-field>
    <mat-icon class="backspace" (click)="onBackspaceButtonPress()">backspace</mat-icon>
    <mat-icon class="copy" [class.disabled-icon]="(isReadonlyMode$ | async)"
      (click)="onCopyButtonPress()">content_paste_go</mat-icon>
  </fieldset>
  <fieldset class="controls">
    <button mat-raised-button type="button" (click)="onButtonPress('(')">(</button>
    <button mat-raised-button type="button" (click)="onButtonPress(')')">)</button>
    <button mat-raised-button type="button" (click)="onButtonPress('%')"
      *ngrxLet="isAllowedToCalculatePercentages$ as isAllowedToCalculatePercentages"
      [disabled]="!isAllowedToCalculatePercentages">&percnt;</button>
    <button mat-raised-button type="button" (click)="onButtonPress('÷')">&divide;</button>
    <button mat-raised-button type="button" (click)="onButtonPress('7')">7</button>
    <button mat-raised-button type="button" (click)="onButtonPress('8')">8</button>
    <button mat-raised-button type="button" (click)="onButtonPress('9')">9</button>
    <button mat-raised-button type="button" (click)="onButtonPress('×')">&times;</button>
    <button mat-raised-button type="button" (click)="onButtonPress('4')">4</button>
    <button mat-raised-button type="button" (click)="onButtonPress('5')">5</button>
    <button mat-raised-button type="button" (click)="onButtonPress('6')">6</button>
    <button mat-raised-button type="button" (click)="onButtonPress('+')">&plus;</button>
    <button mat-raised-button type="button" (click)="onButtonPress('1')">1</button>
    <button mat-raised-button type="button" (click)="onButtonPress('2')">2</button>
    <button mat-raised-button type="button" (click)="onButtonPress('3')">3</button>
    <button mat-raised-button type="button" (click)="onButtonPress('-')">&minus;</button>
    <button mat-raised-button type="button" (click)="onClearButtonPress()">C</button>
    <button mat-raised-button type="button" (click)="onButtonPress('0')">0</button>
    <button mat-raised-button type="button" (click)="onButtonPress(',')">,</button>
    <button mat-raised-button type="button" (click)="onSubmit()">&equals;</button>
  </fieldset>

</form>