import { OverlayContainer } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { toTop } from 'src/app/misc/dialog-utils';

@Component({
  selector: 'app-calculator-dialog',
  templateUrl: './calculator-dialog.component.html',
  styleUrls: ['./calculator-dialog.component.scss'],
})
export class CalculatorDialogComponent {
  public static panelClass = 'calculator-dialog';

  constructor(public cdk: OverlayContainer) {}

  moveToTop = () => toTop(CalculatorDialogComponent.panelClass, this.cdk);
}
