import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { ExamService } from 'src/app/services/exam.service';
import { studentExamsActions } from '../../actions/student-exams.actions';

@Injectable()
export class StudentExamEffects {
  loadStudentExams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(studentExamsActions.loadExamSessionsForUser),
      switchMap(({ uid }) =>
        this.examService
          .getExamSessionsForUser(uid)
          .pipe(
            map((examSessions) =>
              studentExamsActions.examSessionsForUserLoaded({ examSessions })
            )
          )
      )
    )
  );
  constructor(private actions$: Actions, private examService: ExamService) {}
}
