import { cloneDeep } from 'lodash';
import { UserDomainScore } from '../../models/user-domain-score';
import { UserProjectScore } from '../../models/user-project-score';
import { createIdChecker, createIdFilter } from '../array';
import { mathHelper } from '../math-helper';

export const mergeUserUserDomainScoreToUserProjectScore = (
  userDomainScore: UserDomainScore,
  userProjectScore: UserProjectScore
): UserProjectScore => {
  const filterFrom = createIdFilter(userDomainScore.domainId);
  const containedIn = createIdChecker(userDomainScore.domainId);

  const resultObj = cloneDeep(userProjectScore);

  const domainNotStarted =
    userDomainScore.chaptersComplete.length === 0 &&
    userDomainScore.chaptersInProgress.length === 0;

  if (domainNotStarted) {
    resultObj.domainsInProgress = filterFrom(resultObj.domainsInProgress);
    resultObj.domainsComplete = filterFrom(resultObj.domainsComplete);
  }

  const domainComplete =
    userDomainScore.childCount === userDomainScore.chaptersComplete.length;

  if (domainComplete) {
    resultObj.domainsInProgress = filterFrom(resultObj.domainsInProgress);
    if (!containedIn(userProjectScore.domainsComplete)) {
      resultObj.domainsComplete.push(userDomainScore.domainId);
    }
  } else {
    resultObj.domainsComplete = filterFrom(resultObj.domainsComplete);
    if (!containedIn(userProjectScore.domainsInProgress)) {
      resultObj.domainsInProgress.push(userDomainScore.domainId);
    }
  }

  // Update percentages
  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    (resultObj.domainsComplete.length / userProjectScore.childCount) * 100
  );

  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    (resultObj.domainsInProgress.length / userProjectScore.childCount) * 100
  );

  resultObj.timeTaken = userDomainScore.timeTaken;
  resultObj.totalTime = userProjectScore.totalTime + userDomainScore.timeTaken;
  resultObj.timestamp = userDomainScore.timestamp;

  return resultObj;
};
