import { HoursMinutesString } from '../../src/app/helpers/time-helper';
import { Domain } from '../../src/app/models/domain';
import { Group } from '../../src/app/models/group';
import { Project } from '../../src/app/models/project';
import { DbUser } from '../../src/app/models/user';
import { UserChapterScore } from '../../src/app/models/user-chapter-score';
import {
  DraftUserUnitScore,
  UserUnitScore,
} from '../../src/app/models/user-unit-score';
import { Modify } from '../../src/app/types/utility-types';
import { ChapterScore } from './score.enum';

export type TeacherDashboardFilters = {
  group: Group['id'] | undefined;
  project: Project['id'] | undefined;
  domain: Domain['id'] | undefined;
  startDate: number | undefined;
  endDate: number | undefined;
  selectedUsers: DbUser['uid'][] | undefined;
};

export type TeacherDashboardTableData =
  TeacherDashboardTableDataProjectSection[];

export type TeacherDashboardTableDataGrouped =
  TeacherDashboardTableDataProjectSectionWithGroupedScores[];

export type TeacherDashboardTableDataProjectSection = {
  projectId: string | null;
  projectTitle: string;
  tableRows: TeacherDashboardTableRow[];
};
export type TeacherDashboardTableRowWithGroupedScores = Modify<
  TeacherDashboardTableRow,
  {
    scores: TeacherDashboardScore[][];
  }
>;

export type TeacherDashboardTableDataProjectSectionWithGroupedScores = Modify<
  TeacherDashboardTableDataProjectSection,
  {
    tableRows: Array<TeacherDashboardTableRowWithGroupedScores>;
  }
>;

export type TeacherDashboardTableRow = {
  projectId: string | null;
  uid: DbUser['uid'];
  name: DbUser['name'];
  firstName: DbUser['firstName'];
  lastName: DbUser['lastName'];
  groups: string;
  timeTotal: HoursMinutesString;
  timeOverSelectionString: HoursMinutesString;
  scores: TeacherDashboardScore[];
};

export interface TeacherDashboardUnitScore {
  unitId: UserUnitScore['unitId'];
  chapterId: UserUnitScore['chapterId'];
  domainId: UserUnitScore['domainId'];
  projectId: UserUnitScore['projectId'];
  score: UserUnitScore['score'];
  totalTime: UserUnitScore['totalTime'];
  timestamp: UserUnitScore['timestamp'];
  timeOverSelection?: number;
}

export type TeacherDashboardChapterScore = {
  chapterId: UserChapterScore['chapterId'];
  domainId: UserChapterScore['domainId'];
  projectId: UserChapterScore['projectId'];
  percentageComplete: UserChapterScore['percentageComplete'];
  percentageInProgress: UserChapterScore['percentageInProgress'];
  percentageIncorrect: UserChapterScore['percentageIncorrect'];
  score: ChapterScore;
  totalTime: UserChapterScore['totalTime'];
  timestamp: UserChapterScore['timestamp'];
  timeOverSelection?: number;
};

export type TeacherDashboardUnitTupleScore = [
  TeacherDashboardUnitScore,
  { score: TeacherDashboardUnitScore['score'] }?
];

export type TeacherDashboardChapterTupleScore = [
  TeacherDashboardChapterScore,
  { score: TeacherDashboardChapterScore['score'] }?
];

export interface HasTotalTime {
  totalTime: number;
}

export type TimeFromScoresType = [HasTotalTime, HasTotalTime?];

export type StudentWithProject = DbUser & {
  lastProjectId: string | null;
};

export type StrippedUnitScore = Omit<UserUnitScore | DraftUserUnitScore, 'uid'>;

export type StrippedChapterScore = Omit<
  UserChapterScore,
  'uid' | 'childCount' | 'unitsComplete' | 'unitsInProgress' | 'unitsIncorrect'
>;

export type CombinedUnitScoreType = [
  UserUnitScore | DraftUserUnitScore,
  (UserUnitScore | DraftUserUnitScore) | undefined
];

export type CombinedChapterScoreType = [
  UserChapterScore,
  UserChapterScore | undefined
];

export type CombinedChapterScoreTypeMapped = [
  StrippedChapterScore & { timeOverSelection: number; score: number },
  { score: number } | undefined
];

export type UnitScoreWithTimeOverSelection = (
  | UserUnitScore
  | DraftUserUnitScore
) & { timeOverSelection: number };

export type StrippedUnitScoreWithTimeOverSelection = StrippedUnitScore & {
  timeOverSelection: number;
};

export type ChapterScoreWithTimeOverSelection = UserChapterScore & {
  timeOverSelection: number;
};

export type DashboardUnitScoreTuple = [
  StrippedUnitScoreWithTimeOverSelection,
  { score: number } | undefined
];

export type StrippedChapterScoreWithTimeOverSelection = StrippedChapterScore & {
  timeOverSelection: number;
};

export type UnitScoresForStudentType = {
  primaryScores: (UserUnitScore | DraftUserUnitScore)[] | null;
  secondaryScores: (UserUnitScore | DraftUserUnitScore)[] | null;
};

export type ChapterScoresForStudentType = {
  primaryScores: UserChapterScore[] | null;
  secondaryScores: UserChapterScore[] | null;
};

export type TeacherDashboardScore =
  | TeacherDashboardUnitTupleScore
  | TeacherDashboardChapterTupleScore;

export const isTeacherDashboardUnitScore = (
  score: TeacherDashboardUnitScore | TeacherDashboardChapterScore
): score is TeacherDashboardUnitScore => score && 'unitId' in score;

export const isTeacherDashboardUnitTupleScore = (
  score: TeacherDashboardScore
): score is TeacherDashboardUnitTupleScore => score && 'unitId' in score[0];
