import { Chapter } from './chapter';
import { Domain } from './domain';
import { Project } from './project';
import { AppUser } from './user';
import { UserChapterScore } from './user-chapter-score';
import { StandaloneInteractionAttempt } from './user-interaction-attempt';

export type UserDomainScore = {
  uid: AppUser['uid'];
  domainId: Domain['id'];
  projectId: Project['id'];
  childCount: number;
  chaptersComplete: Chapter['id'][];
  chaptersInProgress: Chapter['id'][];
  percentageComplete: number;
  percentageInProgress: number;
  timeTaken: number;
  totalTime: number; // Set server-side in ms
  timestamp?: StandaloneInteractionAttempt['timestamp']; // Set server-side
};

export const getUserDomainScorePath = ({
  projectId,
  domainId,
}: UserDomainScore) => `${projectId}-${domainId}`;

export const getUserDomainScorePathFromUserChapterScore = ({
  projectId,
  domainId,
  chapterId,
}: UserChapterScore) => `${projectId}-${domainId}-${chapterId}`;

export const buildUserDomainScorePath = (projectId: string, domainId: string) =>
  `${projectId}-${domainId}`;

export const buildEmptyUserDomainScore = (
  uid: AppUser['uid'],
  domainId: Domain['id'],
  projectId: Project['id']
): UserDomainScore => ({
  uid,
  domainId,
  projectId,
  childCount: 0, // TODO check
  chaptersComplete: [],
  chaptersInProgress: [],
  percentageComplete: 0,
  percentageInProgress: 0,
  timeTaken: 0,
  totalTime: 0,
});
