import { UserReference } from './user';

export type Group = {
  id: string;
  organizationId: string;
  name: string;
  owners: UserReference[];
  createdBy?: UserReference;
  createdDate?: string;
};

export type DbGroup = Omit<Group, 'id'>;

export type GroupReference = {
  id: Group['id'];
  name: Group['name'];
};

export const getGroupRef = (group: Group): GroupReference => ({
  id: group.id,
  name: group.name,
});

export const buildGroupRef = (id: string, name: string): GroupReference => ({
  id,
  name,
});
