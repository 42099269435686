import { regularExpressions } from 'src/app/helpers/regex';

export const inputParser = (inputString: string): string => {
  const dynamicInputMatch = inputString.match(regularExpressions.dynamicInput);

  if (dynamicInputMatch) {
    const paramString = dynamicInputMatch[1];
    const widthRegex = /width="?(\w+)"?/;
    const widthMatch = paramString.match(widthRegex);
    const width = widthMatch && widthMatch[1];
    const inputHtml = `<input type="text" ${
      width ? 'style="width: ' + width + ';"' : ''
    }/>`;

    inputString = inputString.replace(
      regularExpressions.dynamicInput,
      inputHtml
    );

    return inputParser(inputString);
  }

  return inputString;
};
