<!-- THIS IS FOR DEV TESTING-->

<header mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary="body">
    <h2 mat-dialog-title>Time Travel</h2>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</header>

<button mat-icon-button class="icon-display" (click)="addMinutes(-5)"><mat-icon>replay_5</mat-icon></button>
<button mat-icon-button class="icon-display" (click)="addMinutes(-10)"><mat-icon>replay_10</mat-icon></button>
<button mat-icon-button class="icon-display" (click)="addMinutes(-30)"><mat-icon>replay_30</mat-icon></button>

<button mat-icon-button class="icon-display" (click)="addMinutes(5)"><mat-icon>forward_5</mat-icon></button>
<button mat-icon-button class="icon-display" (click)="addMinutes(10)"><mat-icon>forward_10</mat-icon></button>
<button mat-icon-button class="icon-display" (click)="addMinutes(30)"><mat-icon>forward_30</mat-icon></button>