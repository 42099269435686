export enum InteractionScore {
  null,
  incorrect,
  correctWithHelp,
  correct,
}

export enum UnitScore {
  null,
  inconclusive,
  incorrect,
  correctWithHelp,
  correct,
}

export enum ChapterScore {
  null,
  inProgress,
  incorrect,
  nearComplete,
  complete,
}
