import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { UserChapterScore } from 'shared/models/user-chapter-score';
import { UserDomainScore } from 'shared/models/user-domain-score';
import { UserProjectScore } from 'shared/models/user-project-score';
import {
  DatabaseService,
  GetScoreParams,
} from '../../services/database.service';
import { AppState } from '../../store/reducers/index';
import { selectUserUid } from '../../store/reducers/user.reducer';

export type Progress = {
  percentageInProgress: number;
  percentageComplete: number;
};

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent implements OnInit, OnDestroy {
  @Input() scoreParams: GetScoreParams;
  @Input() diameter = 50;
  @Input() strokeWidth = 10;

  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  progress$: Observable<Progress>;

  constructor(
    private databaseService: DatabaseService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.progress$ = this.store.select(selectUserUid).pipe(
      filter((uid) => !!uid),
      switchMap((uid) =>
        this.databaseService.getUserScoreForEntity({
          ...this.scoreParams,
          uid: this.scoreParams?.uid || uid,
        })
      ),
      map(
        ({
          percentageInProgress,
          percentageComplete,
        }: UserChapterScore | UserDomainScore | UserProjectScore) => ({
          percentageInProgress,
          percentageComplete,
        })
      ),
      takeUntil(this.ngDestroyed$)
    );
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
