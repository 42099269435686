import { Chapter } from './chapter';
import { Domain } from './domain';
import { Project } from './project';
import { ChapterScore } from './score.enum';
import { Unit } from './unit';
import { AppUser } from './user';
import { StandaloneInteractionAttempt } from './user-interaction-attempt';
import { UserUnitScore } from './user-unit-score';

export type UserChapterScore = {
  uid: AppUser['uid'];
  chapterId: Chapter['id'];
  domainId: Domain['id'];
  projectId: Project['id'];
  childCount: number;
  unitsComplete: Unit['id'][];
  unitsInProgress: Unit['id'][];
  unitsIncorrect: Unit['id'][];
  percentageComplete: number; // TODO make helpers for these redundant properties
  percentageInProgress: number;
  percentageIncorrect: number;
  timeTaken: number;
  totalTime: number; // Set server-side in ms
  timestamp?: StandaloneInteractionAttempt['timestamp']; // Set server-side
};

export const getUserChapterScorePath = ({
  projectId,
  domainId,
  chapterId,
}: UserChapterScore) => `${projectId}-${domainId}-${chapterId}`;

export const getUserChapterScorePathFromUserUnitScore = ({
  projectId,
  domainId,
  chapterId,
}: UserUnitScore) => `${projectId}-${domainId}-${chapterId}`;

export const buildUserChapterScorePath = (
  projectId: string,
  domainId: string,
  chapterId: string
) => `${projectId}-${domainId}-${chapterId}`;

export const buildEmptyUserChapterScore = (
  uid: AppUser['uid'],
  chapterId: Chapter['id'],
  domainId: Domain['id'],
  projectId: Project['id'],
  childCount?: number
): UserChapterScore => ({
  uid,
  chapterId,
  domainId,
  projectId,
  childCount: childCount || 0, // TODO check if this can cause issues
  unitsComplete: [],
  unitsInProgress: [],
  unitsIncorrect: [],
  percentageComplete: 0,
  percentageInProgress: 0,
  percentageIncorrect: 0,
  timeTaken: 0,
  totalTime: 0,
});

export const calculateChapterScore = (
  userChapterScore: UserChapterScore
): ChapterScore => {
  const { percentageComplete, percentageInProgress, percentageIncorrect } =
    userChapterScore;

  switch (true) {
    case percentageComplete > 99:
      return ChapterScore.complete;
    case percentageComplete >= 75:
      return ChapterScore.nearComplete;
    case percentageIncorrect >= 25:
      return ChapterScore.incorrect;
    case percentageComplete + percentageInProgress > 0:
      return ChapterScore.inProgress;
    default:
      return ChapterScore.null;
  }
};
