import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { calcUnitScore } from 'src/app/helpers/calc-unit-score';
import {
  UnitScoreClassName,
  UserUnitScoreMapped,
  mapUnitScoreToClassName,
} from 'src/app/helpers/score-helper';
import { UserUnitScore } from 'src/app/models/user-unit-score';
import { UnitContext } from '../../enums/unit-context';
import { mapUserUnitScoreToClassMap } from '../../helpers/score-helper';
import { Unit } from '../../models/unit';

type UnitData = {
  unit: Unit;
  userUnitScoreMapped: UserUnitScoreMapped;
};

const introductionOffset = 110;
const contextPadding = 10;

@Component({
  selector: 'app-student-unit-score',
  templateUrl: './student-unit-score.component.html',
  styleUrls: ['./student-unit-score.component.scss'],
})
export class StudentUnitScoreComponent implements OnInit, OnDestroy {
  @Input() unit$: Observable<Unit>;
  @Input() userUnitScore$: Observable<UserUnitScore>;

  unitScoreClass$: Observable<UnitScoreClassName>;

  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  unitData$: Observable<UnitData>;
  unitData: UnitData;

  unitContexts = UnitContext;

  renderUnitContexts = [
    UnitContext.introduction,
    UnitContext.extra,
    UnitContext.deepening,
    UnitContext.test,
  ];

  distanceBetweenCircles = 50;

  basePath = '';
  uid = '';

  constructor(private router: Router) {}

  ngOnInit() {
    this.userUnitScore$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((userUnitScore: UserUnitScore) => {
        this.basePath =
          `${userUnitScore.projectId}/${userUnitScore.domainId}/${userUnitScore.chapterId}/` +
          `${userUnitScore.unitId}/`;
        this.uid = userUnitScore.uid;
      });

    const userUnitScoreMapped$ = this.userUnitScore$.pipe(
      filter((userUnitScore) => !!userUnitScore),
      map(mapUserUnitScoreToClassMap),
      takeUntil(this.ngDestroyed$)
    );

    this.unitScoreClass$ = this.userUnitScore$.pipe(
      filter((userUnitScore) => !!userUnitScore),
      map(calcUnitScore),
      map(mapUnitScoreToClassName),
      takeUntil(this.ngDestroyed$)
    );

    this.unitData$ = combineLatest([this.unit$, userUnitScoreMapped$]).pipe(
      filter(([unit, userUnitScoreMapped]) => !!unit && !!userUnitScoreMapped),
      map(([unit, userUnitScoreMapped]) => ({
        unit,
        userUnitScoreMapped,
      })),
      takeUntil(this.ngDestroyed$)
    );

    this.unitData$.pipe(takeUntil(this.ngDestroyed$)).subscribe((unitData) => {
      this.unitData = unitData;
    });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  getUnitContextTransform(context: UnitContext) {
    let offset = 0;
    for (const unitContext of this.renderUnitContexts) {
      if (unitContext === context) {
        break;
      }
      const length =
        this.unitData?.unit[this.unitContexts[unitContext]]?.length;
      offset += !!length ? length * this.distanceBetweenCircles : 0;
    }
    return (
      'translate(' +
      (offset +
        introductionOffset +
        this.renderUnitContexts.indexOf(context) * contextPadding) +
      ')'
    );
  }

  getScoreCircleTransform() {
    const { introduction, extra, deepening, test, subtype } =
      this.unitData.unit;
    const lastContextOffset =
      subtype === 'thematic' ? contextPadding * 2 : contextPadding * 5;
    const totalLength =
      introduction.length + extra.length + deepening.length + test.length;
    const translateValue =
      totalLength * this.distanceBetweenCircles +
      introductionOffset +
      lastContextOffset;

    return 'translate(' + translateValue + ')';
  }

  calculateLineLength() {
    const { introduction, extra, deepening, test } = this.unitData.unit;
    const extraOffset = 40;
    const totalLength =
      introduction.length + extra.length + deepening.length + test.length;
    return (
      totalLength * this.distanceBetweenCircles +
      introductionOffset +
      extraOffset
    );
  }

  navigateToPath(path: string) {
    const url = this.basePath + '/' + path + '/' + this.uid;
    window.open(this.router.createUrlTree([url]).toString(), '_blank');
  }
}
