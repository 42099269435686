import { Point } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-point-spot',
  templateUrl: './point-spot.component.html',
  styleUrls: ['./point-spot.component.scss'],
})
export class PointSpotComponent {
  @Input() point: Point;
  @Input() num: number;
  @Input() correct: boolean;

  constructor(private elRef: ElementRef) {}

  getClass() {
    return {
      correct: this.correct === true,
      incorrect: this.correct === false,
    };
  }
}
