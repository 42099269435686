import { AppUser } from 'shared/models/user';
import { UnitContext } from 'shared/enums/unit-context';
import { InteractionIndex, Unit, unitContextsOrder } from 'shared/models/unit';
import { InteractionScore } from '../../../shared/models/score.enum';
import { UserUnitScore } from '../../../shared/models/user-unit-score';
import { unitContextToString } from './unit-context-to-string';
import { calcMinIntroScore } from 'shared/helpers/scores/calc-unit-score';

export const findNextInteractionRoute = (
  unit: Unit,
  unitContext: UnitContext,
  interactionIndex: InteractionIndex,
  userUnitScore: UserUnitScore
): string | null => {
  const surpassMinIntroScore = calcMinIntroScore(userUnitScore);
  const next =
    (unit.subtype === 'thematic' &&
      (findNextInteraction(
        unit,
        userUnitScore,
        unitContext,
        interactionIndex,
        false,
        (score) => score === undefined // First untouched interaction
      ) ||
        findNextInteraction(
          unit,
          null,
          unitContext,
          interactionIndex,
          false,
          (score) => true
        ))) ||
    findNextInteraction(
      unit,
      userUnitScore,
      unitContext,
      interactionIndex,
      surpassMinIntroScore,
      (score) =>
        score === undefined ||
        score === InteractionScore.correctWithHelp ||
        score === InteractionScore.incorrect
    ) ||
    findNextInteraction(
      unit,
      userUnitScore,
      unitContext,
      interactionIndex,
      surpassMinIntroScore,
      (score) => score === InteractionScore.correct
    );

  if (next) {
    return `${unitContextToString(next[0])}/${next[1] + 1}`;
  }

  return null;
};

export const nextUnitContext = (
  unitContext: UnitContext
): UnitContext | null => {
  const currentIndex = unitContextsOrder.indexOf(unitContext);
  const nextIndex = currentIndex + 1;
  return nextIndex < unitContextsOrder.length
    ? unitContextsOrder[nextIndex]
    : null;
};

// export const previousUnitContext = (
//   unitContext: UnitContext
// ): UnitContext | null => {
//   const currentIndex = unitContextsOrder.indexOf(unitContext);
//   const prevIndex = currentIndex - 1;
//   return prevIndex >= 0 ? unitContextsOrder[prevIndex] : null;
// };

const findNextInteraction = (
  unit: Unit,
  userUnitScore: UserUnitScore,
  unitContext: UnitContext,
  interactionIndex: InteractionIndex,
  skipExtra: boolean,
  scoreTestFunction: (score: InteractionScore | undefined) => boolean
): [UnitContext, InteractionIndex] | null => {
  // Always do the test.
  if (unitContext === UnitContext.test) {
    if (interactionIndex < 3) {
      return [UnitContext.test, interactionIndex + 1];
    }
  } else if (unitContext === UnitContext.deepening && interactionIndex === 2) {
    return [UnitContext.test, 0];
  }

  const userScoreContexts = userUnitScore?.contexts || {};
  const userScoreContextArrays = {};
  userScoreContextArrays[UnitContext.introduction] = new Array(
    unit.introduction.length
  );
  userScoreContextArrays[UnitContext.extra] = new Array(unit.extra.length);
  userScoreContextArrays[UnitContext.deepening] = new Array(
    unit.deepening.length
  );
  userScoreContextArrays[UnitContext.test] = new Array(unit.test.length);

  for (const contextKey in userScoreContexts) {
    if (Object.prototype.hasOwnProperty.call(userScoreContexts, contextKey)) {
      const scoreObj = userScoreContexts[contextKey];
      for (const indexKey in scoreObj) {
        if (Object.prototype.hasOwnProperty.call(scoreObj, indexKey)) {
          const score = scoreObj[indexKey];
          userScoreContextArrays[contextKey][indexKey] = score;
        }
      }
    }
  }

  if (unitContext === UnitContext.presentation) {
    unitContext = UnitContext.introduction;
    interactionIndex = -1;
  }

  let firstLoop = true;
  const originIndex = interactionIndex;
  const originContext = unitContext;
  do {
    while (++interactionIndex < userScoreContextArrays[unitContext].length) {
      // We went full circle
      if (interactionIndex === originIndex && unitContext === originContext) {
        return null;
      }

      // Check if the score matches our test function
      const score: InteractionScore =
        userScoreContextArrays[unitContext][interactionIndex];
      if (scoreTestFunction(score)) {
        return [unitContext, interactionIndex];
      }
    }

    interactionIndex = -1;

    // Additional skip for extra
    if (skipExtra && unitContext === UnitContext.introduction) {
      unitContext = nextUnitContext(unitContext);
    }

    unitContext = nextUnitContext(unitContext);

    // Loop around to the beginning if needed
    if (unitContext === null && firstLoop) {
      unitContext = UnitContext.introduction;
      firstLoop = false;
    }
  } while (unitContext !== null);

  return null;
};

const getContextLengths = (unit: Unit) => ({
  [UnitContext.presentation]: unit.presentation ? 1 : 0,
  [UnitContext.introduction]: unit.introduction?.length || 0,
  [UnitContext.extra]: unit.extra?.length || 0,
  [UnitContext.deepening]: unit.deepening?.length || 0,
  [UnitContext.test]: unit.test?.length || 0,
});

const getAvailableContexts = (unit: Unit): UnitContext[] => {
  if (unit.subtype === 'thematic') {
    return [UnitContext.presentation, UnitContext.introduction];
  }
  return [
    UnitContext.presentation,
    UnitContext.introduction,
    UnitContext.extra,
    UnitContext.deepening,
    UnitContext.test,
  ];
};

export type FindReadonlyRoutePayload = {
  unit: Unit;
  unitContext: UnitContext;
  interactionIndex: InteractionIndex;
  uid: AppUser['uid'];
};

const isValidFindReadonlyRoutePayload = (
  payload: FindReadonlyRoutePayload
): boolean =>
  !!payload.unit?.id &&
  typeof payload.unitContext === typeof UnitContext.presentation &&
  typeof payload.interactionIndex === 'number' &&
  !!payload.uid;

export const findNextReadonlyInteractionRoute = (
  payload: FindReadonlyRoutePayload
): string[] | null => {
  const { unit, unitContext, interactionIndex, uid } = payload;

  if (!isValidFindReadonlyRoutePayload(payload)) {
    return null;
  }

  const contextLengths = getContextLengths(unit);
  const contexts = getAvailableContexts(unit);
  let currentContextIndex = contexts.indexOf(unitContext);
  let nextInteractionIndex = interactionIndex + 1;

  if (nextInteractionIndex >= contextLengths[unitContext]) {
    currentContextIndex += 1;
    if (currentContextIndex < contexts.length) {
      nextInteractionIndex = 0;
    } else {
      return null;
    }
  }

  const nextContext = contexts[currentContextIndex];
  return [
    unitContextToString(nextContext),
    (nextInteractionIndex + 1).toString(), // +1 to match the route
    uid,
  ];
};

export const findPrevReadonlyInteractionRoute = (
  payload: FindReadonlyRoutePayload
): string[] | null => {
  const { unit, unitContext, interactionIndex, uid } = payload;

  if (!isValidFindReadonlyRoutePayload(payload)) {
    return null;
  }

  if (unitContext === UnitContext.presentation) {
    return null;
  }

  const contextLengths = getContextLengths(unit);
  const contexts = getAvailableContexts(unit);
  let currentContextIndex = contexts.indexOf(unitContext);
  let prevInteractionIndex = interactionIndex - 1;

  let prevContext = contexts[currentContextIndex];

  if (prevInteractionIndex < 0 && currentContextIndex > 0) {
    currentContextIndex -= 1;
    prevContext = contexts[currentContextIndex];
    prevInteractionIndex = contextLengths[prevContext] - 1;
  } else if (prevInteractionIndex < 0) {
    return null; // At the start of the first context
  }

  return [
    unitContextToString(prevContext),
    (prevInteractionIndex + 1).toString(), // +1 to match the route
    uid,
  ];
};
