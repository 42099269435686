import { EntityType } from '../enums/entity-type';
import { Chapter } from './chapter';
import { Domain } from './domain';
import { DbInteraction, Interaction } from './interaction';
import { Project } from './project';
import { EntityContext } from './shared-basics';
import { DbUnit, Unit } from './unit';

export type StructuralEntity = Readonly<{
  presentation?: never;
  id: string;
  title?: string;
  shortTitle?: string;
  name?: string;
  type: EntityType;
  disabled?: boolean; // Demo purpose
  public?: Project['public'];
  educationTrack?: Project['educationTrack'];
  subtitle?: Project['subtitle'];
  projectColor?: Project['projectColor'];
  children?: string[]; // Entity IDs
  context?: EntityContext;
  icon?: string;
  model?: string;
  version: string;
  versionDate: string;
}>;

export const castToStructuralEntity = (
  entity:
    | Project
    | Domain
    | Chapter
    | DbUnit
    | Unit
    | DbInteraction
    | Interaction
): StructuralEntity => ({
  id: entity.id,
  title: (entity as Project | Domain | Chapter | DbUnit | Unit).title,
  shortTitle: (entity as Project | Domain | Chapter | DbUnit | Unit).shortTitle,
  name: entity.name,
  type: entity.type,
  disabled: (entity as Project | Domain | Chapter | DbUnit | Unit).disabled,
  public: (entity as Project).public,
  educationTrack: (entity as Project).educationTrack,
  projectColor: (entity as Project).projectColor,
  subtitle: (entity as Project).subtitle,
  children: (entity as Project | Domain | Chapter).children,
  context: (entity as Project | Domain | Chapter | DbUnit | Unit).context,
  icon: (entity as Project | Domain | Chapter | DbUnit | Unit).icon,
  model: (entity as DbInteraction | Interaction).model,
  version: entity.version,
  versionDate: entity.versionDate,
});
