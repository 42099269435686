import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppUser, DbUser } from 'src/app/models/user';
import { DatabaseService } from 'src/app/services/database.service';
import { nonProtectedUserRoles, UserRole } from '../../enums/user-role.enum';
import { getRoleName } from '../../helpers/get-role-name';

@Component({
  selector: 'app-set-roles',
  templateUrl: './set-roles.component.html',
  styleUrls: ['./set-roles.component.scss'],
})
export class SetRolesComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  targetUser: DbUser;
  roles: UserRole[] = Object.values(UserRole).filter(
    (r) => typeof r === 'number'
  ) as UserRole[];
  displayedColumns = ['name', 'has-role', 'actions'];
  rolesBeingHandled: UserRole[] = [];

  constructor(
    private databaseService: DatabaseService,
    @Inject(MAT_DIALOG_DATA) private targetUid: AppUser['uid']
  ) {}

  ngOnInit() {
    this.databaseService
      .getUser(this.targetUid)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((user) => (this.targetUser = user));
  }

  displayCell(cellData: UserRole) {
    return getRoleName(cellData);
  }

  isProtected(role: UserRole) {
    return !nonProtectedUserRoles.includes(role);
  }

  userHasRole(role: UserRole): boolean {
    return !!this.targetUser?.roles?.includes(role);
  }

  roleIsBeingHandled(role: UserRole): boolean {
    return this.rolesBeingHandled.includes(role);
  }

  addRole(role: UserRole) {
    this.rolesBeingHandled.push(role);
    this.databaseService
      .addRoleToUser(this.targetUid, role)
      .catch((error) => console.error(error))
      .finally(() => {
        this.rolesBeingHandled = this.rolesBeingHandled.filter(
          (r) => r !== role
        );
      });
  }

  removeRole(role: UserRole) {
    this.rolesBeingHandled.push(role);
    this.databaseService
      .removeRoleFromUser(this.targetUid, role)
      .catch((error) => console.error(error))
      .finally(() => {
        this.rolesBeingHandled = this.rolesBeingHandled.filter(
          (r) => r !== role
        );
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
