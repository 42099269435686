import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { setEducationTrack } from 'src/app/store/actions/shared.actions';
import { selectSnackbarState } from 'src/app/store/reducers/snackbar.reducer';
import { selectShouldShowLicenseWarning } from 'src/app/store/reducers/user.reducer';
import { environment } from '../../environments/environment';
import { hideSnackbar, showSnackbar } from './store/actions/snackbar.actions';
import { AppState } from './store/reducers';
import { EducationTrack } from 'shared/models/project';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private titleService: Title,
    private store: Store<AppState>,
    private snackBar: MatSnackBar
  ) {
    if (!environment.production) {
      this.titleService.setTitle('DEV | ' + this.titleService.getTitle());
    }

    const educationTrack = this.getEducationTrackFromUrl(window.location.href);
    this.store.dispatch(setEducationTrack(educationTrack));
  }

  ngOnInit() {
    this.store.select(selectSnackbarState).subscribe((snackbarState) => {
      if (snackbarState.isOpen) {
        this.snackBar
          .open(snackbarState.message, snackbarState.action, {
            duration: snackbarState.duration,
          })
          .afterDismissed()
          .subscribe(() => {
            this.store.dispatch(hideSnackbar());
          });
      } else {
        this.snackBar.dismiss();
      }
    });

    this.store
      .select(selectShouldShowLicenseWarning)
      .subscribe((shouldShowLicenseWarning) => {
        const message =
          'Je licentie staat op het punt te verlopen. ' +
          'Voer een licentiecode in via "Licentiecode invoeren" in het menu in om de applicatie te blijven gebruiken.';

        if (shouldShowLicenseWarning) {
          this.store.dispatch(
            showSnackbar({
              message,
              action: 'Ok',
            })
          );
        }
      });
  }

  private getEducationTrackFromUrl(url: string): EducationTrack {
    const urlObj = new URL(url.toLowerCase());
    const hostname = urlObj.hostname;
    // This is mainly for testing on localhost
    const educationTrack = urlObj.searchParams.get('educationtrack');

    if (hostname.startsWith('vmbo.') || educationTrack === 'vmbo') {
      return EducationTrack.vmbo;
    }

    // Default to 'mbo'
    return EducationTrack.mbo;
  }
}
