<div mat-dialog-title>{{ title }} voor {{targetUid| username | async}} </div>
<div mat-dialog-content>

    <mat-form-field appearance="outline">
        <mat-label>Project</mat-label>
        <mat-select [formControl]="projectId">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let project of projects$ |async" [value]="project.id">{{ project.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Domain</mat-label>
        <mat-select [formControl]="domainId">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let domain of domains$ |async" [value]="domain.id">{{ domain.title }} </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Chapter</mat-label>
        <mat-select [formControl]="chapterId">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let chapter of chapters$ | async" [value]="chapter.id">{{ chapter.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Close</button>
    <button mat-raised-button (click)="triggerDelete()">Delete</button>