<mat-drawer-container>
    <mat-drawer class="bg-dark" mode="side" opened>

        <mat-toolbar class="bg-dark text-light">
            <span *ngIf="(isReadOnly$ | async); else toets">Beoordeling</span>
            <ng-template #toets>
                <span>Toets</span>
            </ng-template>
            <span class="spacer"></span>
            <ng-container *ngIf="(isReadOnly$ | async)===false; else timeTaken">
                <app-countdown *ngIf="(examSession$ | async)?.openUntil" class="text-light"
                    [futureDate]="(examSession$| async).openUntil" (halfTimeMark)="onHalfTimeMark($event)"
                    (timeIsUpMark)="onTimeIsUpMark()" (warningPeriodMark)="onWarningPeriodMark($event)">
                </app-countdown>
            </ng-container>

            <ng-template #timeTaken>
                <span class="text-light">{{totalDuration$ | async | date: 'HH:mm': "UTC"}}</span>
            </ng-template>
        </mat-toolbar>
        <button *ngIf="(isDevMode$ | async)" mat-raised-button (click)="openTimeControlDialog()">
            Time Travel
        </button>
        <h3 *ngIf="(isReadOnly$ | async)" class="text-center text-light student-name">
            {{(examSession$ | async).uid | username | async }}
        </h3>

        <mat-nav-list>
            <div *ngFor="let examSection of examSections$ | async; let examSectionIndex = index">
                <a routerLink="./start" routerLinkActive="active" class="text-light" mat-list-item>
                    Uitleg
                </a>

                <ng-container *ngIf="examSession$ | async; let session">
                    <div *ngIf="session.startedAt">
                        <a *ngFor="let interaction of examSection.interactions; let examSectionInteractionIndex = index"
                            class="text-light interaction-item"
                            [routerLink]="[ examSectionIndex, examSectionInteractionIndex]" routerLinkActive="active"
                            mat-list-item>

                            {{ examSectionInteractionIndex + 1 }} - {{ interaction.title }}

                            <span class="float-right" *ngIf="(isReadOnly$ | async) else normal">
                                <mat-icon title="Vraag niet beantwoord"
                                    *ngIf="(isInteractionAttempted(examSectionIndex, examSectionInteractionIndex) | async) === false">radio_button_off</mat-icon>
                                <mat-icon title="Fout beantwoord"
                                    *ngIf="isIncorrect(examSectionIndex, examSectionInteractionIndex) | async">
                                    close</mat-icon>
                                <mat-icon title="Goed beantwoord"
                                    *ngIf="isCorrect(examSectionIndex, examSectionInteractionIndex) | async">check</mat-icon>
                            </span>

                            <ng-template #normal>
                                <ng-container
                                    *ngIf="isInteractionAttempted(examSectionIndex, examSectionInteractionIndex) | async; else skipped">
                                    <span class="float-right" title="Vraag beantwoord">
                                        <mat-icon class="question-progress">radio_button_checked</mat-icon>
                                    </span>
                                </ng-container>
                                <ng-template #skipped>
                                    <span class="float-right" title="Vraag overgeslagen">
                                        <mat-icon class="question-progress">radio_button_off</mat-icon>
                                    </span>
                                </ng-template>
                            </ng-template>
                        </a>
                        <ng-container *ngIf="(isReadOnly$ | async)===false">
                            <a routerLink="./end" routerLinkActive="active" class="text-light" mat-list-item>
                                Toets inleveren
                            </a>
                        </ng-container>
                    </div>
                </ng-container>

            </div>
        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet class="exam-router"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>