import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { uniq } from 'lodash';
import { EducationTrack, Project } from '../../../../shared/models/project';
import * as Actions from '../actions';
import { ComponentNames } from '../component-names.enum';

export type SharedState = Readonly<{
  activeComponents: ComponentNames[];
  educationTrack: Project['educationTrack'];
  sessionDateTimestamp: number;
  isLoading: boolean;
}>;

export const initialState: SharedState = {
  activeComponents: [],
  educationTrack: EducationTrack.mbo,
  sessionDateTimestamp: Date.now(),
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(
    Actions.Shared.componentActivated,
    (state, payload): SharedState => ({
      ...state,
      activeComponents: uniq([
        ...state.activeComponents,
        payload.componentName,
      ]),
    })
  ),
  on(
    Actions.Shared.componentDestroyed,
    (state, payload): SharedState => ({
      ...state,
      activeComponents: state.activeComponents.filter(
        (componentName) => componentName !== payload.componentName
      ),
    })
  ),
  on(
    Actions.Shared.setEducationTrack,
    (state, { educationTrack }): SharedState => ({
      ...state,
      educationTrack:
        educationTrack === EducationTrack.vmbo
          ? EducationTrack.vmbo
          : EducationTrack.mbo,
    })
  ),
  on(
    Actions.Shared.setIsLoading,
    (state, { isLoading }): SharedState => ({
      ...state,
      isLoading,
    })
  )
);

export const sharedReducer = (state: SharedState | undefined, action: Action) =>
  reducer(state, action);

// Selectors
export const selectSharedState = createFeatureSelector<SharedState>('shared');

export const selectActiveComponents = createSelector(
  selectSharedState,
  (state) => state.activeComponents
);

export const selectEducationTrack = createSelector(
  selectSharedState,
  (state) => state.educationTrack
);

export const selectSessionDateTimestamp = createSelector(
  selectSharedState,
  (state) => state.sessionDateTimestamp
);

export const selectIsLoading = createSelector(
  selectSharedState,
  (state) => state.isLoading
);
