<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <video autoplay controls [hidden]="!data.video" [src]="data.video">
        Sorry, your browser doesn't support embedded videos.
    </video>
    <p *ngIf="data.text">{{ data.text }}</p>
    <pre *ngIf="data.code">{{ data.code }}</pre>
    <mat-form-field *ngIf="data.textArea" appearance="fill" class="full-width">
        <mat-label *ngIf="data.textArea.label">{{ data.textArea.label }}</mat-label>
        <textarea matInput [formControl]="textArea" [placeholder]="data.textArea.placeholder"
            [ngClass]="{ 'is-invalid': textArea.errors }" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            [cdkAutosizeMinRows]="data.textArea.minRows" [cdkAutosizeMaxRows]="data.textArea.maxRows">
        </textarea>
        <mat-error *ngIf="textArea.errors">
            {{ getValidationErrorMessage(textArea.errors) }}
        </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngFor="let option of data.options" mat-raised-button [mat-dialog-close]="option.value"
        [attr.cdkFocusInitial]="option.focus">{{ option.text }}</button>
</mat-dialog-actions>