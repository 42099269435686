import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from '../../../shared/models/organization';
import { DatabaseService } from '../services/database.service';
import { StorageKeys } from '../services/local-storage.service';
import { CachedPipe } from './cached-pipe.pipe';

@Pipe({
  name: 'organization',
})
export class OrganizationPipe
  extends CachedPipe<Organization, Organization>
  implements PipeTransform
{
  key = StorageKeys.organizations;

  constructor(private databaseService: DatabaseService) {
    super();
  }

  getEntityFromDatabase(
    entityId: Organization['id']
  ): Observable<Organization> {
    return this.databaseService.getOrganization(entityId);
  }
}
