export * as Exam from './exams';
import { TeacherDashboardEffects } from 'src/app/store/effects/teacher-dashboard.effects';
import { AppEffects } from './app.effects';
import { CalculatorEffects } from './calculator.effects';
import { ALL as ExamEffects } from './exams';
import { InteractionEffects } from './interaction.effects';
import { NotepadEffects } from './notepad.effects';

export const ALL = [
  AppEffects,
  ...ExamEffects,
  CalculatorEffects,
  InteractionEffects,
  NotepadEffects,
  TeacherDashboardEffects,
];
