export const safeFilename = (
  filename: string,
  replacement: string = '_'
): string => {
  // Reserved characters and words based on Windows, Linux, and macOS filename restrictions
  const reservedCharacters = /[<>:"\/\\|?*\x00-\x1F]/g; // Control characters and reserved characters
  const reservedWords = /^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/i; // Reserved words in Windows

  // Step 1: Replace reserved characters
  let safeName = filename.replace(reservedCharacters, replacement);

  // Step 2: Replace reserved words
  if (reservedWords.test(safeName)) {
    safeName = replacement + safeName; // Prefix to avoid reserved word issues
  }

  // Step 3: Remove trailing periods and spaces
  safeName = safeName.replace(/[. ]+$/, '');

  // Step 4: Enforce a maximum length (255 characters is a common filesystem limit)
  const maxLength = 255;
  if (safeName.length > maxLength) {
    const extensionIndex = safeName.lastIndexOf('.');
    if (extensionIndex !== -1 && extensionIndex > maxLength - 10) {
      // If there is an extension and it's too far out, trim the name and preserve the extension
      safeName =
        safeName.substring(0, maxLength - (safeName.length - extensionIndex)) +
        safeName.substring(extensionIndex);
    } else {
      safeName = safeName.substring(0, maxLength);
    }
  }

  return safeName;
};
