<svg *ngIf="unitData$ | async as unitData" viewBox="120 50 930 100" xmlns="http://www.w3.org/2000/svg" stroke-width="3">

    <line stroke-linecap="null" stroke-linejoin="null" y2="100" [attr.x2]="calculateLineLength()" y1="100" x1="130"
        fill-opacity="null" stroke-opacity="null" fill="none" />

    <g fill="#fff">
        <g class="unit-block presentation" transform="translate(148)">
            <circle fill="none" r="24" cy="100" cx="0"
                [class.active-interaction]="isActiveInteraction(unitContexts.presentation, 0)" />
            />
            <circle [class.active-interaction-color]="isActiveInteraction(unitContexts.presentation, 0)" r="24" cy="100"
                cx="0" />
            <g transform="translate(-15, 85) scale(0.055)" stroke-width="10" stroke-linecap="round">
                <path d="M500.94,191.81H31.606c-5.888,0-10.667,4.779-10.667,10.667v256c0,29.397,23.915,53.333,53.333,53.333h384
                    			c29.419,0,53.333-23.936,53.333-53.333v-256C511.606,196.588,506.828,191.81,500.94,191.81z M490.273,458.476
                    			c0,17.643-14.357,32-32,32h-384c-17.643,0-32-14.357-32-32V213.143h448V458.476z" />
                <path d="M511.265,93.1L493.11,24.215C488.929,7.468,471.82-3.049,454.86,0.791L25.078,102.764
                    			c-8.448,1.92-15.595,7.061-20.139,14.443c-4.544,7.381-5.888,16.085-3.755,24.533l20.096,79.445
                    			c1.195,4.821,5.547,8.043,10.325,8.043c0.853,0,1.728-0.085,2.645-0.32c5.696-1.429,9.152-7.232,7.701-12.949l-1.173-4.629
                    			l462.528-105.088c2.816-0.661,5.269-2.411,6.763-4.885C511.585,98.882,511.99,95.895,511.265,93.1z M35.553,190.636l-13.696-54.08
                    			c-0.704-2.816-0.235-5.717,1.259-8.192c1.515-2.453,3.904-4.16,6.805-4.843L459.702,21.57c0.768-0.192,1.557-0.277,2.325-0.277
                    			c4.779,0,9.195,3.285,10.411,8.213l15.381,58.347L35.553,190.636z" />
                <path d="M121.718,192.919c-5.291-2.581-11.648-0.491-14.336,4.779l-42.667,85.333c-2.624,5.269-0.491,11.669,4.779,14.315
                    			c1.557,0.768,3.179,1.131,4.779,1.131c3.904,0,7.68-2.155,9.557-5.909l42.667-85.333
                    			C129.121,201.964,126.987,195.564,121.718,192.919z" />
                <path d="M228.364,192.919c-5.269-2.581-11.669-0.491-14.315,4.779l-42.667,85.333c-2.624,5.269-0.491,11.669,4.779,14.315
                    			c1.557,0.768,3.179,1.131,4.779,1.131c3.904,0,7.68-2.155,9.536-5.909l42.667-85.333
                    			C235.766,201.964,233.633,195.564,228.364,192.919z" />
                <path d="M335.03,192.919c-5.248-2.581-11.648-0.491-14.315,4.779l-42.667,85.333c-2.624,5.269-0.491,11.669,4.779,14.315
                    			c1.557,0.768,3.179,1.131,4.779,1.131c3.904,0,7.68-2.155,9.536-5.909l42.667-85.333
                    			C342.433,201.964,340.299,195.564,335.03,192.919z" />
                <path d="M441.697,192.919c-5.291-2.581-11.648-0.491-14.315,4.779l-42.667,85.333c-2.624,5.269-0.491,11.669,4.779,14.315
                    			c1.557,0.768,3.179,1.131,4.779,1.131c3.904,0,7.68-2.155,9.536-5.909l42.667-85.333
                    			C449.099,201.964,446.966,195.564,441.697,192.919z" />
                <path d="M500.94,277.143H31.606c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667H500.94
                    			c5.909,0,10.667-4.779,10.667-10.667S506.828,277.143,500.94,277.143z" />
                <path
                    d="M134.753,173.186L61.281,99.714c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l73.472,73.493
                    			c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.136C138.913,184.108,138.913,177.346,134.753,173.186z" />
                <path
                    d="M239.115,149.484L165.58,76.012c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l73.515,73.472
                    			c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.552-3.115C243.276,160.407,243.276,153.644,239.115,149.484z" />
                <path
                    d="M343.393,125.783L269.985,52.29c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l73.408,73.493
                    			c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.024,7.531-3.115C347.553,136.706,347.553,129.943,343.393,125.783z" />
                <path
                    d="M447.628,102.103L374.198,28.61c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l73.429,73.493
                    			c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115C451.788,113.026,451.788,106.263,447.628,102.103z" />
            </g>
        </g>

        <ng-container *ngFor="let context of renderUnitContexts; let i = index">
            <g [attr.class]="'unit-block ' + unitContexts[context]" [attr.transform]="getUnitContextTransform(context)">
                <g *ngFor="let interaction of unitData.unit[unitContexts[context]], let j = index">
                    <circle fill="none" r="20" cy="100" [attr.cx]="j * distanceBetweenCircles"
                        [class.active-interaction]="isActiveInteraction(context, j)" />
                    <circle [class.active-interaction-color]="isActiveInteraction(context, j)" r="20" cy="100"
                        [attr.cx]="j * distanceBetweenCircles" [ngClass]="unitData.userUnitScoreMapped[context][j]" />
                    <use *ngIf="unitData.userUnitScoreMapped[context][j] === 'fill-red'" class="score-icon"
                        [attr.x]="j * distanceBetweenCircles" y="0" xlink:href="../assets/svg/cross.svg#cross" />
                </g>
            </g>
        </ng-container>

        <g [attr.transform]="getScoreCircleTransform()">
            <circle class="score" r="30" cy="100" [ngClass]="unitScoreClass$ | async" />
            <text *ngIf="(unitScoreClass$ | async) === 'fill-red'" class="unit-score-icon score-icon" x="-17"
                y="114">🞨</text>
        </g>
    </g>
</svg>