import { createAction, props } from '@ngrx/store';
import { EntityType } from '../../enums/entity-type';
import { StructuralEntity } from '../../models/structural-entity';
import { AppUser } from '../../models/user';

export const structureActions = {
  loadChildrenForEntity: createAction(
    '[Structure] Load children for entity',
    (entityId: string, entityType: EntityType) => ({
      entityId,
      entityType,
    })
  ),
  loadEntitiesOfType: createAction(
    '[Structure] Load entities of type',
    (entityType: EntityType) => ({ entityType })
  ),
  loadProjectsForUser: createAction(
    '[Structure] Load projects for user',
    (user: AppUser) => ({ user })
  ),
  lastUsedLocationLoaded: createAction(
    '[Structure] Last used location loaded',
    props<{ projectId: string; chapterId: string; domainId: string }>()
  ),
  renewActiveStructure: createAction(
    '[Structure] Renew active structure',
    props<{ activeStructure: StructuralEntity[] }>()
  ),
  reset: createAction('[Structure] Reset'),
};
