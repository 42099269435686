import { ExamSessionSubComponentEffects } from './exam-session-sub.component.effects';
import { ExamEffects } from './exam.effects';
import { StudentExamEffects } from './student-exam.effects';

export * from './exam-session-sub.component.effects';
export * from './exam.effects';

export const ALL = [
  ExamEffects,
  ExamSessionSubComponentEffects,
  StudentExamEffects,
];
