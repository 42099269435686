import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeInputDialogComponent } from 'src/app/components/code-input-dialog/code-input-dialog.component';
import { AppState } from 'src/app/store/reducers';
import {
  selectMboLicenseDate,
  selectUserHasActiveLicenseForCurrentEducationTrack,
  selectUserIsExemptFromLicense,
  selectVmboLicenseDate,
} from 'src/app/store/reducers/user.reducer';

@Component({
  selector: 'app-license-expired',
  templateUrl: './license-expired.component.html',
  styleUrls: ['./license-expired.component.scss'],
})
export class LicenseExpiredComponent {
  vmboLicenseDate$: Observable<Date>;
  mboLicenseDate$: Observable<Date>;
  userHasLicense$: Observable<boolean>;
  userIsExempt$: Observable<boolean>;

  cardFlavor$: Observable<'licensed' | 'exempt' | 'unlicensed'>;

  constructor(public dialog: MatDialog, private store: Store<AppState>) {
    this.vmboLicenseDate$ = this.store.select(selectVmboLicenseDate);
    this.mboLicenseDate$ = this.store.select(selectMboLicenseDate);
    this.userHasLicense$ = this.store.select(
      selectUserHasActiveLicenseForCurrentEducationTrack
    );
    this.userIsExempt$ = this.store.select(selectUserIsExemptFromLicense);

    this.cardFlavor$ = combineLatest([
      this.userHasLicense$,
      this.userIsExempt$,
    ]).pipe(
      map(([hasLicense, isExempt]) => {
        if (hasLicense) {
          return 'licensed';
        }
        return isExempt ? 'exempt' : 'unlicensed';
      })
    );
  }

  openCodeInputDialog(): void {
    this.dialog.open(CodeInputDialogComponent, {
      width: '400px',
    });
  }
}
