import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  BaseWidget,
  NgAisIndex,
  NgAisInstantSearch,
} from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import { ReplaySubject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

export type SearchQuery = {
  query: string;
  id: string;
};

// TODO find out how TypedBaseWidget is supposed to work
// https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/angular/#favor-using-typedbasewidget-over-basewidget
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutocompleteComponent
  extends BaseWidget
  implements OnInit, OnDestroy
{
  @Output() searchInputChanged = new EventEmitter<string>();
  @Output() outputSelected = new EventEmitter<SearchQuery>();
  @Input() label = 'Zoeken';
  @Input() markRequired = false;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  inputControl = new UntypedFormControl();

  state: {
    query: string;
    refine: (x: string) => any;
    indices: Record<string, Iterable<any>>[];
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('AutocompleteComponent');
  }

  @Input() displayFunction: (option: Record<string, any>) => string = (
    option
  ) => option?.name || '';

  handleChange(textOrOption: string | Record<string, any>) {
    if (typeof textOrOption !== 'string') {
      this.outputSelected.emit({
        query: this.displayFunction(textOrOption),
        id: textOrOption?.objectID,
      });
      return;
    } else {
      this.searchInputChanged.emit(textOrOption);
    }

    this.state.refine(textOrOption);
  }

  clearInput() {
    this.inputControl.setValue('');
  }

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();

    this.inputControl.valueChanges
      .pipe(startWith(''), debounceTime(200), takeUntil(this.ngDestroyed$))
      .subscribe((val) => this.handleChange(val));
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
