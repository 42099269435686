import { Pipe, PipeTransform } from '@angular/core';
import { InteractionScore } from '../../../shared/models/score.enum';

@Pipe({
  name: 'interactionScoreText',
})
export class InteractionScoreTextPipe implements PipeTransform {
  transform(value: InteractionScore): string {
    switch (value) {
      case InteractionScore.null:
        return 'Niet geprobeerd';
      case InteractionScore.incorrect:
        return 'Onjuist';
      case InteractionScore.correctWithHelp:
        return 'Correct met hulp';
      case InteractionScore.correct:
        return 'Correct';
      default:
        return 'Onbekend';
    }
  }
}
