<mat-card>
    <mat-card-title>Inloggen</mat-card-title>
    <mat-card-content>
        <p *ngIf="showNonPreferredProviders">
            Beschikt jouw school over een koppeling met SURF of Entree? Log hier dan ook mee in. Google accounts kunnen
            op dit moment niet worden overgezet naar SURF of Entree.
        </p>

        <button *ngIf="showNonPreferredProviders || preferredLoginProviderName === loginProviderNames.entree"
            mat-raised-button (click)="loginWithEntree()">
            <img src="/assets/icons/Entree_logo_32x32.png" alt="Entree sign-in" aria-label="Entree sign-in" />
            <span>Login met Entree</span>
        </button>
        <button *ngIf="showNonPreferredProviders || preferredLoginProviderName === loginProviderNames.surf"
            mat-raised-button (click)="loginWithSurf()">
            <img src="/assets/icons/surf-logo.png" alt="Surf sign-in" aria-label="Surf sign-in" />
            <span>Login met SURF</span>
        </button>
        <button *ngIf="showNonPreferredProviders || preferredLoginProviderName === loginProviderNames.google"
            mat-raised-button (click)="loginWithGoogle()">
            <img src="/assets/icons/google.svg" alt="Google sign-in" aria-label="Google sign-in" />
            <span>Login met Google</span>
        </button>

    </mat-card-content>
</mat-card>