import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ExamSession, ExamSessionFinalized } from 'shared/models/exam-session';
import { ExamService } from 'src/app/services/exam.service';
import { studentExamsActions } from 'src/app/store/actions/student-exams.actions';
import { selectUserExamSessions } from 'src/app/store/reducers/student-exams.reducer';
import {
  calculateExamSessionGrade,
  calculateExamSessionScorePercentage,
} from '../../../helpers/exam-score-helper';
import { utility } from '../../../helpers/utility';
import { AppState } from '../../../store/reducers/index';
import { selectUserUid } from '../../../store/reducers/user.reducer';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../../dialog/dialog.component';

@Component({
  selector: 'app-exam-overview-student',
  templateUrl: './exam-overview-student.component.html',
  styleUrls: ['./exam-overview-student.component.scss'],
})
export class ExamOverviewStudentComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  examSessionColumns = [
    { field: 'name', title: 'Toets' },
    { field: 'examInstanceCustomTitle', title: 'Titel' },
    { field: 'finishedDate', title: 'Gemaakt op' },
    { field: 'score', title: 'Score' },
    { field: 'actions', title: 'Acties' },
  ];

  displayedColumns = this.examSessionColumns.map((c) => c.field);
  examSessions$: Observable<ExamSession[]>;

  examActivationCode: string;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userUid$: Observable<string>;

  constructor(
    private examService: ExamService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.userUid$ = this.store.select(selectUserUid);
    this.examSessions$ = this.store.select(selectUserExamSessions);
  }

  ngOnInit() {
    this.userUid$
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((uid) =>
        this.store.dispatch(
          studentExamsActions.loadExamSessionsForUser({ uid })
        )
      );
  }

  formatGrade(row: ExamSessionFinalized) {
    if (this.isFinalizedExamSession(row)) {
      if (row.hideGrade) {
        return calculateExamSessionScorePercentage(row) ?? 0 + '%';
      }

      return calculateExamSessionGrade(row) ?? 0;
    }

    return null;
  }

  async getExamByPassphrase() {
    try {
      this.isLoading$.next(true);
      const examInstance = await this.examService.getExamInstanceByPassphrase(
        this.examActivationCode
      );

      if (!examInstance) {
        throw new Error('Toets niet gevonden');
      }

      const examSessionId = await this.examService.createExamSession(
        examInstance.id
      );

      this.router.navigate(['/exam-session', examSessionId]);
    } catch (error) {
      console.error(error);

      this.dialog.open<DialogComponent, DialogData, boolean>(DialogComponent, {
        data: {
          title: 'Er is iets misgegaan',
          text: error.message,
          preset: DialogPreset.close,
        },
        disableClose: true,
      });
    } finally {
      this.isLoading$.next(false);
    }
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  isFinalizedExamSession(session: ExamSession) {
    return this.examService.isFinalizedExamSessionWrapped(session);
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.getExamByPassphrase();
    }

    if (event.target instanceof HTMLInputElement) {
      event.target.blur();
    }
  }
}
