import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Group } from 'src/app/models/group';
import { TeacherDashboardActions } from 'src/app/store/actions';
import {
  TeacherDashboardFilters,
  TeacherDashboardTableDataGrouped,
  TeacherDashboardTableRow,
} from '../../../../shared/models/teacher-dashboard-models';

export interface StudentSortCriteria {
  field: keyof TeacherDashboardTableRow &
    ('firstName' | 'lastName' | 'timeTotal' | 'timeOverSelectionString');
  order: 'asc' | 'desc';
}

export type TeacherDashboardState = Readonly<{
  filters: TeacherDashboardFilters;
  chapterTitles: { [projectId: string]: string[] };
  unitTitles: { [chapterId: string]: string[] };
  groups: Group[];
  tableData: TeacherDashboardTableDataGrouped;
  sortCriteria: StudentSortCriteria;
  isSearching: boolean;
}>;

const initialState: TeacherDashboardState = {
  filters: {
    group: null,
    project: null,
    domain: null,
    startDate: null,
    endDate: null,
    selectedUsers: null,
  },
  chapterTitles: {},
  unitTitles: {},
  groups: [],
  tableData: null,
  sortCriteria: null,
  isSearching: false,
};

const reducer = createReducer(
  initialState,
  on(
    TeacherDashboardActions.selectGroup,
    (state, { selectedGroup }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        group: selectedGroup?.id,
      },
    })
  ),
  on(
    TeacherDashboardActions.selectProject,
    (state, { selectedProjectId }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        project: selectedProjectId,
      },
    })
  ),
  on(
    TeacherDashboardActions.selectDomain,
    (state, { selectedDomain }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        domain: selectedDomain?.id,
      },
    })
  ),
  on(
    TeacherDashboardActions.selectStartDate,
    (state, { startDate }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: startDate ?? null,
      },
    })
  ),
  on(
    TeacherDashboardActions.selectEndDate,
    (state, { endDate }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        endDate: endDate ?? null,
      },
    })
  ),
  on(
    TeacherDashboardActions.selectUsers,
    (state, { users }): TeacherDashboardState => ({
      ...state,
      filters: {
        ...state.filters,
        selectedUsers: users.map((u) => u.uid),
      },
    })
  ),
  on(
    TeacherDashboardActions.performSearch,
    (state): TeacherDashboardState => ({
      ...state,
      tableData: null, // TODO this might not be needed if the table titles would not flip async from state
      isSearching: true,
    })
  ),
  on(
    TeacherDashboardActions.performSearchSuccess,
    (state, { results }): TeacherDashboardState => ({
      ...state,
      isSearching: false,
      tableData: sortTableData(results, state.sortCriteria),
    })
  ),
  on(
    TeacherDashboardActions.performSearchFailure,
    (state): TeacherDashboardState => ({ ...state, isSearching: false })
  ),
  on(
    TeacherDashboardActions.setStudentSortCriteria,
    (state, { criteria }): TeacherDashboardState => ({
      ...state,
      sortCriteria: criteria,
      tableData: sortTableData(state.tableData, criteria),
    })
  ),
  on(
    TeacherDashboardActions.resetTeacherDashboard,
    (): TeacherDashboardState => initialState
  )
);
const sortTableData = (
  tableData: TeacherDashboardTableDataGrouped,
  criteria: StudentSortCriteria | null
): TeacherDashboardTableDataGrouped => {
  if (!criteria) {
    return tableData;
  }

  return tableData.map((section) => ({
    ...section,
    tableRows: [...section.tableRows].sort((a, b) => {
      const fieldA = a[criteria.field];
      const fieldB = b[criteria.field];

      let comparison: number;

      if (
        criteria.field === 'timeTotal' ||
        criteria.field === 'timeOverSelectionString'
      ) {
        // Handle null or empty time fields
        if (!fieldA) {
          return criteria.order === 'asc' ? -1 : 1;
        }
        if (!fieldB) {
          return criteria.order === 'asc' ? 1 : -1;
        }

        // Convert times to minutes for comparison
        const [hoursA, minutesA] = fieldA.split(':').map(Number);
        const [hoursB, minutesB] = fieldB.split(':').map(Number);
        const totalMinutesA = hoursA * 60 + minutesA;
        const totalMinutesB = hoursB * 60 + minutesB;

        comparison = totalMinutesA - totalMinutesB;
      } else {
        // Use localeCompare for non-time strings
        comparison = fieldA.localeCompare(fieldB, 'nl', {
          sensitivity: 'base',
        });
      }

      return criteria.order === 'asc' ? comparison : -comparison;
    }),
  }));
};

export const teacherDashboardReducer = (
  state: TeacherDashboardState | undefined,
  action: Action
) => reducer(state, action);

// Selectors
export const selectTeacherDashboardState =
  createFeatureSelector<TeacherDashboardState>('teacherDashboard');

export const selectTeacherDashboardFilterState = createSelector(
  selectTeacherDashboardState,
  (state) => state.filters
);

export const selectTeacherDashboardTableData = createSelector(
  selectTeacherDashboardState,
  (state) => state.tableData
);

export const selectTeacherDashboardIsSearching = createSelector(
  selectTeacherDashboardState,
  (state) => state.isSearching
);

export const selectTeacherDashboardSortCriteria = createSelector(
  selectTeacherDashboardState,
  (state) => state.sortCriteria
);
