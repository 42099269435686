import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AppUser } from 'shared/models/user';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';

import { LicenseService } from 'src/app/services/license.service';
import { AppState } from 'src/app/store/reducers';
import { selectSessionDateTimestamp } from 'src/app/store/reducers/shared.reducer';
import {
  selectMboLicenseDate,
  selectUser,
  selectVmboLicenseDate,
} from 'src/app/store/reducers/user.reducer';
import { DatabaseService } from '../../services/database.service';
import { regularExpressions } from 'shared/helpers/regex';

@Component({
  selector: 'app-code-input-dialog',
  templateUrl: './code-input-dialog.component.html',
  styleUrls: ['./code-input-dialog.component.scss'],
})
export class CodeInputDialogComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  code = new FormControl('', [
    Validators.required,
    Validators.pattern(regularExpressions.license),
  ]);

  patternHint = 'X-XXX-XXX(-X)';

  callInProgress: boolean;

  vmboLicenseDate$: Observable<Date>;
  mboLicenseDate$: Observable<Date>;
  sessionDate$: Observable<Date>;
  user$: Observable<AppUser>;

  constructor(
    private licenseService: LicenseService,
    private databaseService: DatabaseService,
    public dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.vmboLicenseDate$ = this.store.select(selectVmboLicenseDate);
    this.user$ = this.store.select(selectUser);
    this.mboLicenseDate$ = this.store.select(selectMboLicenseDate);
    this.sessionDate$ = this.store.select(selectSessionDateTimestamp).pipe(
      map((timestamp) => {
        if (timestamp) {
          return new Date(timestamp);
        }
      })
    );

    this.callInProgress = false;

    let prevValue = this.code.value;
    this.code.valueChanges // TODO: Extract logic
      .pipe(distinctUntilChanged(), takeUntil(this.ngDestroyed$))
      .subscribe((value) => {
        const isNotDeleting = value.length > prevValue.length;
        // Uppercase.
        value = value.toUpperCase();
        // Remove double dashes.
        value = value.replace(/--/g, '-');
        // Remove invalid chars.
        value = value.replace(/[^A-Z0-9-]/g, '');

        if (isNotDeleting) {
          // Remove everything after max length
          if (value.length > 11) {
            value = value.substring(0, 11);
          }

          // Add optional dash for last char
          if (value.length === 10) {
            value = value.slice(0, -1) + '-' + value.slice(-1);
          }

          // Add dashes
          if (value.length === 1 || value.length === 5) {
            value += '-';
          }
        }

        prevValue = value;

        this.code.setValue(value);
      });
  }

  submit() {
    this.callInProgress = true;
    this.code.setErrors(null);
    this.licenseService
      .enterLicenseCode(this.code.value)
      .then(
        async ({
          newLicensedAccessUntil,
          newVmboAccessUntil,
          newOrganizationId,
          teacherRole,
        }) => {
          const infoLines = ['De licentie is geactiveerd.'];

          const formatDate = (date: string) =>
            new Date(date).toLocaleDateString('nl-NL', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            });

          if (teacherRole) {
            infoLines.push('Je account is geupgrade naar een docent account.');
          } else {
            if (newVmboAccessUntil) {
              const dateFormatted = formatDate(newVmboAccessUntil);
              infoLines.push(`Je VMBO toegang loopt tot ${dateFormatted}.`);
            }
            if (newLicensedAccessUntil) {
              const dateFormatted = formatDate(newLicensedAccessUntil);
              infoLines.push(`Je MBO toegang loopt tot ${dateFormatted}.`);
            }
          }

          if (newOrganizationId) {
            const org = await this.databaseService
              .getOrganization(newOrganizationId)
              .toPromise();

            infoLines.push(
              `Je account is nu gekoppeld aan: ${
                org?.name || 'Schoolnaam onbekend'
              }.`
            );
          }

          this.dialog
            .open<DialogComponent, DialogData>(DialogComponent, {
              data: {
                title: 'Licentie geactiveerd',
                text: infoLines.join('\n'),
                preset: DialogPreset.ok,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.dialog.closeAll();
            });
        }
      )
      .catch((error) => {
        this.code.setErrors({ serverError: error.message });
        console.error(error);
      })
      .finally(() => {
        this.callInProgress = false;
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
