export enum InteractionEvent {
  // answerButton = 'answerButton',
  // answerOpen = 'answerOpen',
  // asAnswer = 'asAnswer',
  // asFeedback = 'asFeedback',
  // asImage = 'asImage',
  // asQuestion = 'asQuestion',
  // feedbackButton = 'feedbackButton',
  // feedbackCorrectButton = 'feedbackCorrectButton',
  feedbackCorrectLateOpen = 'feedbackCorrectLateOpen',
  feedbackCorrectOpen = 'feedbackCorrectOpen',
  feedbackFalse1NoOption = 'feedbackFalse1NoOption',
  // feedbackFalse1Open = 'feedbackFalse1Open',
  feedbackFalse2NoOption = 'feedbackFalse2NoOption',
  // feedbackFalse2Open = 'feedbackFalse2Open',
  feedbackFalse3NoOption = 'feedbackFalse3NoOption',
  // feedbackFalse3Open = 'feedbackFalse3Open',
  feedbackFalse4NoOption = 'feedbackFalse4NoOption',
  // feedbackFalse4Open = 'feedbackFalse4Open',
  // feedbackFalseButton = 'feedbackFalseButton',
  // feedbackFalseLastTryButton = 'feedbackFalseLastTryButton',
  feedbackFalseLastTryOpen = 'feedbackFalseLastTryOpen',
  feedbackFalseOpen = 'feedbackFalseOpen',
  feedbackOpen = 'feedbackOpen',
  // imageClick = 'imageClick',
  option0Selected = 'option0Selected',
  option1Selected = 'option1Selected',
  option2Selected = 'option2Selected',
  option3Selected = 'option3Selected',
  option4Selected = 'option4Selected',
  option5Selected = 'option5Selected',
  option6Selected = 'option6Selected',
  option7Selected = 'option7Selected',
  option8Selected = 'option8Selected',
  option9Selected = 'option9Selected',
  questionButton = 'questionButton',
  // questionOpen = 'questionOpen',
}
