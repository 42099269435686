import { EntityType } from '../enums/entity-type';
import { UnitContext } from '../enums/unit-context';
import { Modify } from '../types/utility-types';
import { Interaction } from './interaction';
import { SharedBasics } from './shared-basics';

export type VariantArray = (Interaction['id'] | Interaction['id'][])[];
export type IndexedVariantObject = {
  [index: number]: Interaction['id'] | Interaction['id'][];
};

export type Unit = SharedBasics &
  Readonly<{
    type: EntityType.unit;
    subtype?: 'thematic';
    presentation: Interaction['id'];
    infoPopup?: Interaction['id'];
    introduction: VariantArray;
    extra: VariantArray;
    deepening: VariantArray;
    test: VariantArray;
  }>;

export type DbUnit = Modify<
  Unit,
  {
    introduction: IndexedVariantObject;
    extra: IndexedVariantObject;
    deepening: IndexedVariantObject;
    test: IndexedVariantObject;
  }
>;

export type InteractionIndex = number; // Zero based integer

export const unitContextsOrder = [
  UnitContext.presentation,
  UnitContext.introduction,
  UnitContext.extra,
  UnitContext.deepening,
  UnitContext.test,
];
