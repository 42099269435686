import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GoogleAuthProvider, SAMLAuthProvider } from 'firebase/auth';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { UserRole } from 'shared/enums/user-role.enum';
import { userActions } from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';

import { utility } from '../../../shared/helpers/utility';
import { AppUser, AuthUser } from '../../../shared/models/user';
import {
  selectAlgoliaOrgKey,
  selectIsDbUserLoaded,
  selectUser,
} from '../store/reducers/user.reducer';

import { Auth } from '@angular/fire/auth';
import { FireAuthService } from './fire-auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private store: Store<AppState>,
    private auth: Auth = inject(Auth),
    private router: Router
  ) {
    FireAuthService.authState(this.auth).subscribe((user) => {
      if (!environment.production) {
        console.log('authState user', user);
      }

      if (user) {
        const appUser: AuthUser = {
          uid: user.uid,
          photoURL: user.photoURL,
        };
        this.store.dispatch(userActions.loggedIn({ user: appUser }));
      } else {
        this.store.dispatch(userActions.logout());
      }
    });

    // Keep Algolia user search key up to date.
    combineLatest([
      this.store.select(selectUser),
      this.store.select(selectIsDbUserLoaded),
    ])
      .pipe(
        debounceTime(10),
        distinctUntilChanged(
          ([prevUser]: [AppUser, boolean], [curUser]: [AppUser, boolean]) =>
            prevUser?.uid === curUser?.uid &&
            utility.isSetEqual(prevUser?.roles, curUser?.roles) &&
            prevUser?.organizationId === curUser?.organizationId
        )
      )
      .subscribe(([user, userLoaded]) => {
        // The user key requires an organization or admin role.
        if (
          userLoaded &&
          (user?.organizationId || user?.roles?.has(UserRole.admin))
        ) {
          this.store.dispatch(userActions.getAlgoliaUserKey());
        } else {
          // Unload the key if this is no longer true.
          this.store.dispatch(userActions.unloadAlgoliaUserKey());
        }
      });

    // Algolia org search key
    combineLatest([
      this.store.select(selectIsDbUserLoaded),
      this.store.select(selectAlgoliaOrgKey),
    ])
      .pipe(
        filter(([userLoaded, algoliaOrgKey]) => userLoaded && !algoliaOrgKey)
      )
      .subscribe(() => {
        this.store.dispatch(userActions.getAlgoliaOrgKey());
      });
  }

  tokenSignIn(token: string) {
    return FireAuthService.signInWithCustomToken(this.auth, token).catch((e) =>
      console.error(e)
    );
  }

  googleSignIn() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return FireAuthService.signInWithPopup(this.auth, provider).catch((e) =>
      console.error(e)
    );
  }

  entreeSignIn() {
    const provider = new SAMLAuthProvider('saml.kennisnet');

    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return FireAuthService.signInWithPopup(this.auth, provider)
      .then((result) => {
        if (!environment.production) {
          console.log('Kennisnet user:', result);
        }
      })
      .catch((e) => console.error(e));
  }

  surfSignIn() {
    const provider = new SAMLAuthProvider('saml.surf');

    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return FireAuthService.signInWithPopup(this.auth, provider)
      .then((result) => {
        if (!environment.production) {
          console.log('Surfnet user:', result);
        }
      })
      .catch((e) => console.error(e));
  }

  signOut() {
    FireAuthService.signOut(this.auth)
      .then(() => {
        // Timeout has to do with auth guard redirect url.
        window.setTimeout(() => {
          this.router.navigate(['/login']);
        }, 0);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  authState() {
    return FireAuthService.authState(this.auth);
  }
}
