// FIXME: naming conventions
/* eslint-disable @typescript-eslint/naming-convention */
import { Modify } from '../../app/types/utility-types';
import { regularExpressions } from '../helpers/regex';
import { Timestamp } from '../helpers/time-helper';
import { Organization } from './organization';
import { DbUser } from './user';

export const licenseCharset = 'ACDEFGHJKLMNPQRSTUVWXYZ23456789';
export const licensePattern = regularExpressions.license;

export enum LicenseType {
  studentMbo,
  teacher,
  studentVmbo,
}

export const licenseTypeMap: { [key in LicenseType]: string } = {
  [LicenseType.studentMbo]: 'Student MBO',
  [LicenseType.studentVmbo]: 'Student VMBO',
  [LicenseType.teacher]: 'Docent',
};

export enum LicenseDurationType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export const licenseDurationTypeMap: { [key in LicenseDurationType]: number } =
  {
    A: 60, // Two months
    B: 180, // Six months
    C: 365, // One year
    D: 730, // Two years
  };

export const vmboDurationPrefixMap: { [key in LicenseDurationType]: string } = {
  A: 'U', // Two months
  B: 'W', // Six months
  C: 'V', // One year
  D: 'X', // Two years
};

export enum LicenseDistributionChannel {
  B = 'B', // Bookstore
  W = 'W', // Website
  S = 'S', // School
}

export const licenseDistributionChannelMap: {
  [key in LicenseDistributionChannel]: string;
} = {
  B: 'Boekhandel',
  W: 'Website',
  S: 'School',
};

export type BaseLicense = {
  code: string;
  generatedAt: Timestamp;
  generatedBy: DbUser['uid'];
  assignedTo?: DbUser['uid'];
  assignedAt?: Timestamp;
  distributedAt?: Timestamp;
  distributedBy?: DbUser['uid'];
  distributionNote?: string;
  distributionChannel: LicenseDistributionChannel;
};

export type StudentLicenseMbo = BaseLicense & {
  type: LicenseType.studentMbo;
  durationType: LicenseDurationType;
  organizationId?: Organization['id'];
  organizationHardLink?: boolean;
};

export type TeacherLicense = BaseLicense & {
  type: LicenseType.teacher;
  organizationId: Organization['id'];
  organizationHardLink: true;
};

export type StudentLicenseVmbo = Modify<
  StudentLicenseMbo,
  {
    type: LicenseType.studentVmbo;
  }
>;

export type License = StudentLicenseMbo | TeacherLicense | StudentLicenseVmbo;
