import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  CollectionReference,
  DocumentData,
  Query,
  collection,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { GroupReference } from '../../../shared/models/group';
import { DbUser, UserReference } from '../../../shared/models/user';
import { constants } from 'shared/constants';
import { SearchService } from '../types/search-service';
import {
  PaginatedResponse,
  Pagination,
  PaginationService,
} from './pagination.service';
export type UserFilter = {
  uids?: string[];
  organizationId?: DbUser['organizationId'];
  teacher?: UserReference;
  group?: GroupReference;
  orderBy?: keyof DbUser & ('name' | 'createdDate');
  orderDirection?: 'asc' | 'desc';
};

@Injectable({
  providedIn: 'root',
})
export class UserService implements SearchService<UserFilter, DbUser> {
  searchItems: (
    filter: UserFilter,
    pagination: Pagination
  ) => Observable<PaginatedResponse<DbUser>> = this.searchUsers.bind(this);

  constructor(
    private afs: AngularFirestore,
    private paginationService: PaginationService
  ) {}

  searchUsers(
    userFilter?: UserFilter,
    pagination?: Pagination
  ): Observable<PaginatedResponse<DbUser>> {
    const buildQuery = (ref: CollectionReference) => {
      const orderByField = userFilter.orderBy || 'name';
      let userQuery: Query<DocumentData>;

      if (userFilter.orderDirection) {
        userQuery = query(
          ref,
          orderBy(orderByField, userFilter.orderDirection)
        );
      } else {
        userQuery = query(ref, orderBy(orderByField));
      }

      if (userFilter?.organizationId) {
        userQuery = query(
          userQuery,
          where('organizationId', '==', userFilter.organizationId)
        );
      }

      if (userFilter?.uids) {
        userQuery = query(userQuery, where('uid', 'in', userFilter.uids));
      } else {
        // Only one array-contains is allowed per query
        if (userFilter?.group) {
          userQuery = query(
            userQuery,
            where('groups', 'array-contains', userFilter.group)
          );
        } else if (userFilter?.teacher) {
          userQuery = query(
            userQuery,
            where('teachers', 'array-contains', userFilter.teacher)
          );
        }
      }

      return userQuery;
    };

    const q = buildQuery(
      collection(this.afs.firestore, constants.dbCollections.users)
    );

    return this.paginationService.getPage<DbUser>({
      queryName: 'users-search',
      queryFn: q,
      pagination,
    });
  }
}
