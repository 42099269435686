import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  UserChapterScore,
  getUserChapterScorePath,
  getUserChapterScorePathFromUserUnitScore,
} from 'src/app/models/user-chapter-score';
import { scoreActions } from 'src/app/store/actions/score.actions';
import { selectUserUnitScorePath } from 'src/app/store/selectors/router.selectors';
import { calcUnitScore } from '../../helpers/calc-unit-score';
import {
  mapUnitScoreToClassName,
  mapUserUnitScoreToClassMap,
  mergeAttemptToUserUnitScore,
  mergeUserChapterScoreToUserDomainScore,
  mergeUserUnitScoreToUserChapterScore,
  mergeUserUserDomainScoreToUserProjectScore,
} from '../../helpers/score-helper';
import {
  UserDomainScore,
  getUserDomainScorePath,
  getUserDomainScorePathFromUserChapterScore,
} from '../../models/user-domain-score';
import { UserProjectScore } from '../../models/user-project-score';
import {
  UserUnitScore,
  getUserUnitScorePath,
  getUserUnitScorePathFromAttempt,
} from '../../models/user-unit-score';

export type UserUnitScoreState = {
  [unitPath: string]: UserUnitScore;
};

export type UserChapterScoreState = {
  [chapterPath: string]: UserChapterScore;
};

export type UserDomainScoreState = {
  [domainPath: string]: UserDomainScore;
};

export type UserProjectScoreState = {
  [projectId: string]: UserProjectScore;
};

export type ScoreState = Readonly<{
  userUnitScoreState: UserUnitScoreState;
  userChapterScoreState: UserChapterScoreState;
  userDomainScoreState: UserDomainScoreState;
  userProjectScoreState: UserProjectScoreState;
}>;

const initialState: ScoreState = {
  userUnitScoreState: {},
  userChapterScoreState: {},
  userDomainScoreState: {},
  userProjectScoreState: {},
};

const reducer = createReducer(
  initialState,
  // Unit scores
  on(
    scoreActions.userUnitScoreLoaded,
    (state: ScoreState, { userUnitScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userUnitScoreState: { ...state.userUnitScoreState },
      };
      newState.userUnitScoreState[getUserUnitScorePath(userUnitScore)] =
        userUnitScore;
      return newState;
    }
  ),
  on(
    scoreActions.unloadUserUnitScore,
    (state: ScoreState, { unitPath }): ScoreState => {
      const newUserUnitDataState = { ...state.userUnitScoreState };
      delete newUserUnitDataState[unitPath];
      const newState: ScoreState = {
        ...state,
        userUnitScoreState: newUserUnitDataState,
      };
      return newState;
    }
  ),
  on(
    scoreActions.mergeToUserUnitScore,
    (state: ScoreState, { attempt }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userUnitScoreState: { ...state.userUnitScoreState },
      };
      const unitPath = getUserUnitScorePathFromAttempt(attempt);
      newState.userUnitScoreState[unitPath] = mergeAttemptToUserUnitScore(
        newState.userUnitScoreState[unitPath],
        attempt
      );
      return newState;
    }
  ),

  // Chapter scores
  on(
    scoreActions.userChapterScoreLoaded,
    (state: ScoreState, { userChapterScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userChapterScoreState: { ...state.userChapterScoreState },
      };
      newState.userChapterScoreState[
        getUserChapterScorePath(userChapterScore)
      ] = userChapterScore;
      return newState;
    }
  ),
  on(
    scoreActions.unloadUserChapterScore,
    (state: ScoreState, { chapterPath }): ScoreState => {
      const newUserChapterDataState = { ...state.userChapterScoreState };
      delete newUserChapterDataState[chapterPath];
      const newState: ScoreState = {
        ...state,
        userChapterScoreState: newUserChapterDataState,
      };
      return newState;
    }
  ),
  on(
    scoreActions.mergeToUserChapterScore,
    (state: ScoreState, { userUnitScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userChapterScoreState: { ...state.userChapterScoreState },
      };
      const chapterPath =
        getUserChapterScorePathFromUserUnitScore(userUnitScore);
      newState.userChapterScoreState[chapterPath] =
        mergeUserUnitScoreToUserChapterScore(
          userUnitScore,
          newState.userChapterScoreState[chapterPath]
        );
      return newState;
    }
  ),

  // Domain scores
  on(
    scoreActions.userDomainScoreLoaded,
    (state: ScoreState, { userDomainScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userDomainScoreState: { ...state.userDomainScoreState },
      };
      newState.userDomainScoreState[getUserDomainScorePath(userDomainScore)] =
        userDomainScore;
      return newState;
    }
  ),
  on(
    scoreActions.unloadUserDomainScore,
    (state: ScoreState, { domainPath }): ScoreState => {
      const newUserDomainDataState = { ...state.userDomainScoreState };
      delete newUserDomainDataState[domainPath];
      const newState: ScoreState = {
        ...state,
        userDomainScoreState: newUserDomainDataState,
      };
      return newState;
    }
  ),
  on(
    scoreActions.mergeToUserDomainScore,
    (state: ScoreState, { userChapterScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userDomainScoreState: { ...state.userDomainScoreState },
      };
      const domainPath =
        getUserDomainScorePathFromUserChapterScore(userChapterScore);
      newState.userDomainScoreState[domainPath] =
        mergeUserChapterScoreToUserDomainScore(
          userChapterScore,
          newState.userDomainScoreState[domainPath]
        );
      return newState;
    }
  ),

  // Project scores
  on(
    scoreActions.userProjectScoreLoaded,
    (state: ScoreState, { userProjectScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userProjectScoreState: { ...state.userProjectScoreState },
      };
      newState.userProjectScoreState[userProjectScore.projectId] =
        userProjectScore;
      return newState;
    }
  ),
  on(
    scoreActions.unloadUserProjectScore,
    (state: ScoreState, { projectId }): ScoreState => {
      const newUserProjectDataState = { ...state.userProjectScoreState };
      delete newUserProjectDataState[projectId];
      const newState: ScoreState = {
        ...state,
        userProjectScoreState: newUserProjectDataState,
      };
      return newState;
    }
  ),
  on(
    scoreActions.mergeToUserProjectScore,
    (state: ScoreState, { userDomainScore }): ScoreState => {
      const newState: ScoreState = {
        ...state,
        userProjectScoreState: { ...state.userProjectScoreState },
      };
      newState.userProjectScoreState[userDomainScore.projectId] =
        mergeUserUserDomainScoreToUserProjectScore(
          userDomainScore,
          newState.userProjectScoreState[userDomainScore.projectId]
        );
      return newState;
    }
  ),
  on(scoreActions.clearUserUnitScore, (state: ScoreState): ScoreState => {
    const newState: ScoreState = {
      ...state,
      userUnitScoreState: {},
    };
    return newState;
  })
);

export const scoreReducer = (state: ScoreState | undefined, action: Action) =>
  reducer(state, action);

export const selectScoreState = createFeatureSelector<ScoreState>('score');

export const selectUserUnitScoreState = createSelector(
  selectScoreState,
  (state: ScoreState) => state.userUnitScoreState
);

export const selectUserUnitScore = createSelector(
  selectUserUnitScoreState,
  selectUserUnitScorePath,
  (userUnitScoreState, unitPath) => userUnitScoreState[unitPath]
);

export const selectUserChapterScoreState = createSelector(
  selectScoreState,
  (state: ScoreState) => state.userChapterScoreState
);

export const selectUserDomainScoreState = createSelector(
  selectScoreState,
  (state: ScoreState) => state.userDomainScoreState
);

export const selectUserProjectScoreState = createSelector(
  selectScoreState,
  (state: ScoreState) => state.userProjectScoreState
);

export const selectUserUnitScoreToClassMap = createSelector(
  selectUserUnitScore,
  (userUnitScore) => mapUserUnitScoreToClassMap(userUnitScore)
);

export const selectUnitTotalScore = createSelector(
  selectUserUnitScore,
  (userUnitScore) => {
    if (!userUnitScore) {
      return null;
    }
    return calcUnitScore(userUnitScore);
  }
);

export const selectUnitTotalScoreToClass = createSelector(
  selectUnitTotalScore,
  (unitScore) => {
    if (unitScore === null) {
      return null;
    }
    return mapUnitScoreToClassName(unitScore);
  }
);
