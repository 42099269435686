import { createAction, props } from '@ngrx/store';
import { Domain } from 'src/app/models/domain';
import { Group } from 'src/app/models/group';
import { Project } from 'src/app/models/project';
import { UserReference } from 'src/app/models/user';
import {
  StudentSortCriteria,
  TeacherDashboardState,
} from 'src/app/store/reducers/teacher-dashboard.reducer';

export const selectGroup = createAction(
  '[Teacher Dashboard] Select Group',
  props<{ selectedGroup: Group | null }>()
);

export const selectProject = createAction(
  '[Teacher Dashboard] Select Project',
  props<{ selectedProjectId: Project['id'] | null }>()
);

export const selectDomain = createAction(
  '[Teacher Dashboard] Select Domain',
  props<{
    selectedDomain: { id: Domain['id']; title: Domain['title'] } | null;
  }>()
);

export const selectStartDate = createAction(
  '[Teacher Dashboard] Select Start Date',
  props<{ startDate: number | null }>()
);

export const selectEndDate = createAction(
  '[Teacher Dashboard] Select End Date',
  props<{ endDate: number | null }>()
);

export const selectUsers = createAction(
  '[Teacher Dashboard] Select Users',
  props<{ users: UserReference[] }>()
);

export const performSearch = createAction('[Teacher Dashboard] Perform Search');

export const performSearchSuccess = createAction(
  '[Teacher Dashboard] Perform Search Success',
  props<{ results: TeacherDashboardState['tableData'] }>()
);

export const performSearchFailure = createAction(
  '[Teacher Dashboard] Perform Search Failure'
);

export const setStudentSortCriteria = createAction(
  '[Teacher Dashboard] Set Student Sort Criteria',
  props<{ criteria: StudentSortCriteria }>()
);

export const resetTeacherDashboard = createAction(
  '[Teacher Dashboard] Reset Teacher Dashboard'
);
