import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EntityType } from 'shared/enums/entity-type';
import { StructuralEntity } from 'shared/models/structural-entity';
import { AppUser } from 'shared/models/user';
import { Interaction } from '../../../shared/models/interaction';
import { Project } from '../../../shared/models/project';
import { castToStructuralEntity } from '../../../shared/models/structural-entity';
import { Unit } from '../../../shared/models/unit';
import { DatabaseService } from './database.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  constructor(private databaseService: DatabaseService) {}

  getUnit(unitId: Unit['id']): Observable<Unit> {
    const localUnit = LocalStorageService.getUnit(unitId);

    if (localUnit) {
      return of(localUnit);
    }

    return this.databaseService
      .getUnit(unitId)
      .pipe(tap((unit) => unit?.id && LocalStorageService.cacheUnit(unit)));
  }

  getEntity(
    entityId: StructuralEntity['id'],
    entityType: EntityType
  ): Observable<StructuralEntity> {
    if (entityType === EntityType.unit) {
      return this.getUnit(entityId).pipe(
        map((unit) => castToStructuralEntity(unit))
      );
    }

    if (entityType === EntityType.interaction) {
      return this.databaseService.getObjAsStructuralEntity(
        entityId,
        entityType
      );
    }

    const localEntity = LocalStorageService.getEntity(entityId, entityType);

    if (localEntity) {
      return of(localEntity);
    }

    return this.databaseService
      .getObjAsStructuralEntity(entityId, entityType)
      .pipe(
        tap((entity) => entity?.id && LocalStorageService.cacheEntity(entity))
      );
  }

  getEntitiesOfType(entityType: EntityType): Observable<StructuralEntity[]> {
    return this.databaseService
      .getEntitiesOfType(entityType)
      .pipe(tap((entities) => LocalStorageService.cacheEntities(entities)));
  }

  getProjectsForUser(user: AppUser): Observable<Project[]> {
    return this.databaseService
      .getProjectsForUser(user)
      .pipe(tap((entities) => LocalStorageService.cacheEntities(entities)));
  }

  listChildrenForUnit(unitId: Unit['id']): Observable<Interaction['id'][]> {
    return this.getUnit(unitId).pipe(
      map((unit) => {
        const list = [];
        if (unit.presentation) {
          list.push(unit.presentation);
        }
        if (unit.infoPopup) {
          list.push(unit.infoPopup);
        }

        for (const part of [
          unit.introduction,
          unit.extra,
          unit.deepening,
          unit.test,
        ]) {
          if (part) {
            list.push(part);
          }
        }

        return uniq(list.flat(Infinity));
      })
    );
  }

  listChildrenForEntity(
    entityId: StructuralEntity['id'],
    entityType: EntityType
  ) {
    const entity$ = this.getEntity(entityId, entityType);
    return entity$.pipe(
      switchMap((entity) => {
        if (entityType === EntityType.unit) {
          return this.listChildrenForUnit(entityId);
        }

        return of(entity.children);
      })
    );
  }

  getChildrenForEntity(
    entityId: StructuralEntity['id'],
    entityType: EntityType
  ): Observable<Array<StructuralEntity | Unit | Interaction>> {
    if (entityType === EntityType.interaction) {
      return of([]);
    }

    if (entityType === EntityType.unit) {
      return this.listChildrenForUnit(entityId).pipe(
        switchMap((interactionIds) =>
          this.databaseService.getInteractions(interactionIds)
        )
      );
    }

    const children = LocalStorageService.getChildrenForEntity(
      entityId,
      entityType
    );

    if (children) {
      return of(children);
    }

    return this.databaseService.getChildrenForEntity(entityId, entityType).pipe(
      shareReplay(),
      tap((items) =>
        items?.length && entityType === EntityType.chapter
          ? LocalStorageService.cacheUnits(items as Unit[])
          : LocalStorageService.cacheEntities(items as StructuralEntity[])
      )
    );
  }

  getChildrenForEntities(
    entities: StructuralEntity[]
  ): Observable<Array<StructuralEntity | Unit | Interaction>[]> {
    return combineLatest(
      entities.map((entity) => {
        if (entity.type === EntityType.interaction) {
          console.warn(`Attempt to get children for ${entity.type}`);
          return of([]);
        }

        return this.getChildrenForEntity(entity.id, entity.type);
      })
    );
  }
}
