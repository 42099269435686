import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { scoreActions } from 'src/app/store/actions/score.actions';
import { AppState } from 'src/app/store/reducers';
import { selectUserUnitScoreState } from 'src/app/store/reducers/score.reducer';
import {
  selectIsDevMode,
  selectUser,
} from 'src/app/store/reducers/user.reducer';
import {
  InteractionScore,
  UnitScore,
} from '../../../../shared/models/score.enum';
import { environment } from '../../../environments/environment';
import { UnitContext } from '../../enums/unit-context';
import { calcUnitScore } from '../../helpers/calc-unit-score';
import { Unit } from '../../models/unit';
import { buildUserUnitScorePath } from '../../models/user-unit-score';
import { StructureService } from '../../services/structure.service';

type UnitScoreClassName =
  | 'fill-green'
  | 'fill-yellow'
  | 'fill-red'
  | 'fill-steel-grey';

type UserUnitScoreMapped = Readonly<{
  // UnitContext
  [context: number]: {
    // Interaction index, score class name
    [index: number]: UnitScoreClassName;
  };
}>;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss'],
})
export class UnitComponent implements OnInit, OnDestroy {
  @Input()
  unitId: string;
  unitData: { userUnitScoreMapped: UserUnitScoreMapped; unit: Unit };
  scoreClass: UnitScoreClassName;
  unitContexts = UnitContext;
  isDevMode$: Observable<boolean>;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  unitPath: string;
  distanceBetweenCircles = 50;

  constructor(
    private store: Store<AppState>,
    private structureService: StructureService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.isDevMode$ = store.select(selectIsDevMode);
  }

  ngOnInit() {
    const routeParamSnapshot = this.activatedRoute.snapshot.paramMap;
    if (!this.unitId) {
      this.unitId = routeParamSnapshot.get(AppRouteParams.unitId);
    }

    this.unitPath = buildUserUnitScorePath({
      projectId: routeParamSnapshot.get(AppRouteParams.projectId),
      domainId: routeParamSnapshot.get(AppRouteParams.domainId),
      chapterId: routeParamSnapshot.get(AppRouteParams.chapterId),
      unitId: this.unitId,
    });

    // Unit
    const unit$ = this.structureService.getUnit(this.unitId);

    // User data
    combineLatest([
      this.store.select(selectUser),
      this.store.select(selectUserUnitScoreState),
    ])
      .pipe(
        first(
          ([user, userUnitScores]) => !!user && !userUnitScores[this.unitPath]
        ),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(([user]) => {
        this.store.dispatch(
          scoreActions.loadUserUnitScore({
            uid: user.uid,
            unitId: this.unitId,
            chapterId: routeParamSnapshot.get(AppRouteParams.chapterId),
            domainId: routeParamSnapshot.get(AppRouteParams.domainId),
            projectId: routeParamSnapshot.get(AppRouteParams.projectId),
          })
        );
      });

    const userUnitScore$ = this.store.select(selectUserUnitScoreState).pipe(
      first((userUnitScores) => !!userUnitScores[this.unitPath]),
      map((userUnitScores) => userUnitScores[this.unitPath]),
      takeUntil(this.ngDestroyed$)
    );

    combineLatest([unit$, userUnitScore$])
      .pipe(
        first(([unit, userUnitScore]) => !!unit && !!userUnitScore),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(([unit, userUnitScore]) => {
        const contexts = userUnitScore.contexts;
        const mapped = {};
        mapped[UnitContext.presentation] = {};
        mapped[UnitContext.infoPopup] = {};
        mapped[UnitContext.introduction] = {};
        mapped[UnitContext.extra] = {};
        mapped[UnitContext.deepening] = {};
        mapped[UnitContext.test] = {};

        for (const contextKey in contexts) {
          if (Object.prototype.hasOwnProperty.call(contexts, contextKey)) {
            const scoreObj = contexts[contextKey];
            for (const indexKey in scoreObj) {
              if (Object.prototype.hasOwnProperty.call(scoreObj, indexKey)) {
                const score = scoreObj[indexKey];
                mapped[contextKey][indexKey] =
                  this.mapInteractionScoreToClassName(score);
              }
            }
          }
        }

        this.unitData = {
          unit,
          userUnitScoreMapped: mapped,
        };

        this.scoreClass = this.mapUnitScoreToClassName(
          calcUnitScore(userUnitScore)
        );
      });
  }

  clickStart() {
    this.router.navigate([this.unitId, 'presentation', '1'], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy() {
    if (!environment.production) {
      console.log('Unit destroyed');
    }

    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  public getShortcutLineCoordinates(
    contextsToSkip: number[],
    contexts: number[],
    showOnTop: boolean
  ) {
    const numberOfSegments = contexts.length;
    const numberOfSegmentsToSkip = contextsToSkip.length;

    const segmentLength = 40;
    const angleDegree = 30;
    const height = 35;
    const lineMiddle = 100;
    const startOffset = 225;

    const start =
      startOffset +
      this.distanceBetweenCircles * _.sum(contextsToSkip) +
      numberOfSegmentsToSkip * segmentLength;

    const top = showOnTop ? lineMiddle + height : lineMiddle - height;

    const angleRadians = (angleDegree * Math.PI) / 180;
    const cosineSegment = Math.sin(angleRadians) * height;

    const baseLineLength =
      _.sum(contexts) * this.distanceBetweenCircles +
      numberOfSegments * segmentLength;

    const topLineLength = baseLineLength - 2 * cosineSegment;

    const topStart = start + cosineSegment;
    const topEnd = topStart + topLineLength;
    const end = topEnd + cosineSegment;
    return `${start},${lineMiddle} ${topStart},${top} ${topEnd},${top} ${end},${lineMiddle}`;
  }

  public getExtraLaneCoordinates() {
    const contexts = [this.unitData?.unit.extra.length ?? 0];
    const contextsToSkip = [this.unitData?.unit.presentation.length ?? 0];
    return this.getShortcutLineCoordinates(contextsToSkip, contexts, true);
  }

  public getFastLaneCoordinates() {
    const contexts = [
      this.unitData?.unit.introduction.length ?? 0,
      this.unitData?.unit.extra.length ?? 0,
      this.unitData?.unit.deepening.length ?? 0,
    ];

    const contextsToSkip = [];
    return this.getShortcutLineCoordinates(contextsToSkip, contexts, false);
  }

  private mapInteractionScoreToClassName(
    score: InteractionScore
  ): UnitScoreClassName {
    switch (score) {
      case InteractionScore.correct:
        return 'fill-green';
      case InteractionScore.correctWithHelp:
        return 'fill-yellow';
      case InteractionScore.incorrect:
        return 'fill-red';
      default:
        return null;
    }
  }

  private mapUnitScoreToClassName(score: UnitScore): UnitScoreClassName {
    switch (score) {
      case UnitScore.correct:
        return 'fill-green';
      case UnitScore.correctWithHelp:
        return 'fill-yellow';
      case UnitScore.incorrect:
        return 'fill-red';
      case UnitScore.inconclusive:
        return 'fill-steel-grey';
      default:
        return null;
    }
  }
}
