import { createAction, props } from '@ngrx/store';
import { UserChapterScore } from '../../../../shared/models/user-chapter-score';
import { UserDomainScore } from '../../../../shared/models/user-domain-score';
import { InteractionAttempt } from '../../../../shared/models/user-interaction-attempt';
import { UserProjectScore } from '../../../../shared/models/user-project-score';
import { UserUnitScore } from '../../../../shared/models/user-unit-score';

export const scoreActions = {
  // Unit scores
  loadUserUnitScore: createAction(
    '[Score] Load user unit score',
    props<{
      uid: string;
      unitId: string;
      chapterId: string;
      domainId: string;
      projectId: string;
    }>()
  ),
  mergeToUserUnitScore: createAction(
    '[Score] Merge to user unit score',
    (attempt: InteractionAttempt) => ({ attempt })
  ),
  userUnitScoreLoaded: createAction(
    '[Score] User unit score loaded',
    props<{ userUnitScore: UserUnitScore }>()
  ),
  unloadUserUnitScore: createAction(
    '[Score] Unload user unit score',
    (unitPath: string) => ({
      unitPath,
    })
  ),

  clearUserUnitScore: createAction('[Score] Clear user unit score'),

  // Chapter scores
  loadUserChapterScore: createAction(
    '[Score] Load user chapter score',
    props<{
      uid: string;
      chapterId: string;
      domainId: string;
      projectId: string;
    }>()
  ),
  mergeToUserChapterScore: createAction(
    '[Score] Merge to user chapter score',
    (userUnitScore: UserUnitScore) => ({ userUnitScore })
  ),
  userChapterScoreLoaded: createAction(
    '[Score] User chapter score loaded',
    props<{ userChapterScore: UserChapterScore }>()
  ),
  unloadUserChapterScore: createAction(
    '[Score] Unload user chapter score',
    (chapterPath: string) => ({
      chapterPath,
    })
  ),

  // Domain scores
  loadUserDomainScore: createAction(
    '[Score] Load user domain score',
    props<{ uid: string; domainId: string; projectId: string }>()
  ),
  mergeToUserDomainScore: createAction(
    '[Score] Merge to user domain score',
    (userChapterScore: UserChapterScore) => ({ userChapterScore })
  ),
  userDomainScoreLoaded: createAction(
    '[Score] User domain score loaded',
    props<{ userDomainScore: UserDomainScore }>()
  ),
  unloadUserDomainScore: createAction(
    '[Score] Unload user domain score',
    (domainPath: string) => ({
      domainPath,
    })
  ),

  // Project scores
  loadUserProjectScore: createAction(
    '[Score] Load user project score',
    props<{ uid: string; projectId: string }>()
  ),
  mergeToUserProjectScore: createAction(
    '[Score] Merge to user project score',
    (userDomainScore: UserDomainScore) => ({ userDomainScore })
  ),
  userProjectScoreLoaded: createAction(
    '[Score] User project score loaded',
    props<{ userProjectScore: UserProjectScore }>()
  ),
  unloadUserProjectScore: createAction(
    '[Score] Unload user project score',
    props<{ projectId: string }>()
  ),
};
