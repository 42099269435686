import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, timer } from 'rxjs';
import { debounce, first, map } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { getRoleName } from '../../../shared/helpers/get-role-name';
import { selectUserRoles } from '../store/reducers/user.reducer';
import { RouteData } from '../types/route-data';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private store: Store<AppState>, private location: Location) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(selectUserRoles).pipe(
      debounce((roles) => (roles ? EMPTY : timer(1500))),
      map((roles) => {
        const permittedRoles = (route.data as RouteData).roles;
        if (
          roles &&
          Array.from(roles.values()).some((role) => permittedRoles.has(role))
        ) {
          return true;
        } else {
          // TODO: use something better than alert
          alert(
            `Requires one of the following roles: ${Array.from(permittedRoles)
              .map((role) => getRoleName(role))
              .join(', ')}`
          );
          this.location.back();
          return false;
        }
      }),
      first()
    );
  }
}
