<div class="notepad-container">
  <div class="notepad-lines">
    <div class="notepad-line" *ngFor="let line of lines$ | async; let index = index; trackBy: trackLine">
      <div class="notepad-line-source-icon">
        <mat-icon>{{ iconToShow(line) }}</mat-icon>
      </div>
      <div class="notepad-line-content clear-calc-number-listener" [id]="elementId(line.id)"
        [attr.contenteditable]="isEditable(line)" (blur)="onContentChange($event, line.id)">
        <!-- Not showing the line content here, as Angular and the DOM will conflict and values might duplicate on blur -->
        <!-- {{ line.content }} -->
      </div>
      <div>
        <div class="notepad-line-delete-btn" (click)="deleteLine(line.id);">
          <mat-icon>delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div class="notepad-new-line-btn" (click)="addLine()">
      <mat-icon>add_circle</mat-icon>
    </div>
  </div>
</div>