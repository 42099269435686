<h1 mat-dialog-title>
    {{ ((otherUserTargetName$ | async) !== null && 'Kies groep voor: ' + (otherUserTargetName$ | async)) ||
    'Kies je groep' }}
</h1>
<div mat-dialog-content>
    <mat-table *ngIf="(groups$ | async)?.length" [dataSource]="groups$ | async">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actie</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button *ngIf="!userHasGroup(element.id) && !groupIsBeingHandled(element.id)"
                    (click)="addGroup(element)" mat-stroked-button color="primary">Kies</button>
                <button *ngIf="userHasGroup(element.id) && !groupIsBeingHandled(element.id)"
                    (click)="removeGroup(element.id)" mat-stroked-button color="warn">Verwijder</button>
                <mat-spinner *ngIf="groupIsBeingHandled(element.id)" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </mat-cell>
        </ng-container>
        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <p *ngIf="(groups$ | async)?.length === 0">Er zijn nog geen groepen</p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</div>