export const utility = {
  isObject: (value: any) =>
    Object.prototype.toString.call(value) === '[object Object]',
  isNestedArray: (value: any) =>
    Array.isArray(value) && Array.isArray(value[0]),
  isTruthy: <T>(value: T) => !!value,
  isFalsy: (value: any) => !value,
  allAreTruthy: (value: any[]) =>
    Array.isArray(value) && value.every(utility.isTruthy),
  someAreTruthy: (value: any[]) =>
    Array.isArray(value) && value.some(utility.isTruthy),
  isBoolean: (value: any) =>
    value === true ||
    value === false ||
    Object.prototype.toString.call(value) === '[object Boolean]',
  isNotNullOrUndefined: (value: any) => value !== null && value !== undefined,
  randomizeArray: (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  },
  isSetEqual: (a: Set<any>, b: Set<any>) => {
    if (!utility.allAreTruthy([a, b])) {
      return false;
    }
    if (a.size !== b.size) {
      return false;
    }
    for (const item of a) {
      if (!b.has(item)) {
        return false;
      }
    }
    return true;
  },
};

export const isDefined = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

export const isNonEmptyString = (value: any): value is string =>
  typeof value === 'string' && value.length > 0;

export const isNumberGreaterThanZero = (value: any): value is number =>
  typeof value === 'number' && value > 0;

export const lowerCaseAndTrim = (value: string): string =>
  value.toLowerCase().trim();

// Test insurance helper
export const deepFreeze = <T>(obj: T) => {
  const propNames = Object.getOwnPropertyNames(obj);
  for (const name of propNames) {
    const value = (obj as any)[name];
    if (value && (typeof value === 'object' || typeof value === 'function')) {
      deepFreeze(value);
    }
  }

  return Object.freeze(obj);
};
