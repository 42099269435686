import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import { entityValidator } from 'functions/src/helpers/entity-validator';
import { capitalize, flatten, groupBy, orderBy } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import {
  concatMap,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { interactionReviver } from 'shared/helpers/interaction-encoder-reviver';
import { DbInteraction, Interaction } from 'shared/models/interaction';
import {
  UserChapterScore,
  buildEmptyUserChapterScore,
} from 'shared/models/user-chapter-score';
import {
  UserDomainScore,
  buildEmptyUserDomainScore,
} from 'shared/models/user-domain-score';
import {
  UserProjectScore,
  buildEmptyUserProjectScore,
} from 'shared/models/user-project-score';
import { Unit } from '../../../shared/models/unit';

import { constants } from 'shared/constants';
import { ArchiveScoresRequest } from 'shared/models/archive-scores-request';
import {
  TeacherDashboardFilters,
  TeacherDashboardTableDataGrouped,
} from 'shared/models/teacher-dashboard-models';
import { environment } from '../../../environments/environment';
import {
  CreateGroupPayload,
  RemoveRoleFromUserPayload,
  TeacherToUserPayload,
} from '../../../functions/src/models';
import { EntityType } from '../../../shared/enums/entity-type';
import { UserRole } from '../../../shared/enums/user-role.enum';
import {
  findChildCollection,
  findChildCollectionKey,
  findCollection,
} from '../../../shared/helpers/collection-helpers';
import {
  HoursMinutesString,
  msToHoursMinutes,
} from '../../../shared/helpers/time-helper';
import { unitReviver } from '../../../shared/helpers/unit-encoder-reviver';
import { Chapter } from '../../../shared/models/chapter';
import { Domain } from '../../../shared/models/domain';
import {
  DbGroup,
  Group,
  GroupReference,
  getGroupRef,
} from '../../../shared/models/group';
import {
  ImportOrganizationRecord,
  Organization,
} from '../../../shared/models/organization';
import { Project } from '../../../shared/models/project';
import {
  StructuralEntity,
  castToStructuralEntity,
} from '../../../shared/models/structural-entity';
import { DbUnit } from '../../../shared/models/unit';
import { AppUser, DbUser, UserReference } from '../../../shared/models/user';
import {
  AddInteractionAttemptPayload,
  AddStandaloneInteractionAttemptPayload,
  GetUserInteractionAttemptPayload,
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from '../../../shared/models/user-interaction-attempt';
import {
  DraftUserUnitScore,
  UserUnitScore,
  buildDraftUserUnitScore,
} from '../../../shared/models/user-unit-score';
import { AuthService } from './auth.service';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

// TODO: Strong type cloud functions and their return types.

export type UserFilter = {
  uids?: string[];
  organizationId?: DbUser['organizationId'];
  teacher?: UserReference;
  group?: GroupReference;
  orderBy?: keyof DbUser & ('name' | 'createdDate');
  orderDirection?: 'asc' | 'desc';
};

export type GetScoreParams = {
  uid?: AppUser['uid'];
  projectId: Project['id'];
  domainId?: Domain['id'];
  chapterId?: Chapter['id'];
  unitId?: Unit['id'];
  maxTimestamp?: number;
};

export type TimePerDateArray = Array<{
  date: string; // ISO date part
  milliseconds: number;
}>;

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private auth: AuthService,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  async importEntity(entity: any | any[]) {
    if (!Array.isArray(entity)) {
      entity = [entity];
    }

    entity.forEach((item: any) => {
      const validEntityType = entityValidator(item);
      if (!validEntityType) {
        throw new Error(
          'Data appears to be invalid or malformed. ' +
            'Method accepts a single instance or (mixed) array of Project, Domain, Chapter, Unit or Interaction'
        );
      }
    });

    const importEntity = this.aff.httpsCallable('imports-entity');
    return importEntity(entity).toPromise();
  }

  async importSchools(schools: ImportOrganizationRecord[]) {
    if (!Array.isArray(schools)) {
      throw new Error('Expected an array of schools');
    }

    if (schools.some((school) => !school.id || !school.naam)) {
      throw new Error(
        'Data appears to be invalid or malformed. Method accepts an array of schools'
      );
    }

    const importSchools = this.aff.httpsCallable('imports-schools');

    // We can only write 500 records at a time.
    const batches = [];
    while (schools.length > 500) {
      const batch = schools.splice(0, 500);
      batches.push(importSchools(batch).toPromise());
    }
    batches.push(importSchools(schools).toPromise());

    return Promise.all(batches);
  }

  async importDummyData(dummyData: InteractionAttempt[]) {
    if (!Array.isArray(dummyData)) {
      throw new Error('Expected an array of dummy attempts');
    }

    if (dummyData.some((dummyRecord) => !dummyRecord.uid)) {
      throw new Error(
        'Data appears to be invalid or malformed. Method accepts an array of attempts'
      );
    }

    return this.aff
      .httpsCallable('user-addDummyAttempts')(dummyData)
      .toPromise();
  }

  async deleteDummyScores() {
    return this.aff.httpsCallable('user-deleteDummyScores')(null).toPromise();
  }

  async deleteUserScores(uids: string[]) {
    return this.aff
      .httpsCallable('user-deleteScoresForUsers')(uids)
      .toPromise();
  }

  async deleteSpecificUserScores(request: ArchiveScoresRequest) {
    return this.aff
      .httpsCallable('user-archiveScoresForUser')(request)
      .toPromise();
  }

  getInteraction(interactionId: string): Observable<Interaction> {
    if (!interactionId) {
      throw new Error('getInteraction called without interactionId');
    }

    return this.afs
      .collection<DbInteraction>(constants.dbCollections.interactions)
      .doc(interactionId)
      .get()
      .pipe(
        map((snapshot) => {
          if (!snapshot.exists) {
            throw new Error(
              `Interaction with id ${interactionId} does not exist`
            );
          }
          return interactionReviver(snapshot.data());
        })
      );
  }

  getUnit(unitId: string): Observable<Unit> {
    if (!unitId) {
      console.error('getUnit called without unitId');
      return;
    }

    return this.afs
      .collection<DbUnit>(constants.dbCollections.units)
      .doc(unitId)
      .get()
      .pipe(map((snapshot) => unitReviver(snapshot.data())));
  }

  getChildrenForEntity(
    entityId: string,
    entityType: EntityType.project | EntityType.domain | EntityType.chapter
  ): Observable<StructuralEntity[] | Unit[]> {
    const collection = findCollection(entityType);
    const childCollection = findChildCollection(entityType);
    const childCollectionKey = findChildCollectionKey(entityType);

    if (!childCollection || !childCollectionKey) {
      return of(null);
    }

    let childIdsUpper = [];

    const entities$ = this.afs
      .collection(collection)
      .doc(entityId)
      .get()
      .pipe(
        map((doc) => doc.data()[childCollectionKey]),
        switchMap((childIds: StructuralEntity['children']) => {
          if (!childIds || !childIds.length) {
            return of([]);
          }

          // Firebase 'in' operator has a limit of 10.
          // We need to break up the array.
          childIdsUpper = childIds;
          const batches: Array<string[]> = [];
          const childIdsCopy = [...childIds];
          while (childIdsCopy.length > 10) {
            batches.push(childIdsCopy.splice(0, 10));
          }
          batches.push(childIdsCopy);

          return combineLatest(
            batches.flatMap((childIdSegment) =>
              this.afs
                .collection(childCollection, (ref) =>
                  ref.where('id', 'in', childIdSegment)
                )
                .get()
                .pipe(
                  map((querySnapshot) =>
                    querySnapshot.docs
                      .map((dca) => {
                        const data = dca.data() as
                          | Project
                          | Domain
                          | Chapter
                          | DbUnit;
                        return data.type === EntityType.unit
                          ? unitReviver(data)
                          : castToStructuralEntity(data);
                      })
                      .sort(
                        (entity1, entity2) =>
                          childIdSegment.indexOf(entity1.id) -
                          childIdSegment.indexOf(entity2.id)
                      )
                  )
                )
            )
          ).pipe(map((queryResultsArray) => flatten(queryResultsArray)));
        }),
        tap(() => {
          if (!environment.production) {
            console.count(
              `Fetch children for ${capitalize(entityType)} '${entityId}' count`
            );
          }
        }),
        tap((entityArray) => {
          if (entityArray.length !== childIdsUpper.length) {
            const missingChildren = childIdsUpper.filter(
              (childId) => !entityArray.find((entity) => entity.id === childId)
            );
            console.warn(
              `Can't find all children. Parent: ${entityId} (${capitalize(
                entityType
              )}). Missing children: ${missingChildren.join(', ')}`
            );
          }
        })
      );

    return entities$;
  }

  getEntitiesOfType(entityType: EntityType): Observable<StructuralEntity[]> {
    const collection = findCollection(entityType);

    let colRef = this.afs.collection<StructuralEntity>(collection);

    if (collection === constants.dbCollections.projects) {
      colRef = this.afs.collection(collection, (ref) =>
        ref.orderBy('disabled').orderBy('title')
      );
    } else if (collection === constants.dbCollections.interactions) {
      colRef = this.afs.collection(collection, (ref) => ref.orderBy('id'));
    }

    const entities$ = colRef.get().pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data() as
            | Project
            | Domain
            | Chapter
            | DbUnit
            | DbInteraction;
          return castToStructuralEntity(data);
        })
      )
    );

    return entities$;
  }

  async getAllInteractions(): Promise<Interaction[]> {
    const result: Interaction[] = [];
    const snapshot = await this.afs
      .collection<DbInteraction>(constants.dbCollections.interactions)
      .get()
      .toPromise();

    snapshot.forEach((qds) => {
      result.push(interactionReviver(qds.data()));
    });

    return result;
  }

  getInteractions(
    interactionIds: Interaction['id'][]
  ): Observable<Interaction[]> {
    const batches: Interaction['id'][][] = [];
    while (interactionIds.length > 10) {
      batches.push(interactionIds.splice(0, 10));
    }
    batches.push(interactionIds);

    return combineLatest(
      batches.map((interactionIdSegment) =>
        this.afs
          .collection<DbInteraction>(
            constants.dbCollections.interactions,
            (ref) => ref.where('id', 'in', interactionIdSegment)
          )
          .get()
          .pipe(
            map((snapshot) =>
              snapshot.docs.map((doc) => interactionReviver(doc.data()))
            )
          )
      )
    ).pipe(map((queryResultsArray) => queryResultsArray.flat()));
  }

  getObjAsStructuralEntity(
    id: string,
    entityType: EntityType
  ): Observable<StructuralEntity> {
    const collection = findCollection(entityType);

    return this.afs
      .collection(collection)
      .doc(id)
      .get()
      .pipe(
        map((docSnapshot) => {
          const data = docSnapshot.data() as
            | Project
            | Domain
            | Chapter
            | Unit
            | Interaction;

          if (!data) {
            console.error(
              `Entity '${id}' of type '${entityType}' missing in DB`
            );
            return { id } as StructuralEntity;
          }

          return castToStructuralEntity(data);
        })
      );
  }

  entitySetDisabled(
    entityId: string,
    entityType: EntityType,
    disabled: boolean
  ) {
    return this.aff
      .httpsCallable('imports-entitySetDisabled')({
        entityId,
        entityType,
        disabled,
      })
      .toPromise();
  }

  deleteEntity(entityId: string, entityType: EntityType) {
    return this.aff
      .httpsCallable('imports-deleteEntity')({
        entityId,
        entityType,
      })
      .toPromise();
  }

  getUserScoreForEntity({
    uid,
    projectId,
    domainId,
    chapterId,
    unitId,
    maxTimestamp,
  }: GetScoreParams): Observable<
    | DraftUserUnitScore
    | UserUnitScore
    | UserChapterScore
    | UserDomainScore
    | UserProjectScore
    | null
  > {
    const entityType: EntityType =
      (unitId && EntityType.unit) ||
      (chapterId && EntityType.chapter) ||
      (domainId && EntityType.domain) ||
      EntityType.project;

    const collection =
      (unitId && constants.dbCollections.userUnitScores) ||
      (chapterId && constants.dbCollections.userChapterScores) ||
      (domainId && constants.dbCollections.userDomainScores) ||
      constants.dbCollections.userProjectScores;

    return this.afs
      .collection<
        UserUnitScore | UserChapterScore | UserDomainScore | UserProjectScore
      >(collection, (ref) => {
        let query = ref
          .where('uid', '==', uid)
          .where('projectId', '==', projectId);

        if (
          entityType === EntityType.unit ||
          entityType === EntityType.chapter ||
          entityType === EntityType.domain
        ) {
          query = query.where('domainId', '==', domainId);
        }

        if (
          entityType === EntityType.unit ||
          entityType === EntityType.chapter
        ) {
          query = query.where('chapterId', '==', chapterId);
        }

        if (entityType === EntityType.unit) {
          query = query.where('unitId', '==', unitId);
        }

        if (maxTimestamp) {
          query = query.where('timestamp', '<=', maxTimestamp);
        }

        return query.orderBy('timestamp', 'desc').limit(1);
      })
      .get()
      .pipe(
        map((querySnapshot) => {
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].data();
          } else {
            // FIXME: this should return null building empty scores should not be here.
            switch (entityType) {
              case EntityType.unit:
                return buildDraftUserUnitScore({
                  uid,
                  unitId,
                  chapterId,
                  domainId,
                  projectId,
                });
              case EntityType.chapter:
                return buildEmptyUserChapterScore(
                  uid,
                  chapterId,
                  domainId,
                  projectId
                );
              case EntityType.domain:
                return buildEmptyUserDomainScore(uid, domainId, projectId);
              case EntityType.project:
                return buildEmptyUserProjectScore(uid, projectId);
            }
          }
        })
      );
  }

  userScoreForProjectExists(uid: AppUser['uid'], projectId: Project['id']) {
    return this.afs
      .collection<UserProjectScore>(
        constants.dbCollections.userProjectScores,
        (ref) =>
          ref
            .where('uid', '==', uid)
            .where('projectId', '==', projectId)
            .limit(1)
      )
      .get()
      .pipe(map((snapshot) => !snapshot.empty));
  }

  // TODO can this be more readable?
  getUserChapterScoresForProject({
    uid,
    projectId,
    maxTimestamp,
  }: GetScoreParams): Observable<UserChapterScore[] | null> {
    return this.userScoreForProjectExists(uid, projectId).pipe(
      switchMap((scoreExists) => {
        if (!scoreExists) {
          return of(null);
        } else {
          return this.getObjAsStructuralEntity(
            projectId,
            EntityType.project
          ).pipe(
            map((proj: StructuralEntity) =>
              proj.children.map((domainId: Domain['id']) =>
                this.getObjAsStructuralEntity(domainId, EntityType.domain).pipe(
                  map((domain: StructuralEntity) =>
                    domain.children.map(
                      // TODO this sometimes throws, prolly because the domain is missing from the DB
                      (chapterId: Chapter['id']) =>
                        this.getUserScoreForEntity({
                          uid,
                          projectId,
                          domainId,
                          chapterId,
                          maxTimestamp,
                        }) as Observable<UserChapterScore>
                    )
                  ),
                  concatMap((chapterScoreObservables) =>
                    combineLatest(chapterScoreObservables)
                  )
                )
              )
            ),
            concatMap((domainChapterScoreArrays) =>
              combineLatest(domainChapterScoreArrays)
            ),
            map((arrayOfArrays) => arrayOfArrays.flat())
          );
        }
      })
    );
  }

  getUserUnitScoresForDomain({
    uid,
    projectId,
    domainId,
    maxTimestamp,
  }: GetScoreParams): Observable<Array<
    UserUnitScore | DraftUserUnitScore
  > | null> {
    return this.userScoreForProjectExists(uid, projectId).pipe(
      switchMap((scoreExists) => {
        if (!scoreExists) {
          return of(null);
        } else {
          return this.getObjAsStructuralEntity(
            domainId,
            EntityType.domain
          ).pipe(
            map((domain: StructuralEntity) =>
              domain.children.map((chapterId: Chapter['id']) =>
                this.getObjAsStructuralEntity(
                  chapterId,
                  EntityType.chapter
                ).pipe(
                  map((chapter: StructuralEntity) =>
                    chapter.children.map(
                      (unitId: Unit['id']) =>
                        this.getUserScoreForEntity({
                          uid,
                          projectId,
                          domainId,
                          chapterId,
                          unitId,
                          maxTimestamp,
                        }) as Observable<UserUnitScore | DraftUserUnitScore>
                    )
                  ),
                  concatMap((chapterScoreObservables) =>
                    combineLatest(chapterScoreObservables)
                  )
                )
              )
            ),
            concatMap(
              (
                userUnitScoreArrays: Observable<
                  Array<UserUnitScore | DraftUserUnitScore>
                >[]
              ) => combineLatest(userUnitScoreArrays)
            ),
            map((arrayOfArrays: Array<UserUnitScore | DraftUserUnitScore>[]) =>
              arrayOfArrays.flat()
            )
          );
        }
      })
    );
  }

  getAttemptTimePerDayForUser(
    uid: AppUser['uid'],
    daysBack?: number
  ): Observable<TimePerDateArray> {
    // Limit to last 7 days by default
    const limit =
      this.timeService.now() - (daysBack ? daysBack : 7) * 24 * 60 * 60 * 1000;

    return this.afs
      .collection<InteractionAttempt | StandaloneInteractionAttempt>(
        constants.dbCollections.userInteractionAttempts,
        (ref) => ref.where('uid', '==', uid).where('timestamp', '>=', limit)
      )
      .get()
      .pipe(
        map((snapshot) =>
          snapshot.docs
            .map((doc) => doc.data())
            .map((attempt) => ({
              date: new Date(attempt.timestamp).toISOString().substring(0, 10),
              milliseconds: attempt.timeTaken,
            }))
        ),
        map((dateArray) => orderBy(dateArray, 'date', 'desc')),
        map((dateArray) => {
          const groupedByDate = groupBy(dateArray, 'date');
          return Object.keys(groupedByDate).map((date) => ({
            date,
            milliseconds: groupedByDate[date].reduce(
              (acc, curr) => acc + curr.milliseconds,
              0
            ),
          }));
        }),
        map((data) =>
          data.map(({ date, milliseconds }) => ({
            date,
            milliseconds,
          }))
        )
      );
  }

  getTotalTimeForProjectForUser(
    uid: AppUser['uid'],
    projectId: Project['id']
  ): Observable<HoursMinutesString> {
    return this.afs
      .collection<UserProjectScore>(
        constants.dbCollections.userProjectScores,
        (ref) =>
          ref
            .where('uid', '==', uid)
            .where('projectId', '==', projectId)
            .orderBy('timestamp', 'desc')
            .limit(1)
      )
      .get()
      .pipe(
        map((snapshot) => {
          if (snapshot.empty) {
            return 0;
          } else {
            const userProjectScore = snapshot.docs[0].data();
            return userProjectScore.totalTime;
          }
        }),
        map((totalTime) => msToHoursMinutes(totalTime))
      );
  }

  getLastUserInteractionAttempt(
    uid: AppUser['uid']
  ): Observable<InteractionAttempt | null> {
    return this.afs
      .collection<InteractionAttempt | StandaloneInteractionAttempt>(
        constants.dbCollections.userInteractionAttempts,
        (ref) =>
          ref
            .where('uid', '==', uid)
            .orderBy('timestamp', 'desc')
            .orderBy('projectId', 'asc') // This is needed to filter out null values
            .limit(1)
      )
      .get()
      .pipe(
        map((snapshot) =>
          snapshot.empty
            ? null
            : (snapshot.docs[0].data() as InteractionAttempt)
        )
      );
  }

  getUserInteractionAttempt({
    uid,
    projectId,
    domainId,
    chapterId,
    unitId,
    unitContext,
    interactionIndex,
  }: GetUserInteractionAttemptPayload): Observable<InteractionAttempt | null> {
    return this.afs
      .collection<InteractionAttempt | StandaloneInteractionAttempt>(
        constants.dbCollections.userInteractionAttempts,
        (ref) =>
          ref
            .where('uid', '==', uid)
            .where('projectId', '==', projectId)
            .where('domainId', '==', domainId)
            .where('chapterId', '==', chapterId)
            .where('unitId', '==', unitId)
            .where('unitContext', '==', unitContext)
            .where('interactionIndex', '==', interactionIndex)
            .orderBy('timestamp', 'desc')
            .limit(1)
      )
      .get()
      .pipe(
        map((snapshot) =>
          snapshot.empty
            ? null
            : (snapshot.docs[0].data() as InteractionAttempt)
        )
      );
  }

  getUser(uid: AppUser['uid']): Observable<DbUser> {
    return this.afs
      .collection<DbUser>(constants.dbCollections.users)
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map((action) => action.payload.data()),
        takeUntil(this.auth.authState().pipe(first((user) => !user)))
      );
  }

  getUsers(userFilter?: UserFilter) {
    return this.afs
      .collection<DbUser>(constants.dbCollections.users, (ref) => {
        const orderByField = userFilter.orderBy || 'name';
        let query: firebase.firestore.Query<firebase.firestore.DocumentData>;

        if (userFilter.orderDirection) {
          query = ref.orderBy(orderByField, userFilter.orderDirection);
        } else {
          query = ref.orderBy(orderByField);
        }

        if (userFilter?.organizationId) {
          query = query.where(
            'organizationId',
            '==',
            userFilter.organizationId
          );
        }

        if (userFilter?.uids) {
          query = query.where('uid', 'in', userFilter.uids);
        } else {
          // Only one array-contains is allowed per query
          if (userFilter?.group) {
            query = query.where('groups', 'array-contains', userFilter.group);
          } else if (userFilter?.teacher) {
            query = query.where(
              'teachers',
              'array-contains',
              userFilter.teacher
            );
          }
        }

        return query;
      })
      .snapshotChanges()
      .pipe(
        map((dbUsers) => dbUsers.map((dbUser) => dbUser.payload.doc.data())),
        takeUntil(this.auth.authState().pipe(first((user) => !user)))
      );
  }

  addAttempt(
    attempt:
      | AddInteractionAttemptPayload
      | AddStandaloneInteractionAttemptPayload
  ): Promise<InteractionAttempt | StandaloneInteractionAttempt> {
    return this.aff.httpsCallable('user-addAttempt')(attempt).toPromise();
  }

  changeName(names: {
    firstName: string;
    middleName?: string;
    lastName: string;
  }) {
    return this.aff.httpsCallable('user-changeName')(names).toPromise();
  }

  updateLastLogin() {
    return this.aff.httpsCallable('user-updateLastLogin')(null).toPromise();
  }

  deleteScores() {
    return this.aff.httpsCallable('user-deleteScores')(null).toPromise();
  }

  getOrganizations() {
    return this.afs
      .collection<Organization>(constants.dbCollections.organizations, (ref) =>
        ref.orderBy('name')
      )
      .get()
      .pipe(
        map((querySnapshot) => querySnapshot.docs.map((org) => org.data()))
      );
  }

  getOrganization(organizationId: string) {
    if (!organizationId) {
      console.error('getOrganization called without ID');
      return of(null);
    }

    return this.afs
      .collection<Organization>(constants.dbCollections.organizations)
      .doc(organizationId)
      .get()
      .pipe(map((org) => org.data()));
  }

  setOrganization(organizationId: string) {
    return this.aff
      .httpsCallable('user-setOrganization')(organizationId)
      .toPromise();
  }

  removeOrganization() {
    return this.aff.httpsCallable('user-removeOrganization')(null).toPromise();
  }

  addRoleToUser(uid: DbUser['uid'], role: UserRole) {
    return this.aff
      .httpsCallable('user-addRoleToUser')({
        uid,
        role,
      })
      .toPromise();
  }

  removeRoleFromUser(uid: DbUser['uid'], role: UserRole) {
    return this.aff
      .httpsCallable<RemoveRoleFromUserPayload>('user-removeRoleFromUser')({
        uid,
        role,
      })
      .toPromise();
  }

  addTeacherToUser(payload: TeacherToUserPayload) {
    return this.aff
      .httpsCallable<TeacherToUserPayload>('user-addTeacherToUser')(payload)
      .toPromise();
  }

  removeTeacherFromUser(payload: TeacherToUserPayload) {
    return this.aff
      .httpsCallable<TeacherToUserPayload>('user-removeTeacherFromUser')(
        payload
      )
      .toPromise();
  }

  async createGroup(groupPayload: CreateGroupPayload) {
    if (!groupPayload.name) {
      throw new Error('A valid group name is required');
    }

    return this.aff
      .httpsCallable<CreateGroupPayload>('group-createGroup')(groupPayload)
      .toPromise()
      .catch((err) => {
        console.error('Error creating group:', err.message || err);
        throw err;
      });
  }

  async renameGroup(newGroupRef: GroupReference) {
    if (!newGroupRef.name) {
      throw new Error('A valid group name is required');
    }
    if (!newGroupRef.id) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable<GroupReference>('group-renameGroup')(newGroupRef)
      .toPromise()
      .catch((err) => {
        console.error('Error renaming group:', err.message || err);
        throw err;
      });
  }

  async deleteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable<Group['id']>('group-deleteGroup')(groupId)
      .toPromise()
      .catch((err) => {
        console.error('Error deleting group:', err.message || err);
        throw err;
      });
  }

  getGroupMemberCount(group: Group) {
    const groupRef = getGroupRef(group);

    return this.afs
      .collection<DbUser>(constants.dbCollections.users, (ref) =>
        ref
          .where('organizationId', '==', group.organizationId)
          .where('groups', 'array-contains', groupRef)
      )
      .get()
      .pipe(map((snapshot) => snapshot.size));
  }

  addGroupToUser(groupId: Group['id'], targetUid?: DbUser['uid']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    if (targetUid) {
      return this.aff
        .httpsCallable('user-addGroupToUser')({
          groupId,
          uid: targetUid,
        })
        .toPromise();
    } else {
      return this.aff.httpsCallable('user-addGroup')(groupId).toPromise();
    }
  }

  removeGroupFromUser(groupId: Group['id'], targetUid?: DbUser['uid']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    if (targetUid) {
      return this.aff
        .httpsCallable('user-removeGroupFromUser')({
          groupId,
          uid: targetUid,
        })
        .toPromise();
    } else {
      return this.aff.httpsCallable('user-removeGroup')(groupId).toPromise();
    }
  }

  addFavoriteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable<Group['id'], void>('user-addFavoriteGroup')(groupId)
      .toPromise();
  }

  removeFavoriteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable<Group['id'], void>('user-removeFavoriteGroup')(groupId)
      .toPromise();
  }

  addProjectAccessToUser(projectId: Project['id'], uid: DbUser['uid']) {
    if (!projectId) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable('user-addProjectAccessToUser')({
        projectId,
        uid,
      })
      .toPromise();
  }

  removeProjectAccessFromUser(projectId: Project['id'], uid: DbUser['uid']) {
    if (!projectId) {
      throw new Error('ID is required');
    }

    return this.aff
      .httpsCallable('user-removeProjectAccessFromUser')({
        projectId,
        uid,
      })
      .toPromise();
  }

  getFilteredProjectsByAccess(projectIds?: string[]): Observable<Project[]> {
    return this.afs
      .collection<Project>(constants.dbCollections.projects, (ref) => {
        let query: firebase.firestore.Query<firebase.firestore.DocumentData>;

        // Either get all public projects or only those assigned to the user.
        if (projectIds?.length) {
          query = ref.where('id', 'in', projectIds);
        } else {
          query = ref.where('public', '==', true);
        }

        return query.orderBy('disabled').orderBy('title');
      })
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
        )
      );
  }

  // TODO this seems to be triggered more than necessary
  getProjectsForUser(user: AppUser): Observable<Project[]> {
    if (!user) {
      return of([]).pipe(first());
    }

    return this.getFilteredProjectsByAccess(user.projectAccess).pipe(
      takeUntil(this.auth.authState().pipe(filter((u) => !u)))
    );
  }

  getProjectsForStudent(uid: string): Observable<Project[]> {
    return this.getUser(uid).pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        return this.getFilteredProjectsByAccess(user.projectAccess);
      })
    );
  }

  getProjectsWithScoresForUser(uid: string): Observable<Project[]> {
    return this.afs
      .collection<UserProjectScore>(
        constants.dbCollections.userProjectScores,
        (ref) => ref.where('uid', '==', uid)
      )
      .get()
      .pipe(
        map((snapshot) => [
          ...new Set(snapshot.docs.map((doc) => doc.data().projectId)),
        ]),
        switchMap((projectIds) => this.getProjects(projectIds))
      );
  }

  getProjects(projectIds: string[]): Observable<Project[]> {
    return this.afs
      .collection<Project>(constants.dbCollections.projects, (ref) =>
        ref.where('id', 'in', projectIds)
      )
      .get()
      .pipe(
        map((snapshot) => snapshot.docs.map((doc) => doc.data())),
        takeUntil(this.auth.authState().pipe(first((user) => !user)))
      );
  }

  getDomainsWithScoresForUser(
    uid: string,
    projectId: string
  ): Observable<string[]> {
    return this.afs
      .collection<UserDomainScore>(
        constants.dbCollections.userDomainScores,
        (ref) => ref.where('uid', '==', uid).where('projectId', '==', projectId)
      )
      .get()
      .pipe(
        map((snapshot) => [
          ...new Set(snapshot.docs.map((doc) => doc.data().domainId)),
        ])
      );
  }

  getChaptersWithScoresForUser(
    uid: string,
    projectId: string,
    domainId: string
  ) {
    return this.afs
      .collection<UserChapterScore>(
        constants.dbCollections.userChapterScores,
        (ref) =>
          ref
            .where('uid', '==', uid)
            .where('projectId', '==', projectId)
            .where('domainId', '==', domainId)
      )
      .get()
      .pipe(
        map((snapshot) => [
          ...new Set(snapshot.docs.map((doc) => doc.data().chapterId)),
        ])
      );
  }

  getGroupsForOrg(organizationId: Organization['id']): Observable<Group[]> {
    if (!organizationId) {
      console.error('getGroups called without ID');
      return;
    }

    return this.afs
      .collection<DbGroup>(constants.dbCollections.groups, (ref) =>
        ref.where('organizationId', '==', organizationId).orderBy('name')
      )
      .snapshotChanges()
      .pipe(
        map((groups) =>
          groups.map((dca) => ({
            ...dca.payload.doc.data(),
            id: dca.payload.doc.id,
          }))
        )
      );
  }

  getAlgoliaUserKey() {
    return this.aff
      .httpsCallable('algolia-createUserSearchKey')(null)
      .toPromise();
  }

  getAlgoliaOrgKey() {
    return this.aff
      .httpsCallable('algolia-createOrgSearchKey')(null)
      .toPromise();
  }

  // Returns a token that can be used to login as the target user.
  impersonate(targetUid: AppUser['uid']): Promise<string> {
    return this.aff.httpsCallable('user-impersonate')(targetUid).toPromise();
  }

  getTeacherDashboardTableData(
    filters: TeacherDashboardFilters
  ): Observable<TeacherDashboardTableDataGrouped> {
    return this.aff.httpsCallable('teacher-getTeacherDashboardData')(filters);
  }
}
