export enum NotepadLineSource {
  'calculator' = 0,
  'manual' = 1,
}

export type NotepadLine = {
  id: string;
  source: NotepadLineSource;
  content: string;
};

export type NotepadLineForStorage = Omit<NotepadLine, 'id'>;
