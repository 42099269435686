import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';

import { appRoutes } from '../../app-routing.module';
import { selectUserRoles } from '../../store/reducers/user.reducer';
import { AppRoutes } from '../../types/route-data';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  childRoutes$: Observable<AppRoutes>;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private store: Store<AppState>) {}

  // TODO circular dependency routing module
  ngOnInit() {
    this.childRoutes$ = this.store.select(selectUserRoles).pipe(
      map(
        (userRoles) =>
          appRoutes
            .find((r) => r.component === AdminComponent)
            .children.filter(
              (route) =>
                userRoles &&
                Array.from(userRoles.values()).some((userRole) =>
                  route.data?.roles?.has(userRole)
                )
            ) as AppRoutes
      ),
      takeUntil(this.ngDestroyed$)
    );
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
