import { EntityWithProgress } from 'src/app/components/nav/nav.component';
import { EntityType } from 'src/app/enums/entity-type';
import { Project } from 'src/app/models/project';

export const educationTrackFilter = <T extends EntityWithProgress | Project>(
  entities: T[],
  educationTrack: Project['educationTrack']
): T[] =>
  entities?.filter((entity) => {
    if (entity.type === EntityType.project) {
      return !entity.educationTrack || entity.educationTrack === educationTrack;
    } else {
      return true;
    }
  });
