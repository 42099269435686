import { Component, Input, OnInit } from '@angular/core';
import { GetScoreParams } from '../../services/database.service';

@Component({
  selector: 'app-progress-area',
  templateUrl: './progress-area.component.html',
  styleUrls: ['./progress-area.component.scss'],
})
export class ProgressAreaComponent implements OnInit {
  @Input() scoreParams: GetScoreParams;
  entityTypeName: string;

  constructor() {}

  ngOnInit() {
    this.entityTypeName =
      (this.scoreParams.unitId && 'leereenheid') ||
      (this.scoreParams.chapterId && 'hoofdstuk') ||
      (this.scoreParams.domainId && 'domein') ||
      (this.scoreParams.projectId && 'project');
  }
}
