import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padZero',
})
export class PadZeroPipe implements PipeTransform {
  transform(value: number, length: number): string {
    return value.toString().padStart(length, '0');
  }
}
