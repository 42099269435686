<mat-chip-list aria-label="Breadcrumbs">
    <ng-container *ngIf="skipFromBeginning === -1">
        <mat-chip routerLink="/">
            <mat-icon>home</mat-icon>
        </mat-chip>
    </ng-container>

    <ng-container *ngFor="let crumb of breadCrumbs; let i = index">
        <mat-icon *ngIf="i > skipFromBeginning">keyboard_arrow_right</mat-icon>
        <mat-chip *ngIf="i + 1 > skipFromBeginning"
            [routerLink]="((breadCrumbs.length - 1 - i > 0) || null) && pathSuffix + '../'.repeat(breadCrumbs.length - 1 - i) ">
            {{(crumb | async)?.title}}</mat-chip>
    </ng-container>
</mat-chip-list>