import { ScoreIndicatorData } from '../types/score-type';
import { regularExpressions } from './regex';

export type HoursMinutesString = string; // 0:00
export type ISODateString = string; // 2011-10-30
export type ISODateTimeStringWithOffset = string; // 2011-10-05T14:48:00Z
export type Timestamp = number; // milliseconds since 1970

export const readableTime = (ms: number) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysMs = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysMs / (60 * 60 * 1000) + days * 24);
  const hoursMs = ms % (60 * 60 * 1000);
  const minutes = Math.round(hoursMs / (60 * 1000));

  let output = '';

  if (hours > 0) {
    output += hours + ' uur';
  }

  if (minutes > 0) {
    if (hours > 0) {
      output += ', ';
    }

    if (minutes === 1) {
      output += minutes + ' minuut';
    } else {
      output += minutes + ' minuten';
    }
  } else if (!hours) {
    output = '< 1 minuut';
  }

  return output;
};

const padZeroTwo = (n: number) => ('' + n).padStart(2, '0');

export const msToHoursMinutes = (ms: number): HoursMinutesString => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysMs = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysMs / (60 * 60 * 1000) + days * 24);
  const hoursMs = ms % (60 * 60 * 1000);
  const minutes = Math.round(hoursMs / (60 * 1000));

  return `${hours}:${padZeroTwo(minutes)}`;
};

export const getTimeFromScores = (userScore: ScoreIndicatorData) => {
  const primaryScore = Array.isArray(userScore) ? userScore[0] : userScore;
  const secondaryScore = Array.isArray(userScore) ? userScore[1] : null;

  return secondaryScore
    ? primaryScore.totalTime - secondaryScore.totalTime
    : primaryScore.totalTime;
};

export const msToMinutes = (ms: number) => Math.round(ms / 60000);

export const getDatePartFromISOString = (isoDate: string): ISODateString =>
  isoDate.substring(0, 10);

export const getDateAndTimeUpToSecondsFromISOString = (isoDate: string) =>
  isoDate.substring(0, 19);

export const addDaysToDate = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const isValidHoursMinuteString = (
  hoursMinuteString: HoursMinutesString | any
): hoursMinuteString is HoursMinutesString => {
  const isString = typeof hoursMinuteString === 'string';

  if (!isString) {
    return false;
  }

  const isValidFormat =
    regularExpressions.hoursMinuteString.test(hoursMinuteString);

  if (!isValidFormat) {
    return false;
  }

  const [hours, minutes] = hoursMinuteString.split(':').map(Number);

  return hours < 24 && minutes < 60;
};

export const hoursMinuteStringToMS = (
  hoursMinuteString: HoursMinutesString
) => {
  if (!isValidHoursMinuteString(hoursMinuteString)) {
    throw new Error(`Invalid hoursMinuteString: ${hoursMinuteString}`);
  }

  const [hours, minutes] = hoursMinuteString.split(':').map(Number);
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};
