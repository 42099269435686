export class Shape {
  static create(shape: string) {
    if (shape.includes('rect|')) {
      return new Rectangle(shape.split('|')[1]);
    }

    if (shape.includes('poly|')) {
      return new Polygon(shape.split('|')[1]);
    }

    throw new Error('Unknown shape: ' + shape);
  }
}

export class Rectangle {
  topLeft: Point;
  bottomRight: Point;

  constructor(topLeft: Point, bottomRight: Point);
  constructor(coordinateString: string);
  constructor(topLeftOrCoordinateString: string | Point, bottomRight?: Point) {
    if (typeof topLeftOrCoordinateString === 'string') {
      const coords = topLeftOrCoordinateString.split(',');
      this.topLeft = {
        x: +coords[0],
        y: +coords[1],
      };
      this.bottomRight = {
        x: +coords[2],
        y: +coords[3],
      };
    } else {
      this.topLeft = topLeftOrCoordinateString;
      this.bottomRight = bottomRight as Point;
    }
  }

  get height() {
    return this.bottomRight.y - this.topLeft.y;
  }

  get width() {
    return this.bottomRight.x - this.topLeft.x;
  }

  isPointInside = ({ x, y }: Point) =>
    x > this.topLeft.x &&
    x < this.bottomRight.x &&
    y > this.topLeft.y &&
    y < this.bottomRight.y;

  translate(point: Point): this {
    this.topLeft.x += point.x;
    this.topLeft.y += point.y;
    this.bottomRight.x += point.x;
    this.bottomRight.y += point.y;

    return this;
  }
}

export class Polygon {
  points: Point[];

  constructor(coordinateString: string) {
    const coords = coordinateString.split(',');
    const pairs = coords.reduce(
      (result: Array<string[]>, value, index, array) => {
        if (index % 2 === 0) {
          result.push(array.slice(index, index + 2));
        }
        return result;
      },
      []
    );

    this.points = pairs.map(([x, y]) => ({ x: +x, y: +y }));
  }

  //   svg() {
  //     return `<svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
  //   <polygon fill="red" points="${this.points
  //     .map(({ x, y }) => x + ',' + y)
  //     .join(' ')}" />
  // </svg>`;
  //   }

  isPointInside = (point: Point) => {
    let isInside = false;
    let minX = this.points[0].x;
    let maxX = this.points[0].x;
    let minY = this.points[0].y;
    let maxY = this.points[0].y;
    for (let n = 1; n < this.points.length; n++) {
      const currentPoint = this.points[n];
      minX = Math.min(currentPoint.x, minX);
      maxX = Math.max(currentPoint.x, maxX);
      minY = Math.min(currentPoint.y, minY);
      maxY = Math.max(currentPoint.y, maxY);
    }

    if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY) {
      return false;
    }

    let i = 0;
    let j = this.points.length - 1;
    for (j; i < this.points.length; j = i++) {
      if (
        this.points[i].y > point.y !== this.points[j].y > point.y &&
        point.x <
          ((this.points[j].x - this.points[i].x) *
            (point.y - this.points[i].y)) /
            (this.points[j].y - this.points[i].y) +
            this.points[i].x
      ) {
        isInside = !isInside;
      }
    }

    return isInside;
  };
}

export class Point {
  x: Readonly<number>;
  y: Readonly<number>;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
