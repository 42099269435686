import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Organization } from 'shared/models/organization';
import { AppUser } from 'shared/models/user';
import { DatabaseService } from 'src/app/services/database.service';
import { AppState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/reducers/user.reducer';
import { Group, GroupReference } from '../../../../shared/models/group';
import { DbUser } from '../../../../shared/models/user';
import { utility } from 'shared/helpers/utility';

@Component({
  selector: 'app-choose-groups',
  templateUrl: './choose-groups.component.html',
  styleUrls: ['./choose-groups.component.scss'],
})
export class ChooseGroupsComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  user$: Observable<AppUser>;
  otherUserTargetName$: Observable<string | null>;
  targetUser$: Observable<AppUser | DbUser>;
  targetUser: AppUser | DbUser;
  targetUserOrganizationId$: Observable<Organization['id']>;
  groups$: Observable<Group[]>;
  displayedColumns = ['name', 'actions'];
  groupsBeingHandled: Group['id'][] = [];

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    @Inject(MAT_DIALOG_DATA) private targetUid?: AppUser['uid']
  ) {}

  ngOnInit() {
    this.targetUser$ = this.targetUid
      ? this.databaseService.getUser(this.targetUid)
      : this.store.select(selectUser);

    this.targetUser$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((user) => (this.targetUser = user));

    this.targetUserOrganizationId$ = this.targetUser$.pipe(
      filter(utility.isTruthy),
      map((user) => user.organizationId),
      takeUntil(this.ngDestroyed$)
    );

    this.groups$ = this.targetUserOrganizationId$.pipe(
      switchMap(
        (orgId) =>
          (orgId && this.databaseService.getGroupsForOrg(orgId)) || of([])
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.user$ = this.store.select(selectUser);

    this.otherUserTargetName$ = this.user$.pipe(
      filter(utility.isTruthy),
      switchMap((user) => {
        if (!this.targetUid || user.uid === this.targetUid) {
          return of(null);
        } else {
          return this.targetUser$.pipe(
            filter(utility.isTruthy),
            map((targetUser) => targetUser?.name)
          );
        }
      })
    );
  }

  displayCell(cellData: GroupReference[] | string) {
    return (
      (Array.isArray(cellData) && cellData.map((g) => g.name).join(', ')) ||
      cellData
    );
  }

  userHasGroup(groupId: Group['id']): boolean {
    return this.targetUser?.groups?.some((g) => g.id === groupId);
  }

  groupIsBeingHandled(groupId: Group['id']): boolean {
    return this.groupsBeingHandled.includes(groupId);
  }

  addGroup(group: Group) {
    this.groupsBeingHandled.push(group.id);

    this.databaseService
      .addGroupToUser(group.id, this.targetUid)
      .catch((error) => console.error(error))
      .finally(() => {
        this.groupsBeingHandled = this.groupsBeingHandled.filter(
          (id) => id !== group.id
        );
      });
  }

  removeGroup(groupId: Group['id']) {
    this.groupsBeingHandled.push(groupId);
    this.databaseService
      .removeGroupFromUser(groupId, this.targetUid)
      .catch((error) => console.error(error))
      .finally(() => {
        this.groupsBeingHandled = this.groupsBeingHandled.filter(
          (id) => id !== groupId
        );
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
