import { cloneDeep } from 'lodash';
import { InteractionScore, UnitScore } from '../../../shared/models/score.enum';
import { UnitContext } from '../enums/unit-context';
import { Chapter } from '../models/chapter';
import { Domain } from '../models/domain';
import { Unit } from '../models/unit';
import { UserChapterScore } from '../models/user-chapter-score';
import { UserDomainScore } from '../models/user-domain-score';
import { InteractionAttempt } from '../models/user-interaction-attempt';
import { UserProjectScore } from '../models/user-project-score';
import { UserUnitScore } from '../models/user-unit-score';
import { calcUnitScore } from './calc-unit-score';
import { mathHelper } from './math-helper';

export const mergeAttemptToUserUnitScore = (
  userUnitScore: UserUnitScore,
  attempt: InteractionAttempt
): UserUnitScore => {
  const resultObj = cloneDeep(userUnitScore);

  const existingContext = resultObj.contexts[attempt.unitContext];
  if (existingContext && existingContext[attempt.interactionIndex]) {
    existingContext[attempt.interactionIndex] = Math.max(
      resultObj.contexts[attempt.unitContext][attempt.interactionIndex],
      attempt.score
    );
  } else if (existingContext) {
    existingContext[attempt.interactionIndex] = attempt.score;
  } else {
    resultObj.contexts[attempt.unitContext] = {};
    resultObj.contexts[attempt.unitContext][attempt.interactionIndex] =
      attempt.score;
  }

  resultObj.score = calcUnitScore(resultObj);
  resultObj.timeTaken = attempt.timeTaken;
  resultObj.totalTime = userUnitScore.totalTime + attempt.timeTaken;
  resultObj.timestamp = attempt.timestamp;

  return resultObj;
};

export const mergeUserUnitScoreToUserChapterScore = (
  userUnitScore: UserUnitScore,
  userChapterScore: UserChapterScore
): UserChapterScore => {
  const resultObj = cloneDeep(userChapterScore);

  const alreadyDone = userChapterScore.unitsComplete.some(
    (unitId: Unit['id']) => unitId === userUnitScore.unitId
  );
  const unitComplete =
    userUnitScore.score === UnitScore.correct ||
    userUnitScore.score === UnitScore.correctWithHelp;
  const unitIncorrect = userUnitScore.score === UnitScore.incorrect;

  if (unitComplete) {
    resultObj.unitsInProgress = userChapterScore.unitsInProgress.filter(
      (unitId: Unit['id']) => unitId !== userUnitScore.unitId
    );
    resultObj.unitsIncorrect = userChapterScore.unitsIncorrect.filter(
      (unitId: Unit['id']) => unitId !== userUnitScore.unitId
    );
    if (!alreadyDone) {
      resultObj.unitsComplete.push(userUnitScore.unitId);
    }
  } else {
    resultObj.unitsInProgress = Array.from(
      new Set([...resultObj.unitsInProgress, userUnitScore.unitId])
    );
  }

  if (unitIncorrect && !alreadyDone) {
    resultObj.unitsIncorrect = Array.from(
      new Set([...resultObj.unitsIncorrect, userUnitScore.unitId])
    );
  }

  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsComplete.length)
  );
  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsInProgress.length)
  );
  resultObj.percentageIncorrect = mathHelper.roundAndFixPercentage(
    100 / (userChapterScore.childCount / resultObj.unitsIncorrect.length)
  );

  resultObj.timeTaken = userUnitScore.timeTaken;
  resultObj.totalTime = userChapterScore.totalTime + userUnitScore.timeTaken;
  resultObj.timestamp = userUnitScore.timestamp;

  return resultObj;
};

export const mergeUserChapterScoreToUserDomainScore = (
  userChapterScore: UserChapterScore,
  userDomainScore: UserDomainScore
): UserDomainScore => {
  const resultObj = cloneDeep(userDomainScore);

  const alreadyInProgress = userDomainScore.chaptersInProgress.some(
    (chapterId: Chapter['id']) => chapterId === userChapterScore.chapterId
  );
  const alreadyDone = userDomainScore.chaptersComplete.some(
    (chapterId: Chapter['id']) => chapterId === userChapterScore.chapterId
  );
  const chapterComplete =
    userChapterScore.childCount === userChapterScore.unitsComplete.length;

  if (chapterComplete) {
    resultObj.chaptersInProgress = userDomainScore.chaptersInProgress.filter(
      (chapterId: Chapter['id']) => chapterId !== userChapterScore.chapterId
    );
    if (!alreadyDone) {
      resultObj.chaptersComplete.push(userChapterScore.chapterId);
    }
  } else if (!alreadyInProgress) {
    resultObj.chaptersInProgress.push(userChapterScore.chapterId);
  }

  // Update percentages
  resultObj.percentageComplete +=
    !alreadyDone && chapterComplete ? 100 / userDomainScore.childCount : 0;
  resultObj.percentageInProgress +=
    !alreadyInProgress && !chapterComplete
      ? 100 / userDomainScore.childCount
      : 0;
  resultObj.percentageInProgress -=
    alreadyInProgress && chapterComplete ? 100 / userDomainScore.childCount : 0;

  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    resultObj.percentageComplete
  );
  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    resultObj.percentageInProgress
  );

  resultObj.timeTaken = userChapterScore.timeTaken;
  resultObj.totalTime = userDomainScore.totalTime + userChapterScore.timeTaken;
  resultObj.timestamp = userChapterScore.timestamp;

  return resultObj;
};

export const mergeUserUserDomainScoreToUserProjectScore = (
  userDomainScore: UserDomainScore,
  userProjectScore: UserProjectScore
): UserProjectScore => {
  const resultObj = cloneDeep(userProjectScore);

  const alreadyInProgress = userProjectScore.domainsInProgress.some(
    (domainId: Domain['id']) => domainId === userDomainScore.domainId
  );
  const alreadyDone = userProjectScore.domainsComplete.some(
    (domainId: Domain['id']) => domainId === userDomainScore.domainId
  );
  const domainComplete =
    userDomainScore.childCount === userDomainScore.chaptersComplete.length;

  if (domainComplete) {
    resultObj.domainsInProgress = userProjectScore.domainsInProgress.filter(
      (domainId: Domain['id']) => domainId !== userDomainScore.domainId
    );
    if (!alreadyDone) {
      resultObj.domainsComplete.push(userDomainScore.domainId);
    }
  } else if (!alreadyInProgress) {
    resultObj.domainsInProgress.push(userDomainScore.domainId);
  }

  // Update percentages
  resultObj.percentageComplete +=
    !alreadyDone && domainComplete ? 100 / userProjectScore.childCount : 0;
  resultObj.percentageInProgress +=
    !alreadyInProgress && !domainComplete
      ? 100 / userProjectScore.childCount
      : 0;
  resultObj.percentageInProgress -=
    alreadyInProgress && domainComplete ? 100 / userProjectScore.childCount : 0;

  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    resultObj.percentageComplete
  );
  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    resultObj.percentageInProgress
  );

  resultObj.timeTaken = userDomainScore.timeTaken;
  resultObj.totalTime = userProjectScore.totalTime + userDomainScore.timeTaken;
  resultObj.timestamp = userDomainScore.timestamp;

  return resultObj;
};

export type UnitScoreClassName =
  | 'fill-green'
  | 'fill-yellow'
  | 'fill-red'
  | 'fill-steel-grey'
  | null;

export type UserUnitScoreMapped = Readonly<{
  // UnitContext
  [context: number]: {
    // Interaction index, score class name
    [index: number]: UnitScoreClassName;
  };
}>;

export const mapInteractionScoreToClassName = (
  score: InteractionScore
): UnitScoreClassName => {
  switch (score) {
    case InteractionScore.correct:
      return 'fill-green';
    case InteractionScore.correctWithHelp:
      return 'fill-yellow';
    case InteractionScore.incorrect:
      return 'fill-red';
    default:
      return null;
  }
};

export const mapUserUnitScoreToClassMap = (userUnitScore: UserUnitScore) => {
  if (!userUnitScore) {
    return null;
  }

  const contexts = userUnitScore.contexts;
  const classMapped: { [key: string]: { [key: string]: any } } = {
    [UnitContext.introduction]: {},
    [UnitContext.extra]: {},
    [UnitContext.deepening]: {},
    [UnitContext.test]: {},
  };

  for (const contextKey in contexts) {
    if (Object.prototype.hasOwnProperty.call(contexts, contextKey)) {
      const scoreObj = contexts[contextKey];

      for (const indexKey in scoreObj) {
        if (Object.prototype.hasOwnProperty.call(scoreObj, indexKey)) {
          const score = scoreObj[indexKey];
          classMapped[contextKey][indexKey] =
            mapInteractionScoreToClassName(score);
        }
      }
    }
  }

  return classMapped;
};

export const mapUnitScoreToClassName = (
  score: UnitScore
): UnitScoreClassName => {
  switch (score) {
    case UnitScore.correct:
      return 'fill-green';
    case UnitScore.correctWithHelp:
      return 'fill-yellow';
    case UnitScore.incorrect:
      return 'fill-red';
    case UnitScore.inconclusive:
      return 'fill-steel-grey';
    default:
      return null;
  }
};
