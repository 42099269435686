export enum InteractionActionType {
  // gecijferdSPA = 'GecijferdSPA',
  // gecijferdSPA2 = 'GecijferdSPA2',
  // goedrekenenSPA = 'GoedrekenenSPA',
  // hiddenFlash = 'HiddenFlash',
  // hint = 'Hint',
  // image = 'Image',
  // imageDragDrop = 'ImageDragDrop',
  // imageDraggable = 'ImageDraggable',
  // openQuestion = 'OpenQuestion',
  resetClicks = 'ResetClicks',
  // setDefault = 'SetDefault',
  setImage = 'SetImage',
  setQuestion = 'SetQuestion',
  // slideshow = 'Slideshow',
  // sound = 'Sound',
  // timerClose = 'TimerClose',
  // zoomImage = 'ZoomImage',
}
