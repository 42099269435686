<div class="export-convertor-container">
    <mat-card class="mat-card-full">
        <mat-card-header>
            <mat-card-title>Export convertor</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="input-section">
                <p>
                    Paste the entire content of IntraQuest developer JS listing export here. (CTRL+A, CTRL+C)
                </p>
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Paste export text</mat-label>
                    <textarea matInput [(ngModel)]="textAreaContent" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="20"></textarea>
                </mat-form-field>
                <div class="button-row">
                    <button mat-raised-button (click)="convert()">Convert</button>
                    <button mat-raised-button [disabled]="!showDownloadButton" (click)="downloadResults()">
                        <mat-icon>download</mat-icon>
                        Download
                    </button>
                    <button mat-raised-button [disabled]="!showDownloadButton" (click)="copyToClipboard()">
                        <mat-icon>content_copy</mat-icon>
                        Copy
                    </button>
                </div>
            </div>
            <div class="output-section" *ngIf="convertedResults.length > 0">
                <h3>Converted Results</h3>
                <pre>{{ convertedResults | json }}</pre>
            </div>
        </mat-card-content>
    </mat-card>
</div>