import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeacherDashboardHighlightService {

  hoveredColumnIndex$: Observable<number | null>;
  hoveredUnitIndex$: Observable<number | null>;
  private hoveredColumnIndex = new BehaviorSubject<number | null>(null);
  private hoveredUnitIndex = new BehaviorSubject<number | null>(null);

  constructor() {
    this.hoveredColumnIndex$ = this.hoveredColumnIndex.asObservable();
    this.hoveredUnitIndex$ = this.hoveredUnitIndex.asObservable();
  }

  setHoveredColumn(index: number | null): void {
    this.hoveredColumnIndex.next(index);
  }

  setHoveredUnit(index: number | null): void {
    this.hoveredUnitIndex.next(index);
  }
}
