import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { AppState } from 'src/app/store/reducers';
import { errorMessages } from '../../../../shared/error-messages';
import { Group, buildGroupRef } from '../../../../shared/models/group';
import { DatabaseService } from '../../services/database.service';
import { DialogComponent } from '../dialog/dialog.component';

export interface GroupDialogData {
  organizationId: string;
  existingGroup?: Group;
}

@Component({
  selector: 'app-set-group-dialog',
  templateUrl: './set-group-dialog.component.html',
  styleUrls: ['./set-group-dialog.component.scss'],
})
export class SetGroupDialogComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  name = new UntypedFormControl('', [Validators.required]);
  loading$: BehaviorSubject<boolean>;
  saveDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupDialogData
  ) {}

  ngOnInit() {
    this.loading$ = new BehaviorSubject(false);

    if (this.data.existingGroup) {
      this.name.setValue(this.data.existingGroup.name);
    }

    // Disable save when loading
    combineLatest([
      this.name.valueChanges.pipe(debounceTime(300)),
      this.loading$,
    ])
      .pipe(
        map(
          ([name, loading]) =>
            // Name not empty, not the same and not loading
            !name || name === this.data.existingGroup?.name || loading
        ),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((saveDisabled) => this.saveDisabled$.next(saveDisabled));

    // Disable non unique name error when user types
    this.name.valueChanges
      .pipe(debounceTime(300), takeUntil(this.ngDestroyed$))
      .subscribe(() => {
        this.name.setErrors(null);
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  createGroup() {
    if (!this.name.value) {
      return;
    }

    this.loading$.next(true);

    this.databaseService
      .createGroup({
        name: this.name.value,
        organizationId: this.data.organizationId,
      })
      .then(() => this.dialogRef.close())
      .catch((error) => {
        if (error.message === errorMessages.nameNotUnique) {
          this.name.setErrors({ forbiddenName: true });
        }

        this.store.dispatch(showSnackbar({ message: error.message }));
      })
      .finally(() => this.loading$.next(false));
  }

  renameGroup() {
    if (!this.name.value) {
      return;
    }

    this.loading$.next(true);

    this.databaseService
      .renameGroup(buildGroupRef(this.data.existingGroup.id, this.name.value))
      .then(() => this.dialogRef.close())
      .catch((error) => {
        if (error.message === errorMessages.nameNotUnique) {
          this.name.setErrors({ forbiddenName: true });
        }

        this.store.dispatch(showSnackbar({ message: error.message }));
      })
      .finally(() => this.loading$.next(false));
  }
}
