<h1 mat-dialog-title>{{ !!data.existingGroup ? 'Hernoem groep' : 'Maak een groep' }}</h1>
<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Naam</mat-label>
        <input autocomplete="off" [attr.cdkFocusInitial]="true" appSelectOnFocus matInput [formControl]="name">
        <mat-error *ngIf="name.errors?.required">Dit is een verplicht veld</mat-error>
        <mat-error *ngIf="name.errors?.forbiddenName">Deze naam is niet beschikbaar</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false">
        Sluiten
    </button>
    <button mat-raised-button [disabled]="saveDisabled$ | async" [class.spinner]="loading$ | async"
        (click)="!!data.existingGroup ? renameGroup() : createGroup()">
        Opslaan
    </button>
</mat-dialog-actions>