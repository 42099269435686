import { utility } from 'src/app/helpers/utility';
import { Interaction } from 'src/app/models/interaction';
import { InteractionActionType } from '../../enums/interaction-action-type';
import { InteractionEvent } from '../../enums/interaction-event';
import { InteractionModel } from '../../enums/interaction-model';
import { regularExpressions } from '../../helpers/regex';

export enum IssueType {
  dcInOption = 'DC in option text',
  dragElementsTypo = 'dragelementsPosition -> dragElementsPosition',
  incorrectEquationFormat = 'Format should be format="%0.0f"',
  incorrectOptionType = 'Incorrect option type',
  missingHotspots = 'Missing hotspots',
  missingOrderField = 'Missing order field',
  possibleThousandSeparator = 'Possible thousand separator',
  unsupportedAction = 'Action not supported',
  unsupportedEvent = 'Event not supported',
  unsupportedModel = 'Model not supported',
}

export type InteractionIssue = {
  id: string;
  issueType: string;
  path: string;
};

export const issueIdentifiersAndHandlers = {
  dcInOption: (text: string) =>
    !!text.match(regularExpressions.decimalCommaIsTrue),
  fixDcInOption: (text: string) =>
    text.replace(regularExpressions.decimalCommaIsTrue, ''),
  incorrectOptionType: (interaction: Interaction) => {
    switch (interaction.model) {
      case InteractionModel.openText:
      case InteractionModel.openTextMultiple:
      case InteractionModel.openTextInImage:
      case InteractionModel.multipleChoice:
        return !interaction.optionsText;
      case InteractionModel.presentation:
        return !interaction.optionsPresentation;
      case InteractionModel.sortToHotspots:
        return !interaction.optionsHotspot;
      case InteractionModel.sortObjectsOrder:
        return !interaction.optionsSortOrder;
      case InteractionModel.pointAndClick:
        // case InteractionModel.pointAndClickOrder:
        return !interaction.optionsImage;
      default:
        return false;
    }
  },
  fixIncorrectOptionType: (interaction: Interaction) => {
    const optionTypes = {
      optionsText: 'optionsText',
      optionsPresentation: 'optionsPresentation',
      optionsImage: 'optionsImage',
      optionsHotspot: 'optionsHotspot',
      optionsSortOrder: 'optionsSortOrder',
    };

    const renameOptionsTo = (desiredKeyName: string) => {
      const incorrectOptionType = Object.values(optionTypes).find(
        (type) => type !== desiredKeyName && interaction[type]
      );

      if (incorrectOptionType) {
        interaction[desiredKeyName] = interaction[incorrectOptionType];
        delete interaction[incorrectOptionType];
      }
    };

    switch (interaction.model) {
      case InteractionModel.openText:
      case InteractionModel.openTextMultiple:
      case InteractionModel.openTextInImage:
      case InteractionModel.multipleChoice:
        renameOptionsTo(optionTypes.optionsText);
        break;
      case InteractionModel.presentation:
        renameOptionsTo(optionTypes.optionsPresentation);
        break;
      case InteractionModel.sortToHotspots:
        renameOptionsTo(optionTypes.optionsHotspot);
        break;
      case InteractionModel.sortObjectsOrder:
        renameOptionsTo(optionTypes.optionsSortOrder);
        break;
      case InteractionModel.pointAndClick:
        // case InteractionModel.pointAndClickOrder:
        renameOptionsTo(optionTypes.optionsImage);
        break;
      default:
        break;
    }
  },
  missingOrderField: (interaction: Interaction) =>
    interaction.model === InteractionModel.sortObjectsOrder &&
    !interaction.order,
  missingHotspots: (interaction: Interaction) =>
    interaction.model === InteractionModel.sortToHotspots &&
    !interaction.hotspots,
  possibleThousandSeparator: (text: string) =>
    !!text.match(regularExpressions.possibleThousandSeparator),
  removePossibleThousandSeparator: (text: string) =>
    text.replace(regularExpressions.possibleThousandSeparator, ''),
  unsupportedModel: (interaction: Interaction) =>
    !Object.values(InteractionModel).includes(interaction.model),
  unsupportedAction: (actionType: InteractionActionType) =>
    !Object.values(InteractionActionType).includes(actionType),
  unsupportedEvent: (event: InteractionEvent) =>
    !Object.values(InteractionEvent).includes(event),
  incorrectEquationFormat: (text: string) =>
    !!text.match(regularExpressions.incorrectEquationFormat) ||
    !!text.match(regularExpressions.incorrectEquationFormat2),
  fixIncorrectEquationFormat: (text: string) =>
    text
      .replace(regularExpressions.incorrectEquationFormat, 'format="$1"')
      .replace(regularExpressions.incorrectEquationFormat2, 'format="$1"'),
  dragElementsTypo: (interaction: Interaction) =>
    'dragelementsPosition' in interaction && !interaction.dragElementsPosition,
  fixDragElementsTypo: (interaction: Interaction) => {
    if ('dragelementsPosition' in interaction) {
      interaction.dragElementsPosition = (interaction as any)
        .dragelementsPosition as typeof interaction.dragElementsPosition;
      delete (interaction as any).dragelementsPosition;
    }
  },
};

export const findCommonInteractionIssues = (
  interactions: Interaction[]
): InteractionIssue[] | null => {
  const issues = interactions
    .map((interaction) => searchIssuesRecursive(interaction))
    .filter(utility.isTruthy);

  return (issues.length && (issues.flat() as InteractionIssue[])) || null;
};

export const searchIssuesRecursive = (
  interaction: Interaction,
  autoFix = false,
  interactionPart = null,
  path: string[] = null,
  issues = null,
  topLevel = true
): (InteractionIssue | Interaction)[] | null => {
  path = path || [];
  const object = interactionPart || interaction;
  const result: (InteractionIssue | Interaction)[] = issues || [];

  if (autoFix && !result.some((i) => i.id === interaction.id)) {
    result.push(interaction);
  }

  // Top level checks
  if (topLevel) {
    if (issueIdentifiersAndHandlers.unsupportedModel(interaction)) {
      result.push({
        id: interaction.id,
        issueType: IssueType.unsupportedModel,
        path: '',
      });
    }

    if (issueIdentifiersAndHandlers.incorrectOptionType(interaction)) {
      if (autoFix) {
        issueIdentifiersAndHandlers.fixIncorrectOptionType(interaction);
      } else {
        result.push({
          id: interaction.id,
          issueType: IssueType.incorrectOptionType,
          path: '',
        });
      }
    }

    if (issueIdentifiersAndHandlers.missingHotspots(interaction)) {
      result.push({
        id: interaction.id,
        issueType: IssueType.missingHotspots,
        path: '',
      });
    }

    if (issueIdentifiersAndHandlers.missingOrderField(interaction)) {
      result.push({
        id: interaction.id,
        issueType: IssueType.missingOrderField,
        path: '',
      });
    }

    if (issueIdentifiersAndHandlers.dragElementsTypo(interaction)) {
      if (autoFix) {
        issueIdentifiersAndHandlers.fixDragElementsTypo(interaction);
      } else {
        result.push({
          id: interaction.id,
          issueType: IssueType.dragElementsTypo,
          path: '',
        });
      }
    }
  }

  Object.keys(object).forEach((key) => {
    path.push(key);

    if (utility.isObject(object[key]) || Array.isArray(object[key])) {
      // Go deeper.
      searchIssuesRecursive(
        interaction,
        autoFix,
        object[key],
        [...path],
        result,
        false
      );
      path.pop();
    } else {
      // Decimal is true in option.
      if (
        typeof object[key] === 'string' &&
        key === 'option' &&
        interaction.numeric &&
        issueIdentifiersAndHandlers.dcInOption(object[key])
      ) {
        if (autoFix) {
          object[key] = issueIdentifiersAndHandlers.fixDcInOption(object[key]);
        } else {
          result.push({
            id: interaction.id,
            issueType: IssueType.dcInOption,
            path: path.join('/'),
          });
        }
      }

      // Non numeric option detected in numeric interaction.
      if (
        typeof object[key] === 'string' &&
        key === 'option' &&
        interaction.numeric &&
        issueIdentifiersAndHandlers.possibleThousandSeparator(object[key]) &&
        !regularExpressions.equation.test(object[key])
      ) {
        if (autoFix) {
          object[key] =
            issueIdentifiersAndHandlers.removePossibleThousandSeparator(
              object[key]
            );
        } else {
          result.push({
            id: interaction.id,
            issueType: IssueType.possibleThousandSeparator,
            path: path.join('/'),
          });
        }
      }

      // Unsupported Action
      if (
        path.includes('actions') &&
        typeof object[key] === 'string' &&
        key === 'type' &&
        issueIdentifiersAndHandlers.unsupportedAction(object[key] as any)
      ) {
        result.push({
          id: interaction.id,
          issueType: IssueType.unsupportedAction,
          path: path.join('/') + ': ' + object[key],
        });
      }

      // Unsupported Event
      if (
        path.includes('actions') &&
        typeof object[key] === 'string' &&
        key === 'event' &&
        object[key]
          .split(';')
          .filter(utility.isTruthy)
          .some((e: InteractionEvent) =>
            issueIdentifiersAndHandlers.unsupportedEvent(e)
          )
      ) {
        const events = object[key].split(';').filter(utility.isTruthy);
        events.forEach((e: InteractionEvent) => {
          if (issueIdentifiersAndHandlers.unsupportedEvent(e)) {
            result.push({
              id: interaction.id,
              issueType: IssueType.unsupportedEvent,
              path: path.join('/') + ': ' + e,
            });
          }
        });
      }

      // Incorrect equation format
      if (
        typeof object[key] === 'string' &&
        issueIdentifiersAndHandlers.incorrectEquationFormat(object[key])
      ) {
        if (autoFix) {
          object[key] = issueIdentifiersAndHandlers.fixIncorrectEquationFormat(
            object[key]
          );
        } else {
          result.push({
            id: interaction.id,
            issueType: IssueType.incorrectEquationFormat,
            path: path.join('/'),
          });
        }
      }
    }

    if (typeof object[key] !== 'object') {
      path.pop();
    }
  });

  return (result.length && result) || null;
};
