<mat-card>
    <mat-card-header>
        <mat-card-title>
            Search
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="licenseSearch">
            <div class="filter-container">
                <mat-form-field appearance="outline">
                    <input matInput placeholder="License code" formControlName="licenseCode">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="licenseType">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let type of licenseTypeDropdown | keyvalue" [value]="type.key">
                            {{ type.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Duur</mat-label>
                    <mat-select formControlName="durationType">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let duration of durationTypes | keyvalue" [value]="duration.key">
                            {{ duration.key }}: {{ duration.value }} dagen
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ais-instantsearch class="org-filter" *ngIf="orgSearchConfig" [config]="orgSearchConfig">
                    <app-auto-complete [label]="'School'" [displayFunction]="displayOrg"
                        (outputSelected)="selectOrgSearch($event)" (searchInputChanged)="orgInputChangedSearch($event)">
                    </app-auto-complete>
                </ais-instantsearch>

                <mat-form-field appearance="outline">
                    <mat-label>Distributiekanaal</mat-label>
                    <mat-select formControlName="distributionChannel">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let channel of distributionChannelDropdown | keyvalue"
                            [value]="channel.key">
                            {{ channel.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ais-instantsearch class="user-filter" *ngIf="userSearchConfig" [config]="userSearchConfig">
                    <app-auto-complete [label]="'Gebruiker'" (outputSelected)="selectUser($event)"
                        (searchInputChanged)="userInputChanged($event)">
                    </app-auto-complete>
                </ais-instantsearch>

                <mat-form-field class="datepicker filter" appearance="outline">
                    <mat-label>Gegenereerd op</mat-label>
                    <mat-date-range-input [rangePicker]="pickerGeneratedAt">
                        <input matStartDate placeholder="Start date" formControlName="generatedAtStart">
                        <input matEndDate placeholder="End date" formControlName="generatedAtEnd">
                    </mat-date-range-input>
                    <mat-datepicker-toggle *ngIf="this.licenseSearch.value.generatedAtStart" matSuffix
                        (click)="clearGeneratedDate()">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="pickerGeneratedAt"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerGeneratedAt (opened)="clearDistributedDate()"></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field class="datepicker filter" appearance="outline">
                    <mat-label>Gedistribueerd op</mat-label>
                    <mat-date-range-input [rangePicker]="pickerDistributedAt">
                        <input matStartDate placeholder="Start date" formControlName="distributedAtStart">
                        <input matEndDate placeholder="End date" formControlName="distributedAtEnd">
                    </mat-date-range-input>
                    <mat-datepicker-toggle *ngIf="this.licenseSearch.value.distributedAtStart" matSuffix
                        (click)="clearDistributedDate()">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix [for]="pickerDistributedAt"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerDistributedAt (opened)="clearGeneratedDate()"></mat-date-range-picker>
                </mat-form-field>
            </div>

            <button mat-raised-button [disabled]="markAsDistributedInProgress" (click)="search()">Search</button>
            <button mat-raised-button
                [disabled]="(licenseSearchResults$ | async)?.length < 1 || markAsDistributedInProgress"
                (click)="exportCsv()">Export CSV</button>
            <button mat-raised-button
                [disabled]="(licenseSearchResults$ | async)?.length < 1 || markAsDistributedInProgress"
                [class.spinner]="markAsDistributedInProgress" (click)="markAsDistributed()">Mark as distributed</button>
        </form>

        <h4 *ngIf="isDev" style="color: #b92a11">Let op: DEV codes!</h4>

        <mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
                <mat-header-cell *matHeaderCellDef>{{ column.title }}</mat-header-cell>
                <mat-cell *matCellDef="let license">

                    <ng-container *ngIf="column.type === 'date'">
                        {{ license[column.field] | date: 'dd-MM-yyyy' }}
                    </ng-container>

                    <ng-container *ngIf="column.type === 'user'">
                        {{ license[column.field] | username | async}}
                    </ng-container>

                    <ng-container *ngIf="column.type === 'organization'">
                        {{ (license[column.field] | organization | async)?.name }}
                    </ng-container>

                    <ng-container *ngIf="column.type === 'durationType'">
                        {{ license[column.field] }}: {{ durationTypes[license[column.field]] }} dagen
                    </ng-container>

                    <ng-container *ngIf="column.type === 'licenseType'">
                        {{ licenseTypeMap[license[column.field]] }}
                    </ng-container>

                    <ng-container *ngIf="column.type === 'distributionChannel'">
                        {{ distributionChannelMap[license[column.field]] }}
                    </ng-container>

                    <ng-container *ngIf="column.type === undefined">
                        {{ license[column.field] }}
                    </ng-container>

                    <span class="copy pointer" matRipple *ngIf="column.canCopy && license[column.field]"
                        (click)="copyToClipboard(license[column.field])">⧉</span>
                </mat-cell>
            </ng-container>

            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #licensePaginator [hidePageSize]="true" pageSize="50">
        </mat-paginator>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            Generate
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="licenseOptions">
            <div class="filter-container">
                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="licenseType">
                        <mat-option *ngFor="let type of licenseTypeDropdown | keyvalue" [value]="type.key">
                            {{ type.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Duur</mat-label>
                    <mat-select formControlName="durationType">
                        <mat-option *ngFor="let duration of durationTypes | keyvalue" [value]="duration.key">
                            {{ duration.key }}: {{ duration.value }} dagen
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ais-instantsearch class="org-filter" *ngIf="orgSearchConfig" [config]="orgSearchConfig">
                    <app-auto-complete [markRequired]="markOrgRequired" [label]="'School'"
                        [displayFunction]="displayOrg" (outputSelected)="selectOrg($event)"
                        (searchInputChanged)="orgInputChanged($event)">
                    </app-auto-complete>
                </ais-instantsearch>

                <div class="mat-form-field-wrapper checkbox-wrapper">
                    <mat-checkbox formControlName="organizationHardLink">
                        Permanente school koppeling
                    </mat-checkbox>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Distributiekanaal</mat-label>
                    <mat-select formControlName="distributionChannel">
                        <mat-option *ngFor="let channel of distributionChannelDropdown | keyvalue"
                            [value]="channel.key">
                            {{ channel.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Aantal</mat-label>
                    <input matInput autocomplete="off" required type="number" formControlName="amount" />
                    <mat-error *ngIf="licenseOptions.controls.amount.errors?.required">
                        Dit is een verplicht veld
                    </mat-error>
                    <mat-error *ngIf="licenseOptions.controls.amount.errors?.min">
                        Minimaal {{ licenseOptions.controls.amount.errors.min.min }}
                    </mat-error>
                    <mat-error *ngIf="licenseOptions.controls.amount.errors?.max">
                        Maximaal {{ licenseOptions.controls.amount.errors.max.max }}
                    </mat-error>

                </mat-form-field>
            </div>

            <button mat-raised-button
                [disabled]="!licenseOptions.valid || generateInProgress || markAsDistributedInProgress"
                [class.spinner]="generateInProgress" (click)="generate()">Generate</button>
        </form>
    </mat-card-content>
</mat-card>