import { createAction } from '@ngrx/store';
import { Project } from 'src/app/models/project';
import { ComponentNames } from '../component-names.enum';

export const componentActivated = createAction(
  '[Shared] ComponentActivated',
  (componentName: ComponentNames) => ({ componentName })
);

export const componentDestroyed = createAction(
  '[Shared] ComponentDestroyed',
  (componentName: ComponentNames) => ({ componentName })
);

export const setEducationTrack = createAction(
  '[Shared] Set Education Track',
  (educationTrack: Project['educationTrack']) => ({ educationTrack })
);

export const setIsLoading = createAction(
  '[Shared] Set Is Loading',
  (isLoading: boolean) => ({ isLoading })
);
