import { createAction, props } from '@ngrx/store';

export const calculatorActions = {
  calculate: createAction('[Calculator] calculate'),
  calculationResult: createAction(
    '[Calculator] calculation result',
    props<{ input: string; result: string }>()
  ),
  input: createAction('[Calculator] input', props<{ value: string }>()),
  revalidate: createAction('[Calculator] revalidate'),
  backspace: createAction('[Calculator] backspace'),
  clear: createAction('[Calculator] clear'),
  copy: createAction('[Calculator] copy'),
  destroyed: createAction('[Calculator] destroyed'),
  blockOperators: createAction(
    '[Calculator] Block Operators',
    props<{ operators: string[] }>()
  ),
  toggleDialog: createAction('[Calculator] toggle dialog'),
  closeDialog: createAction('[Calculator] close dialog'),
  openDialog: createAction('[Calculator] open dialog'),
  setDialogState: createAction(
    '[Calculator] set dialog state',
    props<{ isOpen: boolean }>()
  ),
};
