import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Exam } from 'shared/models/exam';
import { ExamSession, ExamSessionFinalized } from 'shared/models/exam-session';
import {
  DialogComponent,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';
import { utility } from 'src/app/helpers/utility';
import { ExamService } from 'src/app/services/exam.service';
import { examActions } from 'src/app/store/actions/exam.actions';
import { ComponentNames } from 'src/app/store/component-names.enum';
import { AppState } from 'src/app/store/reducers';
import { selectIsDevMode } from 'src/app/store/reducers/user.reducer';
import { RouteData } from 'src/app/types/route-data';
import { Actions } from '../../../../store';
import {
  selectExam,
  selectExamSession,
  selectIsSessionStarted,
  selectIsSessionStarting,
  selectUnansweredExamQuestionsCount,
} from '../../../../store/reducers/exam.reducer';

@Component({
  selector: 'app-exam-session-sub',
  templateUrl: './exam-session-sub.component.html',
  styleUrls: ['./exam-session-sub.component.scss'],
})
export class ExamSessionSubComponent implements OnInit, OnDestroy {
  exam$: Observable<Exam>;
  examSession$: Observable<ExamSession>;
  isSessionStarting$: Observable<boolean>;
  isSessionStarted$: Observable<boolean>;
  flavor$: Observable<RouteData['flavor']>;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  unansweredQuestionsCount$: Observable<number>;

  showStartButton$: Observable<boolean>;
  showContinueButton$: Observable<boolean>;
  showSubmitButton$: Observable<boolean>;

  isDevMode: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private examService: ExamService,
    private router: Router
  ) {
    this.exam$ = this.store.select(selectExam);
    this.examSession$ = this.store.select(selectExamSession);
    this.isDevMode = this.store.select(selectIsDevMode);
    this.unansweredQuestionsCount$ = this.store.select(
      selectUnansweredExamQuestionsCount
    );
    this.isSessionStarting$ = this.store.select(selectIsSessionStarting);
    this.isSessionStarted$ = this.store.select(selectIsSessionStarted);

    this.flavor$ = this.activatedRoute.data.pipe(
      filter(utility.isTruthy),
      map((data: RouteData) => data.flavor),
      filter(utility.isTruthy)
    );

    this.showStartButton$ = combineLatest([
      this.flavor$,
      this.isSessionStarted$,
    ]).pipe(map(([flavor, isStarted]) => flavor === 'start' && !isStarted));

    this.showContinueButton$ = combineLatest([
      this.flavor$,
      this.isSessionStarted$,
    ]).pipe(map(([flavor, isStarted]) => flavor === 'start' && isStarted));

    this.showSubmitButton$ = this.flavor$.pipe(
      map((flavor) => flavor === 'end')
    );
  }

  ngOnInit() {
    this.store.dispatch(
      Actions.Shared.componentActivated(ComponentNames.examSessionSubComponent)
    );
  }

  ngOnDestroy() {
    this.store.dispatch(
      Actions.Shared.componentDestroyed(ComponentNames.examSessionSubComponent)
    );
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  isFinalizedExamSession(
    examSession: ExamSession
  ): examSession is ExamSessionFinalized {
    return this.examService.isFinalizedExamSessionWrapped(examSession);
  }

  startExam() {
    this.store.dispatch(examActions.startExamSession());
  }

  resumeExam() {
    this.router.navigate(['0', '0'], {
      relativeTo: this.router.routerState.root.firstChild,
    });
  }

  async submitExam(): Promise<void> {
    try {
      const shouldSubmit = await this.showSubmitConfirmDialog().toPromise();

      if (shouldSubmit) {
        this.store.dispatch(examActions.submitExamSession());
      }
    } catch (error) {
      console.error('Error submitting exam:', error);
      // Handle error (e.g., show error message to user)
    }
  }

  private showSubmitConfirmDialog(): Observable<boolean> {
    return this.unansweredQuestionsCount$.pipe(
      first(),
      switchMap((questionCount) => {
        const baseText = 'Weet je zeker dat je de toets wil inleveren?';
        const additionalText =
          questionCount > 0
            ? ` Er zijn nog ${questionCount} vragen niet beantwoord.`
            : '';

        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Toets inleveren',
            text: `${baseText}${additionalText}`,
            preset: DialogPreset.cancelOk,
          },
        });

        return dialogRef.afterClosed();
      }),
      first()
    );
  }
}
