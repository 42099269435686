import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import algoliasearch from 'algoliasearch';
import {
  InstantSearchConfig,
  SearchClient,
} from 'angular-instantsearch/instantsearch/instantsearch';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Organization } from 'shared/models/organization';
import { SearchQuery } from 'src/app/components/auto-complete/auto-complete.component';
import { DatabaseService } from 'src/app/services/database.service';
import { AppState } from 'src/app/store/reducers';
import {
  selectAlgoliaOrgKey,
  selectUserOrganizationId,
} from 'src/app/store/reducers/user.reducer';
import { environment } from '../../../../environments/environment';

import { utility } from '../../../../shared/helpers/utility';
import { AppUser } from '../../../../shared/models/user';
import { constants } from 'shared/constants';
import { selectUser } from '../../store/reducers/user.reducer';

@Component({
  selector: 'app-choose-organization',
  templateUrl: './choose-organization.component.html',
  styleUrls: ['./choose-organization.component.scss'],
})
export class ChooseOrganizationComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchClient: SearchClient;
  config: InstantSearchConfig;
  searchParameters: SearchQuery = {
    query: '',
    id: null,
  };
  user: AppUser;
  userOrganization$: Observable<Organization>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    this.userOrganization$ = this.store.select(selectUserOrganizationId).pipe(
      switchMap((orgId) => {
        if (!orgId) {
          return of(null);
        }
        return this.databaseService.getOrganization(orgId);
      }),
      takeUntil(this.ngDestroyed$)
    );

    this.store
      .select(selectUser)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((user) => (this.user = user));

    this.store
      .select(selectAlgoliaOrgKey)
      .pipe(filter(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((algoliaOrgKey) => {
        this.searchClient = algoliasearch(
          environment.algoliaAppId,
          algoliaOrgKey
        );
        this.config = {
          indexName: constants.algoliaIndices.organizations,
          searchClient: this.searchClient,
        };
      });
  }

  displayOrg(organization: Organization): string {
    if (organization) {
      return (
        organization.name +
        (organization.city ? ' (' + organization.city + ')' : '')
      );
    }

    return '';
  }

  setQuery(event: SearchQuery) {
    this.searchParameters.query = event.query;
    this.searchParameters.id = event.id;
  }

  save() {
    if (this.searchParameters.id && this.user) {
      this.loading$.next(true);
      this.databaseService
        .setOrganization(this.searchParameters.id)
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading$.next(false);
        });
    }
  }

  removeOrganization() {
    if (this.user) {
      this.loading$.next(true);
      this.databaseService
        .removeOrganization()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading$.next(false);
        });
    }
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
