import { License } from '../../models/license';
import { GenericDataSource } from '../../services/generic-datasource';
import { LicenseFilter, LicenseService } from '../../services/license.service';

export class LicenseDataSource extends GenericDataSource<
  License,
  LicenseFilter
> {
  constructor(licensesService: LicenseService) {
    super(licensesService);
  }
}
