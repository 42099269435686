const roundPercentage = (percentage: number) =>
  Math.min(100, Math.max(0, Math.round(percentage)));

export const mathHelper = {
  getRandomInt: (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min,
  roundPercentage,
  roundAndFixPercentage: (percentage: number) =>
    percentage > 97 ? 100 : roundPercentage(percentage), // TODO not very scientific, maybe we should not round. Just fix to 100?
};
