import { Chapter } from './chapter';
import { Domain } from './domain';
import { Project } from './project';
import { InteractionScore, UnitScore } from './score.enum';
import { Unit } from './unit';
import { AppUser } from './user';
import { UserChapterScore } from './user-chapter-score';
import { UserDomainScore } from './user-domain-score';
import {
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from './user-interaction-attempt';
import { UserProjectScore } from './user-project-score';

export const isUserUnitScore = (
  userScore:
    | UserUnitScore
    | DraftUserUnitScore
    | UserChapterScore
    | UserDomainScore
    | UserProjectScore
): userScore is UserUnitScore =>
  (userScore as UserUnitScore).thematicUnit !== undefined;

export const isDraftUserUnitScore = (
  userScore:
    | UserUnitScore
    | DraftUserUnitScore
    | UserChapterScore
    | UserDomainScore
    | UserProjectScore
): userScore is DraftUserUnitScore =>
  (userScore as DraftUserUnitScore).unitId !== undefined &&
  (userScore as UserUnitScore).thematicUnit === undefined;

export type UserUnitScore = {
  uid: AppUser['uid'];
  unitId: Unit['id'];
  chapterId: Chapter['id'];
  domainId: Domain['id'];
  projectId: Project['id'];
  thematicUnit: boolean;
  thematicLength?: number;
  score: UnitScore;
  timeTaken: number;
  totalTime: number; // Set server-side in ms
  timestamp?: StandaloneInteractionAttempt['timestamp']; // Set server-side
  contexts: {
    // UnitContext
    [context: number]: {
      // InteractionIndex: best score
      [index: number]: InteractionScore;
    };
  };
};

export type DraftUserUnitScore = Omit<
  UserUnitScore,
  'thematicUnit' | 'thematicLength'
>;

export type BuildDraftUserUnitScoreParams = {
  uid: AppUser['uid'];
  unitId: Unit['id'];
  chapterId: Chapter['id'];
  domainId: Domain['id'];
  projectId: Project['id'];
};
export const buildDraftUserUnitScore = ({
  uid,
  unitId,
  chapterId,
  domainId,
  projectId,
}: BuildDraftUserUnitScoreParams): DraftUserUnitScore => ({
  uid,
  unitId,
  chapterId,
  domainId,
  projectId,
  score: UnitScore.null,
  contexts: {},
  timeTaken: 0,
  totalTime: 0,
});

export const buildUserUnitScoreFromDraft = (
  emptyScore: DraftUserUnitScore,
  unit: Unit
): UserUnitScore => {
  if (emptyScore.unitId !== unit.id) {
    throw new Error('Unit id mismatch');
  }

  if (unit.subtype === 'thematic') {
    return {
      ...emptyScore,
      thematicUnit: true,
      thematicLength: unit.introduction.length,
    };
  }

  return {
    ...emptyScore,
    thematicUnit: false,
  };
};

export type BuildEmptyUserUnitScoreParams = Omit<
  BuildDraftUserUnitScoreParams,
  'unitId'
> & {
  unit: Unit;
};
export const buildEmptyUserUnitScore = ({
  uid,
  chapterId,
  domainId,
  projectId,
  unit,
}: BuildEmptyUserUnitScoreParams): UserUnitScore =>
  buildUserUnitScoreFromDraft(
    buildDraftUserUnitScore({
      uid,
      unitId: unit.id,
      chapterId,
      domainId,
      projectId,
    }),
    unit
  );

export const getUserUnitScorePath = ({
  projectId,
  domainId,
  chapterId,
  unitId,
}: UserUnitScore) => `${projectId}-${domainId}-${chapterId}-${unitId}`;

export const getUserUnitScorePathFromAttempt = ({
  projectId,
  domainId,
  chapterId,
  unitId,
}: InteractionAttempt) => `${projectId}-${domainId}-${chapterId}-${unitId}`;

export const buildUserUnitScorePath = ({
  projectId,
  domainId,
  chapterId,
  unitId,
}: {
  projectId: string;
  domainId: string;
  chapterId: string;
  unitId: string;
}) => `${projectId}-${domainId}-${chapterId}-${unitId}`;
