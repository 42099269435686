import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EMPTY, timer } from 'rxjs';
import { debounce, first, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.authState().pipe(
      debounce((user) => (user ? EMPTY : timer(1000))),
      map((user) => {
        if (user) {
          return true;
        }

        if (state.url && state.url !== '/') {
          this.router.navigate(['login'], {
            queryParams: { returnUrl: state.url },
          });
        } else {
          this.router.navigate(['login']);
        }

        return false;
      }),
      first()
    );
  }
}
