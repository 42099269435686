import { Environment } from './env.type';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAu-6pjKGdZVWHBjl9EiU2t3DHd_RU84ZI',
    authDomain: 'ffleren-dev.firebaseapp.com',
    projectId: 'ffleren-dev',
    storageBucket: 'ffleren-dev.appspot.com',
    messagingSenderId: '141107195818',
    appId: '1:141107195818:web:897fb391a943837ef885d3',
    measurementId: 'G-4WJ4TVKG1Q',
  },
  algoliaAppId: 'QJUW5K9GB1',
};
