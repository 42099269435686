import { PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  Identifiable,
  LocalStorageService,
  StorageKeys,
} from '../services/local-storage.service';

export abstract class CachedPipe<T extends Identifiable, ReturnT>
  implements PipeTransform
{
  key: StorageKeys;

  transform(entityId: T['id']): Observable<ReturnT> {
    if (!entityId) {
      return of(null);
    }

    const localEntity = LocalStorageService.get<T>(entityId, this.key);
    if (localEntity) {
      return of(this.mapEntity(localEntity));
    }

    return this.getEntityFromDatabase(entityId).pipe(
      tap((entity) => LocalStorageService.cache<T>(entity, this.key)),
      map(this.mapEntity)
    );
  }

  getEntityFromDatabase(entityId: T['id']): Observable<T> {
    throw new Error('Method not implemented.');
  }

  mapEntity(entity: T): ReturnT {
    return entity as unknown as ReturnT;
  }
}
