import { uniqBy } from 'lodash';
import { AudioState } from 'src/app/services/audio.service';
import { EntityType } from '../../../shared/enums/entity-type';
import { Interaction } from '../../../shared/models/interaction';
import { StructuralEntity } from '../../../shared/models/structural-entity';
import { Unit } from '../../../shared/models/unit';

// Example {"7658":{"vab":[0,4]}}
export type AllSessionInteractionUsedVarIndexes = Record<
  Interaction['id'],
  SessionInteractionUsedVarIndexes
>;
// Example {"vab":[0,4]}
export type SessionInteractionUsedVarIndexes = Record<
  string, // Var key
  number[] // Used indexes
>;

export interface Identifiable {
  id: string;
}

const local = window.localStorage;
const session = window.sessionStorage;

const findUnitOrEntity = (
  array: Array<StructuralEntity | Unit>,
  entityId: string,
  entityType: EntityType
): StructuralEntity | Unit =>
  array.find((entity) => entity.id === entityId && entity.type === entityType);

export enum StorageKeys {
  dev = 'dev',
  units = 'units',
  entities = 'entities',
  thematicUnitVars = 'thematicUnitVars',
  audio = 'audio',
  interactionVars = 'interactionVars',
  organizations = 'organizations',
  userNames = 'userNames',
}

export class LocalStorageService {
  static isDevMode() {
    return local.getItem(StorageKeys.dev) === 'true';
  }

  static hasDevModeKey() {
    return local.getItem(StorageKeys.dev) !== null;
  }

  static setDevMode(state: boolean) {
    state = !!state;
    local.setItem(StorageKeys.dev, state.toString());
    return state;
  }

  static cacheUnit(unit: Unit) {
    try {
      const units = LocalStorageService.getUnits();
      if (!units.some((u) => u.id === unit.id)) {
        session.setItem(StorageKeys.units, JSON.stringify([...units, unit]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  static cacheUnits(units: Unit[]) {
    try {
      if (units.some((unit) => unit.type !== EntityType.unit)) {
        console.error('Attempt to cache non-units as units');
        return;
      }

      const localUnits = LocalStorageService.getUnits();
      units = uniqBy(
        units.filter((unit) => !localUnits.find((u) => u.id === unit.id)),
        (unit) => unit.id
      );
      if (units.length) {
        session.setItem(
          StorageKeys.units,
          JSON.stringify(localUnits.concat(units))
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  static removeUnitFromCache(unitId: Unit['id']) {
    const units = LocalStorageService.getUnits();
    LocalStorageService.clearUnitCache();
    LocalStorageService.cacheUnits(units.filter((unit) => unit.id !== unitId));
  }

  static getUnit(unitId: Unit['id']) {
    try {
      const units: Unit[] = LocalStorageService.getUnits();
      return units.find((unit) => unit.id === unitId) || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static cacheEntity(entity: StructuralEntity) {
    return LocalStorageService.cacheEntities([entity]);
  }

  static removeEntityFromCache(
    entityId: StructuralEntity['id'],
    entityType: EntityType
  ) {
    const entities = LocalStorageService.getEntities();
    LocalStorageService.clearEntityCache();
    LocalStorageService.cacheEntities(
      entities.filter(
        (entity) => entity.id !== entityId || entity.type !== entityType
      )
    );
  }

  static cacheEntities(entities: StructuralEntity[]) {
    try {
      // Restrict types
      if (
        entities.some(
          (entity) =>
            entity.type === EntityType.unit ||
            entity.type === EntityType.interaction
        )
      ) {
        console.warn('Attempt to cache non valid entity');
      }

      entities = entities.filter(
        (entity) =>
          entity.type === EntityType.project ||
          entity.type === EntityType.domain ||
          entity.type === EntityType.chapter
      );

      const localEntities = LocalStorageService.getEntities();
      entities = uniqBy(
        entities.filter(
          (entity) => !findUnitOrEntity(localEntities, entity.id, entity.type)
        ),
        (entity) => entity.id + ',' + entity.type
      );
      if (entities.length) {
        session.setItem(
          StorageKeys.entities,
          JSON.stringify(localEntities.concat(entities))
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  static getEntity(
    entityId: StructuralEntity['id'],
    entityType: EntityType
  ): StructuralEntity {
    try {
      return (
        (findUnitOrEntity(
          LocalStorageService.getEntities(),
          entityId,
          entityType
        ) as StructuralEntity) || null
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static getChildrenForEntity(
    entityId: StructuralEntity['id'],
    entityType: EntityType.project | EntityType.domain | EntityType.chapter
  ): Array<StructuralEntity | Unit> {
    try {
      const childType = LocalStorageService.findChildType(entityType);
      const searchPool: Array<StructuralEntity | Unit> =
        childType === EntityType.unit
          ? LocalStorageService.getUnits()
          : LocalStorageService.getEntities();
      const parent = findUnitOrEntity(searchPool, entityId, entityType);

      const children = parent?.children?.map((childId) =>
        findUnitOrEntity(searchPool, childId, childType)
      );

      if (!children || children.some((child) => !child)) {
        return null;
      }

      return children;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static clearCache() {
    LocalStorageService.clearUnitCache();
    LocalStorageService.clearEntityCache();
  }

  // TODO tests
  static getAllThematicUnitVars(): Record<
    Unit['id'],
    Interaction['varsShuffledIndexes']
  > {
    try {
      return JSON.parse(local.getItem(StorageKeys.thematicUnitVars)) || {};
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  // TODO tests
  static getThematicUnitVars(
    unitId: Unit['id']
  ): Interaction['varsShuffledIndexes'] | undefined {
    try {
      const thematicUnitVars = LocalStorageService.getAllThematicUnitVars();
      return thematicUnitVars[unitId];
    } catch (error) {
      console.error(error);
    }
  }

  // TODO tests
  static setThematicUnitVars(
    unitId: Unit['id'],
    varPicks: Interaction['varsShuffledIndexes']
  ) {
    try {
      const thematicUnitVars = LocalStorageService.getAllThematicUnitVars();
      thematicUnitVars[unitId] = varPicks;
      local.setItem(
        StorageKeys.thematicUnitVars,
        JSON.stringify(thematicUnitVars)
      );
    } catch (error) {
      console.error(error);
    }
  }

  // TODO tests
  static removeThematicUnitVars(unitId: Unit['id']) {
    try {
      const thematicUnitVars = LocalStorageService.getAllThematicUnitVars();
      delete thematicUnitVars[unitId];
      local.setItem(
        StorageKeys.thematicUnitVars,
        JSON.stringify(thematicUnitVars)
      );
    } catch (error) {
      console.error(error);
    }
  }

  static getUsedInteractionVarIndexes(
    interactionId: Interaction['id']
  ): SessionInteractionUsedVarIndexes | undefined {
    try {
      const interactionVars =
        LocalStorageService.getAllInteractionUsedVarIndexes();
      return interactionVars[interactionId];
    } catch (error) {
      console.error(error);
    }
  }

  static removeUsedInteractionVarIndexes(interactionId: Interaction['id']) {
    try {
      const interactionVars =
        LocalStorageService.getAllInteractionUsedVarIndexes();
      delete interactionVars[interactionId];

      session.setItem(
        StorageKeys.interactionVars,
        JSON.stringify(interactionVars)
      );
    } catch (error) {
      console.error(error);
    }
  }

  static addInteractionVar(
    interactionId: Interaction['id'],
    varPick: Interaction['varsShuffledIndexes']
  ) {
    try {
      const allInteractionVars =
        LocalStorageService.getAllInteractionUsedVarIndexes();
      allInteractionVars[interactionId] =
        LocalStorageService.getInteractionUsedVarIndexesOrDefault(
          interactionId,
          allInteractionVars
        );

      const interactionVarsForId = allInteractionVars[interactionId];

      for (const [key, value] of Object.entries(varPick)) {
        // Convert existing array to Set to remove duplicates, add new value
        const uniqueSet = new Set(interactionVarsForId[key] || []);
        uniqueSet.add(value);

        // Convert back to array and sort
        interactionVarsForId[key] = Array.from(uniqueSet).sort();
      }

      session.setItem(
        StorageKeys.interactionVars,
        JSON.stringify(allInteractionVars)
      );
    } catch (error) {
      console.error(error);
    }
  }

  static getAudioState(): AudioState | null {
    const audioStateJSON = local.getItem(StorageKeys.audio);
    return audioStateJSON ? (JSON.parse(audioStateJSON) as AudioState) : null;
  }

  static setAudioState(audioState: AudioState) {
    local.setItem(StorageKeys.audio, JSON.stringify(audioState));
  }

  public static getAll<T>(key: StorageKeys): T[] {
    return JSON.parse(session.getItem(key)) || [];
  }

  static get<T extends Identifiable>(id: T['id'], key: StorageKeys): T | null {
    const items = LocalStorageService.getAll<T>(key);
    return items.find((item) => item.id === id) || null;
  }

  static cacheAll<T extends Identifiable>(items: T[], key: StorageKeys) {
    if (items.length) {
      const localItems = LocalStorageService.getAll<T>(key);

      session.setItem(
        key,
        JSON.stringify(uniqBy([...items, ...localItems], 'id'))
      );
    }
  }

  static cache<T extends Identifiable>(item: T, key: StorageKeys) {
    return LocalStorageService.cacheAll([item], key);
  }

  static clear(key: StorageKeys) {
    session.removeItem(key);
  }

  private static getAllInteractionUsedVarIndexes(): AllSessionInteractionUsedVarIndexes {
    return JSON.parse(session.getItem(StorageKeys.interactionVars)) || {};
  }

  private static getInteractionUsedVarIndexesOrDefault(
    interactionId: Interaction['id'],
    sessionInteractionVars: AllSessionInteractionUsedVarIndexes
  ) {
    return sessionInteractionVars[interactionId] || {};
  }

  private static getUnits(): Unit[] {
    return JSON.parse(session.getItem(StorageKeys.units)) || [];
  }

  private static getEntities(): StructuralEntity[] {
    return JSON.parse(session.getItem(StorageKeys.entities)) || [];
  }

  private static clearUnitCache() {
    session.removeItem(StorageKeys.units);
  }

  private static clearEntityCache() {
    session.removeItem(StorageKeys.entities);
  }

  private static findChildType(entityType: EntityType) {
    switch (entityType) {
      case EntityType.project:
        return EntityType.domain;
      case EntityType.domain:
        return EntityType.chapter;
      case EntityType.chapter:
        return EntityType.unit;
      case EntityType.unit:
        return EntityType.interaction;
      case EntityType.interaction:
        return null;
      default:
        throw new Error('Type not supported for operation: ' + entityType);
    }
  }
}
