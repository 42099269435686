import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatabaseService } from '../services/database.service';
import { StorageKeys } from '../services/local-storage.service';
import { CachedPipe } from './cached-pipe.pipe';

type CachedUserName = {
  id: string;
  name: string;
};

@Pipe({
  name: 'username',
})
export class UsernamePipe
  extends CachedPipe<CachedUserName, string>
  implements PipeTransform
{
  key = StorageKeys.userNames;

  constructor(private databaseService: DatabaseService) {
    super();
  }

  getEntityFromDatabase(uid: string): Observable<CachedUserName> {
    return this.databaseService
      .getUser(uid)
      .pipe(map((user) => ({ id: uid, name: user?.name })));
  }

  mapEntity(entity: CachedUserName): string {
    return entity.name;
  }
}
