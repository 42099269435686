import { cloneDeep } from 'lodash';
import { UserChapterScore } from '../../models/user-chapter-score';
import { UserDomainScore } from '../../models/user-domain-score';
import { createIdChecker, createIdFilter } from '../array';
import { mathHelper } from '../math-helper';

export const mergeUserChapterScoreToUserDomainScore = (
  userChapterScore: UserChapterScore,
  userDomainScore: UserDomainScore
): UserDomainScore => {
  const filterFrom = createIdFilter(userChapterScore.chapterId);
  const containedIn = createIdChecker(userChapterScore.chapterId);

  const chapterNotStarted =
    userChapterScore.unitsComplete.length === 0 &&
    userChapterScore.unitsInProgress.length === 0;

  const resultObj = cloneDeep(userDomainScore);
  if (chapterNotStarted) {
    resultObj.chaptersInProgress = filterFrom(resultObj.chaptersInProgress);
    resultObj.chaptersComplete = filterFrom(resultObj.chaptersComplete);
  } else {
    const chapterComplete =
      userChapterScore.childCount === userChapterScore.unitsComplete.length;

    if (chapterComplete) {
      resultObj.chaptersInProgress = filterFrom(resultObj.chaptersInProgress);

      if (!containedIn(userDomainScore.chaptersComplete)) {
        resultObj.chaptersComplete.push(userChapterScore.chapterId);
      }
    } else {
      resultObj.chaptersComplete = filterFrom(resultObj.chaptersComplete);

      if (!containedIn(userDomainScore.chaptersInProgress)) {
        resultObj.chaptersInProgress.push(userChapterScore.chapterId);
      }
    }
  }

  resultObj.percentageComplete = mathHelper.roundAndFixPercentage(
    (resultObj.chaptersComplete.length / userDomainScore.childCount) * 100
  );

  resultObj.percentageInProgress = mathHelper.roundAndFixPercentage(
    (resultObj.chaptersInProgress.length / userDomainScore.childCount) * 100
  );

  resultObj.timeTaken = userChapterScore.timeTaken;
  resultObj.totalTime = userDomainScore.totalTime + userChapterScore.timeTaken;
  resultObj.timestamp = userChapterScore.timestamp;

  return resultObj;
};
