import { equationParser } from 'src/app/helpers/equation-parser';
import { Interaction } from 'src/app/models/interaction';
import { inputParser } from './input-parser';
import { regularExpressions } from './regex';
import { selectParser } from './select-parser';

type TemplateParser = (
  interaction: Interaction,
  stringValue: string,
  key: string
) => string;

export const parseTemplate = (interaction: Interaction): Interaction => {
  let parsedInteraction = parseTemplateRecursive(
    interaction,
    parseTemplateStringPhaseOne
  ) as Interaction;

  parsedInteraction = parseTemplateRecursive(
    parsedInteraction,
    phaseTwoParser
  ) as Interaction;

  return parsedInteraction;
};

const parseTemplateRecursive = (
  interaction: Interaction,
  templateParser: TemplateParser,
  // eslint-disable-next-line @typescript-eslint/ban-types
  interactionPart: object = null
) => {
  const resultObj = {};
  const currentObj = interactionPart || interaction;
  const isArray = Array.isArray(currentObj);

  for (const key in currentObj) {
    if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
      const element = currentObj[key];
      if (typeof element === 'string') {
        resultObj[key] = templateParser(interaction, element, key);
      } else if (typeof element === 'object' && element !== null) {
        resultObj[key] = parseTemplateRecursive(
          interaction,
          templateParser,
          element
        );
      } else {
        resultObj[key] = element;
      }
    }
  }

  if (isArray) {
    return Object.values(resultObj);
  }

  return resultObj;
};

const parseTemplateStringPhaseOne = (
  interaction: Interaction,
  stringValue: string,
  key: string
) => {
  let parsedValue = stringValue;

  // Replace variables.
  const varMatch = parsedValue.match(regularExpressions.interactionVariables);
  if (varMatch) {
    const varName = varMatch[1];
    const shuffled = !!varMatch[2];
    const index1 = +varMatch[3];
    const index2 = varMatch[4] && +varMatch[4];
    const vars = shuffled ? interaction.varsShuffled : interaction.vars;

    const replaceValue =
      '' +
      (typeof index2 !== 'number'
        ? vars[varName][index1]
        : vars[varName][index1][index2]);

    parsedValue = parsedValue.replace(
      regularExpressions.interactionVariables,
      replaceValue
    );

    parsedValue = parseTemplateStringPhaseOne(interaction, parsedValue, key);
  }

  // Parse equations.
  parsedValue = equationParser(parsedValue);

  // Parse |dc.
  parsedValue = parsedValue.replace(
    regularExpressions.decimalComma,
    // deepcode ignore GlobalReplacementRegex: intentional
    (match, capture) => capture.replace('.', ',')
  );

  return parsedValue;
};

const phaseTwoParser = (
  interaction: Interaction,
  stringValue: string,
  key: string
) => {
  let parsedValue = stringValue;

  // Dynamic inputs.
  parsedValue = inputParser(parsedValue);
  parsedValue = selectParser(parsedValue, interaction);

  // Remove all leftover braces.
  parsedValue = parsedValue.replace(regularExpressions.bracedParts, '$1');

  return parsedValue;
};
