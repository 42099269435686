import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Organization } from '../../models/organization';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  organizations$: Observable<Organization[]>;
  displayedColumns = [
    'name',
    'address',
    'city',
    'zipCode',
    'phoneNumber',
    'email',
    'brin',
  ];

  constructor(private databaseService: DatabaseService) {}

  ngOnInit() {
    this.organizations$ = this.databaseService
      .getOrganizations()
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
