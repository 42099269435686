import { EntityType } from '../../../../shared/enums/entity-type';
import { InteractionActionType } from '../../../../shared/enums/interaction-action-type';
import { InteractionModel } from '../../../../shared/enums/interaction-model';
import { getDatePartFromISOString } from '../../../../shared/helpers/time-helper';

const extractAttribFromImageTag = (imageTag: string, attrib: string) => {
  const searchPart = `${attrib}="`;
  imageTag = imageTag.substring(
    imageTag.indexOf(searchPart) + searchPart.length
  );
  return imageTag.substring(0, imageTag.indexOf('"'));
};

const translateOptions = (obj: Record<string, any>) => {
  const optionTypes = {
    optionsText: 'optionsText',
    optionsPresentation: 'optionsPresentation',
    optionsImage: 'optionsImage',
    optionsHotspot: 'optionsHotspot',
    optionsSortOrder: 'optionsSortOrder',
  };

  const renameOptionsTo = (inputObj: Record<string, any>, newName: string) => {
    inputObj[newName] = inputObj.options;
    delete inputObj.options;
  };

  switch (obj.model) {
    case InteractionModel.openText:
    case InteractionModel.openTextMultiple:
    case InteractionModel.openTextInImage:
    case InteractionModel.multipleChoice:
    case InteractionModel.openTextDragDrop:
      renameOptionsTo(obj, optionTypes.optionsText);
      break;
    case InteractionModel.presentation:
      renameOptionsTo(obj, optionTypes.optionsPresentation);
      break;
    case InteractionModel.sortToHotspots:
      renameOptionsTo(obj, optionTypes.optionsHotspot);
      break;
    case InteractionModel.sortObjectsOrder:
      renameOptionsTo(obj, optionTypes.optionsSortOrder);
      break;
    case InteractionModel.pointAndClick:
      // case InteractionModel.pointAndClickOrder:
      renameOptionsTo(obj, optionTypes.optionsImage);
      break;
    default:
      break;
  }
};

export const processInteractionExportText = (text: string) => {
  const replaceList = [
    [/\u0022/g, '\\"'], // Replace " > \"
    [/\\\\"/g, '\\"'], // Replace \\" > \"
    [/'/g, '"'], // Replace ' > "
    [/ dc=1/g, ' dc=true'], // Replace dc=1 > dc=true"
    [/€/g, '&euro;'], // Replace euro
    [/\u2009/g, '&thinsp;'], // Replace &thinsp;
    [/≈/g, '&asymp;'], // Replace ca.
    [/\u2248/g, '&asymp;'], // Replace ca.
  ];

  replaceList.forEach((item) => {
    text = text.replace(item[0], item[1] as string);
  });

  // Get the first line as name
  const name = text.substring(0, text.indexOf('\n'));

  // Remove header
  text = text.substring(text.indexOf('{'));

  // Remove end
  text = text.substring(0, text.lastIndexOf('}') + 1);

  // Regex to remove entire line containing jsLoadAction, including comma on prev line
  text = text.replace(/, \s.*jsLoadAction.*/g, '');

  // Translate events
  text = text.replace(/feedbackFalse1Open/g, 'feedbackFalse1NoOption');
  text = text.replace(/feedbackFalse2Open/g, 'feedbackFalse2NoOption');
  text = text.replace(/feedbackFalse3Open/g, 'feedbackFalse3NoOption');

  // Unescape all quotes below "vars"
  const varText = text.substring(text.indexOf('"vars" :'));
  const varTextUnescaped = varText.replace(/\\"/g, '"');
  text = text.replace(varText, varTextUnescaped);

  // Parse as JSON
  let obj = JSON.parse(text);

  // Remove data tag
  obj = { ...obj, ...obj.data };
  delete obj.data;

  // Find SetImage actions and get the src
  const setImageActions = obj.actions.filter(
    (action) => action.type === InteractionActionType.setImage
  );
  setImageActions.forEach((action) => {
    action.args[0] = extractAttribFromImageTag(action.args[0], 'src');
  });

  // Replace images in options
  const options = obj.options;
  options?.forEach((option) => {
    if (option.image) {
      option.image = extractAttribFromImageTag(option.image, 'src');
    }
  });

  const keysToRemove = ['filename', 'jsLoadModel'];
  keysToRemove.forEach((key) => {
    delete obj[key];
  });

  const keysToAdd = [
    ['name', name],
    ['type', EntityType.interaction],
    ['description', ''],
    ['thumbnail', ''],
    ['versionDate', getDatePartFromISOString(new Date().toISOString())],
  ];
  keysToAdd.forEach((key) => {
    obj[key[0]] = key[1];
  });

  const keysToTranslate = [
    ['dragelementsPosition', 'dragElementsPosition'],
    ['interactie_id', 'id'],
    ['invulzin', 'gap'],
  ];
  keysToTranslate.forEach((key) => {
    if (key[0] in obj) {
      obj[key[1]] = obj[key[0]];
      delete obj[key[0]];
    }
  });

  // Cast to numbers
  const castToNumber = ['maxTimesAnswered', 'maxSeconds'];
  castToNumber.forEach((key) => {
    if (key in obj) {
      obj[key] = +obj[key];
    }
  });

  // CamelCase model
  obj.model = obj.model.substring(0, 1).toLowerCase() + obj.model.substring(1);

  // Change image tag to url plus size
  const imgTag = obj.image;
  obj.image = extractAttribFromImageTag(imgTag, 'src');
  obj.imageWidth = extractAttribFromImageTag(imgTag, 'width');
  obj.imageHeight = extractAttribFromImageTag(imgTag, 'height');

  // Translate options to typed options
  translateOptions(obj);

  // Add maxTimesAnswered to Sort to Hotspot
  if (obj.model === InteractionModel.sortToHotspots) {
    obj.maxTimesAnswered = 3;
  }

  // Find Hint action and replace it with feedbackFalseAfterFirstTry
  const hint = obj.actions?.find((action) => action.type === 'Hint');
  if (hint) {
    obj.actions = obj.actions.filter((action) => action.type !== 'Hint');
    obj.feedbackFalseAfterFirstTry = hint.args[0];
  }

  // Draggable
  if (obj.draggable) {
    const draggableTag = obj.draggable;
    obj.draggable = extractAttribFromImageTag(draggableTag, 'src');
    obj.draggableWidth = extractAttribFromImageTag(draggableTag, 'width');
    obj.draggableHeight = extractAttribFromImageTag(draggableTag, 'height');
  }

  return obj;
};
