import { createAction, props } from '@ngrx/store';
import { Exam } from 'shared/models/exam';
import { ExamInstance } from 'shared/models/exam-instance';
import {
  ExamSession,
  ExamSessionInteractionAttempt,
} from 'shared/models/exam-session';
import { ExamSessionResult } from '../../../../shared/models/exam-result';

export const examActions = {
  loadExam: createAction('[Exam] Load exam', (examId: string) => ({ examId })),
  examLoaded: createAction('[Exam] Loaded exam', props<{ exam: Exam }>()),

  loadExamInstance: createAction(
    '[Exam] Load exam instance',
    (examInstanceId: string) => ({ examInstanceId })
  ),
  examInstanceLoaded: createAction(
    '[Exam] Loaded exam instance',
    props<{ examInstance: ExamInstance }>()
  ),

  loadExamSession: createAction(
    '[Exam] Load exam session',
    (examSessionId: string) => ({ examSessionId })
  ),
  examSessionLoaded: createAction(
    '[Exam] Loaded exam session',
    props<{ examSession: ExamSession }>()
  ),
  loadExamSessionFailed: createAction(
    '[Exam] Load exam session failed',
    props<{ message: string }>()
  ),

  startExamSession: createAction('[Exam] Start exam session'),

  startExamSessionFailed: createAction(
    '[Exam] Start exam session failed',
    props<{ message: string }>()
  ),

  examSessionStarted: createAction(
    '[Exam] Exam session started',
    props<{ examSession: ExamSession }>()
  ),

  submitExamSession: createAction('[Exam] Submit exam session'),

  submitExamSessionFailed: createAction(
    '[Exam] Submit exam session failed',
    props<{ message: string }>()
  ),

  examSessionCompleted: createAction(
    '[Exam] Exam session completed',
    props<{ examSessionResult: ExamSessionResult }>()
  ),

  examSessionComponentDestroyed: createAction(
    '[Exam] Exam session component destroyed'
  ),

  handleExamInteractionAnswer: createAction(
    '[Exam] Handle exam interaction answer',
    props<{
      sectionIndex: number;
      interactionIndex: number;
      attempt: ExamSessionInteractionAttempt;
    }>()
  ),

  storeShuffledVarsIndexes: createAction(
    '[Exam] Store shuffled vars indexes',
    props<{
      sessionId: string;
      sectionIndex: number;
      interactionIndex: number;
      interactionId: string;
      varsShuffledIndexes: Record<string, number>;
    }>()
  ),

  shuffleVarsStored: createAction('[Exam] Shuffle vars stored'),

  shuffleVarsStoredFailed: createAction(
    '[Exam] Shuffle vars stored failed',
    props<{ message: string }>()
  ),

  setReviewMode: createAction(
    '[Exam] Set review mode',
    props<{ readonly: boolean }>()
  ),
};
