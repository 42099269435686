import { InteractionVars } from 'shared/models/interaction-vars';
import { SessionInteractionUsedVarIndexes } from 'src/app/services/local-storage.service';
import { mathHelper } from '../../../shared/helpers/math-helper';
import { Interaction } from '../../../shared/models/interaction';

export const pickRandomVarIndexes = (
  varObj: InteractionVars,
  usedVarIndexes?: SessionInteractionUsedVarIndexes | undefined
): Interaction['varsShuffledIndexes'] | null => {
  const result: Interaction['varsShuffledIndexes'] = {};

  for (const [key, allVars] of Object.entries(varObj)) {
    // Get already used indexes for this key, if any
    const usedIndexes = usedVarIndexes?.[key] ?? [];

    // Get the available indexes for this key
    const availableIndexes = allVars
      .map((_: any, index: number) => index)
      .filter((index: number) => !usedIndexes.includes(index));

    // Handle the case when all indexes are used
    if (availableIndexes.length === 0) {
      // If any of the keys has no available indexes, return null
      return null;
    }

    // Choose a random index from the available indexes
    result[key] =
      availableIndexes[mathHelper.getRandomInt(0, availableIndexes.length - 1)];
  }

  return result;
};

export const pickShuffledVars = (
  varObj: InteractionVars,
  varPicks: Interaction['varsShuffledIndexes']
): InteractionVars => {
  const result: InteractionVars = {};

  for (const [key, value] of Object.entries(varObj)) {
    result[key] = [value[varPicks[key]]];
  }

  return result;
};
