<form>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ label }}</mat-label>
        <input [required]="markRequired" type="text" matInput [matAutocomplete]="auto" [formControl]="inputControl" />

        <button *ngIf="inputControl.value" mat-button matSuffix mat-icon-button aria-label="Clear"
            (click)="clearInput()">
            <mat-icon>clear</mat-icon>
        </button>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunction">
            <div *ngFor="let index of state.indices || []">
                <mat-option *ngFor="let option of index.hits" [value]="option">
                    {{ displayFunction(option) }}
                </mat-option>
            </div>
        </mat-autocomplete>
    </mat-form-field>
</form>