import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { StructuralEntity } from 'src/app/models/structural-entity';
import { structureActions } from 'src/app/store/actions/structure.actions';

export type LastUsedLocation = {
  projectId: string;
  chapterId: string;
  domainId: string;
};

export type NavStructureState = {
  activeNavStructure: StructuralEntity[] | undefined;
  lastUsedLocation: LastUsedLocation | null;
};

export const initialState: NavStructureState = {
  activeNavStructure: [],
  lastUsedLocation: null,
};

const reducer = createReducer(
  initialState,
  on(
    structureActions.reset,
    (state): NavStructureState => ({
      ...initialState,
    })
  ),
  on(
    structureActions.renewActiveStructure,
    (state, { activeStructure }): NavStructureState => ({
      ...state,
      activeNavStructure: activeStructure,
    })
  ),
  on(
    structureActions.lastUsedLocationLoaded,
    (state, { projectId, chapterId, domainId }): NavStructureState => ({
      ...state,
      lastUsedLocation: {
        projectId,
        chapterId,
        domainId,
      },
    })
  )
);

export const structureReducer = (state: NavStructureState, action: Action) =>
  reducer(state, action);

// Selectors
export const selectStructureFeatureState =
  createFeatureSelector<NavStructureState>('structure');

export const selectStructureState = createSelector(
  selectStructureFeatureState,
  (state) => state.activeNavStructure
);

export const selectLastUsedLocation = createSelector(
  selectStructureFeatureState,
  (state) => state.lastUsedLocation
);
