import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { ExamSession } from 'shared/models/exam-session';
import { studentExamsActions } from '../actions/student-exams.actions';

export const studentExamsFeatureKey = 'studentExams';

export type StudentExamsState = Readonly<{
  examSessions: ExamSession[];
}>;

export const initialState: StudentExamsState = {
  examSessions: [],
};

export const reducer = createReducer(
  initialState,
  on(
    studentExamsActions.examSessionsForUserLoaded,
    (state, { examSessions }) => ({
      ...state,
      examSessions,
    })
  )
);

export const studentExamsReducer = (
  state: StudentExamsState | undefined,
  action: Action
) => reducer(state, action);

export const selectStudentExamsState = createFeatureSelector<StudentExamsState>(
  studentExamsFeatureKey
);

export const selectUserExamSessions = createSelector(
  selectStudentExamsState,
  (state) => state.examSessions
);
