import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityType } from 'src/app/enums/entity-type';
import { ChapterScore, UnitScore } from '../../../../shared/models/score.enum';
import {
  TeacherDashboardScore,
  isTeacherDashboardUnitTupleScore,
} from '../../../../shared/models/teacher-dashboard-models';
import { readableTime } from '../../helpers/time-helper';
import { StructureService } from '../../services/structure.service';

type ChapterScoreClass =
  | 'complete'
  | 'near-complete'
  | 'incorrect'
  | 'in-progress'
  | 'not-started';

type UnitScoreClass =
  | 'correct'
  | 'correctWithHelp'
  | 'incorrect'
  | 'inconclusive'
  | 'not-started';

type DashboardScoreClassName = ChapterScoreClass | UnitScoreClass;

@Component({
  selector: 'app-teacher-dashboard-score-indicator',
  templateUrl: './teacher-dashboard-score-indicator.component.html',
  styleUrls: ['./teacher-dashboard-score-indicator.component.scss'],
})
export class TeacherDashboardScoreIndicatorComponent implements OnInit {
  @Input() userScores: TeacherDashboardScore;
  title$: Observable<string>;
  className: DashboardScoreClassName;
  secondaryClassName: DashboardScoreClassName;
  icon: string;

  // TODO change to presentation component
  constructor(private structureService: StructureService) {}

  ngOnInit() {
    if (!this.userScores) {
      return; // FIXME: Unit test reasons
    }

    const primaryScore = this.userScores[0];
    const secondaryScore = this.userScores[1];
    const time = primaryScore.timeOverSelection;
    this.title$ = this.getTitleFromUserScore(this.userScores, time);
    this.className = this.getClassNameFromUserScore(this.userScores);
    this.icon = this.getIconFromClassName(this.className);
    if (secondaryScore && time > 0) {
      this.secondaryClassName = this.getClassNameFromUserScore(
        this.userScores,
        true
      );
    }
  }

  getClassNameFromUserScore(
    userScore: TeacherDashboardScore,
    secondary = false
  ): DashboardScoreClassName {
    const part = (secondary && userScore[1]) || userScore[0];

    if (isTeacherDashboardUnitTupleScore(userScore)) {
      switch (part.score) {
        case UnitScore.correct:
          return 'correct';
        case UnitScore.correctWithHelp:
          return 'correctWithHelp';
        case UnitScore.inconclusive:
          return 'inconclusive';
        case UnitScore.incorrect:
          return 'incorrect';
        default:
          return 'not-started';
      }
    }

    switch (part.score) {
      case ChapterScore.incorrect:
        return 'incorrect';
      case ChapterScore.complete:
        return 'complete';
      case ChapterScore.nearComplete:
        return 'near-complete';
      case ChapterScore.inProgress:
        return 'in-progress';
      default:
        return 'not-started';
    }
  }

  getIconFromClassName(className: DashboardScoreClassName) {
    switch (className) {
      case 'complete':
      case 'correct':
        return '✔';
      case 'near-complete':
      case 'correctWithHelp':
        return '✓';
      case 'incorrect':
        return '✘';
      default:
        return null;
    }
  }

  getTitleFromUserScore(
    userScore: TeacherDashboardScore,
    time: number
  ): Observable<string> {
    const titleTime = readableTime(time);

    if (isTeacherDashboardUnitTupleScore(userScore)) {
      return this.structureService
        .getUnit(userScore[0].unitId)
        .pipe(
          map((unit) => `${unit.title}\n` + (time ? `Tijd: ${titleTime}` : ''))
        );
    } else {
      const complete = Math.round(userScore[0].percentageComplete);
      const inProgress = Math.round(userScore[0].percentageInProgress);

      return this.structureService
        .getEntity(userScore[0].chapterId, EntityType.chapter)
        .pipe(
          map(
            (chapter) =>
              `${chapter.title}\n` +
              (inProgress ? `Mee bezig: ${inProgress}%\n` : '') +
              (complete ? `Compleet: ${complete}%\n` : '') +
              (time ? `Tijd: ${titleTime}` : '')
          )
        );
    }
  }
}
