import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  distinct,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Exam } from 'shared/models/exam';
import { ExamInstance } from 'shared/models/exam-instance';
import {
  calculateExamSessionGrade,
  calculateExamSessionScorePercentage,
  TotalScores,
} from 'src/app/helpers/exam-score-helper';
import { ExamService } from 'src/app/services/exam.service';
import { ExcelService } from 'src/app/services/excel.service';
import { setIsLoading } from 'src/app/store/actions/shared.actions';
import { AppState } from 'src/app/store/reducers';
import {
  ExamResultsOverview,
  ExamSessionResult,
} from '../../../../../shared/models/exam-result';
import {
  ExcelData,
  ExcelRow,
  ExcelTransformation,
} from '../../../../../shared/models/excel-models';
import { AppRouteParams } from '../../../enums/route-params.enum';
import { UsernamePipe } from '../../../misc/username.pipe';
import {
  dialogWidth,
  ExamFinishedComponent,
} from '../exam-finished/exam-finished.component';

type ColumnKey = keyof ExamSessionResult | `${number}` | 'cesuur';

interface ColumnDef {
  key: ColumnKey;
  type: 'user' | 'text' | 'score';
  text: string;
  title?: string;
  align: 'left' | 'center' | 'right';
}

@Component({
  selector: 'app-exam-results-teacher',
  templateUrl: './exam-results-teacher.component.html',
  styleUrls: ['./exam-results-teacher.component.scss'],
})
export class ExamResultsTeacherComponent implements OnInit, OnDestroy {
  examInstanceId$: Observable<string>;
  examInstance$: Observable<ExamInstance>;
  examName$: Observable<Exam['name']>;
  examResults$: Observable<ExamResultsOverview>;

  displayedColumns$: Observable<ColumnDef[]>;
  displayedColumnKeys$: Observable<string[]>;
  dataSource$: Observable<any[]>;

  isExcelDownloading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isDataReady$: Observable<boolean>;

  private ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private readonly labelColumnStart = 5;
  private readonly gradeColumnLetter = 'B';
  private readonly cesuurCellReference = 'C5';
  private readonly defaultCesuur = 55;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private examService: ExamService,
    private excelService: ExcelService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private usernamePipe: UsernamePipe
  ) {}

  ngOnInit() {
    this.examInstanceId$ = this.route.paramMap.pipe(
      map((params) => params.get(AppRouteParams.examInstanceId)),
      distinct(),
      shareReplay(1)
    );

    this.examResults$ = this.examInstanceId$.pipe(
      switchMap(this.loadExamResults.bind(this)),
      shareReplay(1)
    );

    this.examInstance$ = this.examInstanceId$.pipe(
      switchMap((id) => this.examService.getExamInstanceById(id)),
      shareReplay(1)
    );

    this.examName$ = this.examInstance$.pipe(
      switchMap((examInstance) =>
        this.examService.getExam(examInstance.examId)
      ),
      map((exam) => exam.name),
      shareReplay(1)
    );

    const initialExamResultsOverview = {
      resultRows: Array(1).fill({
        questionResults: Array(33).fill(3),
      }),
    };

    this.displayedColumns$ = combineLatest([
      of(initialExamResultsOverview),
      this.examResults$,
    ]).pipe(
      map(([initial, results]) => results || initial),
      filter((examResultsOverview) => !!examResultsOverview),
      map(this.createColumnDefs),
      takeUntil(this.ngDestroyed$)
    );

    this.displayedColumnKeys$ = this.displayedColumns$.pipe(
      map((columns) => columns.map((col) => col.key))
    );

    this.dataSource$ = this.examResults$.pipe(
      filter((examResultsOverview) => !!examResultsOverview),
      map(
        (examResultsOverview: ExamResultsOverview) =>
          examResultsOverview.results
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.isDataReady$ = this.examResults$.pipe(
      map((results) => !!results),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  close() {
    this.router.navigate(['/exams-teacher']);
  }

  navigateToPath(id: string, interactionIndex: number) {
    const url = `exam-session-review/${id}/0/${interactionIndex}`;
    window.open(this.router.createUrlTree([url]).toString(), '_blank');
  }

  onStudentClick = (examSessionResult: ExamSessionResult) => {
    this.dialog.open(ExamFinishedComponent, {
      data: examSessionResult,
      width: dialogWidth,
    });
  };

  downloadExcel() {
    this.isExcelDownloading$.next(true);

    combineLatest([this.examName$, this.examInstance$, this.examResults$])
      .pipe(
        takeUntil(this.ngDestroyed$),
        switchMap(([examName, examInstance, examResults]) =>
          this.prepareExcelData(examName, examInstance, examResults).pipe(
            map((data) => ({
              data,
              transformations: this.prepareExcelTransformations(examResults),
              filename: this.getExcelFilename(
                examName,
                examInstance.customTitle
              ),
            }))
          )
        ),
        switchMap(({ data, transformations, filename }) =>
          this.excelService.downloadExcel(data, transformations, filename)
        )
      )
      .subscribe(
        () => {
          console.log('Excel download initiated');
          this.isExcelDownloading$.next(false);
        },
        (error) => {
          console.error('Error downloading Excel:', error);
          this.isExcelDownloading$.next(false);
        }
      );
  }

  private getExcelFilename(examName: string, customTitle: string): string {
    const date = new Date().toISOString().split('T')[0];
    return `${date}_${examName}_${customTitle}`;
  }

  private createColumnDefs(
    examResultsOverview: ExamResultsOverview
  ): ColumnDef[] {
    const baseColumns: ColumnDef[] = [
      { key: 'uid', type: 'user', text: 'Student', align: 'left' },
      { key: 'totalScore', type: 'score', text: 'Cijfer', align: 'right' },
      { key: 'timeSpent', type: 'text', text: 'Tijd', align: 'right' },
    ];
    const questionColumns: ColumnDef[] =
      examResultsOverview.interactionTitles?.map((title, index) => ({
        key: `${index}`,
        type: 'score',
        text: `${index + 1}`,
        title,
        align: 'center',
      })) || [];

    return [...baseColumns, ...questionColumns];
  }

  private prepareExcelData(
    examName: string,
    examInstance: ExamInstance,
    examResults: ExamResultsOverview
  ): Observable<ExcelData> {
    const headerRows: ExcelRow[] = [
      {
        cells: [
          { value: `${examName} | ${examInstance.customTitle}`, column: 1 },
        ],
      },
      {
        cells: [
          {
            value: `Gemiddelde score: ${examResults.averageScorePercentage}%`,
            column: 1,
          },
        ],
      },
      {
        cells: [
          { value: `Totaal gemaakt: ${examResults.completedCount}`, column: 1 },
        ],
      },
      {
        cells: [
          {
            value: `Groep: ${examResults.studentGroups.join(', ')}`,
            column: 1,
          },
        ],
      },
      {
        cells: [
          { value: 'Cesuur:', column: 2 },
          { value: this.defaultCesuur.toString(), column: 3 },
          { value: '%', column: 4 },
        ],
      },
    ];

    const columnHeaders: ExcelRow = {
      cells: [
        { value: 'Student', column: 1 },
        { value: 'Cijfer', column: 2 },
        { value: 'Cesuur', column: 3 },
        { value: 'Tijd', column: 4 },
        ...examResults.labelTitles.map((title, index) => ({
          value: title,
          column: index + this.labelColumnStart,
        })),
      ],
    };

    const dataRowsObservables = examResults.results.map(
      (sessionResult: ExamSessionResult, index: number) =>
        this.createDataRow(sessionResult, examResults, index + 7)
    );

    return forkJoin(dataRowsObservables).pipe(
      map((dataRows) => ({
        rows: [...headerRows, columnHeaders, ...dataRows],
      }))
    );
  }

  private getCesuurCell = (rowNumber: number) =>
    of({
      formula:
        `=IF(${this.gradeColumnLetter}${rowNumber}*10<=${this.cesuurCellReference}, ` +
        `ROUND(1.1 + (${this.gradeColumnLetter}${rowNumber}*10/${this.cesuurCellReference}) * 4.4, 1), ` +
        `ROUND(((${this.gradeColumnLetter}${rowNumber}*10 - ${this.cesuurCellReference}) ` +
        `/ (100 - ${this.cesuurCellReference})) * 4.5 + 5.5, 1))`,
    });

  private createDataRow(
    sessionResult: ExamSessionResult,
    examResults: ExamResultsOverview,
    rowNumber: number
  ): Observable<ExcelRow> {
    return forkJoin([
      this.usernamePipe.transform(sessionResult.uid),
      this.getGradeCell(sessionResult, examResults),
      this.getCesuurCell(rowNumber),
      this.getTimeCell(sessionResult),
      ...this.getLabelScoreCells(sessionResult, examResults),
    ]).pipe(
      map((cells) => ({
        cells: cells.map((value, index) => ({ value, column: index + 1 })),
      }))
    );
  }

  private getGradeCell(
    sessionResult: ExamSessionResult,
    examResults: ExamResultsOverview
  ): Observable<string> {
    const scoreObject: TotalScores = {
      totalScore: sessionResult.totalScore,
      maxTotalScore: sessionResult.maxTotalScore,
    };
    return of(
      examResults.hideGrade
        ? `${calculateExamSessionScorePercentage(scoreObject)}%`
        : `${calculateExamSessionGrade(scoreObject)?.toLocaleString('nl-NL', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}`
    );
  }

  private getTimeCell(sessionResult: ExamSessionResult): Observable<string> {
    return of(sessionResult.timeSpent);
  }

  private getLabelScoreCells(
    sessionResult: ExamSessionResult,
    examResults: ExamResultsOverview
  ): Observable<string>[] {
    return examResults.labelTitles.map((labelKey) => {
      const labelScore = sessionResult.labelScores[labelKey];
      return of(labelScore ? `${labelScore.scorePercentage}%` : '');
    });
  }

  private prepareExcelTransformations(
    examResults: ExamResultsOverview
  ): ExcelTransformation[] {
    const columnCount = this.createColumnDefs(examResults).length;

    const baseTransformations: ExcelTransformation[] = [
      { type: 'bold', target: { row: 1 } },
      { type: 'bold', target: { row: 2 } },
      { type: 'bold', target: { row: 3 } },
      { type: 'bold', target: { row: 4 } },
      { type: 'bold', target: { row: 5 } },
      { type: 'bold', target: { row: 6 } },
      { type: 'sticky', target: { row: 6 } },
      {
        type: 'merge',
        target: { row: 1, columnStart: 1, columnEnd: columnCount },
      },
      {
        type: 'merge',
        target: { row: 2, columnStart: 1, columnEnd: columnCount },
      },
      {
        type: 'merge',
        target: { row: 3, columnStart: 1, columnEnd: columnCount },
      },
      {
        type: 'merge',
        target: { row: 4, columnStart: 1, columnEnd: columnCount },
      },
    ];

    const labelColumnTransformations: ExcelTransformation[] = [];

    for (let i = 0; i < columnCount - this.labelColumnStart + 1; i++) {
      labelColumnTransformations.push(
        {
          type: 'rotate',
          target: { row: 6, column: this.labelColumnStart + i },
          value: 90,
        },
        {
          type: 'width',
          target: { column: this.labelColumnStart + i },
          value: 6,
        },
        {
          type: 'height',
          target: { row: 6 },
          value: 200,
        },
        { type: 'width', target: { column: 3 }, value: 8 } // Cesuur
      );
    }

    return [...baseTransformations, ...labelColumnTransformations];
  }

  private loadExamResults(id: string): Observable<ExamResultsOverview> {
    setTimeout(() => {
      this.store.dispatch(setIsLoading(true));
    });
    return from(this.examService.getExamInstanceResultsById(id)).pipe(
      finalize(() => {
        this.store.dispatch(setIsLoading(false));
      })
    );
  }
}
