import { UnitContext } from 'shared/enums/unit-context';
import { Unit } from 'shared/models/unit';

import { unitContextToString } from './unit-context-to-string';

export const getUnitContextLocationString = (
  unit: Unit,
  unitContext: UnitContext,
  interactionIndex: number
) => {
  const contextName = dutchContextName(
    unitContext,
    unit.subtype === 'thematic'
  );
  const context = unit[unitContextToString(unitContext)];

  if (Array.isArray(context)) {
    return `${contextName} ${interactionIndex + 1}/${context.length}`;
  } else {
    return contextName;
  }
};

const dutchContextName = (unitContext: UnitContext, thematicUnit?: boolean) => {
  switch (unitContext) {
    case UnitContext.presentation:
      return 'Presentatie';
    case UnitContext.infoPopup:
      return 'Info popup';
    case UnitContext.introduction:
      return thematicUnit ? 'Vraag' : 'Introductie';
    case UnitContext.extra:
      return 'Extra';
    case UnitContext.deepening:
      return 'Verdieping';
    case UnitContext.test:
      return 'Toets';
    default:
      throw new Error('Unknown context');
  }
};
