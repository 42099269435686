import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { selectUserIsAdmin } from 'src/app/store/reducers/user.reducer';
import { Group } from '../../models/group';
import { Organization } from '../../models/organization';
import { AppUser, UserReference } from '../../models/user';
import { AppState } from '../../store/reducers';
import {
  selectUserOrganizationId,
  selectUserUid,
} from '../../store/reducers/user.reducer';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../dialog/dialog.component';
import {
  GroupDialogData,
  SetGroupDialogComponent,
} from '../set-group-dialog/set-group-dialog.component';

type GroupWithMemberCount = Group & { memberCount: number };

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  groups$: Observable<GroupWithMemberCount[]>;
  userIsAdmin$: Observable<boolean>;
  userUid$: Observable<AppUser['uid']>;
  organization$: Observable<Organization>;
  selectedOrganizationId: string;
  selectedOrganizationName: string;
  organizations$: Observable<Organization[]>;
  displayedColumns = ['name', 'owners', 'memberCount', 'actions'];

  orgControl = new UntypedFormControl();
  filteredOptions$: Observable<Organization[]>;

  groupsBeingHandled = new Set<Group['id']>();

  constructor(
    private databaseService: DatabaseService,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userIsAdmin$ = this.store
      .select(selectUserIsAdmin)
      .pipe(takeUntil(this.ngDestroyed$));

    this.userUid$ = this.store
      .select(selectUserUid)
      .pipe(takeUntil(this.ngDestroyed$));

    this.organization$ = combineLatest([
      this.orgControl.valueChanges.pipe(startWith(''), debounceTime(200)),
      this.store.select(selectUserOrganizationId),
    ]).pipe(
      switchMap(([formValue, userOrganization]) => {
        if (formValue?.name) {
          return of(formValue as Organization);
        } else {
          return userOrganization
            ? this.databaseService.getOrganization(userOrganization)
            : of(null);
        }
      }),
      takeUntil(this.ngDestroyed$)
    );

    this.organization$.pipe(takeUntil(this.ngDestroyed$)).subscribe((org) => {
      this.selectedOrganizationId = org.id;
      this.selectedOrganizationName = org.name;
    });

    this.organizations$ = this.userIsAdmin$.pipe(
      switchMap((isAdmin) => {
        if (isAdmin) {
          return this.databaseService.getOrganizations();
        } else {
          return of([] as Organization[]);
        }
      }),
      takeUntil(this.ngDestroyed$)
    );

    this.filteredOptions$ = combineLatest([
      this.orgControl.valueChanges.pipe(startWith(''), debounceTime(200)),
      this.organizations$,
    ]).pipe(
      map(([value, organizations]) =>
        typeof value === 'string'
          ? [value, organizations || []]
          : [value.name, organizations || []]
      ),
      map(([name, organizations]) =>
        name
          ? this.organizationFilter(name, organizations)
          : organizations.slice()
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.groups$ = this.organization$.pipe(
      switchMap((organization) => {
        if (!organization) {
          return of([] as Group[]);
        }
        return this.databaseService.getGroupsForOrg(organization.id);
      }),
      switchMap((groups) => {
        if (groups.length === 0) {
          return of([] as GroupWithMemberCount[]);
        }

        return combineLatest(
          groups.map((group) =>
            this.databaseService.getGroupMemberCount(group).pipe(
              map((members) => ({
                ...group,
                memberCount: members || null, // Filter out zeros
              }))
            )
          )
        );
      }),
      takeUntil(this.ngDestroyed$)
    );
  }

  displayOrg(organization: Organization): string {
    if (organization) {
      return (
        organization.name +
        (organization.city ? ' (' + organization.city + ')' : '')
      );
    }

    return '';
  }

  displayCell(cellData: UserReference[] | string) {
    return (
      (Array.isArray(cellData) && cellData.map((u) => u.name).join(', ')) ||
      cellData
    );
  }

  addGroupDialog() {
    this.dialog.open<SetGroupDialogComponent, GroupDialogData>(
      SetGroupDialogComponent,
      {
        data: {
          organizationId: this.selectedOrganizationId,
        },
      }
    );
  }

  async renameGroupDialog(group: GroupWithMemberCount) {
    this.dialog.open<SetGroupDialogComponent, GroupDialogData>(
      SetGroupDialogComponent,
      {
        data: {
          existingGroup: group,
          organizationId: this.selectedOrganizationId,
        },
      }
    );
  }

  async deleteGroup(group: GroupWithMemberCount) {
    const dialogRef = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        data: {
          title: 'Verwijder groep',
          text:
            `Weet je zeker dat je de groep "${group.name}" wil verwijderen?` +
            `${group.memberCount ? '\nDe groep bevat leden.' : ''}`,
          preset: DialogPreset.cancelOk,
        },
      }
    );

    const result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }

    this.groupsBeingHandled.add(group.id);
    this.databaseService
      .deleteGroup(group.id)
      .catch((err) => {
        this.store.dispatch(
          showSnackbar({
            message: `Groep kon niet verwijderd worden:\n ${err.message}`,
          })
        );
      })
      .finally(() => {
        this.groupsBeingHandled.delete(group.id);
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  private organizationFilter(
    searchValue: string,
    organizations: Organization[]
  ): Organization[] {
    const searchValueLowerCase = searchValue.toLowerCase();

    return searchValueLowerCase
      .split(' ')
      .reduce(
        (accumulator, searchPart) =>
          accumulator.filter(
            (org) =>
              org.name.toLowerCase().includes(searchPart) ||
              org.city.toLowerCase().includes(searchPart) ||
              org.address.toLowerCase().includes(searchPart)
          ),
        organizations
      );
  }
}
