export const validateOpenFrom = (openFromDateTime: number): boolean => {
  const now = Date.now();
  const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;

  return openFromDateTime >= twentyFourHoursAgo;
};

export const validateOpenTo = (openToDateTime: number): boolean => {
  const now = Date.now();
  return openToDateTime >= now;
};

export const validateOpenFromFE = (
  openFromDate: Date,
  openFromTime: string | null
): boolean => {
  let openFromDateTime = new Date(openFromDate).setHours(0, 0, 0, 0);

  if (openFromTime) {
    const [hours, minutes] = openFromTime.split(':').map(Number);
    openFromDateTime += hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  return validateOpenFrom(openFromDateTime);
};

export const validateOpenToFE = (
  openToDate: Date,
  openToTime: string | null
): boolean => {
  let openToDateTime = new Date(openToDate).setHours(0, 0, 0, 0);

  if (openToTime) {
    const [hours, minutes] = openToTime.split(':').map(Number);
    openToDateTime += hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  } else {
    // Add 23:59 if no time is provided
    openToDateTime += 23 * 60 * 60 * 1000 + 59 * 60 * 1000;
  }

  return validateOpenTo(openToDateTime);
};
