import { Injectable } from '@angular/core';
import * as firestore from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor() {}

  public get collectionData() {
    return firestore.collectionData;
  }

  public get query() {
    return firestore.query;
  }

  public get collection() {
    return firestore.collection;
  }

  public get where() {
    return firestore.where;
  }

  public get limitToLast() {
    return firestore.limitToLast;
  }

  public get limit() {
    return firestore.limit;
  }

  public get orderBy() {
    return firestore.orderBy;
  }

  public get startAfter() {
    return firestore.startAfter;
  }

  public get endBefore() {
    return firestore.endBefore;
  }

  public get getDocs() {
    return firestore.getDocs;
  }

  public get getDoc() {
    return firestore.getDoc;
  }

  public get getCountFromServer() {
    return firestore.getCountFromServer;
  }
}
