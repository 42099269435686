import { DbUnit, Unit } from '../models/unit';

export const unitEncoder = (unit: Unit): DbUnit =>
  // Firebase does not allow storage of nested arrays hence we convert it
  // to an object with the indices as keys.
  ({
    ...unit,
    introduction: { ...unit.introduction },
    extra: { ...unit.extra },
    deepening: { ...unit.deepening },
    test: { ...unit.test },
  });

export const unitReviver = (dbUnit: DbUnit): Unit =>
  // We transform the Firebase object back to its original state with nested arrays.
  ({
    ...dbUnit,
    introduction: dbUnit.introduction ? Object.values(dbUnit.introduction) : [],
    extra: dbUnit.extra ? Object.values(dbUnit.extra) : [],
    deepening: dbUnit.deepening ? Object.values(dbUnit.deepening) : [],
    test: dbUnit.test ? Object.values(dbUnit.test) : [],
  });
