import { UnitContext } from '../../enums/unit-context';
import { InteractionScore, UnitScore } from '../../models/score.enum';
import { UserUnitScore } from '../../models/user-unit-score';

const calcThematicUnitScore = (scores: {
  thematicLength: number;
  normalScoreCorrect: number;
  normalScoreCorrectWithHelp: number;
  normalScoreIncorrect: number;
}): UnitScore => {
  const maxScore = scores.thematicLength * 2;
  const score =
    scores.normalScoreCorrect * 2 + scores.normalScoreCorrectWithHelp;
  const complete =
    scores.thematicLength ===
    scores.normalScoreCorrect +
      scores.normalScoreCorrectWithHelp +
      scores.normalScoreIncorrect;
  const started =
    scores.normalScoreCorrect +
      scores.normalScoreCorrectWithHelp +
      scores.normalScoreIncorrect >
    0;

  switch (true) {
    case complete && score >= maxScore * 0.7:
      return UnitScore.correct;
    case complete && score >= maxScore * 0.5:
      return UnitScore.correctWithHelp;
    case complete:
      return UnitScore.incorrect;
    case started:
      return UnitScore.inconclusive;
    default:
      return UnitScore.null;
  }
};

export const calcUnitScore = (userUnitScore: UserUnitScore): UnitScore => {
  const contexts = userUnitScore.contexts;
  const mapped: Record<string, InteractionScore[]> = {};
  mapped[UnitContext.test] = [];

  mapped[UnitContext.introduction] = [];
  mapped[UnitContext.extra] = [];
  mapped[UnitContext.deepening] = [];

  for (const contextKey in contexts) {
    if (Object.prototype.hasOwnProperty.call(contexts, contextKey)) {
      const scoreObj = contexts[contextKey];
      for (const indexKey in scoreObj) {
        if (Object.prototype.hasOwnProperty.call(scoreObj, indexKey)) {
          const score = scoreObj[indexKey];
          mapped[contextKey].push(score);
        }
      }
    }
  }

  const testScoreCorrect = mapped[UnitContext.test].filter(
    (s: InteractionScore) => s === InteractionScore.correct
  ).length;
  const testScoreIncorrect = mapped[UnitContext.test].filter(
    (s: InteractionScore) => s === InteractionScore.incorrect
  ).length;

  const normalScoreCorrect = mapped[UnitContext.introduction]
    .concat(mapped[UnitContext.extra], mapped[UnitContext.deepening])
    .filter((s: InteractionScore) => s === InteractionScore.correct).length;
  const normalScoreCorrectWithHelp = mapped[UnitContext.introduction]
    .concat(mapped[UnitContext.extra], mapped[UnitContext.deepening])
    .filter(
      (s: InteractionScore) => s === InteractionScore.correctWithHelp
    ).length;
  const normalScoreIncorrect = mapped[UnitContext.introduction]
    .concat(mapped[UnitContext.extra], mapped[UnitContext.deepening])
    .filter((s: InteractionScore) => s === InteractionScore.incorrect).length;

  if (userUnitScore.thematicUnit) {
    return calcThematicUnitScore({
      thematicLength: userUnitScore.thematicLength as number,
      normalScoreCorrect,
      normalScoreCorrectWithHelp,
      normalScoreIncorrect,
    });
  }

  if (
    testScoreCorrect >= 3 ||
    (testScoreCorrect === 2 && normalScoreCorrect >= 7) ||
    (testScoreCorrect === 1 && normalScoreCorrect >= 9)
  ) {
    return UnitScore.correct;
  } else if (
    testScoreCorrect >= 1 &&
    testScoreCorrect + normalScoreCorrect + normalScoreCorrectWithHelp >= 9
  ) {
    return UnitScore.correctWithHelp;
  } else if (testScoreIncorrect >= 2) {
    return UnitScore.incorrect;
  } else if (
    normalScoreCorrect >= 1 ||
    normalScoreCorrectWithHelp >= 1 ||
    normalScoreIncorrect >= 1 ||
    testScoreCorrect >= 1 ||
    testScoreIncorrect >= 1
  ) {
    return UnitScore.inconclusive;
  } else {
    return UnitScore.null;
  }
};

export const calcMinIntroScore = (userUnitScore: UserUnitScore): boolean => {
  if (!userUnitScore?.contexts[UnitContext.introduction]) {
    return false;
  }

  const introScoreObj = userUnitScore.contexts[UnitContext.introduction];
  const scoreArray: InteractionScore[] = [];

  for (const indexKey in introScoreObj) {
    if (Object.prototype.hasOwnProperty.call(introScoreObj, indexKey)) {
      const score = introScoreObj[indexKey];
      scoreArray.push(score);
    }
  }

  const introScoreCorrect = scoreArray.filter(
    (s: InteractionScore) => s === InteractionScore.correct
  ).length;

  const introScoreCorrectWithHelp = scoreArray.filter(
    (s: InteractionScore) => s === InteractionScore.correctWithHelp
  ).length;

  return (
    introScoreCorrect >= 3 ||
    (introScoreCorrect === 2 && introScoreCorrectWithHelp === 2)
  );
};
