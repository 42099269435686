<h1 mat-dialog-title>{{ (targetUser$ | async)?.name || userRef.name }}</h1>

<mat-dialog-content>
    <p>
        Laatst gezien: {{ ((targetUser$ | async)?.lastSeen | date: 'fullDate') || 'Onbekend' }}
        <br />
        Groepen: <span>{{ (groups$ | async)?.join(', ') }}</span>
        <button mat-icon-button (click)="openChooseGroupsDialog()" title="Bewerk groepen">
            <mat-icon>edit</mat-icon>
        </button>
        <br />
        Docenten: <span>{{ (teachers$ | async)?.join(', ') }}</span>
        <button *ngIf="(isFollowing$ | async) === false" [disabled]="loading" mat-button color="primary" title="Volgen"
            (click)="followStudent()">
            <mat-icon>link</mat-icon>
        </button>
        <button *ngIf="(isFollowing$ | async) === true" [disabled]="loading" mat-button color="accent" title="Ontvolgen"
            (click)="unfollowStudent()">
            <mat-icon>link_off</mat-icon>
        </button>
        <br />
    </p>
    <div echarts [options]="options"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</mat-dialog-actions>