import { UnitContext } from '../enums/unit-context';
import { Timestamp } from '../helpers/time-helper';
import { Chapter } from './chapter';
import { Domain } from './domain';
import { Interaction } from './interaction';
import { NotepadLineForStorage } from './notepad-line';
import { Project } from './project';
import { InteractionScore } from './score.enum';
import { InteractionIndex, Unit } from './unit';
import { AppUser } from './user';

export type StandaloneInteractionAttempt = {
  uid: AppUser['uid'];
  interactionId: Interaction['id'];
  interactionVersion: Interaction['version'];
  score: InteractionScore;
  answers: string[];
  notepadLines?: NotepadLineForStorage[];
  vars: string[]; // TODO Deprecate in favor of varsShuffledIndexes.
  varsShuffledIndexes?: Interaction['varsShuffledIndexes'];
  try: number;
  timeTaken: number; // ms
  timestamp: Timestamp; // Overwritten server-side
};

export type InteractionAttempt = StandaloneInteractionAttempt & {
  unitId: Unit['id'];
  unitContext: UnitContext;
  interactionIndex: InteractionIndex;
  chapterId: Chapter['id'];
  domainId: Domain['id'];
  projectId: Project['id'];
};

export const isFullInteractionAttempt = (
  attempt: InteractionAttempt | StandaloneInteractionAttempt
): attempt is InteractionAttempt =>
  (attempt as InteractionAttempt)?.projectId !== undefined;

export type AddInteractionAttemptPayload = Omit<
  InteractionAttempt,
  'uid' | 'timestamp'
>;

export type AddStandaloneInteractionAttemptPayload = Omit<
  StandaloneInteractionAttempt,
  'uid' | 'timestamp'
>;

export type AddInteractionAttemptPayloadWithoutTimeTaken = Omit<
  AddInteractionAttemptPayload,
  'timeTaken'
>;

export type AddStandaloneInteractionAttemptPayloadWithoutTimeTaken = Omit<
  AddStandaloneInteractionAttemptPayload,
  'timeTaken'
>;

export type GetUserInteractionAttemptPayload = {
  uid: AppUser['uid'];
  projectId: Project['id'];
  domainId: Domain['id'];
  chapterId: Chapter['id'];
  unitId: Unit['id'];
  unitContext: UnitContext;
  interactionIndex: InteractionIndex;
};

export const maxTextLengthForNotepadLinesCombined = 2500;

export const isValidAddInteractionAttemptPayload = (
  payload: AddInteractionAttemptPayload | AddStandaloneInteractionAttemptPayload
): payload is
  | AddInteractionAttemptPayload
  | AddStandaloneInteractionAttemptPayload =>
  !!(
    payload &&
    payload.interactionId &&
    payload.interactionVersion &&
    payload.score &&
    payload.answers &&
    payload.vars &&
    typeof payload.try === 'number' &&
    typeof payload.timeTaken === 'number' &&
    notepadLinesAreValid(payload.notepadLines)
  );

const notepadLinesAreValid: (
  notepadLines?: NotepadLineForStorage[]
) => boolean = (notepadLines) => {
  if (!notepadLines) {
    return true;
  }

  const lengthOfAllEntries = notepadLines.reduce((previousSum, note) => {
    const sum = previousSum + note.content.length;
    return sum;
  }, 0);

  return lengthOfAllEntries <= maxTextLengthForNotepadLinesCombined;
};
