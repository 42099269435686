import { cloneDeep } from 'lodash';
import { InteractionAttempt } from '../../models/user-interaction-attempt';
import { UserUnitScore } from '../../models/user-unit-score';
import { calcUnitScore } from './calc-unit-score';

export const mergeAttemptToUserUnitScore = (
  userUnitScore: UserUnitScore,
  attempt: InteractionAttempt
): UserUnitScore => {
  const resultObj = cloneDeep(userUnitScore);

  const existingContext = resultObj.contexts[attempt.unitContext];
  if (existingContext && existingContext[attempt.interactionIndex]) {
    existingContext[attempt.interactionIndex] = Math.max(
      resultObj.contexts[attempt.unitContext][attempt.interactionIndex],
      attempt.score
    );
  } else if (existingContext) {
    existingContext[attempt.interactionIndex] = attempt.score;
  } else {
    resultObj.contexts[attempt.unitContext] = {};
    resultObj.contexts[attempt.unitContext][attempt.interactionIndex] =
      attempt.score;
  }

  resultObj.score = calcUnitScore(resultObj);
  resultObj.timeTaken = attempt.timeTaken;
  resultObj.totalTime = userUnitScore.totalTime + attempt.timeTaken;
  resultObj.timestamp = attempt.timestamp;

  return resultObj;
};
