import {
  ExamSessionInteractionAttempt,
  ExamSessionInteractionAttemptEncrypted,
} from '../../shared/models/exam-session';
import { InteractionScore } from '../../shared/models/score.enum';

export const extractExamSessionAttemptFields = (
  interactionAttempt:
    | ExamSessionInteractionAttempt
    | ExamSessionInteractionAttemptEncrypted,
  score: InteractionScore | null = null
):
  | ExamSessionInteractionAttempt
  | Omit<ExamSessionInteractionAttempt, 'score'> => {
  const fields = {
    interactionId: interactionAttempt.interactionId,
    interactionVersion: interactionAttempt.interactionVersion,
    answers: interactionAttempt.answers,
    varsShuffledIndexes: interactionAttempt.varsShuffledIndexes,
    timestamp: interactionAttempt.timestamp,
    timeTaken: interactionAttempt.timeTaken,
    notepadLines: interactionAttempt.notepadLines,
  };

  if (score !== null) {
    return {
      ...fields,
      score,
    };
  }

  return fields;
};
