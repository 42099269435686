import { UserRole } from '../enums/user-role.enum';

export const constants = {
  dbCollections: {
    projects: 'projects',
    domains: 'domains',
    chapters: 'chapters',
    units: 'units',
    interactions: 'interactions',
    userInteractionAttempts: 'userInteractionAttempts',
    userUnitScores: 'userUnitScores',
    userChapterScores: 'userChapterScores',
    userDomainScores: 'userDomainScores',
    userProjectScores: 'userProjectScores',
    users: 'users',
    organizations: 'organizations',
    groups: 'groups',
    licenses: 'licenses',
    exams: 'exams',
    examInstances: 'examInstances',
    examSessions: 'examSessions',
  },
  algoliaIndices: {
    organizations: 'organizations',
    users: 'users',
  },
  placeholderInteractionId: '99999', // Placeholder interaction
  supportEmail: 'helpdesk@ffrekenen.nl',
  maxDistributionNoteLength: 60,
  svoOrganizationId: '626',
  mathPrecision: 14,
  roundingPrecision: 7,
  mathNotationFixed: 'fixed' as
    | 'fixed'
    | 'exponential'
    | 'engineering'
    | 'auto',
  percentageDomains: ['re_mbo2_d4', 're_mbo3_d4', 're_mbo4_d4'],
  maxSkips: 2,
  maxLicenseCount: 250,
  licenseWarningTimeFrame: 14 * 24 * 60 * 60 * 1000, // 14 days
  licenseExemptRoles: [UserRole.admin, UserRole.teacher],
  longDateTimeFormat: 'd MMM yyyy, H:mm',
};
