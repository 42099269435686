<h1 mat-dialog-title>Kies je school</h1>

<mat-dialog-content>
    <h4 *ngIf="(userOrganization$ | async)">Huidige school: {{ (userOrganization$ | async)?.name }}</h4>
    <br />
    <ais-instantsearch *ngIf="config" [config]="config">
        <app-auto-complete [label]="'School'" [displayFunction]="displayOrg" (outputSelected)="setQuery($event)">
        </app-auto-complete>
    </ais-instantsearch>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
    <button mat-raised-button [disabled]="!user || (loading$ | async) || !(userOrganization$ | async | truthy)"
        (click)="removeOrganization()">Verwijder</button>
    <button mat-raised-button
        [disabled]="!user || !searchParameters.id || (loading$ | async) || (userOrganization$ | async)?.id === searchParameters.id"
        (click)="save()">Opslaan</button>
</mat-dialog-actions>