import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExamSessionResult } from '../../../../../shared/models/exam-result';

export const dialogWidth = '600px';

@Component({
  selector: 'app-exam-finished',
  templateUrl: './exam-finished.component.html',
  styleUrls: ['./exam-finished.component.scss'],
})
export class ExamFinishedComponent implements OnInit {
  scoresPerLabel: Record<string, number>;
  totalScore: number;
  maxTotalScore: number;
  maxScorePerLabel: Record<string, number>;
  uid: string;
  hideGrade?: boolean;
  grade: number = null;
  percentageScore: number = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ExamSessionResult & { hideGrade: boolean },
    private dialogRef: MatDialogRef<ExamFinishedComponent>
  ) {}

  ngOnInit() {
    this.scoresPerLabel = Object.fromEntries(
      Object.entries(this.data.labelScores).map(([label, scores]) => [
        label,
        scores.score,
      ])
    );
    this.totalScore = this.data.totalScore;
    this.maxTotalScore = this.data.maxTotalScore;
    this.maxScorePerLabel = Object.fromEntries(
      Object.entries(this.data.labelScores).map(([label, scores]) => [
        label,
        scores.maxScore,
      ])
    );
    this.uid = this.data.uid;
    this.hideGrade = this.data.grade === null;

    this.grade = this.data.grade;
    this.percentageScore = this.data.scorePercentage;
  }
}
