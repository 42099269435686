import { Point, Polygon, Rectangle } from '../types/shapes';

export type InteractionOptionText = {
  option: string;
  field?: string;
  correct: boolean;
  feedback?: string;
  feedbackSound?: string;
  feedbackLate?: string;
  feedbackLateSound?: string;
};

export type InteractionOptionPresentation = {
  option: string;
  area?: string;
  feedbackFalse?: string;
  image?: string;
  video?: string;
};

export type InteractionOptionImage = {
  area: string;
  shape?: Rectangle | Polygon;
  correct: boolean;
  feedback?: string;
  feedbackLate?: string;
  feedbackSound?: string;
};

export type InteractionOptionHotspot = {
  index?: number; // Set during parsing
  draggedTo?: Point;
  cdkDragFreeDragPosition?: Point;
  disabled?: boolean;
  inImage?: boolean;
  correct?: boolean;
  target: string;
  option: string;
  picture: string;
  feedbackCorrect: string;
  feedbackFalse: string;
  feedback?: string;
  feedbackLate?: never;
  feedbackSound?: string;
};

export const isInteractionOptionHotspot = (
  option: any
): option is InteractionOptionHotspot => option?.target !== undefined;

export const isInteractionOptionHotspotArray = (
  options: any[]
): options is InteractionOptionHotspot[] =>
  options.every(isInteractionOptionHotspot);

export type Hotspot = {
  id: string;
  name: string;
  area: string;
  shape?: Rectangle;
};

export type InteractionOptionSortOrder = {
  option: string;
  picture?: string;
  position?: number;
};

export type Order = {
  correct: boolean;
  feedback?: string;
  feedbackSound?: string;
  option: string; // Order '1,3,2,4'
};

export type InteractionOption =
  | InteractionOptionText
  | InteractionOptionImage
  | InteractionOptionHotspot
  | Order;

export const correctnessSorter = (
  a: InteractionOption,
  b: InteractionOption
) => {
  if (a.correct && !b.correct) {
    return -1;
  }
  if (a.correct && b.correct) {
    return 1;
  }
  return 0;
};
