import { createMurmurHash } from '../../shared/helpers/crypto';
import { extractExamSessionAttemptFields } from '../../shared/helpers/extract-exam-session-attempt';
import {
  ExamSessionInteractionAttempt,
  ExamSessionInteractionAttemptEncrypted,
} from '../../shared/models/exam-session';

export const encryptExamSessionInteractionAttempt = (
  interactionAttempt: ExamSessionInteractionAttempt
): ExamSessionInteractionAttemptEncrypted => {
  const interactionAttemptFields = extractExamSessionAttemptFields(
    interactionAttempt,
    interactionAttempt.score
  );
  const interactionAttemptString = JSON.stringify(interactionAttemptFields);
  const cryptoHash = createMurmurHash(interactionAttemptString);

  delete (interactionAttemptFields as any).score;

  const encryptedInteractionAttempt: ExamSessionInteractionAttemptEncrypted = {
    ...interactionAttemptFields,
    crypto: cryptoHash,
  };

  return encryptedInteractionAttempt;
};
