import { Domain } from './domain';
import { Project } from './project';
import { AppUser } from './user';
import { StandaloneInteractionAttempt } from './user-interaction-attempt';

export type UserProjectScore = {
  uid: AppUser['uid'];
  projectId: Project['id'];
  childCount: number;
  percentageComplete: number;
  domainsComplete: Domain['id'][];
  domainsInProgress: Domain['id'][];
  percentageInProgress: number;
  timeTaken: number;
  totalTime: number; // Set server-side in ms
  timestamp?: StandaloneInteractionAttempt['timestamp']; // Set server-side
};

export const buildEmptyUserProjectScore = (
  uid: AppUser['uid'],
  projectId: Project['id']
): UserProjectScore => ({
  uid,
  projectId,
  childCount: 0, // TODO check
  domainsComplete: [],
  domainsInProgress: [],
  percentageComplete: 0,
  percentageInProgress: 0,
  timeTaken: 0,
  totalTime: 0,
});
