<mat-card *ngIf="(cardFlavor$ | async) === 'unlicensed'">
    <mat-card-title>
        Je licentie is verlopen
    </mat-card-title>
    <mat-card-content>
        <p>Je licentie voor onze software is helaas verlopen.</p>
    </mat-card-content>
    <mat-card-actions>
        <a mat-button color="primary" href="https://intraquestwebshop.nl/rekensoftware" target="_blank" rel="noopener">
            Koop een nieuwe licentie
        </a>
        <button mat-raised-button color="accent" (click)="openCodeInputDialog()">Licentiecode invoeren</button>
    </mat-card-actions>
</mat-card>

<mat-card *ngIf="(cardFlavor$ | async) === 'licensed'">
    <mat-card-title>
        Je hebt een actieve licentie
    </mat-card-title>
    <mat-card-content>
        <p *ngIf="(mboLicenseDate$ | async) as mboDate">
            {{ 'MBO licentie geldig tot ' + (mboDate | date: 'fullDate') }}
        </p>
        <p *ngIf="(vmboLicenseDate$ | async) as vmboDate">
            {{ 'VMBO licentie geldig tot ' + (vmboDate | date: 'fullDate') }}
        </p>
    </mat-card-content>
    <mat-card-actions>
        <a mat-button color="primary" href="https://intraquestwebshop.nl/rekensoftware" target="_blank" rel="noopener">
            Koop een nieuwe licentie
        </a>
        <button mat-raised-button color="accent" (click)="openCodeInputDialog()">Licentiecode invoeren</button>
        <a mat-button color="accent" routerLink="/">
            Ga naar Home
        </a>
    </mat-card-actions>
</mat-card>

<mat-card *ngIf="(cardFlavor$ | async) === 'exempt'">
    <mat-card-title>
        Je hebt een vrijstelling
    </mat-card-title>
    <mat-card-content>
        <p>Je hebt vrije toegang tot onze software.</p>
    </mat-card-content>
    <mat-card-actions>
        <a mat-button color="accent" routerLink="/">
            Ga naar Home
        </a>
    </mat-card-actions>
</mat-card>