import { createAction, props } from '@ngrx/store';
import { Domain } from 'shared/models/domain';
import { InteractionOptionSortOrder } from 'shared/models/interaction-option';
import { InteractionEvent } from '../../../../shared/enums/interaction-event';
import { UnitContext } from '../../../../shared/enums/unit-context';
import { Chapter } from '../../../../shared/models/chapter';
import { Project } from '../../../../shared/models/project';
import { InteractionIndex, Unit } from '../../../../shared/models/unit';
import {
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from '../../../../shared/models/user-interaction-attempt';
import { Interaction } from '../../../../shared/models/interaction';
import { InteractionRouteParams } from '../reducers/interaction.reducer';
import { Point } from 'shared/types/shapes';
export const interactionActions = {
  loadInteraction: createAction(
    '[Interaction] Load interaction',
    (interactionId: string) => ({ interactionId })
  ),
  interactionLoaded: createAction(
    '[Interaction] Interaction loaded',
    props<{ interaction: Interaction }>()
  ),
  interactionParsed: createAction(
    '[Interaction] Interaction Parsed',
    props<{
      parsedInteraction: Interaction;
      answers: string[];
      timestamp: number;
    }>()
  ),

  // reset: createAction('[Interaction] Reset'),

  loadUnit: createAction(
    '[Interaction] Load Unit',
    props<{ unitId: Unit['id'] }>()
  ),
  unitLoaded: createAction(
    '[Interaction] Unit Loaded',
    props<{ unit: Unit }>()
  ),

  // changeRouteParams: createAction(
  //   '[Interaction] Change Route Params',
  //   props<{
  //     projectId: Project['id'] | null;
  //     domainId: Domain['id'] | null;
  //     chapterId: Chapter['id'] | null;
  //     unitId: Unit['id'] | null;
  //     interactionId: Interaction['id'] | null;
  //     unitContext: UnitContext | null;
  //     interactionIndex: InteractionIndex | null;
  //   }>()
  // ),

  routeParamsChanged: createAction(
    '[Interaction] Route Params Changed',
    props<InteractionRouteParams>()
  ),

  componentDestroyed: createAction('[Interaction] Component Destroyed'),

  // variantIndexPicked: createAction(
  //   '[Interaction] Variant Index Picked',
  //   props<{ variantIndex: number }>()
  // ),

  // nextInteractionRoute$: BehaviorSubject<string> = new BehaviorSubject(null);
  // events: Set<InteractionEvent> = new Set();
  // imageUrl: string;
  // presentationSteps?: InteractionOptionPresentation[];
  // activeArea?: Rectangle;
  // showArea: boolean;

  // showOkButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // showNextButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // showFastForwardButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // showSkipButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  // showNextExamInteractionButton$: BehaviorSubject<boolean> =
  //   new BehaviorSubject(false);
  // showPrevExamInteractionButton$: BehaviorSubject<boolean> =
  //   new BehaviorSubject(false);

  // nextExamInteractionRoute$: BehaviorSubject<string> = new BehaviorSubject(
  //   null
  // );
  // prevExamInteractionRoute$: BehaviorSubject<string> = new BehaviorSubject(
  //   null
  // );

  // unit$: Observable<Unit> | undefined;
  // ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  // activityTimestamp: number;
  // maxTimerId: number;
  // timerExpired = false;
  // tries = 0;
  // noImageScaling = false;
  // locked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // question: string;
  // isDevMode$: Observable<boolean>;
  // debugEvents: InteractionEvent[];
  // videoHelper: VideoHelper;
  // showVideo = false;
  // contextLocation: string;
  // hasDragged = false;
  // currentSortOrder: string; // Order like '1,3,2,5'
  // openTextDraggables: number;
  // pointClicks: Point[];
  // radioOptions: string[];
  // radioSelected: string;
  // radioSelectedPrev: string;

  // projectId$: BehaviorSubject<Project['id']> = new BehaviorSubject(null);
  // domainId$: BehaviorSubject<Domain['id']> = new BehaviorSubject(null);
  // chapterId$: BehaviorSubject<Chapter['id']> = new BehaviorSubject(null);
  // unitId$: BehaviorSubject<Unit['id']> = new BehaviorSubject(null);
  // unitContext$: BehaviorSubject<UnitContext> = new BehaviorSubject(null);
  // interactionIndex$: BehaviorSubject<InteractionIndex> = new BehaviorSubject(
  //   null
  // );

  // interactionId$: BehaviorSubject<Interaction['id']> = new BehaviorSubject(
  //   null
  // );
  // userUnitScore$: Observable<UserUnitScore>;
  // userUnitScorePath$: Observable<string>;
  // shouldFindFocus: boolean;

  // calcDialogRef: MatDialogRef<CalculatorDialogComponent>;
  // lastFocussedInput?: HTMLInputElement;
  // lastCopiedValue$: Observable<string>;

  // examSessionId$: BehaviorSubject<string> = new BehaviorSubject(null);
  // examSectionIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
  // examSectionInteractionIndex$: BehaviorSubject<number> = new BehaviorSubject(
  //   null
  // );
  // isExamSession$: Observable<boolean>;
  // make actions for all of these

  setNextInteractionRoute: createAction(
    '[Interaction] Set next interaction route',
    (nextInteractionRoute: string) => ({ nextInteractionRoute })
  ),
  setNextExamInteractionRoute: createAction(
    '[Interaction] Set next exam interaction route',
    (nextExamInteractionRoute: string) => ({ nextExamInteractionRoute })
  ),
  setPrevExamInteractionRoute: createAction(
    '[Interaction] Set prev exam interaction route',
    (prevExamInteractionRoute: string) => ({ prevExamInteractionRoute })
  ),
  setShowNextButton: createAction(
    '[Interaction] Set show next button',
    (showNextButton: boolean) => ({ showNextButton })
  ),
  setShowFastForwardButton: createAction(
    '[Interaction] Set show fast forward button',
    (showFastForwardButton: boolean) => ({ showFastForwardButton })
  ),
  setShowSkipButton: createAction(
    '[Interaction] Set show skip button',
    (showSkipButton: boolean) => ({ showSkipButton })
  ),
  setShowNextExamInteractionButton: createAction(
    '[Interaction] Set show next exam interaction button',
    (showNextExamInteractionButton: boolean) => ({
      showNextExamInteractionButton,
    })
  ),
  setShowPrevExamInteractionButton: createAction(
    '[Interaction] Set show prev exam interaction button',
    (showPrevExamInteractionButton: boolean) => ({
      showPrevExamInteractionButton,
    })
  ),
  setProjectId: createAction(
    '[Interaction] Set project id',
    (projectId: Project['id']) => ({ projectId })
  ),
  setDomainId: createAction(
    '[Interaction] Set domain id',
    (domainId: Domain['id']) => ({ domainId })
  ),
  setChapterId: createAction(
    '[Interaction] Set chapter id',
    (chapterId: Chapter['id']) => ({ chapterId })
  ),
  setUnitId: createAction(
    '[Interaction] Set unit id',
    (unitId: Unit['id']) => ({ unitId })
  ),
  setUnitContext: createAction(
    '[Interaction] Set unit context',
    (unitContext: UnitContext) => ({ unitContext })
  ),
  setInteractionIndex: createAction(
    '[Interaction] Set interaction index',
    (interactionIndex: InteractionIndex) => ({ interactionIndex })
  ),
  setInteractionId: createAction(
    '[Interaction] Set interaction id',
    (interactionId: Interaction['id']) => ({ interactionId })
  ),
  setExamSessionId: createAction(
    '[Interaction] Set exam session id',
    (examSessionId: string) => ({ examSessionId })
  ),
  setExamSectionIndex: createAction(
    '[Interaction] Set exam section index',
    (examSectionIndex: number) => ({ examSectionIndex })
  ),
  setExamSectionInteractionIndex: createAction(
    '[Interaction] Set exam section interaction index',
    (examSectionInteractionIndex: number) => ({ examSectionInteractionIndex })
  ),
  setLocked: createAction('[Interaction] Set locked', (locked: boolean) => ({
    locked,
  })),
  setQuestion: createAction(
    '[Interaction] Set question',
    (question: string) => ({ question })
  ),
  setImage: createAction(
    '[Interaction] Set image',
    (imageSrc: Interaction['image']) => ({
      imageSrc,
    })
  ),

  setHasDragged: createAction(
    '[Interaction] Set has dragged',
    (hasDragged: boolean) => ({ hasDragged })
  ),
  setMaxTimerId: createAction(
    '[Interaction] Set max timer id',
    (maxTimerId: number) => ({ maxTimerId })
  ),
  setTimerExpired: createAction(
    '[Interaction] Set timer expired',
    (timerExpired: boolean) => ({ timerExpired })
  ),
  setStartTime: createAction(
    '[Interaction] Set start time',
    (timestamp: number) => ({ timestamp })
  ),
  setTries: createAction('[Interaction] Set tries', (tries: number) => ({
    tries,
  })),
  setDebugEvents: createAction(
    '[Interaction] Set debug events',
    (debugEvents: InteractionEvent[]) => ({ debugEvents })
  ),
  setOptionsSortOrder: createAction(
    '[Interaction] Set current sort order',
    (optionsSortOrder: InteractionOptionSortOrder[]) => ({ optionsSortOrder })
  ),

  incrementOpenTextDraggables: createAction(
    '[Interaction] Increment open text draggables'
  ),

  setOpenTextDraggables: createAction(
    '[Interaction] Set open text draggables',
    (openTextDraggables: number) => ({ openTextDraggables })
  ),

  openTextDraggablesConditionalIncrement: createAction(
    '[Interaction] Open text draggables conditional increment',
    (openTextDraggables: number) => ({ openTextDraggables })
  ),

  setRadioSelected: createAction(
    // TODO rename to index or whatever it is
    '[Interaction] Set radio selected',
    (radioSelected: string) => ({ radioSelected })
  ),
  radioSelectedPrev: createAction(
    '[Interaction] Set radio selected prev',
    (radioSelectedPrev: string) => ({ radioSelectedPrev })
  ),

  clickMediaArea: createAction(
    '[Interaction] Click media area',
    (clickedPoint: Point) => ({ clickedPoint })
  ),

  handleAnswer: createAction(
    '[Interaction] Handle answer',
    props<{
      uiAnswers?: string[];
      timestamp: number;
    }>()
  ),

  dragEnd: createAction(
    '[Interaction] Drag end',
    props<{
      index: number;
      draggedTo: Point;
      inImage: boolean;
      cdkDragFreeDragPosition: Point;
    }>()
  ),

  setIsSavingAnswer: createAction(
    '[Interaction] Set is saving answer',
    (isSavingAnswer: boolean) => ({ isSavingAnswer })
  ),

  userInteractionAttemptLoaded: createAction(
    '[Interaction] User interaction attempt loaded',
    props<{
      userInteractionAttempt: StandaloneInteractionAttempt | InteractionAttempt;
    }>()
  ),

  restoreAttemptState: createAction(
    '[Interaction] Restore attempt state',
    props<{ attempt: StandaloneInteractionAttempt | InteractionAttempt }>()
  ),

  setIsExamSession: createAction(
    '[Interaction] Set is exam session',
    (isExamSession: boolean) => ({ isExamSession })
  ),

  setIsReadonlyMode: createAction(
    '[Interaction] Set is readonly mode',
    (isReadonlyMode: boolean) => ({ isReadonlyMode })
  ),

  setIsDirty: createAction(
    '[Interaction] Set is dirty',
    props<{ isDirty: boolean }>()
  ),
};
