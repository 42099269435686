import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { ChooseTeachersComponent } from 'src/app/components/choose-teachers/choose-teachers.component';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';
import { EntityType } from 'src/app/enums/entity-type';
import { AuthService } from 'src/app/services/auth.service';
import { AppState } from 'src/app/store/reducers';
import {
  selectIsContentAdminMode,
  selectIsDevMode,
  selectIsDevModeAllowed,
  selectUser,
  selectUserOrganizationId,
} from 'src/app/store/reducers/user.reducer';
import { environment } from '../../../environments/environment';
import { AppRouteParams } from '../../enums/route-params.enum';
import { UserRole } from '../../enums/user-role.enum';
import { StructuralEntity } from '../../models/structural-entity';
import { AppUser } from '../../models/user';
import { DatabaseService } from '../../services/database.service';
import { StructureService } from '../../services/structure.service';
import { userActions } from '../../store/actions/user.actions';
import {
  selectIsContentAdminModeAllowed,
  selectIsDbUserLoaded,
  selectUserIsAdmin,
  selectUserIsDev,
  selectUserIsTeacher,
  selectUserNameIsIncomplete,
  selectUserOrganizationHardLink,
} from '../../store/reducers/user.reducer';
import { AccountSettingsComponent } from '../account-settings/account-settings.component';
import { ChooseGroupsComponent } from '../choose-groups/choose-groups.component';
import { ChooseOrganizationComponent } from '../choose-organization/choose-organization.component';
import { CodeInputDialogComponent } from '../code-input-dialog/code-input-dialog.component';
import { selectIsLoading } from 'src/app/store/reducers/shared.reducer';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  user$: Observable<AppUser>;
  isLoggedIn$: Observable<boolean>;
  userIsAdmin$: Observable<boolean>;
  userIsDev$: Observable<boolean>;
  userIsTeacher$: Observable<boolean>;
  activeProject: StructuralEntity;

  userRoles = UserRole;

  isDevModeAllowed$: Observable<boolean>;
  isDevMode$: Observable<boolean>;
  isContentAdminModeAllowed$: Observable<boolean>;
  isContentAdminMode$: Observable<boolean>;

  isProduction: boolean;

  showChooseOrgButton$: Observable<boolean>;
  showChooseOrgButtonInHeader$: Observable<boolean>;

  isLoading$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private structureService: StructureService,
    private router: Router
  ) {
    this.user$ = this.store.select(selectUser);
    this.isLoggedIn$ = this.store.select(selectIsDbUserLoaded);

    this.isDevModeAllowed$ = this.store.select(selectIsDevModeAllowed);
    this.isDevMode$ = this.store.select(selectIsDevMode);

    this.isContentAdminModeAllowed$ = this.store.select(
      selectIsContentAdminModeAllowed
    );
    this.isContentAdminMode$ = this.store.select(selectIsContentAdminMode);

    this.userIsAdmin$ = this.store.select(selectUserIsAdmin);
    this.userIsDev$ = this.store.select(selectUserIsDev);
    this.userIsTeacher$ = this.store.select(selectUserIsTeacher);
    this.isLoading$ = this.store.select(selectIsLoading);

    // Trigger change name dialog when user name is incomplete
    this.store
      .select(selectUserNameIsIncomplete)
      .subscribe((userNameIsIncomplete) => {
        if (userNameIsIncomplete) {
          this.changeName();
        }
      });

    this.showChooseOrgButton$ = combineLatest([
      this.isLoggedIn$,
      this.store.select(selectUserOrganizationHardLink),
    ]).pipe(map(([isLoggedIn, orgHardLink]) => isLoggedIn && !orgHardLink));

    this.showChooseOrgButtonInHeader$ = combineLatest([
      this.isLoggedIn$,
      this.store.select(selectUserOrganizationId),
    ]).pipe(map(([isLoggedIn, orgId]) => isLoggedIn && !orgId));

    this.isProduction = environment.production;

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.paramMap),
        map((params) => params.get(AppRouteParams.projectId))
      )
      .subscribe(async (projectId) => {
        if (projectId) {
          this.activeProject = await this.structureService
            .getEntity(projectId, EntityType.project)
            .toPromise();
        } else {
          this.activeProject = null;
        }
      });
  }

  signOut() {
    this.authService.signOut();
  }

  deleteUserScores() {
    this.dialog
      .open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'Score verwijderen',
          text: 'Weet je zeker dat je alle scores wilt verwijderen?',
          preset: DialogPreset.okCancel,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.databaseService
            .deleteScores()
            .then(() => {
              const innerDialogRef = this.dialog.open<
                DialogComponent,
                DialogData
              >(DialogComponent, {
                data: {
                  title: 'Score verwijderd',
                  text: 'De scores zijn verwijderd.',
                  preset: DialogPreset.ok,
                },
              });

              innerDialogRef
                .afterClosed()
                .pipe(first())
                .subscribe(() => {
                  window.location.reload();
                });
            })
            .catch((e) => {
              console.error(e);
              this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
                data: {
                  title: 'Oops',
                  text: e.message || 'Er ging iets mis.',
                  preset: DialogPreset.ok,
                },
              });
            });
        }
      });
  }

  chooseOrganization() {
    this.dialog.open<ChooseOrganizationComponent>(ChooseOrganizationComponent);
  }

  chooseTeacher() {
    this.dialog.open<ChooseTeachersComponent>(ChooseTeachersComponent);
  }

  chooseGroups() {
    this.dialog.open<ChooseGroupsComponent>(ChooseGroupsComponent);
  }

  changeName() {
    this.dialog.open<AccountSettingsComponent>(AccountSettingsComponent);
  }

  showCodeDialog() {
    this.dialog.open<CodeInputDialogComponent>(CodeInputDialogComponent);
  }

  toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  setDevMode(changeEvent: MatSlideToggleChange) {
    this.store.dispatch(userActions.setDevMode(changeEvent.checked));
  }

  setContentAdminMode(changeEvent: MatSlideToggleChange) {
    this.store.dispatch(userActions.setContentAdminMode(changeEvent.checked));
  }
}
