import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { AppUser } from '../../models/user';
import { DatabaseService } from '../../services/database.service';
import { AppState } from '../../store/reducers/index';
import {
  selectIsDbUserLoaded,
  selectUser,
  selectUserNameIsIncomplete,
} from '../../store/reducers/user.reducer';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('userSettingsForm') userSettingsForm: NgForm;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  user$: Observable<AppUser>;
  userSettingsFormGroup = this.fb.group({
    firstName: [
      null as AppUser['firstName'],
      Validators.compose([Validators.required]),
    ],
    middleName: [null as AppUser['middleName']],
    lastName: [
      null as AppUser['lastName'],
      Validators.compose([Validators.required]),
    ],
  });

  userNameIncomplete$: Observable<boolean>;
  saveSettingsInProgress: boolean;

  constructor(
    private databaseService: DatabaseService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AccountSettingsComponent>
  ) {}

  ngOnInit() {
    this.user$ = this.store
      .select(selectUser)
      .pipe(takeUntil(this.ngDestroyed$));

    this.userNameIncomplete$ = this.store
      .select(selectUserNameIsIncomplete)
      .pipe(takeUntil(this.ngDestroyed$));

    // Prevent close of dialog if user's saved name is incomplete
    this.userNameIncomplete$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((userNameIsIncomplete) => {
        if (userNameIsIncomplete) {
          this.dialogRef.disableClose = true;
        } else {
          this.dialogRef.disableClose = false;
        }
      });

    this.store
      .select(selectIsDbUserLoaded)
      .pipe(
        first(),
        switchMap(() => this.user$),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((user) => {
        this.userSettingsFormGroup.controls.firstName.setValue(user.firstName);
        this.userSettingsFormGroup.controls.middleName.setValue(
          user.middleName
        );
        this.userSettingsFormGroup.controls.lastName.setValue(user.lastName);
        this.userSettingsFormGroup.markAsPristine();
      });
  }

  saveUserSettings() {
    const names = this.userSettingsForm.value;
    this.userSettingsFormGroup.markAsPristine();
    this.saveSettingsInProgress = true;
    this.databaseService
      .changeName(names)
      .catch((error) => {
        this.userSettingsFormGroup.markAsDirty();
        console.error(error);
      })
      .finally(() => {
        this.saveSettingsInProgress = false;
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
