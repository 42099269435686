import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { selectUserHasAppAccess } from 'src/app/store/reducers/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class LicenseGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectUserHasAppAccess),
      debounce((licensed) => (licensed ? EMPTY : timer(2000))),
      map((licensed) =>
        licensed ? true : this.router.parseUrl('license-expired')
      )
    );
  }
}
