import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';
import { EntityContext } from 'src/app/models/shared-basics';
import { DatabaseService } from 'src/app/services/database.service';
import { RouteData } from 'src/app/types/route-data';
import { AppRouteParams } from '../../enums/route-params.enum';

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.scss'],
})
export class ContextComponent implements OnInit {
  context: EntityContext;

  constructor(
    private activatedRoute: ActivatedRoute,
    private databaseService: DatabaseService,
    private dialog: MatDialog
  ) {
    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.data as Observable<RouteData>,
    ]).subscribe(([paramMap, data]) => {
      const currentId =
        paramMap.get(AppRouteParams.chapterId) ||
        paramMap.get(AppRouteParams.domainId) ||
        paramMap.get(AppRouteParams.projectId);

      if (currentId) {
        this.databaseService
          .getObjAsStructuralEntity(currentId, data.navType)
          .pipe(
            first(),
            map((obj) => obj.context)
          )
          .subscribe((context) => {
            this.context = context;
          });
      }
    });
  }

  ngOnInit() {}

  openVideo() {
    this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: this.context?.description,
        video: this.context?.video,
        preset: DialogPreset.close,
      },
    });
  }
}
