import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Exam, ExamSection } from 'shared/models/exam';
import {
  ExamSession,
  ExamSessionFinalized,
  ExamSessionSections,
} from 'shared/models/exam-session';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { AppState } from 'src/app/store/reducers';
import {
  createSelectIsInteractionAttempted,
  selectExam,
  selectExamSections,
  selectExamSession,
  selectExamSessionSections,
  selectIsReadonly,
} from 'src/app/store/reducers/exam.reducer';
import { selectIsDevMode } from 'src/app/store/reducers/user.reducer';
import { utility } from '../../../../../shared/helpers/utility';
import { examActions } from '../../../store/actions/exam.actions';
import {
  createSelectIsInteractionCorrect,
  createSelectIsInteractionIncorrect,
} from '../../../store/reducers/exam.reducer';
import { CountdownTimer } from '../../countdown/countdown-timer';
import { TimeTravelDialogComponent } from '../../testing/time-travel-dialog/time-travel-dialog.component';

@Component({
  selector: 'app-exam-session',
  templateUrl: './exam-session.component.html',
  styleUrls: ['./exam-session.component.scss'],
})
export class ExamSessionComponent implements OnInit, OnDestroy {
  exam$: Observable<Exam>;
  examSections$: Observable<ExamSection[]>;
  examSession$: Observable<ExamSession>;
  isDevMode$: Observable<boolean>;

  isReadOnly$: Observable<boolean>;

  totalDuration$: Observable<number>;
  sessionSections$: Observable<ExamSessionSections>;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.examSession$ = this.store.select(selectExamSession);
    this.isDevMode$ = this.store.select(selectIsDevMode);
    this.isReadOnly$ = this.store.select(selectIsReadonly);
    this.totalDuration$ = this.examSession$.pipe(
      filter(utility.isTruthy),
      map(
        (examSession: ExamSessionFinalized) =>
          (examSession.finishedAt ?? examSession.openUntil) -
          examSession.startedAt
      )
    );

    this.exam$ = this.store.select(selectExam);
    this.examSections$ = this.store.select(selectExamSections);

    this.sessionSections$ = this.store.select(selectExamSessionSections);

    this.activatedRoute.url
      .pipe(map((url) => !!url.find((u) => u.path === 'exam-session-review')))
      .subscribe((url) => {
        this.store.dispatch(examActions.setReviewMode({ readonly: url }));
      });
  }

  ngOnDestroy() {
    this.store.dispatch(examActions.examSessionComponentDestroyed());
  }

  isInteractionAttempted = (sectionIndex: number, interactionIndex: number) =>
    this.store.select(
      createSelectIsInteractionAttempted({ sectionIndex, interactionIndex })
    );

  isCorrect = (sectionIndex: number, interactionIndex: number) =>
    this.store.select(
      createSelectIsInteractionCorrect({ sectionIndex, interactionIndex })
    );

  isIncorrect = (sectionIndex: number, interactionIndex: number) =>
    this.store.select(
      createSelectIsInteractionIncorrect({ sectionIndex, interactionIndex })
    );

  onHalfTimeMark = (e: CountdownTimer) => {
    this.store.dispatch(
      showSnackbar({
        message: `Je hebt nog ${e.minutes} minuten over.`,
      })
    );
  };

  onTimeIsUpMark = () => {
    this.store.dispatch(
      showSnackbar({
        message: `De tijd is op!`,
      })
    );
    this.store.dispatch(examActions.submitExamSession());
  };

  onWarningPeriodMark = (e: CountdownTimer) => {
    this.store.dispatch(
      showSnackbar({
        message: `Je hebt nog maar ${e.minutes} minuten over.`,
      })
    );
  };

  openTimeControlDialog() {
    this.dialog.open<TimeTravelDialogComponent>(TimeTravelDialogComponent, {
      hasBackdrop: false,
      position: { bottom: '50px', right: '10px' },
    });
  }
}
