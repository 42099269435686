export enum InteractionModel {
  // countCoins = 'sortObjectsScoreMatch',
  // countCoinsExtra = 'sortObjectsScore',
  // countDragDrop = 'countDragDrop',
  // countDragDropObjects = 'countDragDropObjects',
  // ictTrainer = 'vistaPresentation',
  // information = 'information',
  multipleChoice = 'mPC',
  // multipleResponse = 'multipleResponse',
  openText = 'openText',
  openTextDragDrop = 'openTextDragDrop',
  openTextInImage = 'openMultiImage',
  openTextMultiple = 'openMulti',
  pointAndClick = 'pointAndClick',
  // pointAndClickOrder = 'pointAndClickOrder',
  presentation = 'presentation',
  // sortObjectsMatch = 'match',
  sortObjectsOrder = 'sort',
  // sortObjectsToTargets = 'sortToTargets',
  sortToHotspots = 'sortToHotspots',
}
