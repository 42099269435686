import { Injectable } from '@angular/core';
import {
  AggregateQuerySnapshot,
  DocumentData,
  Query,
} from 'firebase/firestore';

import { Observable, from, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';
export enum PaginationDirection {
  first,
  next,
  prev,
}

export class Pagination {
  constructor(
    public pageSize: number = Number.MAX_VALUE - 1,
    public direction: PaginationDirection = PaginationDirection.first
  ) {}
}

export type PageBounds = {
  first: any;
  last: any;
  total: number;
};

type PagedRequest = {
  queryName: string;
  queryFn: Query<DocumentData>;
  pagination: Pagination;
};

export type PaginatedResponse<T> = {
  data: T[];
  totalCount: number;
};

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  private pageBoundsMap: Map<string, PageBounds> = new Map();
  private countRequest$: Observable<AggregateQuerySnapshot<DocumentData>> =
    new Observable();
  constructor(private firestoreService: FirestoreService) {}

  getPage<T>({
    queryName,
    queryFn,
    pagination,
  }: PagedRequest): Observable<PaginatedResponse<T>> {
    const {
      endBefore,
      getCountFromServer,
      getDocs,
      limit,
      limitToLast,
      startAfter,
      query,
    } = this.firestoreService;
    const pageBounds = this.getPageBounds(queryName);

    let firebaseQuery = queryFn;
    if (pagination?.pageSize > 0) {
      const overlappingPageSize = pagination.pageSize + 1;

      switch (pagination.direction) {
        case PaginationDirection.first:
          firebaseQuery = query(firebaseQuery, limit(overlappingPageSize));
          this.clearPageBounds(queryName);
          break;
        case PaginationDirection.next:
          firebaseQuery = query(
            firebaseQuery,
            startAfter(pageBounds.last),
            limit(overlappingPageSize)
          );
          break;
        case PaginationDirection.prev:
          firebaseQuery = query(
            firebaseQuery,
            endBefore(pageBounds.first),
            limitToLast(overlappingPageSize)
          );
          break;
      }
    }

    this.countRequest$ = from(getCountFromServer(queryFn));

    const result = from(getDocs(firebaseQuery));

    return zip(result, this.countRequest$).pipe(
      tap(([docs, count]) => {
        let bonds = this.getPageBounds(queryName);
        if (docs.docs.length > 0) {
          bonds = {
            first: docs.docs[0],
            last: docs.docs[docs.docs.length - 1],
            total: count.data().count as number,
          };
        } else {
          bonds = {
            first: bonds.last,
            last: bonds.last,
            total: count.data().count as number,
          };
        }
        this.pageBoundsMap.set(queryName, bonds);
        console.log(this.pageBoundsMap);
      }),
      map(
        ([docs, count]) =>
          ({
            data: docs.docs.map((doc) => doc.data()),
            totalCount: count.data().count as number,
          } as PaginatedResponse<T>)
      )
    );
  }

  private clearPageBounds(queryName: string) {
    this.pageBoundsMap.delete(queryName);
  }

  private getPageBounds(key: string) {
    return (
      this.pageBoundsMap.get(key) || {
        first: null,
        last: null,
        total: 0,
      }
    );
  }
}
