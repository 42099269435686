import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { utility } from 'shared/helpers/utility';
import {
  validateOpenFromFE,
  validateOpenToFE,
} from 'shared/validators/valid-exam-date.validator';
import { ExamService } from 'src/app/services/exam.service';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { AppState } from 'src/app/store/reducers';
import { dateAfter } from 'src/app/validators/date-after.validator';
import {
  CreateExamInstancePayload,
  isCreateExamInstancePayload,
} from '../../../../../functions/src/models';
import { errorMessages } from '../../../../../shared/error-messages';
import { hoursMinuteStringToMS } from '../../../../../shared/helpers/time-helper';
import { Exam } from '../../../../../shared/models/exam';
import { AppRouteParams } from '../../../enums/route-params.enum';
import { validTimeString } from '../../../validators/valid-time-string.validator';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../../dialog/dialog.component';

type ExamFormControls = {
  customTitle: FormControl<string>;
  openFrom: FormControl<Date | null>;
  openFromTime: FormControl<string>;
  openTo: FormControl<Date | null>;
  openToTime: FormControl<string>;
  passphrase: FormControl<string>;
  duration: FormControl<number>;
};

@Component({
  selector: 'app-ready-exam',
  templateUrl: './ready-exam.component.html',
  styleUrls: ['./ready-exam.component.scss'],
})
export class ReadyExamComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  form: FormGroup<ExamFormControls>;

  exam: Exam;

  startDateMapped$: Observable<number>;
  endDateMapped$: Observable<number>;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private examService: ExamService,
    private store: Store<AppState>
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(
        switchMap((paramMap) =>
          this.examService.getExam(paramMap.get(AppRouteParams.examId))
        )
      )
      .subscribe((exam) => {
        this.form.controls.duration.setValue(exam.timeLimitMinutes);
        this.exam = exam;
      });

    this.startDateMapped$ = this.getDateValueObservable(
      this.form.controls.openFrom
    );
    this.endDateMapped$ = this.getDateValueObservable(
      this.form.controls.openTo
    );

    // FIXME: this is copy paste logic
    // Link datepickers.
    let lastStartDate: number;
    combineLatest([this.startDateMapped$, this.endDateMapped$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([startDate, endDate]) => {
        if (utility.allAreTruthy([startDate, endDate]) && startDate > endDate) {
          if (lastStartDate === startDate) {
            this.form.controls.openFrom.setValue(new Date(endDate));
          } else {
            this.form.controls.openTo.setValue(new Date(startDate));
          }
        }

        lastStartDate = startDate;
      });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue();
    const openFrom = formValue.openFrom;
    const openFromTime = formValue.openFromTime;
    const openTo = formValue.openTo;
    const openToTime = formValue.openToTime;
    const durationMinutes = formValue.duration;

    // Convert 24h notation to ms
    const fromTimeMs = openFromTime ? hoursMinuteStringToMS(openFromTime) : 0;
    const toTimeMs = openToTime
      ? hoursMinuteStringToMS(openToTime)
      : hoursMinuteStringToMS('23:59');

    // Full date and time
    const from = openFrom.valueOf() + fromTimeMs;
    const to = openTo.valueOf() + toTimeMs;

    const payload: CreateExamInstancePayload = {
      ...formValue,
      examId: this.exam.id,
      openFrom: from,
      openTo: to,
      duration: durationMinutes * 60 * 1000,
    };

    if (!isCreateExamInstancePayload(payload)) {
      console.error('Invalid payload', payload);
      return;
    }

    this.isLoading$.next(true);
    this.examService
      .createExamInstance(payload)
      .then(() => {
        const dialogRef = this.dialog.open<DialogComponent, DialogData>(
          DialogComponent,
          {
            data: {
              title: 'Toets gereed',
              text: `De toets staat klaar voor gebruik.`,
              preset: DialogPreset.close,
            },
          }
        );

        return dialogRef
          .afterClosed()
          .toPromise()
          .then(() => {
            this.router.navigate(['exams-teacher']);
          });
      })
      .catch((error) => {
        if (error.message === errorMessages.passphraseNotUniqueForPeriod) {
          this.store.dispatch(
            showSnackbar({
              message: `Het wachtwoord is al in gebruik voor een andere toets in deze periode.`,
            })
          );
        } else {
          this.store.dispatch(
            showSnackbar({
              message: `Er is iets misgegaan: ${error.message}`,
            })
          );
        }

        console.error(error);
      })
      .finally(() => {
        this.isLoading$.next(false);
      });
  }

  clearFromDate() {
    this.form.controls.openFrom.setValue(null);
  }

  clearToDate() {
    this.form.controls.openTo.setValue(null);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  private initForm() {
    this.form = this.fb.group<ExamFormControls>(
      {
        customTitle: this.fb.control('', {
          validators: Validators.required,
          nonNullable: true,
        }),
        openFrom: this.fb.control<Date | null>(null, {
          validators: [Validators.required, dateAfter(moment().startOf('day'))],
        }),
        openFromTime: this.fb.control('', {
          validators: validTimeString,
          nonNullable: true,
        }),
        openTo: this.fb.control<Date | null>(null, {
          validators: Validators.required,
        }),
        openToTime: this.fb.control('', {
          validators: validTimeString,
          nonNullable: true,
        }),
        passphrase: this.fb.control('', {
          validators: Validators.required,
          nonNullable: true,
        }),
        duration: this.fb.control(0, {
          validators: [Validators.required, Validators.pattern(/^\d{1,3}$/)],
          nonNullable: true,
        }),
      },
      {
        validators: [this.validateOpenFrom(), this.validateOpenTo()],
      }
    );
  }

  private getDateValueObservable(
    control: AbstractControl
  ): Observable<number | null> {
    return control.valueChanges.pipe(
      startWith(control.value),
      debounceTime(1000),
      map((date: Date | null) => date?.valueOf() ?? null),
      takeUntil(this.ngDestroyed$)
    );
  }

  private isExamFormGroup = (
    control: AbstractControl
  ): control is FormGroup<ExamFormControls> =>
    control instanceof FormGroup &&
    'openFrom' in control.controls &&
    'openFromTime' in control.controls &&
    'openTo' in control.controls &&
    'openToTime' in control.controls;

  private validateOpenFrom =
    (): ValidatorFn =>
    (group: AbstractControl): ValidationErrors | null => {
      if (!this.isExamFormGroup(group)) {
        console.error('Unexpected form structure');
        return { unexpectedFormStructure: true };
      }

      const { openFrom, openFromTime } = group.controls;

      if (!validateOpenFromFE(openFrom.value, openFromTime.value)) {
        return { invalidOpenFrom: true };
      }

      return null;
    };

  private validateOpenTo =
    (): ValidatorFn =>
    (group: AbstractControl): ValidationErrors | null => {
      if (!this.isExamFormGroup(group)) {
        console.error('Unexpected form structure');
        return { unexpectedFormStructure: true };
      }

      const { openTo, openToTime } = group.controls;

      if (!validateOpenToFE(openTo.value, openToTime.value)) {
        return { invalidOpenTo: true };
      }

      return null;
    };
}
