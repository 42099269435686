<div mat-dialog-title>{{ this.data.unit.title }} </div>
<div mat-dialog-content>
    <table mat-table [dataSource]="userUnitScores$" class="scores-table">

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>Naam</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Datum/Tijd</th>
            <td mat-cell *matCellDef="let element">
                {{ element.score["timestamp"] | date: dateFormat }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Score">
            <th mat-header-cell *matHeaderCellDef>Score</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.score.timestamp">
                    <app-student-unit-score [unit$]="unit$" [userUnitScore$]="toObservable(element.score)"
                        class="student-unit-score"></app-student-unit-score>
                </ng-container>

                <ng-container *ngIf="!element.score.timestamp">
                    <span>Geen score</span>
                </ng-container>
            </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
</div>