import { Injectable } from '@angular/core';
import { InteractionModel } from 'shared/enums/interaction-model';
import {
  ExamSession,
  ExamSessionInteractionAttempt,
} from 'shared/models/exam-session';
import { Interaction } from 'shared/models/interaction';
import { fieldInputParser } from 'src/app/helpers/field-input-parser';
import { NotepadLineForStorage } from '../../../shared/models/notepad-line';
import { Unit } from '../../../shared/models/unit';
import {
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from '../../../shared/models/user-interaction-attempt';
import { issueIdentifiersAndHandlers } from '../components/import/find-issues';
import { parseTemplate } from '../helpers/template-parser';
import { VariationService } from './variation.service';
import { Rectangle, Shape } from 'shared/types/shapes';
import { utility } from 'shared/helpers/utility';

@Injectable({
  providedIn: 'root',
})
export class InteractionParserService {
  constructor(private variationService: VariationService) {}

  // TODO tests
  async parseInteraction(
    interaction: Interaction,
    unit?: Unit,
    examSessionId?: ExamSession['id'],
    attempt?: InteractionAttempt | StandaloneInteractionAttempt,
    examInteractionAttempt?: ExamSessionInteractionAttempt
  ): Promise<{
    parsedInteraction: Interaction;
    answers: string[];
    lines: NotepadLineForStorage[];
  }> {
    interaction = this.variationService.addShuffledVars(
      interaction,
      unit,
      examSessionId,
      attempt?.varsShuffledIndexes ??
        examInteractionAttempt?.varsShuffledIndexes
    );

    let answers: string[];
    let lines: NotepadLineForStorage[];

    if (!!examInteractionAttempt) {
      answers = examInteractionAttempt.answers;
      lines = examInteractionAttempt.notepadLines;
    }

    if (!!attempt) {
      answers = attempt.answers;
    }

    // TODO: Common typo, this should be removed when all content is updated.
    if (issueIdentifiersAndHandlers.dragElementsTypo(interaction)) {
      issueIdentifiersAndHandlers.fixDragElementsTypo(interaction);
    }

    // TODO: Create new model for parsed interaction?
    const parsedInteraction = parseTemplate(interaction);

    // FIXME this is done in two places, the intr comp also sets answers
    if (parsedInteraction.model === InteractionModel.openTextInImage) {
      parsedInteraction.gap = fieldInputParser(parsedInteraction, answers);
    }

    // TODO restore exam answers
    if (parsedInteraction.optionsImage) {
      parsedInteraction.optionsImage.forEach((option) => {
        option.shape = option.area ? Shape.create(option.area) : null;
      });
    }

    // TODO restore exam answers
    if (parsedInteraction.optionsHotspot) {
      parsedInteraction.optionsHotspot.forEach((option, i) => {
        option.index = i;
      });

      parsedInteraction.hotspots.forEach((hotspot) => {
        hotspot.shape = hotspot.area
          ? (Shape.create(hotspot.area) as Rectangle)
          : null;
      });
    }

    if (parsedInteraction.model === InteractionModel.multipleChoice) {
      // TODO check if we can shuffle these without consequences.
      // This was changed during ngrx refactor.
      if (parsedInteraction.random) {
        utility.randomizeArray(parsedInteraction.optionsText);
      }
    }

    // TODO restore exam answers
    if (parsedInteraction.optionsSortOrder) {
      parsedInteraction.optionsSortOrder.forEach((option, i) => {
        option.position = i + 1;
      });

      if (parsedInteraction.random) {
        utility.randomizeArray(parsedInteraction.optionsSortOrder);
      }
    }

    answers = answers || [];
    lines = lines || [];
    return { parsedInteraction, answers, lines };
  }
}
