<mat-accordion>
  <mat-expansion-panel [expanded]="entityPanelOpenState" (opened)="entityPanelOpenState = true"
    (closed)="entityPanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Import entities {{ isProduction ? 'PROD' : 'DEV' }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #importEntityForm="ngForm" [formGroup]="importEntityFormGroup" novalidate (ngSubmit)="importJsonEntities()"
      autocomplete="off">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <textarea matInput #message #autosize="cdkTextareaAutosize" cdkTextareaAutosize cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="10" placeholder="Paste JSON" formControlName="jsonImport"></textarea>
            <mat-error *ngIf="importEntityFormGroup.controls['jsonImport'].hasError('required')">
              JSON is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="importEntityFormGroup.controls['jsonImport'].hasError('minlength')">
              JSON is <strong>too short</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button title="Download structure as CSV" mat-raised-button (click)="downloadStructure()" type="button">
          <i class="material-icons">account_tree</i>
        </button>
        <button [disabled]="!importEntityFormGroup.valid" mat-raised-button type="submit">Upload JSON</button>
        <button mat-raised-button (click)="validateDb()" [disabled]="validateDbInProgress" type="button">
          Validate DB
        </button>
        <button mat-raised-button (click)="findMissingChildren()" [disabled]="validateChildrenInProgress" type="button">
          Validate children
        </button>
        <button [disabled]="!interactionIssues?.length || !importEntityFormGroup.valid" mat-raised-button
          (click)="autoFixEntityJson()" type="button">
          Auto fix JSON
        </button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="interactionIssues?.length">
  <mat-expansion-panel [expanded]="entityPanelOpenState" (opened)="issuePanelOpenState = true"
    (closed)="issuePanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Possible issues detected
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-table [dataSource]="interactionIssues">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="issueType">
        <mat-header-cell *matHeaderCellDef> Issue type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.issueType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="path">
        <mat-header-cell *matHeaderCellDef> Path </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.path}} </mat-cell>
      </ng-container>

      <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Import schools {{ isProduction ? 'PROD' : 'DEV' }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #importSchoolForm="ngForm" [formGroup]="importSchoolFormGroup" novalidate (ngSubmit)="importSchoolJson()"
      autocomplete="off">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <textarea matInput #message #autosize="cdkTextareaAutosize" cdkTextareaAutosize cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="10" placeholder="Paste JSON" formControlName="jsonImport"></textarea>
            <mat-error *ngIf="importSchoolFormGroup.controls['jsonImport'].hasError('required')">
              JSON is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="importSchoolFormGroup.controls['jsonImport'].hasError('minlength')">
              JSON is <strong>too short</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button [disabled]="!importSchoolFormGroup.valid" mat-raised-button type="submit">Upload school
          JSON</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        List interactions {{ isProduction ? 'PROD' : 'DEV' }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #listInteractionsForm="ngForm" [formGroup]="listInteractionsFormGroup" novalidate
      (ngSubmit)="listInteractions()" autocomplete="off">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <textarea matInput #message #autosize="cdkTextareaAutosize" cdkTextareaAutosize cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="10" placeholder="1, 2, 3" formControlName="interactionIds"></textarea>
            <mat-error *ngIf="listInteractionsFormGroup.controls['interactionIds'].hasError('required')">
              Interaction Ids are <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button mat-raised-button type="submit"
          [disabled]="!listInteractionsFormGroup.valid || listInteractionsInProgress">
          List interactions
        </button>
        <button mat-raised-button (click)="listUnusedInteractions()" [disabled]="listUnusedInteractionsInProgress"
          type="button">
          List unused interactions
        </button>
        <button *ngIf="$userIsDev | async" mat-raised-button color="warn" type="button"
          (click)="deleteInteractions(interactions$)" [disabled]="!(interactions$ | async)?.length || deleteInteractionsInProgress || 
          !listInteractionsFormGroup.controls.interactionIds.value?.length">
          <i class="material-icons">delete</i>
        </button>
        <button [disabled]="!(interactions$ | async)?.length || setDisableInteractionsInProgress" mat-raised-button
          type="button" (click)="setDisableInteractions(interactions$, false)">
          Enable list
        </button>
        <button [disabled]="!(interactions$ | async)?.length || setDisableInteractionsInProgress" mat-raised-button
          type="button" (click)="setDisableInteractions(interactions$, true)">
          Disable list
        </button>
      </mat-action-row>
    </form>

    <mat-table [dataSource]="interactions$ | async" class="interaction-list-table">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.version}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="versionDate">
        <mat-header-cell *matHeaderCellDef> Version date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.versionDate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <mat-header-cell *matHeaderCellDef> Disabled </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="toggleDisableInteraction(element)">
            <mat-icon>{{element.disabled ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row mat-header-row *matHeaderRowDef="displayedInteractionColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: displayedInteractionColumns;">

      </mat-row>
    </mat-table>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="$userIsDev | async">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Import dummy data {{ isProduction ? 'PROD' : 'DEV' }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #importSchoolForm="ngForm" [formGroup]="importDummyDataFormGroup" novalidate
      (ngSubmit)="importDummyDataJson()" autocomplete="off">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <textarea matInput #message #autosize="cdkTextareaAutosize" cdkTextareaAutosize cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="10" placeholder="Paste Attempt[] JSON" formControlName="jsonImport"></textarea>
            <mat-error *ngIf="importDummyDataFormGroup.controls['jsonImport'].hasError('required')">
              JSON is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="importDummyDataFormGroup.controls['jsonImport'].hasError('minlength')">
              JSON is <strong>too short</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button mat-raised-button type="button" (click)="deleteScoresForUsers()">Delete for UIDs</button>
        <button mat-raised-button type="button" (click)="deleteDummyScores()">Delete dummy scores</button>
        <button [disabled]="!importDummyDataFormGroup.valid" mat-raised-button type="submit">Upload dummy data</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</mat-accordion>