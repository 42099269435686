import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TeacherDashboardHighlightService } from 'src/app/services/teacher-dashboard-highlight.service';

@Directive({
  selector: '[appHighlightTeacherDashboardColumn]',
})
export class HighlightTeacherDashboardColumnDirective implements OnDestroy {
  @Input('appHighlightTeacherDashboardColumn') columnIndex: number;
  @Input() unitIndex: number;

  @HostBinding('class.same-unit') isUnitHighlighted = false;
  @HostBinding('class.highlighted') isHighlighted = false;

  private subscription: Subscription;
  private unitSubscription: Subscription;

  constructor(
    private teacherDashboardHighlightService: TeacherDashboardHighlightService
  ) {
    // Subscribe to the hoveredColumnIndex$ observable to react to changes
    this.subscription =
      this.teacherDashboardHighlightService.hoveredColumnIndex$.subscribe(
        (hoveredIndex) => {
          // Compare the directive's columnIndex with the currently hovered column index
          // Set isHighlighted true if they match, false otherwise
          this.isHighlighted = hoveredIndex === this.columnIndex;
        }
      );
    this.unitSubscription =
      this.teacherDashboardHighlightService.hoveredUnitIndex$.subscribe(
        (hoveredIndex) => {
          // Compare the directive's columnIndex with the currently hovered column index
          // Set isHighlighted true if they match, false otherwise
          this.isUnitHighlighted = hoveredIndex === this.unitIndex;
        }
      );
  }

  @HostListener('mouseenter') onMouseEnter() {
    // When the mouse enters, update the hovered column index in the service
    this.teacherDashboardHighlightService.setHoveredColumn(this.columnIndex);
    this.teacherDashboardHighlightService.setHoveredUnit(this.unitIndex);
  }

  @HostListener('mouseleave') onMouseLeave() {
    // Reset the hovered column index when the mouse leaves
    this.teacherDashboardHighlightService.setHoveredColumn(null);
    this.teacherDashboardHighlightService.setHoveredUnit(null);
  }

  ngOnDestroy() {
    // Clean up the subscription to prevent memory leaks
    this.subscription.unsubscribe();
    this.unitSubscription.unsubscribe();
  }
}
