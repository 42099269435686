import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Organization } from 'shared/models/organization';
import { AppUser, dbToAppUser } from 'shared/models/user';
import { constants } from 'shared/constants';
import { userActions } from 'src/app/store/actions/user.actions';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { EducationTrack } from '../../../../shared/models/project';
import { appUserHasAnyOfRoles } from '../../helpers/user-role-helpers';
import { LocalStorageService } from '../../services/local-storage.service';
import {
  selectEducationTrack,
  selectSessionDateTimestamp,
} from './shared.reducer';

export type UserState = Readonly<{
  user: AppUser;
  algoliaOrgKey?: string;
  algoliaUserKey?: string;
  isDevMode: boolean;
  isContentAdminMode: boolean;
  organization: Organization | undefined;
}>;

const initialState: UserState = {
  user: null,
  isDevMode: LocalStorageService.isDevMode(),
  isContentAdminMode: false,
  organization: undefined,
};

const reducer = createReducer(
  initialState,
  // Login
  on(
    userActions.loggedIn,
    (state, payload): UserState => ({
      ...initialState,
      user: payload.user,
    })
  ),
  on(
    userActions.userLoaded,
    (state, payload): UserState => ({
      ...state,
      user: payload.dbUser && dbToAppUser(payload.dbUser),
    })
  ),
  on(userActions.logout, (state): UserState => ({ ...initialState })),

  // Organization
  on(
    userActions.organizationLoaded,
    (state, payload): UserState => ({
      ...state,
      organization: payload.organization,
    })
  ),

  // Algolia
  on(
    userActions.algoliaOrgKeyLoaded,
    (state, payload): UserState => ({
      ...state,
      algoliaOrgKey: payload.algoliaOrgKey,
    })
  ),
  on(
    userActions.algoliaUserKeyLoaded,
    (state, payload): UserState => ({
      ...state,
      algoliaUserKey: payload.algoliaUserKey,
    })
  ),
  on(userActions.unloadAlgoliaUserKey, (state, payload): UserState => {
    const newState = { ...state };
    delete newState.algoliaUserKey;
    return newState;
  }),

  // Dev mode
  on(
    userActions.devModeSet,
    (state, payload): UserState => ({
      ...state,
      isDevMode: payload.isDevMode,
    })
  ),

  // Content admin mode
  on(
    userActions.setContentAdminMode,
    (state, payload): UserState => ({
      ...state,
      isContentAdminMode: payload.contentAdminMode,
    })
  )
);

export const userReducer = (state: UserState | undefined, action: Action) =>
  reducer(state, action);

// Selectors
export const selectUserDataState = createFeatureSelector<UserState>('user');

export const selectUser = createSelector(
  selectUserDataState,
  (state: UserState) => state.user
);

export const selectUserUid = createSelector(
  selectUserDataState,
  (state: UserState) => state.user?.uid
);

export const selectUserRoles = createSelector(
  selectUser,
  (user: AppUser) => user?.roles
);

const userHasRole = createSelector(
  selectUser,
  (user: AppUser) => (role: UserRole) => !!user?.roles?.has(role)
);

export const selectUserIsAdmin = createSelector(userHasRole, (fun) =>
  fun(UserRole.admin)
);

export const selectUserIsDev = createSelector(userHasRole, (fun) =>
  fun(UserRole.developer)
);

export const selectUserIsTeacher = createSelector(userHasRole, (fun) =>
  fun(UserRole.teacher)
);

export const selectUserOrganizationHardLink = createSelector(
  selectUser,
  (user: AppUser) => user?.organizationHardLink
);

export const selectUserOrganizationId = createSelector(
  selectUser,
  (user: AppUser) => user?.organizationId
);

export const selectUserTeachers = createSelector(
  selectUser,
  (user: AppUser) => user?.teachers || []
);

export const selectIsDbUserLoaded = createSelector(
  selectUser,
  (user: AppUser) => !!user?.createdDate
);

export const selectAlgoliaOrgKey = createSelector(
  selectUserDataState,
  (userState) => userState?.algoliaOrgKey
);

export const selectAlgoliaUserKey = createSelector(
  selectUserDataState,
  (userState) => userState?.algoliaUserKey
);

export const selectUserNameIsIncomplete = createSelector(
  selectUser,
  selectIsDbUserLoaded,
  (user, userLoaded) => userLoaded && (!user?.lastName || !user?.firstName)
);

const hasAllowedRoles = (state: UserState, roles: UserRole[]): boolean => {
  if (!state.user) {
    return false;
  }

  return appUserHasAnyOfRoles(state.user, roles);
};

export const selectIsDevModeAllowed = createSelector(
  selectUserDataState,
  (state: UserState) => {
    const allowedRoles = [UserRole.admin, UserRole.developer];
    return hasAllowedRoles(state, allowedRoles);
  }
);

export const selectIsDevMode = createSelector(
  selectUserDataState,
  selectIsDevModeAllowed,
  (state: UserState, isDevModeAllowed) => !!state.isDevMode && isDevModeAllowed
);

export const selectIsContentAdminModeAllowed = createSelector(
  selectUserDataState,
  (state: UserState) => {
    const allowedRoles = [UserRole.admin, UserRole.developer];
    return hasAllowedRoles(state, allowedRoles);
  }
);

export const selectIsContentAdminMode = createSelector(
  selectUserDataState,
  selectIsContentAdminModeAllowed,
  (state: UserState, isContentAdminModeAllowed) =>
    !!state.isContentAdminMode && isContentAdminModeAllowed
);

export const selectUserOrganization = createSelector(
  selectUserDataState,
  (state: UserState) => state.organization
);

export const selectIsUserOrganizationLoaded = createSelector(
  selectIsDbUserLoaded,
  selectUser,
  selectUserOrganization,
  (userLoaded: boolean, user: AppUser, organization: Organization) => {
    if (!userLoaded) {
      return false;
    }

    if (!user.organizationId) {
      return true;
    }

    return user.organizationId === organization?.id;
  }
);

const isUserExemptFromLicense = (
  user: AppUser,
  organization: Organization | undefined
): boolean =>
  appUserHasAnyOfRoles(user, constants.licenseExemptRoles) ||
  organization?.licenseExempt;

const isAccountWithinGracePeriod = (
  user: AppUser,
  sessionDateTimestamp: number,
  gracePeriod: number
): boolean => {
  const userCreatedTimestamp = Date.parse(user.createdDate);
  return sessionDateTimestamp < userCreatedTimestamp + gracePeriod;
};

export const selectShouldShowLicenseWarning = createSelector(
  selectIsUserOrganizationLoaded,
  selectUser,
  selectUserOrganization,
  selectEducationTrack,
  selectSessionDateTimestamp,
  (
    organizationLoaded,
    user,
    userOrganization,
    educationTrack,
    sessionDateTimestamp
  ) => {
    if (!organizationLoaded) {
      return false;
    }

    if (isUserExemptFromLicense(user, userOrganization)) {
      return false;
    }

    const accessUntilTimestamp = getAccessUntilTimestamp(user, educationTrack);

    if (accessUntilTimestamp === null) {
      return true;
    }

    if (
      accessUntilTimestamp - constants.licenseWarningTimeFrame >
      sessionDateTimestamp
    ) {
      return false;
    }

    return true;
  }
);

export const selectUserHasActiveLicenseForCurrentEducationTrack =
  createSelector(
    selectIsUserOrganizationLoaded,
    selectUser,
    selectEducationTrack,
    selectSessionDateTimestamp,
    (organizationLoaded, user, educationTrack, sessionDateTimestamp) => {
      if (!organizationLoaded) {
        return true;
      }

      const accessUntilTimestamp = getAccessUntilTimestamp(
        user,
        educationTrack
      );

      if (accessUntilTimestamp === null) {
        return false;
      }

      return accessUntilTimestamp > sessionDateTimestamp;
    }
  );

export const selectUserIsExemptFromLicense = createSelector(
  selectUser,
  selectUserOrganization,
  (user, organization) => !!user && isUserExemptFromLicense(user, organization)
);

export const selectUserHasAppAccess = createSelector(
  selectIsUserOrganizationLoaded,
  selectUserHasActiveLicenseForCurrentEducationTrack,
  selectUserIsExemptFromLicense,
  selectUser,
  selectSessionDateTimestamp,
  (
    isOrganizationLoaded,
    hasActiveLicense,
    isExemptFromLicense,
    user,
    sessionDateTimestamp
  ) => {
    if (!isOrganizationLoaded) {
      return true;
    }

    if (isExemptFromLicense) {
      return true;
    }

    if (hasActiveLicense) {
      return true;
    }

    if (
      isAccountWithinGracePeriod(
        user,
        sessionDateTimestamp,
        constants.licenseWarningTimeFrame
      )
    ) {
      return true;
    }

    return false;
  }
);

// Helper function to get the accessUntilTimestamp
const getAccessUntilTimestamp = (
  user: AppUser,
  educationTrack: EducationTrack
): number | null => {
  if (educationTrack === EducationTrack.vmbo) {
    return user.vmboAccessUntil ? Date.parse(user.vmboAccessUntil) : null;
  } else {
    return user.licensedAccessUntil
      ? Date.parse(user.licensedAccessUntil)
      : null;
  }
};

export const selectVmboLicenseDate = createSelector(
  selectUser,
  (user: AppUser) => {
    if (!user || !user.vmboAccessUntil) {
      return null;
    }
    return new Date(user.vmboAccessUntil);
  }
);

export const selectMboLicenseDate = createSelector(
  selectUser,
  (user: AppUser) => {
    if (!user || !user.licensedAccessUntil) {
      return null;
    }
    return new Date(user.licensedAccessUntil);
  }
);

export const selectUserFavoriteGroups = createSelector(
  selectUser,
  (user) => user?.favGroups || []
);
