import { EntityId } from '../models/entity-id';

const idFilter = (arr: EntityId[], idToRemove: EntityId) =>
  arr.filter((id) => id !== idToRemove);

const idChecker = (arr: EntityId[], idToCheck: EntityId) =>
  arr.some((id) => id === idToCheck);

export const createIdFilter = (idToRemove: EntityId) => (arr: EntityId[]) =>
  idFilter(arr, idToRemove);

export const createIdChecker = (idToCheck: EntityId) => (arr: EntityId[]) =>
  idChecker(arr, idToCheck);
