import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { utility } from './utility';

export class VideoHelper {
  private videoElement$: BehaviorSubject<HTMLVideoElement> =
    new BehaviorSubject(null);
  private hasSource = false;

  linkPlayer(videoElement: HTMLVideoElement | null) {
    this.hasSource = false;
    this.videoElement$.next(videoElement);
  }

  play(src: string = null) {
    const videoEl = this.videoElement$.getValue();

    if (src && !videoEl) {
      this.videoElement$.pipe(first(utility.isTruthy)).subscribe(() => {
        this.play(src);
      });
      return;
    }

    if (src) {
      this.hasSource = true;
      videoEl.src = src;
      videoEl.play().catch((e) => {});
    } else if (this.hasSource) {
      videoEl.play().catch((e) => {});
    }
  }

  load(src: string) {
    const videoEl = this.videoElement$.getValue();

    if (src && !videoEl) {
      this.videoElement$.pipe(first(utility.isTruthy)).subscribe(() => {
        this.load(src);
      });
      return;
    }

    this.hasSource = true;
    videoEl.src = src;
  }

  pause() {
    this.videoElement$.getValue()?.pause();
  }

  isPaused() {
    return this.videoElement$.getValue()?.paused;
  }

  togglePlay() {
    this.videoElement$.pipe(first(utility.isTruthy)).subscribe((videoEl) => {
      if (videoEl.paused) {
        this.play();
      } else {
        this.pause();
      }
    });
  }

  getPlayTime() {
    return this.videoElement$.getValue()?.currentTime;
  }

  ended() {
    return this.videoElement$.getValue()?.ended;
  }
}
