/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { EntityType } from 'shared/enums/entity-type';
import { UserChapterScore } from 'shared/models/user-chapter-score';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseService } from 'src/app/services/database.service';
import { scoreActions } from 'src/app/store/actions/score.actions';
import { structureActions } from 'src/app/store/actions/structure.actions';
import { Interaction } from '../../../../shared/models/interaction';
import {
  StructuralEntity,
  castToStructuralEntity,
} from '../../../../shared/models/structural-entity';
import { Unit } from '../../../../shared/models/unit';
import { UserDomainScore } from '../../../../shared/models/user-domain-score';
import { UserProjectScore } from '../../../../shared/models/user-project-score';
import {
  DraftUserUnitScore,
  buildUserUnitScoreFromDraft,
  isUserUnitScore,
} from '../../../../shared/models/user-unit-score';
import { LocalStorageService } from '../../services/local-storage.service';
import { StructureService } from '../../services/structure.service';
import { userActions } from '../actions/user.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private databaseService: DatabaseService,
    private structureService: StructureService,
    private authService: AuthService
  ) {}

  loadEntitiesFor = createEffect(() =>
    this.actions$.pipe(
      ofType(structureActions.loadChildrenForEntity),
      switchMap(({ entityId, entityType }) =>
        this.structureService.getChildrenForEntity(entityId, entityType).pipe(
          map((activeStructure) => {
            if (
              activeStructure.some(
                ({ type }) =>
                  type === EntityType.unit || type === EntityType.interaction
              )
            ) {
              activeStructure = activeStructure.map(
                (item: Unit | Interaction) => castToStructuralEntity(item)
              );
            }

            return structureActions.renewActiveStructure({
              activeStructure: activeStructure as StructuralEntity[],
            });
          })
        )
      )
    )
  );
  loadEntitiesOfType = createEffect(() =>
    this.actions$.pipe(
      ofType(structureActions.loadEntitiesOfType),
      switchMap(({ entityType }) =>
        this.structureService
          .getEntitiesOfType(entityType)
          .pipe(
            map((activeStructure) =>
              structureActions.renewActiveStructure({ activeStructure })
            )
          )
      )
    )
  );

  loadProjectsForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(structureActions.loadProjectsForUser),
      switchMap(({ user }) =>
        this.structureService
          .getProjectsForUser(user)
          .pipe(
            map((activeStructure) =>
              structureActions.renewActiveStructure({ activeStructure })
            )
          )
      )
    )
  );

  loadLastUserInteractionAttempt = createEffect(() =>
    this.actions$.pipe(
      ofType(structureActions.loadProjectsForUser),
      switchMap(({ user }) =>
        this.databaseService.getLastUserInteractionAttempt(user.uid).pipe(
          filter((attempt) => !!attempt),
          map(({ projectId, domainId, chapterId }) =>
            structureActions.lastUsedLocationLoaded({
              projectId,
              domainId,
              chapterId,
            })
          )
        )
      )
    )
  );

  fetchAndLoadUserUnitScore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scoreActions.loadUserUnitScore),
      mergeMap((scoreParams) =>
        this.databaseService.getUserScoreForEntity(scoreParams).pipe(
          switchMap((userUnitScore) => {
            if (isUserUnitScore(userUnitScore)) {
              return of(scoreActions.userUnitScoreLoaded({ userUnitScore }));
            } else {
              return this.structureService
                .getUnit((userUnitScore as DraftUserUnitScore).unitId)
                .pipe(
                  map((unit) =>
                    scoreActions.userUnitScoreLoaded({
                      userUnitScore: buildUserUnitScoreFromDraft(
                        userUnitScore as DraftUserUnitScore,
                        unit
                      ),
                    })
                  )
                );
            }
          })
        )
      )
    )
  );

  // TODO merge these into one
  loadUserChapterScore = createEffect(() =>
    this.actions$.pipe(
      ofType(scoreActions.loadUserChapterScore),
      mergeMap((scoreParams) =>
        this.databaseService.getUserScoreForEntity(scoreParams).pipe(
          map((userChapterScore) =>
            scoreActions.userChapterScoreLoaded({
              userChapterScore: userChapterScore as UserChapterScore,
            })
          )
        )
      )
    )
  );

  loadUserDomainScore = createEffect(() =>
    this.actions$.pipe(
      ofType(scoreActions.loadUserDomainScore),
      mergeMap((scoreParams) =>
        this.databaseService.getUserScoreForEntity(scoreParams).pipe(
          map((userDomainScore) =>
            scoreActions.userDomainScoreLoaded({
              userDomainScore: userDomainScore as UserDomainScore,
            })
          )
        )
      )
    )
  );

  loadUserProjectScore = createEffect(() =>
    this.actions$.pipe(
      ofType(scoreActions.loadUserProjectScore),
      mergeMap((scoreParams) =>
        this.databaseService.getUserScoreForEntity(scoreParams).pipe(
          map((userProjectScore) =>
            scoreActions.userProjectScoreLoaded({
              userProjectScore: userProjectScore as UserProjectScore,
            })
          )
        )
      )
    )
  );

  getAlgoliaOrgKey = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getAlgoliaOrgKey),
      switchMap(() =>
        this.databaseService
          .getAlgoliaOrgKey()
          .then((algoliaOrgKey) =>
            userActions.algoliaOrgKeyLoaded(algoliaOrgKey)
          )
      )
    )
  );

  getAlgoliaUserKey = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getAlgoliaUserKey),
      switchMap(() =>
        this.databaseService
          .getAlgoliaUserKey()
          .then((algoliaUserKey) =>
            userActions.algoliaUserKeyLoaded(algoliaUserKey)
          )
      )
    )
  );

  updateLastLogin = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loggedIn),
        switchMap(() => this.databaseService.updateLastLogin())
      ),
    { dispatch: false }
  );

  getDbUser = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loggedIn),
      switchMap(({ user: { uid } }) =>
        this.databaseService.getUser(uid).pipe(
          // We filter out lastSeen because this triggers unneeded re-renders
          distinctUntilChanged((prev, cur) => {
            const a = { ...prev };
            delete a.lastSeen;
            const b = { ...cur };
            delete b.lastSeen;
            return JSON.stringify(a) === JSON.stringify(b);
          }),
          map((dbUser) => userActions.userLoaded({ dbUser }))
        )
      )
    )
  );

  setDevMode = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setDevMode),
      map(({ isDevMode }) =>
        userActions.devModeSet(LocalStorageService.setDevMode(isDevMode))
      )
    )
  );

  getUserOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.userLoaded),
      // Only fetch organization if organizationId has changed
      distinctUntilChanged(
        (prev, cur) => prev.dbUser.organizationId === cur.dbUser.organizationId
      ),
      switchMap(({ dbUser }) => {
        const organizationId = dbUser.organizationId;
        if (!organizationId) {
          return of(
            userActions.organizationLoaded({ organization: undefined })
          );
        }

        return this.databaseService.getOrganization(organizationId).pipe(
          map((organization) =>
            userActions.organizationLoaded({ organization })
          ),
          catchError((error) => {
            console.error('Failed to load organization:', error);
            return EMPTY;
          })
        );
      })
    )
  );

  // Effect to impersonate a user with the given uid
  impersonateUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.impersonate),
        switchMap(({ uid }) =>
          this.databaseService
            .impersonate(uid)
            .then((token) => this.authService.tokenSignIn(token))
        )
      ),
    { dispatch: false }
  );
}
