import { UserRole } from 'src/app/enums/user-role.enum';
import { AppUser } from 'src/app/models/user';

export const getAppUserRoles = (appUser: AppUser): Set<UserRole> =>
  new Set<UserRole>(appUser.roles);

export const appUserHasRole = (appUser: AppUser, role: UserRole): boolean => {
  const userRoles = getAppUserRoles(appUser);
  return userRoles.has(role);
};

export const appUserHasAnyOfRoles = (
  appUser: AppUser,
  roles: UserRole[]
): boolean => {
  const userRoles = getAppUserRoles(appUser);
  return roles.some((role) => userRoles.has(role));
};
