import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { processInteractionExportText } from 'src/app/components/export-convertor/export-convertor-helpers';
import { downloadJSON } from '../../helpers/download-helper';
import { getDatePartFromISOString } from '../../helpers/time-helper';
import { showSnackbar } from '../../store/actions/snackbar.actions';
import { AppState } from '../../store/reducers';

@Component({
  selector: 'app-export-convertor',
  templateUrl: './export-convertor.component.html',
  styleUrls: ['./export-convertor.component.scss'],
})
export class ExportConvertorComponent implements OnInit {
  textAreaContent = '';
  convertedResults = [];
  showDownloadButton = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  convert() {
    try {
      const input = this.textAreaContent;

      // Split different interactions
      const interactionTexts = input.split('\nFile: ');

      // Throw away the first part
      interactionTexts.shift();

      this.convertedResults = interactionTexts.map(
        processInteractionExportText
      );

      // Enable download button
      this.showDownloadButton = true;
    } catch (e) {
      alert('Error: ' + e.message);
      console.error(e);
    }
  }

  downloadResults() {
    const filename =
      `export-${this.convertedResults[0].id}-${
        this.convertedResults[this.convertedResults.length - 1].id
      }_` + `${getDatePartFromISOString(new Date().toISOString())}`;

    downloadJSON(this.convertedResults, filename);
  }

  copyToClipboard() {
    const jsonString = JSON.stringify(this.convertedResults, null, 2);
    navigator.clipboard.writeText(jsonString).then(
      () => {
        this.store.dispatch(
          showSnackbar({
            message: 'Copied to clipboard',
            action: 'Close',
            duration: 2000,
          })
        );
      },
      (err) => {
        console.error('Could not copy text: ', err);
        this.store.dispatch(
          showSnackbar({ message: 'Failed to copy', action: 'Close' })
        );
      }
    );
  }
}
