<h1 mat-dialog-title>Kies je docenten</h1>

<mat-dialog-content>
    <mat-list>
        <mat-list-item *ngFor="let teacher of userTeachers$ | async">{{ teacher.name }}
            <mat-icon *ngIf="!teacher.beingRemoved" class="remove-teacher" (click)="removeTeacher(teacher.uid)">close
            </mat-icon>
            <mat-spinner *ngIf="teacher.beingRemoved" diameter="18"></mat-spinner>
        </mat-list-item>
    </mat-list>
    <br />
    <ais-instantsearch *ngIf="searchConfig" [config]="searchConfig">
        <app-auto-complete [label]="'Docent'" (outputSelected)="setQuery($event)"></app-auto-complete>
        <ais-configure [searchParameters]="searchParameters"></ais-configure>
    </ais-instantsearch>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
    <button mat-raised-button [disabled]="(canAddTeacher$ | async) !== true" (click)="addTeacher()">Voeg toe</button>
</mat-dialog-actions>