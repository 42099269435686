import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from 'src/app/components/dialog/dialog.component';
import {
  dialogWidth,
  ExamFinishedComponent,
} from 'src/app/components/exams/exam-finished/exam-finished.component';
import { errorMessages } from 'src/app/helpers/error-messages';
import { ExamService } from 'src/app/services/exam.service';
import { TimeService } from 'src/app/services/time-service.interface';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import {
  selectAnswerContext,
  selectCurrentExamInteractionId,
  selectCurrentExamSectionIndex,
  selectCurrentExamSectionInteractionIndex,
  selectCurrentExamSessionInteractionAttempt,
  selectExam,
  selectExamSessionId,
  selectIsExamSession,
  selectIsReadonly,
} from 'src/app/store/reducers/exam.reducer';
import { encryptExamSessionInteractionAttempt } from '../../../../../shared/helpers/encrypt-exam-session-attempt';
import { examActions } from '../../actions/exam.actions';
import { interactionActions } from '../../actions/interaction.actions';
import { notepadActions } from '../../actions/notepad.actions';
import { setIsLoading } from '../../actions/shared.actions';
import { AppState } from '../../reducers/index';
import {
  selectIsAllowedToSaveAnswer,
  selectSaveExamAttemptPayloadWithoutTimeTaken,
  selectStartTimestamp,
} from '../../reducers/interaction.reducer';
import { selectNotepadLinesForStorageAsArray } from '../../reducers/notepad.reducer';

@Injectable()
export class ExamEffects {
  loadExam = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.loadExam),
      switchMap(({ examId }) =>
        this.examService
          .getExam(examId)
          .pipe(map((exam) => examActions.examLoaded({ exam })))
      )
    )
  );

  loadExamInstance = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.loadExamInstance),
      switchMap(({ examInstanceId }) =>
        from(this.examService.getExamInstanceById(examInstanceId)).pipe(
          map((examInstance) =>
            examActions.examInstanceLoaded({ examInstance })
          )
        )
      )
    )
  );

  loadExamSession = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.loadExamSession),
      switchMap(({ examSessionId }) =>
        this.examService.getExamSession(examSessionId).pipe(
          map((examSession) => examActions.examSessionLoaded({ examSession })),
          catchError((error) =>
            of(examActions.loadExamSessionFailed({ message: error.message }))
          )
        )
      )
    )
  );

  startExamSession = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.startExamSession),
      withLatestFrom(this.store.select(selectExamSessionId)),
      switchMap(([, examSessionId]) =>
        from(this.examService.startExamSession(examSessionId)).pipe(
          map((session) =>
            examActions.examSessionStarted({ examSession: session })
          ),
          catchError((error) => {
            if (error.message === errorMessages.examSessionAlreadyOpen) {
              return of(examActions.examSessionStarted({ examSession: null }));
            } else {
              this.showErrorDialog({ message: error.message });
              return of(
                examActions.startExamSessionFailed({ message: error.message })
              );
            }
          })
        )
      )
    )
  );

  submitExamSession = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.submitExamSession),
      withLatestFrom(this.store.select(selectExamSessionId)),
      switchMap(([, examSessionId]) => {
        this.store.dispatch(setIsLoading(true));
        return from(this.examService.submitExamSession(examSessionId)).pipe(
          map((examSessionResult) =>
            examActions.examSessionCompleted({ examSessionResult })
          ),
          catchError((error) => {
            this.store.dispatch(showSnackbar({ message: error.message }));
            return of(
              examActions.submitExamSessionFailed({ message: error.message })
            );
          }),
          finalize(() => {
            this.store.dispatch(setIsLoading(false));
          })
        );
      })
    )
  );

  showExamSessionScoreToStudent = createEffect(
    () =>
      this.actions$.pipe(
        ofType(examActions.examSessionCompleted),
        withLatestFrom(this.store.select(selectExam)),
        switchMap(([{ examSessionResult }, exam]) => {
          if (examSessionResult) {
            return this.dialog
              .open(ExamFinishedComponent, {
                data: {
                  ...examSessionResult,
                  hideGrade: exam.hideGrade,
                },
                width: dialogWidth,
                disableClose: true,
              })
              .afterClosed();
          } else {
            return of(null);
          }
        }),
        map(() => {
          this.router.navigate(['exams-student']);
        })
      ),
    { dispatch: false }
  );

  storeShuffledIndexes = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.interactionParsed),
      withLatestFrom(
        this.store.select(selectIsReadonly),
        this.store.select(selectExamSessionId),
        this.store.select(selectCurrentExamSessionInteractionAttempt),
        this.store.select(selectCurrentExamInteractionId),
        this.store.select(selectCurrentExamSectionIndex),
        this.store.select(selectCurrentExamSectionInteractionIndex)
      ),
      filter(
        ([
          _,
          isReadonly,
          examSessionId,
          sessionInteractionAttempt,
          interactionId,
          sectionIndex,
          interactionIndex,
        ]) =>
          !!examSessionId &&
          !sessionInteractionAttempt?.varsShuffledIndexes &&
          !isReadonly
      ),
      switchMap(
        ([
          interaction,
          isReadonly,
          examSessionId,
          _,
          interactionId,
          sectionIndex,
          interactionIndex,
        ]) =>
          of(
            examActions.storeShuffledVarsIndexes({
              sessionId: examSessionId,
              sectionIndex,
              interactionIndex,
              interactionId,
              varsShuffledIndexes:
                interaction.parsedInteraction.varsShuffledIndexes,
            })
          )
      )
    )
  );

  setReadonlyOnInteraction = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.interactionParsed),
      withLatestFrom(this.store.select(selectIsReadonly)),
      switchMap(([_, isReadonly]) => [
        interactionActions.setIsReadonlyMode(isReadonly),
        notepadActions.isReadonlyMode({
          isReadonlyMode: isReadonly,
        }),
      ])
    )
  );

  storeIndexes = createEffect(() =>
    this.actions$.pipe(
      ofType(examActions.storeShuffledVarsIndexes),
      switchMap(
        ({
          sessionId,
          sectionIndex,
          interactionIndex,
          interactionId,
          varsShuffledIndexes,
        }) =>
          from(
            this.examService
              .storeExamInteractionShuffledVars(
                sessionId,
                sectionIndex,
                interactionIndex,
                interactionId,
                varsShuffledIndexes
              )
              .pipe(
                map(() => examActions.shuffleVarsStored()),
                catchError((error) => {
                  this.store.dispatch(showSnackbar({ message: error.message }));
                  return of(
                    examActions.shuffleVarsStoredFailed({
                      message: error.message,
                    })
                  );
                })
              )
          )
      )
    )
  );

  storeExamInteractionAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(interactionActions.handleAnswer),
        withLatestFrom(
          this.store.select(selectSaveExamAttemptPayloadWithoutTimeTaken),
          this.store.select(selectIsAllowedToSaveAnswer),
          this.store.select(selectIsExamSession),
          this.store.select(selectStartTimestamp),
          this.store.select(selectAnswerContext),
          this.store.select(selectNotepadLinesForStorageAsArray)
        ),
        filter(
          ([
            action,
            saveExamAttemptPayloadWithoutTimeTaken,
            isAllowedToSaveAnswer,
            isExamSession,
            startTimestamp,
            answerContext,
            notepadLines,
          ]) => isAllowedToSaveAnswer && isExamSession
        ),
        tap(() => {
          this.store.dispatch(
            interactionActions.setStartTime(this.timeService.now())
          );
          this.store.dispatch(interactionActions.setIsSavingAnswer(true));
        }),
        map(
          ([
            action,
            saveExamAttemptPayloadWithoutTimeTaken,
            isAllowedToSaveAnswer,
            isExamSession,
            startTimestamp,
            answerContext,
            notepadLines,
          ]) => ({
            examAnswerPayload: {
              ...saveExamAttemptPayloadWithoutTimeTaken,
              timeTaken: this.timeService.now() - startTimestamp,
              timestamp: this.timeService.now(),
              notepadLines,
            },
            answerContext,
          })
        ),
        tap(({ examAnswerPayload, answerContext }) => {
          this.store.dispatch(
            examActions.handleExamInteractionAnswer({
              sectionIndex: answerContext.examSectionIndex,
              interactionIndex: answerContext.examInteractionIndex,
              attempt: examAnswerPayload,
            })
          );
        }),
        switchMap(({ examAnswerPayload, answerContext }) =>
          from(
            this.examService.saveExamAnswer({
              answerContext,
              encryptedInteractionAttempt:
                encryptExamSessionInteractionAttempt(examAnswerPayload),
            })
          ).pipe(
            catchError((error) => {
              console.error(error);
              this.store.dispatch(
                showSnackbar({
                  message: 'Er was een probleem met opslaan',
                  action: 'Ok',
                })
              );
              return EMPTY;
            }),
            finalize(() => {
              this.store.dispatch(interactionActions.setIsSavingAnswer(false));
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private examService: ExamService,
    @Inject(TIME_SERVICE) private timeService: TimeService,

    private router: Router
  ) {}

  private showErrorDialog({ message }: { message: string }) {
    this.dialog.open<DialogComponent, DialogData>(DialogComponent, {
      data: {
        title: 'Er ging iets mis',
        text: message,
        preset: DialogPreset.ok,
      },
    });
  }
}
