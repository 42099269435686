<ng-container *ngIf="!unitData">
    <div class="skeleton-title"> </div>
    <div class="skeleton"> </div>
</ng-container>

<ng-container *ngIf="unitData">
    <h3>{{unitData?.unit.title}}</h3>
    <svg class="unit-progress" viewBox="0 0 1240 170" xmlns="http://www.w3.org/2000/svg" stroke-width="3">
        <polyline id="slowLane" *ngIf="unitData?.unit.subtype !== 'thematic'" [attr.points]="getExtraLaneCoordinates()"
            fill="none" stroke="black" stroke-dasharray="5,5" fill-opacity="null" stroke-opacity="50%" />

        <use transform="translate(550, 20) scale(0.15)" *ngIf="unitData?.unit.subtype !== 'thematic'"
            xlink:href="../assets/svg/running.svg#running-man" fill="rgb(32, 92, 135)" />
        <polyline id="fastLane" *ngIf="unitData?.unit.subtype !== 'thematic'" [attr.points]="getFastLaneCoordinates()"
            fill="none" stroke="black" stroke-dasharray="5,5" fill-opacity="null" stroke-opacity="50%" />

        <line stroke-linecap="null" stroke-linejoin="null" y2="100" [attr.x2]="(unitData?.unit.introduction.length + 
            unitData?.unit.extra.length + 
            unitData?.unit.deepening.length + 
            unitData?.unit.test.length) * 50 + (unitData?.unit.subtype === 'thematic' && 280 || 410)" y1="100" x1="100"
            fill-opacity="null" stroke-opacity="null" fill="none" />

        <g fill="#fff">
            <g class="start" (click)="clickStart()">
                <circle r="42" cy="100" cx="70" stroke-width="4" />
                <use transform="translate(60, 79.5)" xlink:href="../assets/svg/play.svg#play" />
            </g>
            <g class="unit-block presentation" transform="translate(178)">
                <circle r="24" cy="100" cx="0" />
                <use transform="translate(-15, 85) scale(0.055)" xlink:href="../assets/svg/video.svg#video" />
            </g>
            <g class="unit-block introduction" transform="translate(270)">
                <g *ngFor="let interaction of unitData?.unit.introduction, let i = index">
                    <circle r="20" cy="100" [attr.cx]="i * distanceBetweenCircles"
                        [ngClass]="unitData?.userUnitScoreMapped[unitContexts.introduction][i]" />

                    <use *ngIf="unitData?.userUnitScoreMapped[unitContexts.introduction][i] === 'fill-red'"
                        class="score-icon" [attr.x]="i * distanceBetweenCircles" y="0"
                        xlink:href="../assets/svg/cross.svg#cross" />
                </g>
                <ng-container *ngIf="unitData && unitData.unit.subtype !== 'thematic'">
                    <text [attr.x]="((unitData?.unit.introduction.length-1)*distanceBetweenCircles)/2" y="165">
                        Introductie
                    </text>
                </ng-container>
            </g>
            <g class="unit-block extra"
                [attr.transform]="'translate('+(unitData?.unit.extra.length * 50 + 210 + 100)+')'">
                <g *ngFor="let interaction of unitData?.unit.extra, let i = index">
                    <circle r="20" cy="100" [attr.cx]="i * distanceBetweenCircles"
                        [ngClass]="unitData?.userUnitScoreMapped[unitContexts.extra][i]" />

                    <use *ngIf="unitData?.userUnitScoreMapped[unitContexts.extra][i] === 'fill-red'" class="score-icon"
                        [attr.x]="i * distanceBetweenCircles" y="0" xlink:href="../assets/svg/cross.svg#cross" />
                </g>
                <ng-container *ngIf="unitData && unitData.unit.subtype !== 'thematic'">
                    <text [attr.x]="((unitData?.unit.extra.length-1)*distanceBetweenCircles)/2" y="165">
                        Extra Oefenen
                    </text>
                </ng-container>
            </g>
            <g class="unit-block deepening"
                [attr.transform]="'translate('+((unitData?.unit.introduction.length + unitData?.unit.extra.length) * 50 + 210 + 140)+')'">
                <g *ngFor="let interaction of unitData?.unit.deepening, let i = index">
                    <circle r="20" cy="100" [attr.cx]="i * distanceBetweenCircles"
                        [ngClass]="unitData?.userUnitScoreMapped[unitContexts.deepening][i]" />

                    <use *ngIf="unitData?.userUnitScoreMapped[unitContexts.deepening][i] === 'fill-red'"
                        class="score-icon" [attr.x]="i * distanceBetweenCircles" y="0"
                        xlink:href="../assets/svg/cross.svg#cross" />

                </g>
                <ng-container *ngIf="unitData && unitData.unit.subtype !== 'thematic'">
                    <text [attr.x]="((unitData?.unit.deepening.length-1)*distanceBetweenCircles)/2" y="165">
                        Verdieping
                    </text>
                </ng-container>
            </g>
            <g class="unit-block test" [attr.transform]="'translate('+((unitData?.unit.introduction.length + 
                unitData?.unit.extra.length + 
                unitData?.unit.deepening.length) * distanceBetweenCircles + 390)+')'">
                <g *ngFor="let interaction of unitData?.unit.test, let i = index">
                    <circle r="20" cy="100" [attr.cx]="i * distanceBetweenCircles"
                        [ngClass]="unitData?.userUnitScoreMapped[unitContexts.test][i]" />

                    <use *ngIf="unitData?.userUnitScoreMapped[unitContexts.test][i] === 'fill-red'" class="score-icon"
                        [attr.x]="i * distanceBetweenCircles" y="0" xlink:href="../assets/svg/cross.svg#cross" />
                </g>

                <ng-container *ngIf="unitData && unitData.unit.subtype !== 'thematic'">
                    <text [attr.x]="((unitData?.unit.test.length-1)*distanceBetweenCircles)/2" y="165">
                        Toets
                    </text>
                </ng-container>
            </g>

            <g [attr.transform]="'translate('+((unitData?.unit.introduction.length + 
            unitData?.unit.extra.length + 
            unitData?.unit.deepening.length + 
            unitData?.unit.test.length) * distanceBetweenCircles + 
            (unitData?.unit.subtype === 'thematic' && 310 || 440))+')'">
                <circle class="score" r="30" cy="100" [ngClass]="scoreClass" />
                <use *ngIf="scoreClass === 'fill-red'" class="unit-score-icon score-icon"
                    xlink:href="../assets/svg/cross.svg#cross" />
            </g>
        </g>
    </svg>

    <!-- Quick links for dev -->
    <div class="dev-links" *ngIf="isDevMode$ | async">
        <div class="unit-block">
            <!-- Presentation -->
            <a [routerLink]="[unitData?.unit.id + '/presentation/1']">{{unitData?.unit.presentation}}</a>
        </div>
        <div class="unit-block">
            <!-- Intro -->
            <a *ngFor="let interaction of unitData?.unit.introduction, let i = index"
                [routerLink]="[unitData?.unit.id +'/introduction/', i + 1]">{{interaction}}</a>
        </div>
        <div class="unit-block">
            <!-- Extra -->
            <a *ngFor="let interaction of unitData?.unit.extra, let i = index"
                [routerLink]="[unitData?.unit.id +'/extra/', i + 1]">{{interaction}}</a>
        </div>
        <div class="unit-block">
            <!-- Deepening -->
            <a *ngFor="let interaction of unitData?.unit.deepening, let i = index"
                [routerLink]="[unitData?.unit.id +'/deepening/', i + 1]">{{interaction}}</a>
        </div>
        <div class="unit-block">
            <!-- Test -->
            <a *ngFor="let interaction of unitData?.unit.test, let i = index"
                [routerLink]="[unitData?.unit.id +'/test/', i + 1]">{{interaction}}</a>
        </div>
    </div>
</ng-container>