import { LicenseFilter } from 'src/app/services/license.service';
import { utility } from '../../../../shared/helpers/utility';
import { getDateAndTimeUpToSecondsFromISOString } from 'shared/helpers/time-helper';

const MAX_FILENAME_LENGTH = 160;

export const generateLicenseExportFilename = (
  count: number,
  filter: LicenseFilter
): string => {
  const date = getDateAndTimeUpToSecondsFromISOString(new Date().toISOString());
  const parts = ['licenses', `count-${count}`, date];

  if (filter.code) {
    parts.push(`code-${filter.code}`);
  }

  if (utility.isNotNullOrUndefined(filter.type)) {
    parts.push(`type-${filter.type}`);
  }

  if (filter.organizationId) {
    parts.push(`org-${filter.organizationId}`);
  }
  if (filter.durationType) {
    parts.push(`duration-${filter.durationType}`);
  }
  if (filter.distributionChannel) {
    parts.push(`channel-${filter.distributionChannel}`);
  }
  if (filter.assignedTo) {
    parts.push(`assignedTo-${filter.assignedTo}`);
  }
  if (filter.generatedAtStart) {
    parts.push(
      `genStart-${new Date(filter.generatedAtStart).toLocaleDateString(
        'nl-NL'
      )}`
    );
  }
  if (filter.generatedAtEnd) {
    parts.push(
      `genEnd-${new Date(filter.generatedAtEnd).toLocaleDateString('nl-NL')}`
    );
  }
  if (filter.distributedAtStart) {
    parts.push(
      `distStart-${new Date(filter.distributedAtStart).toLocaleDateString(
        'nl-NL'
      )}`
    );
  }
  if (filter.distributedAtEnd) {
    parts.push(
      `distEnd-${new Date(filter.distributedAtEnd).toLocaleDateString('nl-NL')}`
    );
  }

  let filename = parts.join('_');

  // Truncate if necessary
  if (filename.length > MAX_FILENAME_LENGTH) {
    filename = filename.slice(0, MAX_FILENAME_LENGTH);
  }

  return filename;
};
