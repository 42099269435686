import { createAction, props } from '@ngrx/store';
import { UserState } from 'src/app/store/reducers/user.reducer';
import { AppUser, AuthUser, DbUser } from '../../../../shared/models/user';

export const userActions = {
  // Login
  loggedIn: createAction('[User] Logged in', props<{ user: AuthUser }>()),
  userLoaded: createAction('[User] User loaded', props<{ dbUser: DbUser }>()),
  logout: createAction('[User] Logout'),

  // Organization
  organizationLoaded: createAction(
    '[User] Organization loaded',
    props<{ organization: UserState['organization'] }>()
  ),

  // Algolia
  getAlgoliaOrgKey: createAction('[User] Get Algolia org key'),
  algoliaOrgKeyLoaded: createAction(
    '[User] Algolia org key loaded',
    (algoliaOrgKey: string) => ({ algoliaOrgKey })
  ),
  getAlgoliaUserKey: createAction('[User] Get Algolia user key'),
  algoliaUserKeyLoaded: createAction(
    '[User] Algolia user key loaded',
    (algoliaUserKey: string) => ({ algoliaUserKey })
  ),
  unloadAlgoliaUserKey: createAction('[User] Unload Algolia user key'),

  // Dev settings
  setDevMode: createAction('[User] Set dev mode', (isDevMode: boolean) => ({
    isDevMode,
  })),
  devModeSet: createAction('[User] Dev mode set', (isDevMode: boolean) => ({
    isDevMode,
  })),

  // Content admin mode
  setContentAdminMode: createAction(
    '[User] Set content admin mode',
    (contentAdminMode: boolean) => ({
      contentAdminMode,
    })
  ),

  impersonate: createAction('[User] Impersonate', (uid: AppUser['uid']) => ({
    uid,
  })),
};
