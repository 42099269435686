import { UnitContext } from '../enums/unit-context';
export const unitContextToString = (unitContext: UnitContext): string => {
  switch (unitContext) {
    case UnitContext.infoPopup:
      return 'infoPopup';
    case UnitContext.presentation:
      return 'presentation';
    case UnitContext.introduction:
      return 'introduction';
    case UnitContext.extra:
      return 'extra';
    case UnitContext.deepening:
      return 'deepening';
    case UnitContext.test:
      return 'test';
    default:
      throw new Error('Unknown unit context');
  }
};
