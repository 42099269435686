<h1 mat-dialog-title>Code invoeren</h1>
<p>Vroeger gebruikte licentiecodes:</p>
<ul *ngIf="user$ | async as u">
    <li *ngFor="let license of u.licenses">{{ license }}</li>
</ul>
<mat-dialog-content>
    <p>Voer hier je licentiecode in.</p>

    <mat-form-field appearance="outline">
        <mat-label>Licentiecode</mat-label>
        <input matInput [placeholder]="patternHint" autocomplete="off" [formControl]="code" type="text"
            [ngClass]="{ 'is-invalid': code.errors }">
        <mat-error *ngIf="code.errors?.required">
            Licentiecode is verplicht
        </mat-error>
        <mat-error *ngIf="code.errors?.pattern">
            Licentiecode volgt het formaat: {{ patternHint }}
        </mat-error>
        <mat-error *ngIf="code.errors?.serverError">
            {{ code.errors.serverError }}
        </mat-error>
    </mat-form-field>

    <p class="license-date" [ngClass]="(mboLicenseDate$ | async) < (sessionDate$ | async) ? 'text-red' : 'text-green'"
        *ngIf="(mboLicenseDate$ | async) as mboDate">
        {{ 'MBO licentie geldig tot ' + (mboDate | date: 'fullDate') }}
    </p>

    <p class="license-date" [ngClass]="(vmboLicenseDate$ | async) < (sessionDate$ | async) ? 'text-red' : 'text-green'"
        *ngIf="(vmboLicenseDate$ | async) as vmboDate">
        {{ 'VMBO licentie geldig tot ' + (vmboDate | date: 'fullDate') }}
    </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
    <button mat-raised-button color="primary" [disabled]="!code.valid || callInProgress"
        [class.spinner]="callInProgress" (click)="submit()">
        Code invoeren
    </button>
</mat-dialog-actions>