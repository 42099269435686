<h2>Set project access</h2>
<h4>User: {{ user?.name }}</h4>
<mat-table [dataSource]="projects$ | async">
    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button *ngIf="!userHasProjectAccess(element.id)" (click)="addAccess(element.id)" mat-stroked-button
                color="primary">Kies</button>
            <button *ngIf="userHasProjectAccess(element.id)" (click)="removeAccess(element.id)" mat-stroked-button
                color="warn">Verwijder</button>
        </mat-cell>
    </ng-container>
    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>