import { EntityType } from '../../../src/app/enums/entity-type';

export const entityValidator = (entity: any): EntityType | false => {
  if (
    typeof entity !== 'object' ||
    typeof entity.id !== 'string' ||
    !entity.id.length ||
    typeof entity.type !== 'string'
  ) {
    return false;
  }

  switch (entity.type) {
    case EntityType.project:
    case EntityType.domain:
    case EntityType.chapter:
      if (
        !Array.isArray(entity.children) ||
        typeof entity.children[0] !== 'string' ||
        !entity.title ||
        !entity.version
      ) {
        return false;
      }
      return entity.type;
    case EntityType.unit:
      if (
        !entity.presentation ||
        !entity.title ||
        !Array.isArray(entity.introduction) ||
        (typeof entity.introduction[0] !== 'string' &&
          !Array.isArray(entity.introduction[0])) ||
        !entity.version
      ) {
        return false;
      }
      return entity.type;
    case EntityType.interaction:
      if (!entity.model || !entity.version) {
        return false;
      }
      return entity.type;
  }

  return false;
};
