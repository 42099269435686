import { DbInteraction, Interaction } from '../models/interaction';
import {
  DbInteractionVars,
  InteractionVars,
  StringOrNumberArray,
} from '../models/interaction-vars';
import { utility } from './utility';

export const interactionEncoder = (interaction: Interaction): DbInteraction => {
  // Firebase does not allow storage of nested arrays hence we convert it
  // to an object with the indices as keys.
  if (
    utility.isObject(interaction.vars) &&
    Object.values(interaction.vars).some(utility.isNestedArray)
  ) {
    const resultVarsObj: DbInteractionVars = {};

    Object.keys(interaction.vars).forEach((key) => {
      const element = interaction.vars[key];
      if (Array.isArray(element)) {
        resultVarsObj[key] = { ...element };
      } else {
        resultVarsObj[key] = element;
      }
    });

    return { ...interaction, vars: resultVarsObj };
  }

  return interaction as DbInteraction;
};

export const interactionReviver = (interaction: DbInteraction): Interaction => {
  // We transform the Firebase object back to its original state with nested arrays.
  if (
    utility.isObject(interaction.vars) &&
    Object.values(interaction.vars).some((x) => utility.isObject(x))
  ) {
    const resultVarsObj: InteractionVars = {};

    Object.keys(interaction.vars).forEach((key) => {
      const element = interaction.vars[key];
      if (utility.isObject(element)) {
        resultVarsObj[key] = Object.values(element);
      } else {
        resultVarsObj[key] = element as StringOrNumberArray;
      }
    });

    return { ...interaction, vars: resultVarsObj };
  }

  return interaction as Interaction;
};
