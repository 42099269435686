import { OverlayContainer } from '@angular/cdk/overlay';

export const toTop = (id: string, cdk: OverlayContainer) => {
  cdk.getContainerElement().childNodes.forEach((x: any) => {
    if (x.innerHTML.indexOf('id="' + id + '"') <= 0) {
      x.style['z-index'] = 1000;
    } else {
      x.style['z-index'] = 1001;
    }
  });
};
