import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum DialogPreset {
  ok,
  okCancel,
  cancelOk,
  close,
}

export interface TextAreaSettings {
  label?: string;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  value?: string;
  validators?: ValidatorFn[];
}

export interface DialogData {
  title: string;
  text?: string;
  code?: string;
  video?: string;
  textArea?: TextAreaSettings;
  options?: Array<{
    text: string;
    value: any;
    focus?: true;
  }>;
  preset?: DialogPreset;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  textArea: FormControl<string>;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    switch (this.data.preset) {
      case DialogPreset.okCancel:
        this.data.options = [
          { text: 'Annuleer', value: false },
          { text: 'OK', value: true, focus: true },
        ];
        break;
      case DialogPreset.cancelOk:
        this.data.options = [
          { text: 'OK', value: true },
          { text: 'Annuleer', value: false, focus: true },
        ];
        break;
      case DialogPreset.ok:
        this.data.options = [{ text: 'OK', value: true, focus: true }];
        break;
      case DialogPreset.close:
        this.data.options = [{ text: 'Sluiten', value: null, focus: true }];
        break;
      default:
        break;
    }

    if (this.data.textArea) {
      this.textArea = new FormControl(this.data.textArea.value || '');

      if (this.data.textArea.validators) {
        this.textArea.setValidators(this.data.textArea.validators);
      }

      this.data.textArea.maxRows ??= 10;

      // Replace the 'true' option with the textarea value
      // TODO is this hacky?
      this.textArea.valueChanges.subscribe((value) => {
        this.data.options = this.data.options.map((option) => {
          if (option.value === true || typeof option.value === 'string') {
            return { ...option, value };
          }
          return option;
        });
      });

      // TODO: validation does not work. Prevent the dialog from closing.
    }
  }

  // TODO: put it validation service? Or appFormControl component?
  getValidationErrorMessage(errors: ValidationErrors): string {
    if (errors.required) {
      return 'Dit veld is verplicht';
    }
    if (errors.minlength) {
      return `Dit veld moet minimaal ${errors.minlength.requiredLength} tekens bevatten`;
    }
    if (errors.maxlength) {
      return `Dit veld mag maximaal ${errors.maxlength.requiredLength} tekens bevatten`;
    }
    // Return the first error message
    const firstError = Object.values(errors)[0];
    const message =
      firstError.message || (typeof firstError === 'string' && firstError);
    return message || 'Ongeldige waarde';
  }
}
