import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearchConfig,
  SearchClient,
  SearchParameters,
} from 'angular-instantsearch/instantsearch/instantsearch';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { constants } from 'shared/constants';
import { DatabaseService } from 'src/app/services/database.service';
import { environment } from '../../../../environments/environment';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { utility } from '../../../../shared/helpers/utility';
import { DbUser, UserReference } from '../../../../shared/models/user';
import { AppState } from '../../store/reducers';
import {
  selectAlgoliaUserKey,
  selectUserOrganizationId,
  selectUserTeachers,
} from '../../store/reducers/user.reducer';
import { SearchQuery } from '../auto-complete/auto-complete.component';

@Component({
  selector: 'app-choose-teachers',
  templateUrl: './choose-teachers.component.html',
  styleUrls: ['./choose-teachers.component.scss'],
})
export class ChooseTeachersComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  searchClient: SearchClient;
  searchConfig: InstantSearchConfig;
  searchParameters: SearchParameters;

  userTeachers$: Observable<
    (UserReference & {
      beingRemoved: boolean;
    })[]
  >;
  selectedTeacherUid$ = new BehaviorSubject<UserReference['uid']>(null);
  teachersBeingAdded$ = new BehaviorSubject<DbUser['uid'][]>([]);
  teachersBeingRemoved$ = new BehaviorSubject<DbUser['uid'][]>([]);
  canAddTeacher$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    this.userTeachers$ = combineLatest([
      this.store.select(selectUserTeachers),
      this.teachersBeingRemoved$,
    ]).pipe(
      map(([teachers, teachersBeingRemoved]) =>
        teachers.map((teacher) => ({
          ...teacher,
          beingRemoved: teachersBeingRemoved.includes(teacher.uid),
        }))
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.canAddTeacher$ = combineLatest([
      this.selectedTeacherUid$,
      this.teachersBeingAdded$,
      this.store.select(selectUserTeachers),
    ]).pipe(
      map(
        ([teacherUid, teachersBeingAdded, userTeachers]) =>
          teacherUid &&
          !teachersBeingAdded.includes(teacherUid) &&
          !userTeachers.map(({ uid }) => uid).includes(teacherUid)
      ),
      takeUntil(this.ngDestroyed$)
    );

    combineLatest([
      this.store.select(selectUserOrganizationId),
      this.store.select(selectAlgoliaUserKey),
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([organizationId, algoliaUserKey]) => {
        if (!utility.allAreTruthy([organizationId, algoliaUserKey])) {
          this.searchConfig = null;
          return;
        }

        this.searchClient = algoliasearch(
          environment.algoliaAppId,
          algoliaUserKey
        );
        this.searchConfig = {
          indexName: constants.algoliaIndices.users,
          searchClient: this.searchClient,
        };

        this.searchParameters = {
          filters: `organizationId:${organizationId} AND roles:${UserRole.teacher}`,
        };
      });
  }

  setQuery(event: SearchQuery) {
    this.selectedTeacherUid$.next(event.id);
  }

  addTeacher() {
    const teacherUid = this.selectedTeacherUid$.getValue();

    this.teachersBeingAdded$.next([
      ...this.teachersBeingAdded$.getValue(),
      teacherUid,
    ]);

    this.databaseService
      .addTeacherToUser({ teacherUid })
      .catch((e) => console.error(e))
      .finally(() => {
        this.teachersBeingAdded$.next(
          this.teachersBeingAdded$
            .getValue()
            .filter((uid) => uid !== teacherUid)
        );
      });
  }

  removeTeacher(teacherUid: UserReference['uid']) {
    this.teachersBeingRemoved$.next([
      ...this.teachersBeingRemoved$.getValue(),
      teacherUid,
    ]);
    this.databaseService
      .removeTeacherFromUser({ teacherUid })
      .catch((e) => console.error(e))
      .finally(() => {
        this.teachersBeingRemoved$.next(
          this.teachersBeingRemoved$
            .getValue()
            .filter((uid) => uid !== teacherUid)
        );
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
