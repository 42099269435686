export const regularExpressions = {
  bracedParts: /{(.*?)}/g,
  decimalComma: /{([\d\.]+)?\|dc}/g,
  decimalCommaIsTrue: /dc=true/gi,
  dynamicInput: /{input(.*?)}/,
  dynamicSelect: /{select}/,
  equation: /{math equation=(\S*?)(\s.+?)?}/,
  equationFormat: /format="(.*?)"/,
  floatFormat: /\.(\d+)f/i,
  incorrectEquationFormat: /format:"(.*?)"/,
  incorrectEquationFormat2: /format[=:]([a-z0-9.%]+)(?![a-z0-9.%])/i,
  interactionVariables: /\$(\w+)\.(shuffled\.)?(\d+)\.?(\d+)?/,
  license: /^[A-Z]{1}-[A-Z0-9]{3}-[A-Z0-9]{3}(-[A-Z]{1})?$/,
  possibleThousandSeparator: /[,. ](?=\d{3})/g,
  hoursMinuteString: /^\d{1,2}:\d{2}$/,
};
