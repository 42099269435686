import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { InteractionActionType } from 'shared/enums/interaction-action-type';
import { InteractionModel } from 'shared/enums/interaction-model';
import { InteractionEvent } from '../../../../shared/enums/interaction-event';

import {
  ExamSession,
  ExamSessionInteractionAttemptWithoutTimeTaken,
} from 'shared/models/exam-session';
import { DbUser } from 'shared/models/user';
import { scoreActions } from 'src/app/store/actions/score.actions';

import { UnitContext } from '../../../../shared/enums/unit-context';
import { calcUnitScore } from '../../../../shared/helpers/scores/calc-unit-score';
import { Chapter } from '../../../../shared/models/chapter';
import { Domain } from '../../../../shared/models/domain';
import { Interaction } from '../../../../shared/models/interaction';
import {
  InteractionOptionHotspot,
  InteractionOptionPresentation,
} from '../../../../shared/models/interaction-option';
import { Project } from '../../../../shared/models/project';
import {
  InteractionScore,
  UnitScore,
} from '../../../../shared/models/score.enum';
import { InteractionIndex, Unit } from '../../../../shared/models/unit';
import {
  AddInteractionAttemptPayloadWithoutTimeTaken,
  AddStandaloneInteractionAttemptPayloadWithoutTimeTaken,
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from '../../../../shared/models/user-interaction-attempt';
import { Point, Rectangle } from '../../../../shared/types/shapes';
import { calcUnitSkips } from '../../helpers/calc-unit-skips';
import {
  findNextInteractionRoute,
  findNextReadonlyInteractionRoute,
  findPrevReadonlyInteractionRoute,
} from '../../helpers/find-next-interaction-route';
import { getUnitContextLocationString } from '../../helpers/get-unit-context-location';
import { constants } from 'shared/constants';
import { interactionActions } from '../actions/interaction.actions';
import { selectIsExamSession } from './exam.reducer';
import { selectNotepadLinesForStorageAsArray } from './notepad.reducer';
import { selectUserUnitScore } from './score.reducer';
import { selectStudentUid } from './student.reducer';
import { selectUserUid } from './user.reducer';
import { validateAnswers } from './validate-answer';

export type AutoPlay = boolean;

export type InteractionState = Readonly<{
  rawInteraction: Interaction | null;
  parsedInteraction: Interaction | null;
  tries: number;
  projectId: Project['id'] | null;
  domainId: Domain['id'] | null;
  chapterId: Chapter['id'] | null;
  unitContext: UnitContext | null;
  interactionIndex: InteractionIndex | null;
  unit: Unit | null;
  // variantIndexPick: number | null;
  startTimestamp: number | null;
  question: Interaction['question'] | null;
  imageSrc: string | null; // FIXME Has multiple sources: root and presentation steps and events.
  hasDragged: boolean;
  openTextDraggables: number;
  feedback: string | null;
  feedbackSound: string | null;
  presentationStepIndex: number;
  showPresentationAreaIndicator: boolean;
  correct: boolean | null;
  clickedPoints: Point[];
  uiAnswers: string[] | null;
  answersCorrectness: boolean[] | null;
  isCompleted: boolean; // Locked and answer stored
  isSavingAnswer: boolean;
  isAllowedToSaveAnswer: boolean;
  userInteractionAttempt:
    | StandaloneInteractionAttempt
    | InteractionAttempt
    | null;
  isExamSession: boolean;
  isReadonlyMode: boolean;
  isDirty: boolean;
}>;

export const initialState: InteractionState = {
  rawInteraction: null,
  parsedInteraction: null,
  tries: 0,
  projectId: null,
  domainId: null,
  chapterId: null,
  unitContext: null,
  interactionIndex: null,
  unit: null,
  // variantIndexPick: null,
  startTimestamp: null,
  question: null,
  imageSrc: null,
  hasDragged: false,
  openTextDraggables: 0,
  feedback: null,
  feedbackSound: null,
  presentationStepIndex: 0,
  showPresentationAreaIndicator: false,
  correct: null,
  clickedPoints: [],
  uiAnswers: null,
  answersCorrectness: null,
  isCompleted: false,
  isSavingAnswer: false,
  isAllowedToSaveAnswer: true,
  userInteractionAttempt: null,
  isExamSession: false,
  isReadonlyMode: false,
  isDirty: false,
};

export type InteractionRouteParams = Readonly<{
  uid?: DbUser['uid'];
  projectId?: Project['id'];
  domainId?: Domain['id'];
  chapterId?: Chapter['id'];
  unitId?: Unit['id'];
  interactionId?: Interaction['id'];
  unitContext?: UnitContext;
  interactionIndex?: InteractionIndex;
  examSessionId?: ExamSession['id'];
  examSectionIndex?: number;
  examSectionInteractionIndex?: number;
}>;

export type ClickedPoint = {
  point: Point;
  isCorrect: boolean | null;
};

const getActivePresentationArea = (
  activePresentationStep: InteractionOptionPresentation
) => {
  const area = activePresentationStep?.area;
  // TODO support other shapes?
  if (area?.includes('rect|')) {
    return new Rectangle(area.split('|')[1]);
  }

  return null;
};

const getPresentationSteps = (parsedInteraction: Interaction) =>
  parsedInteraction?.model === InteractionModel.presentation
    ? parsedInteraction.optionsPresentation
    : [];

const reducer = createReducer(
  initialState,
  on(
    interactionActions.interactionLoaded,
    (state, { interaction }): InteractionState => ({
      ...state,
      rawInteraction: interaction,
      parsedInteraction: null,
    })
  ),
  on(
    interactionActions.interactionParsed,
    (state, { parsedInteraction, answers, timestamp }): InteractionState => ({
      ...state,
      uiAnswers: answers,
      parsedInteraction,
      startTimestamp: timestamp,
      question: parsedInteraction.question,
      hasDragged: parsedInteraction.model === InteractionModel.sortObjectsOrder,
      openTextDraggables:
        parsedInteraction.model === InteractionModel.openTextDragDrop ? 1 : 0,
      imageSrc:
        parsedInteraction.model === InteractionModel.presentation
          ? parsedInteraction.optionsPresentation[0].image
          : parsedInteraction.image,
      feedback:
        parsedInteraction.model === InteractionModel.presentation
          ? parsedInteraction.optionsPresentation[0].option
          : null,
      presentationStepIndex: 0,
    })
  ),
  on(
    interactionActions.unitLoaded,
    (state, { unit }): InteractionState => ({
      ...state,
      unit,
    })
  ),
  on(
    interactionActions.routeParamsChanged,
    (
      state,
      { projectId, domainId, chapterId, unitContext, interactionIndex }
    ): InteractionState => ({
      ...initialState, // Reset
      projectId,
      domainId,
      chapterId,
      unitContext,
      interactionIndex,
      isSavingAnswer: state.isSavingAnswer,
      isExamSession: state.isExamSession,
    })
  ),
  on(
    interactionActions.componentDestroyed,
    (state): InteractionState => ({
      ...initialState, // Reset
      isSavingAnswer: state.isSavingAnswer,
      isExamSession: state.isExamSession,
    })
  ),
  on(
    interactionActions.setQuestion,
    (state, { question }): InteractionState => ({
      ...state,
      question,
    })
  ),
  on(
    interactionActions.setImage,
    (state, { imageSrc }): InteractionState => ({
      ...state,
      imageSrc,
    })
  ),
  on(
    interactionActions.setHasDragged,
    (state, { hasDragged }): InteractionState => ({
      ...state,
      hasDragged,
    })
  ),
  on(
    interactionActions.setOptionsSortOrder,
    (state, { optionsSortOrder }): InteractionState => ({
      ...state,
      hasDragged: true,
      parsedInteraction: {
        ...state.parsedInteraction,
        optionsSortOrder,
      },
    })
  ),
  on(
    interactionActions.setOpenTextDraggables,
    (state, { openTextDraggables }): InteractionState => ({
      ...state,
      openTextDraggables,
    })
  ),
  on(
    interactionActions.incrementOpenTextDraggables,
    (state): InteractionState => ({
      ...state,
      openTextDraggables: state.openTextDraggables + 1,
    })
  ),
  // Increment openTextDraggables when user is dragging the last draggable
  on(
    interactionActions.openTextDraggablesConditionalIncrement,
    (state, { openTextDraggables }): InteractionState => ({
      ...state,
      openTextDraggables:
        state.openTextDraggables === openTextDraggables
          ? openTextDraggables + 1
          : state.openTextDraggables,
    })
  ),
  on(
    interactionActions.clickMediaArea,
    (state, { clickedPoint }): InteractionState => {
      if (getIsLocked(state)) {
        return state;
      }

      const newState = { ...state };
      const model = state.parsedInteraction.model;

      const presentationSteps = getPresentationSteps(state.parsedInteraction);
      const activeStep = presentationSteps[state.presentationStepIndex];
      const activeArea = getActivePresentationArea(activeStep);

      if (model === InteractionModel.presentation && activeArea) {
        if (!activeArea.isPointInside(clickedPoint)) {
          // Indicate that clicked area was incorrect
          newState.showPresentationAreaIndicator = true;
        } else {
          const newPresentationStepIndex = state.presentationStepIndex + 1;
          const presentationStepCount = presentationSteps.length;

          const newStep = presentationSteps[newPresentationStepIndex];

          newState.presentationStepIndex = newPresentationStepIndex;

          if (newPresentationStepIndex + 1 >= presentationStepCount) {
            newState.correct = true;
          }

          newState.feedback = newStep.option;
          newState.imageSrc = newStep.image;
          newState.showPresentationAreaIndicator = false;
        }
      } else if (
        model === InteractionModel.pointAndClick &&
        !!state.parsedInteraction.optionsImage
      ) {
        const clickCountNeeded = getClickCountNeeded(state.parsedInteraction);

        // Loops around
        newState.clickedPoints =
          state.clickedPoints.length >= clickCountNeeded
            ? [clickedPoint]
            : [...state.clickedPoints, clickedPoint];
      }

      return newState;
    }
  ),
  on(
    interactionActions.setStartTime,
    (state, { timestamp }): InteractionState => ({
      ...state,
      startTimestamp: timestamp,
    })
  ),
  on(
    interactionActions.handleAnswer,
    (state, { uiAnswers, timestamp }): InteractionState => {
      if (skipHandleAnswers(state)) {
        return {
          ...state,
          isDirty: false, // Reset isDirty state even when skipping
        };
      }

      const timerExpired =
        !!state.parsedInteraction.maxSeconds &&
        state.startTimestamp + state.parsedInteraction.maxSeconds * 1000 <
          timestamp;
      const answers = getAnswers({ ...state, uiAnswers });

      if (!answers) {
        throw Error('No answers found in UI or State');
      }

      const tries = state.tries + 1;

      const validateAnswersOutput = validateAnswers({
        timerExpired,
        answers,
        parsedInteraction: state.parsedInteraction,
        tries,
        isTestOrExam:
          state.unitContext === UnitContext.test || state.isExamSession,
      });

      const mergedOptions = state.parsedInteraction.optionsHotspot?.map((o) => {
        const modifiedOption =
          validateAnswersOutput.modifiedInteractionOptionHotspots?.find(
            (m) => m.index === o.index
          );

        if (modifiedOption) {
          return { ...o, ...modifiedOption };
        } else {
          return o;
        }
      });

      const newOptions = mergedOptions?.map((option) => {
        if (option.correct) {
          return { ...option, disabled: true };
        } else {
          return {
            ...option,
            inImage: false,
            draggedTo: null,
            cdkDragFreeDragPosition: null,
          };
        }
      });

      const newState: InteractionState = {
        ...state,
        ...(state.isExamSession
          ? {}
          : handleActions(validateAnswersOutput.events, state)),
        uiAnswers,
        feedback: validateAnswersOutput.feedback,
        feedbackSound: validateAnswersOutput.feedbackSound,
        correct: validateAnswersOutput.correct,
        answersCorrectness: validateAnswersOutput.answersCorrectness,
        tries,
        hasDragged: false,
        parsedInteraction: {
          ...state.parsedInteraction,
          optionsHotspot: newOptions,
        },
        isDirty: false, // Always reset isDirty state
      };

      return newState;
    }
  ),
  on(
    interactionActions.dragEnd,
    (
      state,
      { index, draggedTo, inImage, cdkDragFreeDragPosition }
    ): InteractionState => ({
      ...state,
      parsedInteraction: {
        ...state.parsedInteraction,
        optionsHotspot: state.parsedInteraction.optionsHotspot.map((option) => {
          if (option.index === index) {
            return {
              ...option,
              draggedTo,
              inImage,
              cdkDragFreeDragPosition,
              correct: null,
            };
          }
          return option;
        }),
      },
      hasDragged: state.hasDragged || inImage,
    })
  ),
  on(
    scoreActions.mergeToUserUnitScore,
    (state): InteractionState => ({
      ...state,
      isCompleted: getIsLocked(state),
    })
  ),
  on(
    interactionActions.setIsSavingAnswer,
    (state, { isSavingAnswer }): InteractionState => ({
      ...state,
      isSavingAnswer,
      // We need this extra property to make sure the answer before the lock is saved.
      isAllowedToSaveAnswer: !isSavingAnswer && !getIsLocked(state),
    })
  ),
  on(
    interactionActions.userInteractionAttemptLoaded,
    (state, { userInteractionAttempt }): InteractionState => ({
      ...state,
      userInteractionAttempt,
    })
  ),
  on(
    interactionActions.restoreAttemptState,
    (state, { attempt }): InteractionState => {
      const timerExpired =
        !!state.parsedInteraction.maxSeconds &&
        state.parsedInteraction.maxSeconds < attempt.timeTaken / 1000;

      const answers = attempt.answers;
      const tries = attempt.try;

      const validateAnswersOutput = validateAnswers({
        timerExpired,
        answers,
        parsedInteraction: state.parsedInteraction,
        tries,
        isTestOrExam:
          state.unitContext === UnitContext.test || state.isExamSession,
      });

      const newState: InteractionState = {
        ...state,
        ...handleActions(validateAnswersOutput.events, state),
        uiAnswers: attempt.answers,
        feedback: validateAnswersOutput.feedback,
        feedbackSound: validateAnswersOutput.feedbackSound,
        correct: validateAnswersOutput.correct,
        answersCorrectness: validateAnswersOutput.answersCorrectness,
        tries,
        hasDragged: false,
        parsedInteraction: {
          ...state.parsedInteraction,
          // optionsHotspot: newOptions,
        },
      };

      return newState;
    }
  ),
  on(
    interactionActions.setIsExamSession,
    (state, { isExamSession }): InteractionState => {
      const newState = { ...state };
      newState.isExamSession = isExamSession;
      return newState;
    }
  ),
  on(
    interactionActions.setIsReadonlyMode,
    (state, { isReadonlyMode }): InteractionState => ({
      ...state,
      isReadonlyMode,
    })
  ),
  on(
    interactionActions.setIsDirty,
    (state, { isDirty }): InteractionState => ({
      ...state,
      isDirty,
    })
  )
);

const handleActions = (
  events: Set<InteractionEvent>,
  state: InteractionState
): InteractionState => {
  const actions = state.parsedInteraction.actions;
  if (!actions?.length) {
    return;
  }

  const newState = { ...state };

  events.forEach((event) => {
    actions.forEach((action) => {
      if (action.event.split(';').some((e: InteractionEvent) => event === e)) {
        switch (action.type) {
          case InteractionActionType.setImage:
            newState.imageSrc = action.args[0];
            break;
          case InteractionActionType.setQuestion:
            newState.question = action.args[0];
            break;
          case InteractionActionType.resetClicks:
            // We ignore this.
            break;
          default:
            console.warn(`Action type '${action.type}' is not supported.`);
        }
      }
    });
  });

  return newState;
};

const skipHandleAnswers = (state: InteractionState): boolean => {
  if (getIsLocked(state)) {
    return true;
  }

  switch (state.parsedInteraction.model) {
    case InteractionModel.sortToHotspots:
    case InteractionModel.sortObjectsOrder:
      return !state.hasDragged;
  }

  return false;
};

const getAnswers = (
  state: InteractionState
): string[] | InteractionOptionHotspot[] => {
  switch (state.parsedInteraction.model) {
    case InteractionModel.pointAndClick:
      return state.clickedPoints.map((p) => `${p.x},${p.y}`);
    case InteractionModel.sortObjectsOrder:
      return [
        state.parsedInteraction.optionsSortOrder
          .map((x) => x.position)
          .join(','),
      ];
    case InteractionModel.sortToHotspots:
      return state.parsedInteraction.optionsHotspot.filter((x) => !!x.inImage);
  }

  return state.uiAnswers;
};

export const interactionReducer = (
  state: InteractionState | undefined,
  action: Action
) => reducer(state, action);

// Selectors
export const selectInteractionState =
  createFeatureSelector<InteractionState>('interaction');

export const selectRawInteraction = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.rawInteraction
);

export const selectParsedInteraction = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.parsedInteraction
);

export const selectIsPresentation = createSelector(
  selectParsedInteraction,
  (parsedInteraction): boolean =>
    parsedInteraction?.model === InteractionModel.presentation
);

// TODO this should probably move to its own state
export const selectInteractionUnit = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.unit
);

export const selectIsThematicInteractionUnit = createSelector(
  selectInteractionUnit,
  (unit) => unit?.subtype === 'thematic'
);

export const selectInteractionIndex = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.interactionIndex
);

export const selectUnitContext = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.unitContext
);

export const selectContextLocation = createSelector(
  selectInteractionUnit,
  selectUnitContext,
  selectInteractionIndex,
  selectRawInteraction,
  selectIsExamSession,
  (unit, unitContext, interactionIndex, interaction, isExamSession) => {
    if (
      unit &&
      typeof interactionIndex === 'number' &&
      typeof unitContext === typeof UnitContext.presentation
    ) {
      return getUnitContextLocationString(unit, unitContext, interactionIndex);
    }

    if (isExamSession) {
      return '';
    }

    return `ID: ${interaction?.id} | Version: ${interaction?.version} | Version date: ${interaction?.versionDate}`;
  }
);

export const selectNextInteractionRoute = createSelector(
  selectInteractionUnit,
  selectUnitContext,
  selectInteractionIndex,
  selectUserUnitScore,
  (unit, unitContext, interactionIndex, userUnitScore) => {
    if (
      unit?.id &&
      typeof unitContext === typeof UnitContext.presentation &&
      typeof interactionIndex === 'number' &&
      userUnitScore
    ) {
      return findNextInteractionRoute(
        unit,
        unitContext,
        interactionIndex,
        userUnitScore
      );
    }

    return null;
  }
);

// export const selectVariantCount = createSelector(
//   selectUnit,
//   selectUnitContext,
//   selectInteractionIndex,
//   (unit, unitContext, interactionIndex) => {
//     if (
//       unit &&
//       typeof unitContext === typeof UnitContext.presentation &&
//       typeof interactionIndex === 'number'
//     ) {
//       return getVariantCountAtUnitPos({
//         unit,
//         unitContext,
//         index: interactionIndex,
//       });
//     }

//     return null;
//   }
// );

export const selectInteractionId = createSelector(
  selectRawInteraction,
  (interaction) => interaction?.id
);

// unit
export const selectUnitId = createSelector(
  selectInteractionUnit,
  (unit) => unit?.id
);

export const selectIsReadonlyMode = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.isReadonlyMode
);

export const selectNoImageScaling = createSelector(
  selectParsedInteraction,
  (parsedInteraction) => {
    if (parsedInteraction) {
      return [
        InteractionModel.openTextDragDrop,
        InteractionModel.openTextInImage,
        InteractionModel.pointAndClick,
        InteractionModel.sortObjectsOrder,
        InteractionModel.sortToHotspots,
      ].includes(parsedInteraction.model);
    }

    return false;
  }
);

export const selectShowOkButton = createSelector(
  selectParsedInteraction,
  selectIsReadonlyMode,
  (parsedInteraction, isReadonlyMode) => {
    if (isReadonlyMode) {
      return false;
    }

    if (parsedInteraction) {
      return (
        !!parsedInteraction.gap ||
        ([
          InteractionModel.sortToHotspots,
          InteractionModel.sortObjectsOrder,
          InteractionModel.multipleChoice,
        ].includes(parsedInteraction.model) &&
          parsedInteraction.model !== InteractionModel.presentation)
      );
    }

    return false;
  }
);

export const selectImageUrl = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.imageSrc
);

export const selectQuestion = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.question
);

export const selectCurrentSortOrder = createSelector(
  selectParsedInteraction,
  (parsedInteraction) =>
    parsedInteraction?.optionsSortOrder?.map((x) => x.position).join(',')
);

export const selectHasDragged = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.hasDragged
);

export const selectOpenTextDraggables = createSelector(
  selectInteractionState,
  (state) => state.openTextDraggables
);

export const selectInteractionModel = createSelector(
  selectRawInteraction,
  (interaction) => interaction?.model
);

export const selectPresentationSteps = createSelector(
  selectParsedInteraction,
  getPresentationSteps
);

export const selectPresentationStepIndex = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.presentationStepIndex
);

export const selectActivePresentationStep = createSelector(
  selectPresentationSteps,
  selectPresentationStepIndex,
  (presentationSteps, index) => presentationSteps[index]
);

export const selectVideoUrl = createSelector(
  selectActivePresentationStep,
  (activePresentationStep) => activePresentationStep?.video
);

export const selectVideoSource = createSelector(
  selectVideoUrl,
  selectPresentationStepIndex,
  (videoUrl, stepIndex): [string, AutoPlay] | null => {
    if (!!videoUrl) {
      if (stepIndex === 0) {
        return [videoUrl, false];
      }
      return [videoUrl, true];
    }
    return null;
  }
);

export const selectShowVideo = createSelector(
  selectVideoUrl,
  (video: string) => !!video
);

export const selectActivePresentationArea = createSelector(
  selectActivePresentationStep,
  getActivePresentationArea
);

export const selectPresentationIndicationArea = createSelector(
  selectInteractionState,
  selectActivePresentationArea,
  (state, area) => (state.showPresentationAreaIndicator ? area : null)
);

export const selectPresentationStepCount = createSelector(
  selectPresentationSteps,
  (presentationSteps) => presentationSteps.length
);

export const isLastPresentationStep = createSelector(
  selectPresentationStepIndex,
  selectPresentationStepCount,
  (stepIndex, stepCount) => stepIndex + 1 === stepCount
);

export const selectClickedPoints = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.clickedPoints
);

export const selectScore = createSelector(
  selectInteractionState,
  (state: InteractionState) => {
    if (state.correct) {
      if (state.tries > 1) {
        return InteractionScore.correctWithHelp;
      }
      return InteractionScore.correct;
    }
    return InteractionScore.incorrect;
  }
);

export const selectOptionHotspots = createSelector(
  selectParsedInteraction,
  (parsedInteraction) => {
    if (parsedInteraction) {
      return parsedInteraction.optionsHotspot;
    }
    return [];
  }
);

export const selectTryCount = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.tries
);

export const selectIsFirstTry = createSelector(
  selectTryCount,
  (tries) => tries === 0
);

export const selectIsTest = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.unitContext === UnitContext.test
);

export const selectIsCorrect = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.correct
);

export const selectAnswersCorrectness = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.answersCorrectness
);

export const selectLastTry = createSelector(
  selectInteractionModel,
  selectTryCount,
  selectOptionHotspots,
  selectIsTest,
  selectIsCorrect,
  selectAnswersCorrectness,
  (model, tries, optionHotspots, isTest, isCorrect, answersCorrectness) => ({
    model,
    tries,
    optionHotspots,
    isTest,
    isCorrect,
    answersCorrectness,
  })
);

const selectInteractionPath = createSelector(
  selectInteractionState,
  (state: InteractionState) => ({
    projectId: state.projectId,
    domainId: state.domainId,
    chapterId: state.chapterId,
    unitId: state.unit?.id,
    unitContext: state.unitContext,
    interactionIndex: state.interactionIndex,
  })
);

export const selectInteractionUnitId = createSelector(
  selectInteractionUnit,
  (unit) => unit?.id
);

export const selectIsAllowedToCalculatePercentages = createSelector(
  selectInteractionState,
  (state: InteractionState) =>
    !constants.percentageDomains.includes(state.domainId)
);

// const vars =
//       this.parsedInteraction.varsShuffled &&
//       Object.values(this.parsedInteraction.varsShuffled).map((x) => '' + x);

//     const varsShuffledIndexes = this.parsedInteraction.varsShuffledIndexes;

//     let score = InteractionScore.incorrect;
//     if (this.correct$.getValue()) {
//       if (this.tries > 1) {
//         score = InteractionScore.correctWithHelp;
//       } else {
//         score = InteractionScore.correct;
//       }
//     }

//     if (typeof answers[0] !== 'string') {
//       answers = answerCorrectness.map((correct) => correct.toString());
//     }

//     const pureAttempt: StandaloneInteractionAttempt = {
//       uid: this.uid,
//       interactionId: this.interactionId$.getValue(),
//       interactionVersion: this.parsedInteraction.version,
//       score,
//       answers: answers as string[],
//       vars,
//       varsShuffledIndexes,
//       try: this.tries,
//       timeTaken: Date.now() - this.activityTimestamp,
//       timestamp: Date.now(),
//     };

//     this.activityTimestamp = Date.now();

//     const fullAttempt: InteractionAttempt = {
//       ...pureAttempt,
//       projectId: this.projectId$.getValue(),
//       domainId: this.domainId$.getValue(),
//       chapterId: this.chapterId$.getValue(),
//       unitId: this.unitId$.getValue(),
//       unitContext: this.unitContext$.getValue(),
//       interactionIndex: this.interactionIndex$.getValue(),
//     };

//     const attempt = fullAttempt.projectId ? fullAttempt : pureAttempt;
//     this.databaseService.addAttempt(attempt);

//     if ('projectId' in attempt) {
//       // Merge score with unit data to avoid extra server trip.
//       this.store.dispatch(
//         userActions.mergeToUserUnitScore(attempt as InteractionAttempt)
//       );
//     }

export const selectAddInteractionAttemptPayloadWithoutTimeTaken =
  createSelector(
    selectInteractionState,
    selectParsedInteraction,
    selectInteractionPath,
    selectScore,
    selectAnswersCorrectness,
    selectNotepadLinesForStorageAsArray,
    (
      state: InteractionState,
      parsedInteraction,
      path,
      score,
      answersCorrectness,
      notepadLines
    ):
      | AddInteractionAttemptPayloadWithoutTimeTaken
      | AddStandaloneInteractionAttemptPayloadWithoutTimeTaken => {
      if (!parsedInteraction || !answersCorrectness) {
        return null;
      }

      const vars =
        parsedInteraction.varsShuffled &&
        Object.values(parsedInteraction.varsShuffled).map((x) => '' + x);

      let answers = getAnswers(state);

      if (answers && typeof answers[0] !== 'string') {
        answers = answersCorrectness.map((correct) => correct.toString());
      }

      const varsShuffledIndexes = parsedInteraction.varsShuffledIndexes;

      const attempt: AddStandaloneInteractionAttemptPayloadWithoutTimeTaken = {
        interactionId: parsedInteraction.id,
        interactionVersion: parsedInteraction.version,
        score,
        answers: answers as string[],
        notepadLines,
        vars,
        varsShuffledIndexes,
        try: state.tries,
      };

      const fullAttempt: AddInteractionAttemptPayloadWithoutTimeTaken = {
        ...attempt,
        projectId: path.projectId,
        domainId: path.domainId,
        chapterId: path.chapterId,
        unitId: path.unitId,
        unitContext: path.unitContext,
        interactionIndex: path.interactionIndex,
      };

      return fullAttempt.projectId ? fullAttempt : attempt;
    }
  );

const getMaxTries = (parsedInteraction: Interaction) =>
  parsedInteraction?.maxTimesAnswered;

export const selectMaxTries = createSelector(
  selectParsedInteraction,
  getMaxTries
);

const getHasNoMoreTries = (
  isTest: boolean,
  maxTries: number | null,
  tries: number | null,
  isExamSession: boolean
) => {
  if (isExamSession) {
    return false;
  } else {
    return (tries && isTest) || (maxTries && tries >= maxTries);
  }
};

export const selectHasNoMoreTries = createSelector(
  selectIsTest,
  selectMaxTries,
  selectTryCount,
  selectIsExamSession,
  getHasNoMoreTries
);

const getIsLocked = (state: InteractionState) =>
  (getIsCorrect(state) && !getIsExamSession(state)) ||
  getIsReadonlyMode(state) ||
  getHasNoMoreTries(
    getIsTest(state),
    getMaxTries(state.parsedInteraction),
    getTryCount(state),
    getIsExamSession(state)
  );

const getIsReadonlyMode = (state: InteractionState) => state.isReadonlyMode;

export const selectIsLocked = createSelector(
  selectInteractionState,
  getIsLocked
);

export const getClickCountNeeded = (parsedInteraction: Interaction) =>
  parsedInteraction?.optionsImage?.filter((o) => o.correct).length;

const getIsTest = (state: InteractionState) =>
  state.unitContext === UnitContext.test;

const getIsExamSession = (state: InteractionState) => state.isExamSession;

const getTryCount = (state: InteractionState) => state.tries;

const getIsCorrect = (state: InteractionState) => state.correct;

export const selectFeedback = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.feedback
);

export const selectIsEndOfUnit = createSelector(
  selectIsLocked,
  selectUnitContext,
  selectInteractionIndex,
  selectInteractionUnit,
  (locked, unitContext, interactionIndex, unit): boolean =>
    // End of test or end of thematic unit
    !!unit &&
    locked &&
    ((unitContext === UnitContext.test &&
      interactionIndex === unit.test.length - 1) ||
      (unit.subtype === 'thematic' &&
        interactionIndex === unit.introduction.length - 1))
);

export const selectShowNextButton = createSelector(
  selectNextInteractionRoute,
  selectIsLocked,
  selectUnitContext,
  selectIsReadonlyMode,
  (nextInteractionRoute, locked, unitContext, isReadonlyMode): boolean =>
    !isReadonlyMode &&
    !!nextInteractionRoute &&
    (locked || unitContext === UnitContext.presentation)
);

export const selectShowFastForwardButton = createSelector(
  selectShowNextButton,
  selectUnitContext,
  selectInteractionUnit,
  (showNextButton, unitContext, unit): boolean => {
    if (unitContext !== UnitContext.presentation) {
      return false;
    }

    if (unit?.subtype === 'thematic') {
      return false;
    }

    return showNextButton;
  }
);

export const selectIsSavingAnswer = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.isSavingAnswer
);

export const selectDisableSkipButton = createSelector(
  selectInteractionUnit,
  selectUserUnitScore,
  selectUnitContext,
  selectInteractionIndex,
  selectIsSavingAnswer,
  (
    unit,
    userUnitScore,
    unitContext,
    interactionIndex,
    isSavingAnswer
  ): boolean =>
    isSavingAnswer ||
    (unitContext !== UnitContext.presentation &&
      unitContext !== UnitContext.test &&
      unit?.subtype !== 'thematic' &&
      !!userUnitScore &&
      calcUnitSkips(userUnitScore, {
        unitContext,
        interactionIndex,
      }) >= constants.maxSkips)
);

export const selectShowSkipButton = createSelector(
  selectNextInteractionRoute,
  selectIsLocked,
  selectUnitContext,
  selectUserUnitScore,
  selectIsEndOfUnit,
  selectIsReadonlyMode,
  (
    nextInteractionRoute,
    locked,
    unitContext,
    userUnitScore,
    isEndOfUnit,
    isReadonlyMode
  ) => {
    if (!nextInteractionRoute) {
      return false;
    }

    if (locked) {
      return false;
    }

    if (isEndOfUnit) {
      return false;
    }

    if (
      unitContext === UnitContext.presentation &&
      (!userUnitScore || calcUnitScore(userUnitScore) === UnitScore.null)
    ) {
      return false;
    }

    if (isReadonlyMode) {
      return false;
    }

    return true;
  }
);

export const selectIsInteractionReady = createSelector(
  selectInteractionState,
  (state: InteractionState) => !!state.parsedInteraction
);

export const selectShowStartOfTestDialog = createSelector(
  selectUnitContext,
  selectInteractionIndex,
  selectIsReadonlyMode,
  selectIsInteractionReady,
  (
    unitContext,
    interactionIndex,
    isReadonlyMode,
    isInteractionReady
  ): boolean =>
    isInteractionReady &&
    !isReadonlyMode &&
    unitContext === UnitContext.test &&
    interactionIndex === 0
);

export const selectRadioOptions = createSelector(
  selectParsedInteraction,
  (parsedInteraction: Interaction): string[] =>
    parsedInteraction?.model === InteractionModel.multipleChoice
      ? parsedInteraction.optionsText.map((option) => option.option)
      : []
);

export const selectClickedPointsWithCorrectness = createSelector(
  selectClickedPoints,
  selectAnswersCorrectness,
  (clickedPoints, answersCorrectness): ClickedPoint[] =>
    clickedPoints.map((point, i) => ({
      point,
      isCorrect: answersCorrectness?.[i] ?? null,
    }))
);

export const selectIsCompleted = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.isCompleted
);

export const selectShowEndOfUnitDialog = createSelector(
  selectIsEndOfUnit,
  selectUserUnitScore,
  selectIsCompleted,
  (
    isEndOfUnit,
    userUnitScore,
    isCompleted
  ): { showDialog: boolean; isCorrect: boolean } => ({
    showDialog: isEndOfUnit && isCompleted && !!userUnitScore,
    isCorrect: userUnitScore
      ? calcUnitScore(userUnitScore) === UnitScore.correct
      : null,
  })
);

export const selectIsDirty = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.isDirty
);

export const selectIsOkBtnDisabled = createSelector(
  selectIsSavingAnswer,
  selectIsLocked,
  selectIsExamSession,
  selectIsDirty,
  (isSavingAnswer, locked, isExamSession, isDirty) =>
    isExamSession ? !isDirty : isSavingAnswer || locked
);

export const selectStartTimestamp = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.startTimestamp
);

export const selectIsAllowedToSaveAnswer = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.isAllowedToSaveAnswer
);

export const selectIsSoundEnabled = createSelector(
  selectParsedInteraction,
  (parsedInteraction): boolean => !!parsedInteraction?.soundOn
);

const selectFeedbackSound = createSelector(
  selectInteractionState,
  (state: InteractionState): string | null => state.feedbackSound
);

export const selectQuestionSound = createSelector(
  selectParsedInteraction,
  selectIsSoundEnabled,
  selectTryCount,
  selectIsCorrect,
  selectHasNoMoreTries,
  selectFeedbackSound,
  (
    parsedInteraction,
    isSoundEnabled,
    tries,
    isCorrect,
    hasNoMoreTries,
    feedbackSound
  ): string | null => {
    if (!parsedInteraction) {
      return null;
    }

    if (!isSoundEnabled) {
      return null;
    }

    if (feedbackSound) {
      return feedbackSound;
    }

    if (tries === 0) {
      return parsedInteraction.questionSound;
    }

    if (isCorrect) {
      return parsedInteraction.feedbackCorrectSound;
    }

    if (hasNoMoreTries) {
      return parsedInteraction.feedbackFalseLastTrySound;
    }

    return parsedInteraction.feedbackFalseSound;
  }
);

export const selectAutoNextInput = createSelector(
  selectParsedInteraction,
  (parsedInteraction): boolean => !!parsedInteraction?.autoNextInput
);

export const selectLastUserInteractionAttempt = createSelector(
  selectInteractionState,
  (state: InteractionState) => state.userInteractionAttempt
);

export const selectLastUserInteractionAttemptUid = createSelector(
  selectLastUserInteractionAttempt,
  (userInteractionAttempt) => userInteractionAttempt?.uid
);

export const selectUserAnswers = createSelector(
  selectLastUserInteractionAttempt,
  selectInteractionState,
  (userInteractionAttempt, state) =>
    state.isExamSession ? state.uiAnswers : userInteractionAttempt?.answers
);

export const selectShowStopButton = createSelector(
  selectIsExamSession,
  (isExamSession): boolean => !isExamSession
);

export const selectNextInteractionRouteReadonly = createSelector(
  selectInteractionUnit,
  selectUnitContext,
  selectInteractionIndex,
  selectLastUserInteractionAttemptUid,
  (unit, unitContext, interactionIndex, uid): string[] | null =>
    findNextReadonlyInteractionRoute({
      unit,
      unitContext,
      interactionIndex,
      uid,
    })
);

export const selectPrevInteractionRouteReadonly = createSelector(
  selectInteractionUnit,
  selectUnitContext,
  selectInteractionIndex,
  selectLastUserInteractionAttemptUid,
  (unit, unitContext, interactionIndex, uid): string[] | null =>
    findPrevReadonlyInteractionRoute({
      unit,
      unitContext,
      interactionIndex,
      uid,
    })
);

export const selectUnitScoreUid = createSelector(
  selectUserUid,
  selectStudentUid,
  (uid, studentUid): string => studentUid || uid
);

const filterExamInteractionScore = (score: InteractionScore) =>
  score === InteractionScore.correct ||
  score === InteractionScore.correctWithHelp
    ? InteractionScore.correct
    : InteractionScore.incorrect;

export const selectSaveExamAttemptPayloadWithoutTimeTaken = createSelector(
  selectAddInteractionAttemptPayloadWithoutTimeTaken,
  (attempt) => {
    const examInteractionAttempt: ExamSessionInteractionAttemptWithoutTimeTaken =
      {
        interactionVersion: attempt?.interactionVersion,
        answers: attempt?.answers,
        varsShuffledIndexes: attempt?.varsShuffledIndexes,
        score: filterExamInteractionScore(attempt?.score),
        interactionId: attempt?.interactionId,
      };

    return examInteractionAttempt;
  }
);

export const selectIsExamReview = createSelector(
  selectIsExamSession,
  selectIsReadonlyMode,
  (isExamSession, isReadonlyMode) => isExamSession && isReadonlyMode
);

export const selectCorrectReviewAnswers = createSelector(
  selectParsedInteraction,
  (parsedInteraction) => {
    if (parsedInteraction) {
      return parsedInteraction.optionsText
        .filter((option) => option.correct)
        .map((option) => option.option);
    }

    return [];
  }
);
