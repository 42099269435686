import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ExcelData,
  ExcelTransformation,
} from '../../../shared/models/excel-models';
import { safeFilename } from '../helpers/safe-filename-helper';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private aff: AngularFireFunctions) {}

  generateExcel(
    data: ExcelData,
    transformations: ExcelTransformation[]
  ): Observable<string> {
    const generateExcelFunction = this.aff.httpsCallable<
      { data: ExcelData; transformations: ExcelTransformation[] },
      string
    >('excel-generateExcelFunction');

    return generateExcelFunction({ data, transformations });
  }

  downloadExcel(
    data: ExcelData,
    transformations: ExcelTransformation[],
    fileName: string
  ): Observable<void> {
    if (!fileName.endsWith('.xlsx')) {
      fileName += '.xlsx';
    }

    fileName = safeFilename(fileName);

    return this.generateExcel(data, transformations).pipe(
      map((base64Excel: string) => {
        const binaryString = window.atob(base64Excel);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes.buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
    );
  }
}
