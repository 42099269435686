import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { EntityType } from 'shared/enums/entity-type';
import { AppRouteParams } from 'src/app/enums/route-params.enum';
import { StructuralEntity } from '../../../../shared/models/structural-entity';
import { DatabaseService } from '../../services/database.service';
import { RouteData } from '../../types/route-data';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() skipFromBeginning = -1;
  @Input() pathSuffix = '';
  breadCrumbs: Observable<StructuralEntity>[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private databaseService: DatabaseService
  ) {
    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.data as Observable<RouteData>,
    ]).subscribe(([paramMap, data]) => {
      this.breadCrumbs.length = 0;

      const projectId = paramMap.get(AppRouteParams.projectId);
      const domainId = paramMap.get(AppRouteParams.domainId);
      const chapterId = paramMap.get(AppRouteParams.chapterId);
      const unitId = paramMap.get(AppRouteParams.unitId);

      if (projectId) {
        this.breadCrumbs.push(
          this.databaseService.getObjAsStructuralEntity(
            projectId,
            EntityType.project
          )
        );
      }
      if (domainId) {
        this.breadCrumbs.push(
          this.databaseService.getObjAsStructuralEntity(
            domainId,
            EntityType.domain
          )
        );
      }
      if (chapterId) {
        this.breadCrumbs.push(
          this.databaseService.getObjAsStructuralEntity(
            chapterId,
            EntityType.chapter
          )
        );
      }
      if (unitId) {
        this.breadCrumbs.push(
          this.databaseService.getObjAsStructuralEntity(unitId, EntityType.unit)
        );
      }
    });
  }

  ngOnInit() {}
}
