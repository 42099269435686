<svg *ngIf="unitData$ | async as unitData" viewBox="20 50 980 100" xmlns="http://www.w3.org/2000/svg" stroke-width="3">

    <line stroke-linecap="null" stroke-linejoin="null" y2="100" [attr.x2]="calculateLineLength()" y1="100" x1="30"
        fill-opacity="null" stroke-opacity="null" fill="none" />

    <g fill="#fff">
        <g class="unit-block presentation" transform="translate(48)">
            <circle class="pointer" (click)="navigateToPath('presentation/1')" r="24" cy="100" cx="0" />
            <use class="pointer-events-none" transform="translate(-15, 85) scale(0.055)"
                xlink:href="../assets/svg/video.svg#video" />
        </g>

        <ng-container *ngFor="let context of renderUnitContexts; let i = index">
            <g [attr.class]="'unit-block ' + unitContexts[context]" [attr.transform]="getUnitContextTransform(context)">
                <g *ngFor="let interaction of unitData.unit[unitContexts[context]], let j = index"
                    (click)="navigateToPath(unitContexts[context] + '/' + (j+1))">
                    <circle class="pointer" r="20" cy="100" [attr.cx]="j * distanceBetweenCircles"
                        [ngClass]="unitData.userUnitScoreMapped[context][j]" />
                    <use *ngIf="unitData.userUnitScoreMapped[context][j] === 'fill-red'"
                        class="score-icon pointer-events-none" [attr.x]="j * distanceBetweenCircles" y="0"
                        xlink:href="../assets/svg/cross.svg#cross" />
                </g>
            </g>
        </ng-container>

        <g [attr.transform]="getScoreCircleTransform()">
            <circle class="score" r="30" cy="100" [ngClass]="unitScoreClass$ | async" />
            <text *ngIf="(unitScoreClass$ | async) === 'fill-red'" class="unit-score-icon score-icon" x="-17"
                y="114">🞨</text>
        </g>
    </g>
</svg>