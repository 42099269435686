/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import algoliasearch from 'algoliasearch';
import {
  InstantSearchConfig,
  SearchClient,
} from 'angular-instantsearch/instantsearch/instantsearch';
import moment, { Moment } from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { LicenseDataSource } from 'src/app/components/licenses/licenses-data-source';
import { utility } from 'src/app/helpers/utility';
import {
  LicenseDistributionChannel,
  LicenseType,
  StudentLicenseMbo,
  StudentLicenseVmbo,
  licenseTypeMap,
} from 'src/app/models/license';
import {
  LicenseFilter,
  LicenseService,
} from 'src/app/services/license.service';
import { PaginatedResponse } from 'src/app/services/pagination.service';
import { AppState } from 'src/app/store/reducers';
import { selectAlgoliaOrgKey } from 'src/app/store/reducers/user.reducer';
import { FormGroupModel } from 'src/app/types/form-group-model';
import { environment } from 'src/environments/environment';
import { downloadCSV } from '../../helpers/download-helper';
import { constants } from '../../misc/constants';
import {
  License,
  LicenseDurationType,
  licenseDistributionChannelMap,
  licenseDurationTypeMap,
} from '../../models/license';
import { Organization } from '../../models/organization';
import { selectAlgoliaUserKey } from '../../store/reducers/user.reducer';
import { SearchQuery } from '../auto-complete/auto-complete.component';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../dialog/dialog.component';
import { generateLicenseExportFilename } from './generate-license-export-filename';
interface LicenseOptionsForm {
  licenseType: keyof typeof LicenseType;
  durationType: LicenseDurationType;
  organizationId: License['organizationId'];
  organizationHardLink: boolean;
  distributionChannel: License['distributionChannel'];
  amount: number | null;
}

interface LicenseSearchForm {
  licenseCode: License['code'];
  licenseType: keyof typeof LicenseType | '';
  durationType: LicenseDurationType;
  organizationId: License['organizationId'];
  distributionChannel: LicenseDistributionChannel;
  assignedTo: License['assignedTo'];
  generatedAtStart: Moment | null;
  generatedAtEnd: Moment | null;
  distributedAtStart: Moment | null;
  distributedAtEnd: Moment | null;
}

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('licensePaginator') paginator: MatPaginator;

  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isDev = !environment.production;
  licenseSearchResults$: Observable<License[]>;

  columns = [
    {
      field: 'code',
      title: 'Code',
      canCopy: true,
    },
    {
      field: 'type',
      title: 'Type',
      type: 'licenseType',
    },
    {
      field: 'durationType',
      title: 'Duur',
      type: 'durationType',
    },
    {
      field: 'organizationId',
      title: 'School',
      type: 'organization',
    },
    {
      field: 'organizationHardLink',
      title: 'Harde link',
    },
    {
      field: 'distributionChannel',
      title: 'Distributie',
      type: 'distributionChannel',
    },
    {
      field: 'assignedTo',
      title: 'Toegewezen aan',
      type: 'user',
      canCopy: true,
    },
    {
      field: 'assignedAt',
      title: 'Toegewezen op',
      type: 'date',
      canCopy: true,
    },
    {
      field: 'generatedAt',
      title: 'Gegenereerd op',
      type: 'date',
      canCopy: true,
    },
    {
      field: 'generatedBy',
      title: 'Gegenereerd door',
      type: 'user',
      canCopy: true,
    },
    {
      field: 'distributedAt',
      title: 'Gedistribueerd op',
      type: 'date',
      canCopy: true,
    },
    {
      field: 'distributedBy',
      title: 'Gedistribueerd door',
      type: 'user',
      canCopy: true,
    },
    {
      field: 'distributionNote',
      title: 'Distributie notitie',
    },
  ];

  licenseTypeDropdown: Record<keyof typeof LicenseType, string> = {
    studentMbo: 'Student MBO',
    teacher: 'Docent',
    studentVmbo: 'Student VMBO',
  };

  displayedColumns = this.columns.map((c) => c.field);

  // TODO better types
  licenseTypes: Array<keyof typeof LicenseType> = [
    'studentMbo',
    'teacher',
    'studentVmbo',
  ];
  licenseTypeMap = licenseTypeMap;
  durationTypes = licenseDurationTypeMap;
  distributionChannels: Array<keyof typeof LicenseDistributionChannel> = [
    'S',
    'W',
    'B',
  ];

  distributionChannelDropdown: Record<
    keyof typeof LicenseDistributionChannel,
    string
  > = {
    S: 'School',
    W: 'Webshop',
    B: 'Boekhandel',
  };

  distributionChannelMap = licenseDistributionChannelMap;

  orgSearchClient: SearchClient;
  orgSearchConfig: InstantSearchConfig;

  userSearchClient: SearchClient;
  userSearchConfig: InstantSearchConfig;

  markOrgRequired = false;

  licenseOptions: FormGroupModel<LicenseOptionsForm>;
  licenseSearch: FormGroupModel<LicenseSearchForm>;

  generateInProgress: boolean;
  markAsDistributedInProgress: boolean;
  dataSource: LicenseDataSource;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private licenseService: LicenseService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.internalPaginator = this.paginator;
    this.licenseSearchResults$ = this.dataSource.connect();
  }

  initGenerateForm() {
    this.licenseOptions = this.fb.nonNullable.group({
      licenseType: [
        'studentMbo' as LicenseOptionsForm['licenseType'],
        Validators.required,
      ],
      durationType: [LicenseDurationType.A, Validators.required],
      organizationId: [null as LicenseOptionsForm['organizationId']],
      organizationHardLink: [
        null as LicenseOptionsForm['organizationHardLink'],
      ],
      distributionChannel: [LicenseDistributionChannel.S, Validators.required],
      amount: [
        null as LicenseOptionsForm['amount'],
        [
          Validators.required,
          Validators.min(1),
          Validators.max(constants.maxLicenseCount),
        ],
      ],
    });

    // Type selection effects
    this.licenseOptions.get('licenseType').valueChanges.subscribe((type) => {
      switch (type) {
        case 'teacher': {
          this.licenseOptions.get('durationType').disable();

          this.licenseOptions.get('organizationHardLink').disable();
          this.licenseOptions.get('organizationHardLink').setValue(true);

          this.licenseOptions.get('distributionChannel').disable();
          this.licenseOptions
            .get('distributionChannel')
            .setValue(LicenseDistributionChannel.S);

          // Mark org as required
          this.licenseOptions
            .get('organizationId')
            .setValidators([Validators.required]);

          this.licenseOptions.patchValue({
            distributionChannel: LicenseDistributionChannel.S,
            organizationHardLink: true,
          });

          this.markOrgRequired = true;
          break;
        }
        case 'studentVmbo': {
          this.licenseOptions
            .get('durationType')
            .setValue(LicenseDurationType.C);

          // Unmark org as required
          this.licenseOptions.get('organizationId').setValidators([]);
          this.licenseOptions.get('organizationHardLink').enable();
          this.markOrgRequired = false;
          break;
        }
        default: {
          this.licenseOptions.get('durationType').enable();
          this.licenseOptions.get('organizationHardLink').enable();
          this.licenseOptions.get('distributionChannel').enable();

          // Unmark org as required
          this.licenseOptions.get('organizationId').setValidators([]);
          this.markOrgRequired = false;
          break;
        }
      }
    });
  }

  initSearchForm() {
    this.licenseSearch = this.fb.group<LicenseSearchForm>({
      licenseCode: null,
      licenseType: null,
      durationType: null,
      organizationId: null,
      distributionChannel: null,
      assignedTo: null,
      generatedAtStart: null,
      generatedAtEnd: null,
      distributedAtStart: null,
      distributedAtEnd: null,
    });
  }

  ngOnInit() {
    this.generateInProgress = false;
    this.initGenerateForm();
    this.initSearchForm();

    // Init org search
    this.store
      .select(selectAlgoliaOrgKey)
      .pipe(first(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((algoliaOrgKey) => {
        this.orgSearchClient = algoliasearch(
          environment.algoliaAppId,
          algoliaOrgKey
        );
        this.orgSearchConfig = {
          indexName: constants.algoliaIndices.organizations,
          searchClient: this.orgSearchClient,
        };
      });

    // Init user search
    this.store
      .select(selectAlgoliaUserKey)
      .pipe(first(utility.isTruthy), takeUntil(this.ngDestroyed$))
      .subscribe((algoliaUserKey) => {
        this.userSearchClient = algoliasearch(
          environment.algoliaAppId,
          algoliaUserKey
        );
        this.userSearchConfig = {
          indexName: constants.algoliaIndices.users,
          searchClient: this.userSearchClient,
        };
      });

    this.dataSource = new LicenseDataSource(this.licenseService);
  }

  async markAsDistributed() {
    this.licenseService
      .searchLicenses(this.buildFilter())
      .pipe(first())
      .subscribe(async (licenses) => {
        const codes = licenses.data.map((license) => license.code);

        const dialogResponse: string | false = await this.dialog
          .open<DialogComponent, DialogData>(DialogComponent, {
            data: {
              title: 'Licenties distribueren',
              text: `Wil je deze ${codes.length} licenties markeren als gedistribueerd?`,
              textArea: {
                label: 'Notitie',
                validators: [
                  Validators.maxLength(constants.maxDistributionNoteLength),
                ],
              },
              preset: DialogPreset.okCancel,
            },
            disableClose: true,
          })
          .afterClosed()
          .toPromise();

        if (dialogResponse === false) {
          return;
        }

        const note =
          dialogResponse && typeof dialogResponse === 'string'
            ? dialogResponse
            : null;

        this.markAsDistributedInProgress = true;

        this.licenseService
          .markLicensesAsDistributed({
            codes,
            distributionNote: note,
          })
          .then((distributedAt) => {
            this.exportCsv();
          })
          .catch((error) => {
            this.dialog.open(DialogComponent, {
              data: {
                title: 'Fout',
                text: error.message,
                preset: DialogPreset.ok,
              },
            });
            console.error(error);
          })
          .finally(() => {
            this.markAsDistributedInProgress = false;
          });
      });
  }

  exportCsv() {
    const filter = this.buildFilter();

    this.licenseService
      .searchLicenses(filter)
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((response) => this.exportLicensesCsv(response, filter));
  }

  exportLicensesCsv(
    response: PaginatedResponse<License>,
    filter: LicenseFilter
  ) {
    const licenses = response.data;
    if (!licenses?.length) {
      return;
    }

    // Section Filters
    const filterHeaders = [
      'Code',
      'Type',
      'Organization ID',
      'Duration',
      'Channel',
      'Assigned to',
      'Generated at start',
      'Generated at end',
      'Distributed at start',
      'Distributed at end',
    ];

    const filterValues = [
      filter.code,
      filter.type,
      filter.organizationId,
      filter.durationType,
      filter.distributionChannel,
      filter.assignedTo,
      filter.generatedAtStart
        ? new Date(filter.generatedAtStart).toLocaleString('nl-NL')
        : null,
      filter.generatedAtEnd
        ? new Date(filter.generatedAtEnd).toLocaleString('nl-NL')
        : null,
      filter.distributedAtStart
        ? new Date(filter.distributedAtStart).toLocaleString('nl-NL')
        : null,
      filter.distributedAtEnd
        ? new Date(filter.distributedAtEnd).toLocaleString('nl-NL')
        : null,
    ];

    const csvData = [['Filters'], filterHeaders, filterValues, []];

    // Section Data
    const headers = [
      'Code',
      'Type',
      'Organization ID',
      'Org hard link',
      'Duration',
      'Channel',
      'Generated at',
      'Generated by',
      'Distributed at',
      'Distributed by',
      'Distribution note',
      'Environment',
    ];
    csvData.push(headers);

    licenses.forEach((license) => {
      csvData.push([
        license.code,
        '' + license.type,
        license.organizationId,
        '' + license.organizationHardLink,
        (license as StudentLicenseMbo | StudentLicenseVmbo).durationType, // TODO fix type
        license.distributionChannel,
        new Date(license.generatedAt).toLocaleString('nl-NL'),
        license.generatedBy,
        license.distributedAt
          ? new Date(license.distributedAt).toLocaleString('nl-NL')
          : null,
        license.distributedBy,
        license.distributionNote,
        environment.production ? 'PROD' : 'DEV',
      ]);
    });

    const filename = generateLicenseExportFilename(licenses.length, filter);
    downloadCSV(csvData, filename);
  }

  generate() {
    this.generateInProgress = true;
    const form: LicenseOptionsForm = this.licenseOptions.getRawValue();
    this.licenseService
      .generateLicenses({
        count: form.amount,
        type: LicenseType[form.licenseType],
        durationType: form.durationType,
        distributionChannel: form.distributionChannel,
        organizationId: form.organizationId,
        organizationHardLink: form.organizationHardLink,
      })
      .then((licenses) => {
        // Set search form to timestamp of last generated license
        this.licenseSearch.reset();
        this.licenseSearch
          .get('generatedAtStart')
          .setValue(moment.unix(licenses[0].generatedAt / 1000));

        this.licenseSearch
          .get('generatedAtEnd')
          .setValue(
            moment.unix(licenses[licenses.length - 1].generatedAt / 1000)
          );

        this.search();
      })
      .catch((error) => {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'Fout',
            text: error.message,
            preset: DialogPreset.ok,
          },
        });
        console.error(error);
      })
      .finally(() => {
        this.generateInProgress = false;
      });
  }

  search() {
    this.dataSource.loadFirstPage(this.buildFilter());
  }

  displayOrg(organization: Organization): string {
    if (organization) {
      return (
        organization.name +
        (organization.city ? ' (' + organization.city + ')' : '')
      );
    }

    return '';
  }

  selectOrg(query: SearchQuery) {
    this.licenseOptions.get('organizationId').setValue(query.id);
  }

  orgInputChanged(text: string) {
    this.licenseOptions.get('organizationId').setValue(null);
  }

  selectOrgSearch(query: SearchQuery) {
    this.licenseSearch.get('organizationId').setValue(query.id);
  }

  orgInputChangedSearch(text: string) {
    this.licenseSearch.get('organizationId').setValue(null);
  }

  selectUser(query: SearchQuery) {
    if (query.id) {
      this.licenseSearch.get('assignedTo').setValue(query.id);
    }
  }

  userInputChanged(text: string) {
    this.licenseSearch.get('assignedTo')?.setValue(null);
  }

  copyToClipboard(licenseCode: License['code']) {
    navigator.clipboard.writeText(licenseCode);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  clearGeneratedDate() {
    this.licenseSearch.get('generatedAtStart').setValue(null);
    this.licenseSearch.get('generatedAtEnd').setValue(null);
  }

  clearDistributedDate() {
    this.licenseSearch.get('distributedAtStart').setValue(null);
    this.licenseSearch.get('distributedAtEnd').setValue(null);
  }

  private buildFilter(): LicenseFilter {
    return {
      code: this.licenseSearch.value.licenseCode,
      type: LicenseType[this.licenseSearch.value.licenseType],
      durationType: this.licenseSearch.value.durationType,
      organizationId: this.licenseSearch.value.organizationId,
      distributionChannel: this.licenseSearch.value.distributionChannel,
      generatedAtStart: this.licenseSearch.value.generatedAtStart?.valueOf(),
      generatedAtEnd: this.licenseSearch.value.generatedAtEnd
        ?.add(1, 'day')
        .add(-1, 'second')
        ?.valueOf(),
      assignedTo: this.licenseSearch.value.assignedTo,
      distributedAtStart:
        this.licenseSearch.value.distributedAtStart?.valueOf(),
      distributedAtEnd: this.licenseSearch.value.distributedAtEnd
        ?.add(1, 'day')
        .add(-1, 'second')
        ?.valueOf(),
    };
  }
}
