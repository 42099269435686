import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, timer } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { TimeService } from 'src/app/services/time-service.interface';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { CountdownTimer } from './countdown-timer';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  @Input() futureDate: number;
  @Input() warningPeriodMinutes = 6;

  @Output() halfTimeMark = new EventEmitter<CountdownTimer>();
  @Output() timeIsUpMark = new EventEmitter<CountdownTimer>();
  @Output() warningPeriodMark = new EventEmitter<CountdownTimer>();

  countDownTimer$: Observable<CountdownTimer>;

  constructor(@Inject(TIME_SERVICE) private timeService: TimeService) {}

  ngOnInit(): void {
    this.countDownTimer$ = timer(0, 1000).pipe(
      map(() => this.calcDateDiff()),
      shareReplay(1)
    );

    this.countDownTimer$
      .pipe(
        first(),
        switchMap((x) =>
          timer((x.totalSeconds / 2) * 1000).pipe(
            map(() => CountdownTimer.fromSeconds(x.totalSeconds / 2))
          )
        )
      )
      .subscribe((y) => this.halfTimeMark.emit(y));

    this.countDownTimer$
      .pipe(first((t) => t.totalSeconds <= this.warningPeriodMinutes * 60))
      .subscribe((t) => this.warningPeriodMark.emit(t));

    this.countDownTimer$
      .pipe(first((t) => t.expired))
      .subscribe((t) => this.timeIsUpMark.emit(t));
  }

  calcDateDiff = (): CountdownTimer => {
    const countDownDate = this.futureDate;
    const distance = Math.max(0, countDownDate - this.timeService.now());
    return CountdownTimer.fromMilliseconds(distance);
  };
}
