import { UserRole } from '../../shared/enums/user-role.enum';
import {
  isNonEmptyString,
  isNumberGreaterThanZero,
  utility,
} from '../../shared/helpers/utility';
import { ExamInstance } from '../../shared/models/exam-instance';
import {
  ExamSession,
  ExamSessionInteractionAttemptEncrypted,
  ExamSessionNotSubmitted,
  isExamSessionInteractionAttemptEncrypted,
} from '../../shared/models/exam-session';
import { GroupReference } from '../../shared/models/group';
import { Interaction } from '../../shared/models/interaction';
import {
  License,
  StudentLicenseMbo,
  StudentLicenseVmbo,
} from '../../shared/models/license';
import { Organization } from '../../shared/models/organization';
import { DbUser, UserReference } from '../../shared/models/user';

export type EnterLicenseResult = {
  newLicensedAccessUntil: DbUser['licensedAccessUntil'];
  newVmboAccessUntil?: DbUser['vmboAccessUntil'];
  newOrganizationId: Organization['id'] | undefined;
  teacherRole: boolean;
};

export interface ValidationError<T = any> {
  field: Extract<keyof T, string | number>;
  error: string;
}

export type GenerateLicensePayload = {
  count: number;
  type: License['type'];
  distributionChannel: License['distributionChannel'];
  organizationId?: License['organizationId'];
  organizationHardLink?: License['organizationHardLink'];
  durationType?:
    | StudentLicenseMbo['durationType']
    | StudentLicenseVmbo['durationType'];
};

export type MarkLicensesAsDistributedPayload = {
  codes: string[];
  distributionNote: string;
};

export type CreateGroupPayload = {
  name: string;
  organizationId?: string;
};

export type TeacherToUserPayload = {
  teacherUid: DbUser['uid'];
  targetUid?: DbUser['uid'];
};

export type RemoveRoleFromUserPayload = {
  uid: DbUser['uid'];
  role: UserRole;
};

export type CreateExamInstancePayload = Omit<
  ExamInstance,
  'id' | 'examVersion' | 'teacherId' | 'createdAt' | 'organizationId'
>;

export const isCreateExamInstancePayload = (
  payload: CreateExamInstancePayload
): payload is CreateExamInstancePayload =>
  utility.isObject(payload) &&
  isNonEmptyString(payload.examId) &&
  isNonEmptyString(payload.customTitle) &&
  isNonEmptyString(payload.passphrase) &&
  isNonEmptyString(payload.passphrase.trim()) &&
  isNumberGreaterThanZero(payload.openFrom) &&
  isNumberGreaterThanZero(payload.openTo);

// TODO WIP
type GetExamInstanceResultsFilterBase = {
  allTime?: boolean;
};

// TODO WIP
export type GetExamInstanceResultsFilterTeacher =
  GetExamInstanceResultsFilterBase & {
    ownerUid: DbUser['uid'];
  };

// TODO WIP
export type GetExamInstanceResultsFilterOrganization =
  GetExamInstanceResultsFilterBase & {
    organizationId: Organization['id'];
  };

// TODO WIP
export type GetExamInstanceResultsFilter =
  | GetExamInstanceResultsFilterTeacher
  | GetExamInstanceResultsFilterOrganization;

export type SaveExamAnswerPayload = {
  answerContext: {
    examSessionId: ExamSessionNotSubmitted['id'];
    examSectionIndex: number;
    examInteractionIndex: number;
  };
  encryptedInteractionAttempt: ExamSessionInteractionAttemptEncrypted;
};

export const isSaveExamAnswerPayload = (
  payload: SaveExamAnswerPayload
): payload is SaveExamAnswerPayload => {
  if (!utility.isObject(payload)) {
    return false;
  }

  const { answerContext, encryptedInteractionAttempt } = payload;

  if (
    !utility.isObject(answerContext) ||
    !isNonEmptyString(answerContext.examSessionId) ||
    !isFinite(answerContext.examSectionIndex) ||
    !isFinite(answerContext.examInteractionIndex)
  ) {
    return false;
  }

  if (!isExamSessionInteractionAttemptEncrypted(encryptedInteractionAttempt)) {
    return false;
  }

  return true;
};

export type GetExamSessionInteractionVarsShuffledIndexesPayload = {
  examSessionId: ExamSession['id'];
  interactionId: Interaction['id'];
};

export type UserFilterBE = {
  uids?: string[];
  organizationId?: DbUser['organizationId'];
  teacher?: UserReference;
  group?: GroupReference;
  orderBy?: keyof DbUser & ('name' | 'createdDate');
  orderDirection?: 'asc' | 'desc';
};
