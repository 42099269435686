import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { TeacherDashboardActions } from 'src/app/store/actions';
import { AppState } from 'src/app/store/reducers';
import { selectTeacherDashboardFilterState } from 'src/app/store/reducers/teacher-dashboard.reducer';

@Injectable()
export class TeacherDashboardEffects {
  performSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardActions.performSearch),
      withLatestFrom(this.store.select(selectTeacherDashboardFilterState)),
      // tap(([, filters]) => console.log('filters', filters)),
      switchMap(([, filters]) =>
        this.databaseService.getTeacherDashboardTableData(filters).pipe(
          // tap((tableData) => console.log('tableData', tableData)),
          map((results) =>
            TeacherDashboardActions.performSearchSuccess({ results })
          ),
          catchError(() => of(TeacherDashboardActions.performSearchFailure()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private databaseService: DatabaseService,
    private store: Store<AppState>
  ) {}
}
