import { evaluate, format as mathFormat } from 'mathjs';
import { constants } from 'shared/constants';
import { regularExpressions } from 'shared/helpers/regex';

// TODO move to own file
const formatter = (num: number, format: string | null): string => {
  let foundFormat = false;
  if (format?.toLowerCase() === '%d') {
    foundFormat = true;
    return '' + Math.round(num);
  } else if (format?.toLowerCase().includes('f')) {
    foundFormat = true;
    const regex = regularExpressions.floatFormat;
    const decimals = +regex.exec(format)[1];
    return num.toFixed(decimals);
  }

  if (format && !foundFormat) {
    console.error(`Format not recognized: ${format}`);
  }

  return '' + num;
};

export const equationParser = (equationString: string): string => {
  const equationMatch = equationString.match(regularExpressions.equation);
  if (equationMatch) {
    let equation = equationMatch[1];
    const valueString = equationMatch[2];
    const formatMatch = valueString?.match(regularExpressions.equationFormat);
    const decimalComma = valueString?.match(
      regularExpressions.decimalCommaIsTrue
    )
      ? true
      : false;
    const format = formatMatch && formatMatch[1];

    // Math operators, placeholder and Mathjs equivalent
    const operatorPlaceholders: [RegExp, string, string][] = [
      [/Math\.round/g, '≈', 'round'],
      [/Math\.pow/g, '︿', 'pow'],
      [/Math\.PI/g, 'π', 'pi'],
    ];

    operatorPlaceholders.forEach(
      (pair) => (equation = equation.replace(pair[0], pair[1]))
    );

    const variables = equation.match(/([a-zA-Z])/gi);

    let resultEquation = equation;
    variables?.forEach((i) => {
      const replaceExp = new RegExp(i, 'gi');
      const valueExp = new RegExp(i + '=(-?[\\d\\.]+)'); // Optional minus, digits and dots
      const valueMatch = valueString.match(valueExp);
      resultEquation = resultEquation.replace(replaceExp, valueMatch[1]);
    });

    // Replace placeholders with Mathjs equivalent
    operatorPlaceholders.forEach(
      (pair) =>
        (resultEquation = resultEquation.replace(
          new RegExp(pair[1], 'g'),
          pair[2]
        ))
    );

    resultEquation = evaluate(resultEquation);
    resultEquation = mathFormat(resultEquation, {
      precision: constants.mathPrecision,
    });
    resultEquation = '' + formatter(+resultEquation, format);

    if (decimalComma) {
      // deepcode ignore GlobalReplacementRegex: intentional
      resultEquation = resultEquation.replace('.', ',');
    }

    equationString = equationString.replace(
      regularExpressions.equation,
      resultEquation
    );

    return equationParser(equationString);
  }

  return equationString;
};
