import { EntityType } from '../enums/entity-type';
import { constants } from '../misc/constants';

export const findCollection = (entityType: EntityType): string => {
  switch (entityType) {
    case EntityType.project:
      return constants.dbCollections.projects;
    case EntityType.domain:
      return constants.dbCollections.domains;
    case EntityType.chapter:
      return constants.dbCollections.chapters;
    case EntityType.unit:
      return constants.dbCollections.units;
    case EntityType.interaction:
      return constants.dbCollections.interactions;
    default:
      throw new Error('Type not supported for operation: ' + entityType);
  }
};

export const findChildCollectionKey = (
  entityType: EntityType
): string | null => {
  switch (entityType) {
    case EntityType.unit:
    case EntityType.interaction:
      console.error('This method is not suited for type ' + entityType);
      return null;
    default:
      return 'children';
  }
};

export const findChildCollection = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.project:
      return constants.dbCollections.domains;
    case EntityType.domain:
      return constants.dbCollections.chapters;
    case EntityType.chapter:
      return constants.dbCollections.units;
    case EntityType.unit:
      return constants.dbCollections.interactions;
    case EntityType.interaction:
      return null;
    default:
      throw new Error('Type not supported for operation: ' + entityType);
  }
};
